import { CurrencyPipe } from '@angular/common';
import { Component, computed, effect, input } from '@angular/core';

@Component({
  selector: 'app-product-message',
  standalone: true,
  imports: [],
  templateUrl: './product-message.component.html',
  styleUrl: './product-message.component.scss'
})
export class ProductMessageComponent {
  productName = input<string>();
  productPrice = input<number>();
  productImage = input<string>();
  productUrl = input<string>();
  currency = input<string>();

  priceDisplay = computed(() => {
    let price = (this.productPrice() ?? 0) / 100;
    return `${this.currency() === 'GBP' ? '£' : ''}${price.toFixed(2)}`;
  })

  openProduct() {
    window.open(this.productUrl(), '_blank');
  }
}
