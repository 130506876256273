import { AfterViewInit, Component, effect, inject, OnDestroy, ViewChild } from '@angular/core';
import { ChatFloatingTriggerComponent } from "../components/chat-floating-trigger/chat-floating-trigger.component";
import { ChatStore } from 'src/app/stores/chat/chat.store';
import { ChatWindowComponent } from "../components/chat-window/chat-window.component";
import { Overlay, OverlayConfig, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-joii-chat',
  standalone: true,
  imports: [ChatFloatingTriggerComponent, ChatWindowComponent, OverlayModule, PortalModule],
  templateUrl: './joii-chat.component.html',
  styleUrl: './joii-chat.component.scss'
})
export class JoiiChatComponent implements AfterViewInit, OnDestroy {
  // Services.
  readonly chatStore = inject(ChatStore);
  readonly cdkOverlay = inject(Overlay);
  readonly authService = inject(AuthenticationService);

  overlayRef!: OverlayRef;
  unreadMessageCount = this.chatStore.unreadCount;
  originalFavicon: HTMLLinkElement = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  subscription = new Subscription();
    
  @ViewChild(CdkPortal) chatOverlay!: CdkPortal;

  /**
   * Constructor
   * Updates the unread count in the favicon when the unread count changes.
   */
  constructor() {
    effect(() => {
      this.updateFaviconWithUnreadCount(this.unreadMessageCount());
    });
  }
  /**
   * Opens the chat overlay in the CDK portal overlay.
   * This ensures that the chat window will always be above all other content.
   */
  ngAfterViewInit(): void {
    const overlayConfig: OverlayConfig = {
      positionStrategy: this.cdkOverlay.position().global().bottom('0px').right('0px'),
      scrollStrategy: this.cdkOverlay.scrollStrategies.reposition(),
    } 
    const ref = this.cdkOverlay.create(overlayConfig);
    ref.attach(this.chatOverlay);
    this.originalFavicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  }
  /**
   * Unsubscribes from the unread count subscription and detaches the overlay.
   */
  ngOnDestroy(): void {
    this.overlayRef?.detach();
    this.subscription?.unsubscribe();
  }
  /**
   * Draws the favicon with the unread count in the tab.
   * @param unreadCount 
   * @returns {void}
   */
  updateFaviconWithUnreadCount(unreadCount: number): void {
    if (!this.originalFavicon) return;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const img = new Image();
    img.src = this.originalFavicon.href;

    img.onload = () => {
      const size = 64;
      canvas.width = size;
      canvas.height = size;

      if (context) {
        context.drawImage(img, 0, 0, size, size);

        if (unreadCount > 0) {
          context.beginPath();
          context.arc(size - 16, 16, 20, 0, 2 * Math.PI);
          context.fillStyle = 'red';
          context.fill();

          context.font = 'bold 20px Arial';
          context.fillStyle = 'white';
          context.textAlign = 'center';
          context.textBaseline = 'middle';
          context.fillText(unreadCount.toString(), size - 16, 16);
        }

        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = canvas.toDataURL('image/png');
        const currentFavicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;

        document.head.removeChild(currentFavicon);
        document.head.appendChild(newFavicon);
      }
    };
  }
}
