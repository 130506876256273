import { Component, inject, input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inprogress-call-message',
  standalone: true,
  imports: [],
  templateUrl: './inprogress-call-message.component.html',
  styleUrl: './inprogress-call-message.component.scss'
})
export class InprogressCallMessageComponent {
  staffName = input<string>()
  staffImage = input<string>()
  callId = input<string>()
  chatId = input<string>()

  router = inject(Router);

  NavigateToCall() {
    this.router.navigate([`/chatcall/${this.chatId()}/${this.callId()}`]);
  }
}
