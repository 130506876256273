import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { JoiiChatComponent } from 'src/app/joii-chat/joii-chat/joii-chat.component';
import { AuthenticatedStoreProviders } from 'src/app/stores/app-stores';
import { ChatStore } from 'src/app/stores/chat/chat.store';

/**
 * Authenticated container component
 * Wraps authenticated components and services.
 * This component is used to provide stores to all authenticated components.
 * When this component is destroyed, all services will be destroyed.
 */
@Component({
  selector: 'app-authenticated-container',
  standalone: true,
  imports: [RouterOutlet, JoiiChatComponent],
  providers: [...AuthenticatedStoreProviders], // Services will be created and destroyed with this wrapper component.
  templateUrl: './authenticated-container.component.html',
  styleUrl: './authenticated-container.component.scss'
})
export class AuthenticatedContainerComponent {
  chatStore = inject(ChatStore);
}
