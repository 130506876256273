import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { bookingexternal, consultationexternal } from "src/shared/services/client/client"
import { withUser } from "../features/user/user.feature";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { pipe, switchMap, exhaustMap, of } from "rxjs";
import { computed, effect, inject, untracked } from "@angular/core";
import { BookingDataService } from "src/app/services/booking.data.service/booking.data.service";
import { tapResponse } from "@ngrx/operators";
import { first } from "lodash";
import { withAuthStateReset } from "../features/auth-reset/auth-reset.feature";
import { withActivePet } from "../features/active-pet/active-pet.feature";
import { withTreatmentPlanNotifications } from "../features/treatment-plan-notification/treatment-plan-notification";
import { ClientErrorMessage } from "src/shared/services/client/clienterror.interface";
import { TreatmentPlansStore } from "../treatment-plans/treatment-plans.store";
import { MixpanelService } from "src/shared/services/mix-panel.service/mix-panel.service";

export type TreatmentPlan = {
    appointment: bookingexternal.GetAppointmentResponse,
    treatmentPlan: consultationexternal.ConsultationTreatmentPlanResponse
}

type AppointmentsState = {
    appointments: bookingexternal.GetAppointmentResponse[]
    latestUnreadTreatmentPlan: TreatmentPlan | null | undefined,
    refreshInterval: number,
    loadingAppointments: boolean,
    loadingTreatmentPlan: boolean,
    errorAppointments: ClientErrorMessage | undefined,
    errorTreatmentPlan: ClientErrorMessage | undefined,
    initialLoad: boolean,
}

const initialState: AppointmentsState = {
    appointments: [],
    latestUnreadTreatmentPlan: null,
    refreshInterval: 15000,
    loadingAppointments: false,
    loadingTreatmentPlan: false,
    initialLoad: true,
    errorAppointments: undefined,
    errorTreatmentPlan: undefined,
}
/**
 * AppointmentsStore
 * A SignalStore that manages the state of the appointments feature.
 * Handles setting and getting of appointments, treatment plans, and unread treatment plans.
 * Will notify the user when a treatment plan is ready.
 */
export const AppointmentsStore = signalStore(
    withState(initialState),
    withUser(),
    withActivePet(),
    withAuthStateReset<AppointmentsState>(initialState),
    withComputed(({ appointments, activePet }) => ({
        // The next appointment for the active pet.
        nextAppointment: computed(() => {
            return appointments().filter(appointment => {
                const unconfirmedAppointmentsStatuses = ["Reserved", "PaymentRequired"];
                const bookedAppointmentsStatuses = ["Booked", "Waiting"];
                const inConsultationStatus = ["Ready", "InProgress"];
                const completedStatus = ["Complete", "Abandoned", "Cancelled", "Disrupted"];

                // Appointments should only show for the current pet.
                if (appointment.pet_id !== activePet()?.doc_id) {
                    return false;
                }

                // The appointment should not be completed.
                if (completedStatus.includes(appointment.consult_status || '') || completedStatus.includes(appointment.status)) {
                    return false;
                }

                // When the appointment is booked or waiting it should stay active until completed.
                if (bookedAppointmentsStatuses.includes(appointment.status) || unconfirmedAppointmentsStatuses.includes(appointment.status) || inConsultationStatus.includes(appointment.consult_status || '')) {
                    return true;
                }

                return false;
            }).sort((a, b) => {
                const statusPriority: { [key: string]: number } = { "Waiting": 1, "Booked": 2, "Reserved": 3, "PaymentRequired": 4, };
                const statusA = statusPriority[a.status] || 5;
                const statusB = statusPriority[b.status] || 5;
                if (statusA !== statusB) {
                    return statusA - statusB;
                }
                return new Date(a.start.utc).getTime() - new Date(b.start.utc).getTime();
            })[0];
        }),
        // Appointments with treatment plans that are ready for the active pet.
        appointmentsWithTreatmentPlan: computed(() => {
            return appointments().filter(appointment => appointment.treatment_plan_ready === true && appointment.pet_id === activePet()?.doc_id).sort((a, b) => new Date(b.start.utc).getTime() - new Date(a.start.utc).getTime());
        }),
        // Appointments that are unconfirmed and require payment or confirmation for the active pet.
        unconfirmedAppointments: computed(() => {
            const validStatuses = ["Reserved", "PaymentRequired"];
            return appointments().filter(appointment => validStatuses.includes(appointment.status) && appointment.pet_id === activePet()?.doc_id);
        })
    })),
    withMethods((
        store,
        bookingService = inject(BookingDataService),
        treatmentPlanStore = inject(TreatmentPlansStore),
        mixPanelService = inject(MixpanelService)) => {
        /**
         * Sets the appointments for the user.
         * Will set the loading state to true while fetching appointments.
         */
        const setMyAppointments = rxMethod<void>(
            pipe(
                exhaustMap(() => {
                    patchState(store, { loadingAppointments: true, errorAppointments: undefined });
                    return bookingService.ListMyAppointments({ Desc: true, RequestedItemsPerPage: 50, RequestedPageNumber: 1 }).pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { appointments: response.appointments });
                            },
                            error: (error: any) => {
                                patchState(store, { appointments: [], errorAppointments: error });
                                console.error(error)
                            },
                            finalize: () => {
                                patchState(store, { loadingAppointments: false, initialLoad: false });
                            }
                        })
                    )
                })
            )
        )
        /**
         * Cancels an appointment.
         * Will set the appointment status to 'Abandoned' if the appointment requires payment or is reserved.
         */
        const cancelAppointment = rxMethod<bookingexternal.GetAppointmentResponse>(
            pipe(
                exhaustMap((appointment) => {
                    const status = (appointment.status === 'PaymentRequired' || appointment.status === 'Reserved') ? 'Abandoned' : 'Cancelled';
                    return bookingService.UpdateAppointmentStatus(appointment.doc_id, { status: status }).pipe(
                        tapResponse({
                            next: () => {
                                setMyAppointments();
                            },
                            error: (error) => {
                                console.error(error);
                            }
                        })
                    )
                })
            )
        )
        /**
         * Sets the latest unread treatment plan for the user.
         */
        const setUnreadTreatmentPlan = rxMethod<bookingexternal.GetAppointmentResponse[]>(
            pipe(
                switchMap((appointments) => {
                    const latestAppointment = first(appointments);
                    if (!latestAppointment) {
                        patchState(store, { latestUnreadTreatmentPlan: null, errorTreatmentPlan: undefined });
                        return of(null);
                    }
                    patchState(store, { loadingTreatmentPlan: true, errorTreatmentPlan: undefined });
                    return bookingService.GetTreatmentPlan(latestAppointment.doc_id).pipe(
                        tapResponse({
                            next: (response) => {
                                if (!response?.is_treatment_plan_read) {
                                    patchState(store, { latestUnreadTreatmentPlan: { appointment: latestAppointment, treatmentPlan: response } });
                                    return;
                                }
                                patchState(store, { latestUnreadTreatmentPlan: null });
                            },
                            error: (error: any) => {
                                patchState(store, { errorTreatmentPlan: error });
                                console.error(error);
                            },
                            finalize: () => {
                                patchState(store, { loadingTreatmentPlan: false, initialLoad: false });
                            }
                        })
                    )
                })
            )
        );
        /**
         * Marks the treatment plan as read.
         * Will update the unread treatment plan state.
         */
        const markTreatmentPlanAsRead = rxMethod<TreatmentPlanReadRequest>(
            pipe(
                exhaustMap((request) => {
                    return bookingService.MarkTreatmentPlanAsRead(request.appointment.doc_id).pipe(
                        tapResponse({
                            next: () => {
                                treatmentPlanStore.setUnreadCount();
                                setUnreadTreatmentPlan(store.appointmentsWithTreatmentPlan());
                                mixPanelService.track('treatmentplan.read', { appointment_doc_id: request.appointment.doc_id, pet_doc_id: request.appointment.pet_id, user_doc_id: request.userId });
                            },
                            error: (error) => {
                                console.error(error);
                            }
                        })
                    )
                })
            )
        );

        return { setMyAppointments, setUnreadTreatmentPlan, cancelAppointment, markTreatmentPlanAsRead }
    }),
    withHooks({
        onInit(store) {
            // Set the user's appointments and unread treatment plans on initialization.
            effect(() => {
                const user = store.user();
                if (user !== null) {
                    store.setMyAppointments();
                }
            }, { allowSignalWrites: true })
            // Will update the unread treatment plan when appointments change.
            effect(() => {
                const appointmentsWithTreatmentPlan = store.appointmentsWithTreatmentPlan();
                untracked(() => {
                    if (appointmentsWithTreatmentPlan.length === 0) {
                        return;
                    }
                    store.setUnreadTreatmentPlan(appointmentsWithTreatmentPlan);
                });
            }, { allowSignalWrites: true });
        }
    })
);
export interface TreatmentPlanReadRequest {
    appointment: bookingexternal.GetAppointmentResponse
    userId: string
}