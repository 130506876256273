import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, signal, Signal, WritableSignal } from '@angular/core';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { JoiiHeader, JoiiHeaderEnvironments, JoiiHeaderItem, JoiiHeaderItemType } from './joii-header.interface';
import { getAuth } from '@angular/fire/auth';
import { Tenant } from 'src/environments/evironment.interface';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrazeService } from 'src/app/services/braze.service/braze.service';
import { Subscription, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { NgClass, NgStyle } from '@angular/common';
import { BrazeContentCardFeedComponent } from '../../braze/brazecontentcardsfeed/brazecontentcardfeed.component';
import { NgIconsModule } from '@ng-icons/core';
import { RestartService } from 'src/shared/services/restart/restart.service';
import { LanguageDropdownComponent } from "../../language-dropdown/language-dropdown.component";
import { FormsModule } from '@angular/forms';
import { CallPipe } from 'ngxtension/call-apply';
import { MixpanelService } from 'src/shared/services/mix-panel.service/mix-panel.service';

@Component({
  selector: 'app-joii-header',
  templateUrl: './joii-header.component.html',
  styleUrls: ['./joii-header.component.scss'],
  standalone: true,
  imports: [ProgressBarComponent, NgStyle, NgClass, BrazeContentCardFeedComponent, NgIconsModule, LanguageDropdownComponent, FormsModule, CallPipe]
})
export class JoiiHeaderComponent implements OnInit, OnDestroy {
  // Services
  restartService = inject(RestartService);

  @Input() config: JoiiHeader = {
    defaultHeaderPaths: [],
    items: [],
    logo: ''
  };
  @Input() showNotificationBell: boolean = false;
  showNotificationRedDot: boolean = false;
  showNotificationDropdown: boolean = false;
  showNotificationNoMessages: boolean = false;
  showLanguageDropdown: Signal<boolean> = signal(false);
  showDefault: boolean = false;
  showSignInButtons: boolean = false;
  showMenu: boolean = false;
  showProggressBar: boolean = false;
  isUserLoggedIn: boolean = false;
  progressPercentage: string = '0%';
  activeButtonIndexes: number[] = [-1];
  tenant?: Tenant;
  route: string = '';
  isDropdownOpen: { [key: string]: boolean } = {};
  showDownloadApp: boolean = false;
  isProduction!: boolean;
  languageCode: string = 'en';
  contentCardsSubscription: Subscription = new Subscription();
  @Output() clickedmenuitem = new EventEmitter<JoiiHeaderItem>();
  mixPanelService = inject(MixpanelService);
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    protected activtatedRoute: ActivatedRoute,
    private brazeService: BrazeService) { }

  ngOnInit(): void {
    this.languageCode = this.getLanguage();
    this.isProduction = environment.environment == 'production';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showMenu = false;
      } else if (event instanceof NavigationEnd) {
        this.showDefault = this.isPathDefault(event.url);
        this.showProggressBar = this.shouldShowProgressBar(event.url);
        this.isUserLoggedIn = this.authenticationService.isLoggedIn();
        this.showSignInButtons = this.shouldSignInButtonsBeShown(event.url);
        this.showLanguageDropdown = this.authenticationService.isStaff;
        this.route = event.url;
        this.markActiveButtons(event);
      }
      this.tenant = this.authenticationService.getActiveTenant();
    });

    this.checkForNotificationRedDot();
    this.checkForNotificationNoMessages();
  }
  ngOnDestroy(): void {
    this.contentCardsSubscription.unsubscribe();
  }

  toggle() {
    this.showMenu = !this.showMenu;
  }

  getLanguage() {
    return localStorage.getItem('lang') || 'en';
  }

  onLanguageChange(code: string) {
    localStorage.setItem('lang', code);
    this.languageCode = code;
    this.restartService.restartApp();
  }

  isPathDefault(path: string): boolean {
    return this.config.defaultHeaderPaths.some(defaultHeaderPath => {
      const wildcardIndex = defaultHeaderPath.path.indexOf('*');
      if (wildcardIndex === -1) {
        return path === defaultHeaderPath.path;
      } else {
        const pathStart = defaultHeaderPath.path.substring(0, wildcardIndex);
        return path.startsWith(pathStart);
      }
    }) || window.location.href.includes("notfound");
  }

  shouldSignInButtonsBeShown(path: string): boolean {
    return path == '/'
  }

  shouldShowProgressBar(path: string): boolean {
    if (this.config.defaultHeaderPaths.some(defaultHeaderPath => defaultHeaderPath.path == path)) {
      let defaultHeader = this.config.defaultHeaderPaths.find(defaultHeaderPath => defaultHeaderPath.path == path);
      if (defaultHeader && defaultHeader?.progress > 0 && defaultHeader?.progress <= 100) {
        this.progressPercentage = defaultHeader.progress + '%'
        return true
      }
    }
    return false
  }

  register() {
    this.router.navigate(['/tenants/register']);
  }

  logoClick() {
    this.router.navigate(['/']);
  }

  login() {
    this.router.navigate(['/tenants/login']);
  }

  menuItemClicked(menuItem: JoiiHeaderItem, index: number) {
    if (menuItem.type === JoiiHeaderItemType.Logout) {
      this.authenticationService.logout().finally(() => {
        this.router.navigate(['/']);
      });
    } else if (menuItem.type === JoiiHeaderItemType.Register) {
      this.router.navigate(['/tenants/register']);
    } else if (menuItem.type === JoiiHeaderItemType.Callback) {
      this.clickedmenuitem.emit(menuItem);
    } else if (menuItem.type === JoiiHeaderItemType.Path) {
      this.resetActiveButtonIndexes()
      if (menuItem.title === 'Call a Vet' && menuItem.state === 'mobile') {
        this.showDownloadApp = true;
      } else {
        this.router.navigate([menuItem.id], {
          queryParams: menuItem.queryParams
        });
      }
      this.resetActiveButtonIndexes();
    } else if (menuItem.type === JoiiHeaderItemType.Dropdown) {
      this.toggleDropdown(menuItem.id);
    } else if (menuItem.type === JoiiHeaderItemType.Link) {
      window.open(menuItem.id)
    } else if (menuItem.type === JoiiHeaderItemType.TokenCopier) {
      async function getToken() {
        const currentUser = getAuth()?.currentUser;
        let token = "";
        if (currentUser) {
          const idTokenResult = await currentUser.getIdTokenResult(false);
          if (idTokenResult) {
            token = idTokenResult.token;
          }
        }
        try {
          await navigator.clipboard.writeText(JSON.stringify({
            "Authorization": token,
            "Accept-Language": "en",
          }));
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      }

      getToken();
    };

  }
  setActiveButtonIndex(index: number): void {
    this.activeButtonIndexes.push(index);
  }
  resetActiveButtonIndexes(): void {
    this.activeButtonIndexes = [];
  }
  isActiveButtonIndex(index: number): boolean {
    return this.activeButtonIndexes.includes(index);
  }

  toggleDropdown(id: string): void {
    if (this.isDropdownOpen[id] === undefined) {
      this.isDropdownOpen[id] = false;
    }
    this.isDropdownOpen[id] = !this.isDropdownOpen[id];
  }
  markActiveButtons(event: NavigationEnd): void {
    this.resetActiveButtonIndexes()
    let items = this.config.items.filter((item) => event.url.startsWith(item.id));
    if (items && items.length > 0) {
      items.map((item) => {
        if (item.type == JoiiHeaderItemType.Dropdown) {
          let subItem = item.subItems?.find((item) => event.url.startsWith(item.id));
          if (subItem) {
            if (!this.isDropdownOpen[item.id]) {
              this.toggleDropdown(item.id)
            }
            this.setActiveButtonIndex(subItem.index)
          }
        }
        else {
          this.setActiveButtonIndex(item.index)
        }
      })
    }
  }

  toggleNotificationBell(): void {
    if (this.showNotificationDropdown) {
      this.showNotificationRedDot = false;
    } else {
      this.mixPanelService.track('inbox');
    }
    this.showNotificationDropdown = !this.showNotificationDropdown;

  }

  checkForNotificationNoMessages(): void {
    this.showNotificationNoMessages = this.brazeService.isContentCardsEmpty('inbox_feed');
  }

  checkForNotificationRedDot(): void {
    this.showNotificationRedDot = this.brazeService.unreadContentCards('inbox_feed');
  }

  handleIsMobile(): void {
    this.showDownloadApp = false;
    this.router.navigate(['vetcall/book']);
  }

  showDownloadAppModal(): void {
    this.showDownloadApp = true;
  }
}
