import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-ended-call-message',
  standalone: true,
  imports: [],
  templateUrl: './ended-call-message.component.html',
  styleUrl: './ended-call-message.component.scss'
})
export class EndedCallMessageComponent {
  staffName = input<string>()
  staffImage = input<string>()
  duration = input<number>(0)
}
