// Code generated by the Encore v1.46.16 client generator. DO NOT EDIT.

// Disable eslint, jshint, and jslint for this file.
/* eslint-disable */
/* jshint ignore:start */
/*jslint-disable*/

/**
 * BaseURL is the base URL for calling the Encore application's API.
 */
export type BaseURL = string

export const Local: BaseURL = "http://localhost:4000"

/**
 * Environment returns a BaseURL for calling the cloud environment with the given name.
 */
export function Environment(name: string): BaseURL {
    return `https://${name}-core-6p22.encr.app`
}

/**
 * PreviewEnv returns a BaseURL for calling the preview environment with the given PR number.
 */
export function PreviewEnv(pr: number | string): BaseURL {
    return Environment(`pr${pr}`)
}

/**
 * Client is an API client for the core-6p22 Encore application.
 */
export default class Client {
    public readonly accountexternal: accountexternal.ServiceClient
    public readonly accountinternal: accountinternal.ServiceClient
    public readonly bookingexternal: bookingexternal.ServiceClient
    public readonly bookinginternal: bookinginternal.ServiceClient
    public readonly consultationexternal: consultationexternal.ServiceClient
    public readonly consultationinternal: consultationinternal.ServiceClient
    public readonly crmexternal: crmexternal.ServiceClient
    public readonly prodexternal: prodexternal.ServiceClient
    public readonly prodinternal: prodinternal.ServiceClient
    public readonly reportingexternal: reportingexternal.ServiceClient
    public readonly scexternal: scexternal.ServiceClient
    public readonly scinternal: scinternal.ServiceClient
    public readonly seeding: seeding.ServiceClient
    public readonly trialmanagementexternal: trialmanagementexternal.ServiceClient
    public readonly trialmanagementinternal: trialmanagementinternal.ServiceClient
    public readonly usersubmission: usersubmission.ServiceClient
    public readonly venomcodeinternal: venomcodeinternal.ServiceClient
    public readonly vetaichatexternal: vetaichatexternal.ServiceClient
    public readonly vetaichatinternal: vetaichatinternal.ServiceClient


    /**
     * Creates a Client for calling the public and authenticated APIs of your Encore application.
     *
     * @param target  The target which the client should be configured to use. See Local and Environment for options.
     * @param options Options for the client
     */
    constructor(target: BaseURL, options?: ClientOptions) {
        const base = new BaseClient(target, options ?? {})
        this.accountexternal = new accountexternal.ServiceClient(base)
        this.accountinternal = new accountinternal.ServiceClient(base)
        this.bookingexternal = new bookingexternal.ServiceClient(base)
        this.bookinginternal = new bookinginternal.ServiceClient(base)
        this.consultationexternal = new consultationexternal.ServiceClient(base)
        this.consultationinternal = new consultationinternal.ServiceClient(base)
        this.crmexternal = new crmexternal.ServiceClient(base)
        this.prodexternal = new prodexternal.ServiceClient(base)
        this.prodinternal = new prodinternal.ServiceClient(base)
        this.reportingexternal = new reportingexternal.ServiceClient(base)
        this.scexternal = new scexternal.ServiceClient(base)
        this.scinternal = new scinternal.ServiceClient(base)
        this.seeding = new seeding.ServiceClient(base)
        this.trialmanagementexternal = new trialmanagementexternal.ServiceClient(base)
        this.trialmanagementinternal = new trialmanagementinternal.ServiceClient(base)
        this.usersubmission = new usersubmission.ServiceClient(base)
        this.venomcodeinternal = new venomcodeinternal.ServiceClient(base)
        this.vetaichatexternal = new vetaichatexternal.ServiceClient(base)
        this.vetaichatinternal = new vetaichatinternal.ServiceClient(base)
    }
}

/**
 * ClientOptions allows you to override any default behaviour within the generated Encore client.
 */
export interface ClientOptions {
    /**
     * By default the client will use the inbuilt fetch function for making the API requests.
     * however you can override it with your own implementation here if you want to run custom
     * code on each API request made or response received.
     */
    fetcher?: Fetcher

    /** Default RequestInit to be used for the client */
    requestInit?: Omit<RequestInit, "headers"> & { headers?: Record<string, string> }

    /**
     * Allows you to set the authentication data to be used for each
     * request either by passing in a static object or by passing in
     * a function which returns a new object for each request.
     */
    auth?: accountinternal.MyAuthParams | AuthDataGenerator
}

export namespace accountexternal {
    export interface AddressDetailsResponse {
        postcode: string
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
    }

    export interface AddressSuggestion {
        address: string
        id: string
    }

    export interface AddressSuggestionsRequest {
        SearchTerm: string
    }

    export interface AddressSuggestionsResponse {
        suggestions: AddressSuggestion[]
    }

    export interface CountryResponse {
        countries: string[]
    }

    export interface CreateAddressRequest {
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
        postcode: string
    }

    export interface CreatePetRequest {
        /**
         * Name
         * The name of the pet.
         */
        name: string

        /**
         * Gender
         * The gender of the pet.
         * 
         * Accepted values are:  **Male**, **Female**, **Unknown**.
         */
        gender: string

        /**
         * Weight
         * The weight of the pet.
         */
        weight?: entities.WeightType

        /**
         * DateOfBirth
         * The pet date of birth.
         * 
         * Accepted date format: **YYYY-MM-DD**.
         */
        "date_of_birth": string

        /**
         * Neutered
         * Indicates if the pet has been neutered.
         */
        neutered?: boolean

        /**
         * SpeciesID
         * The **doc_id** of the species associated with the pet in **UUID** format.
         */
        "species_doc_id": string

        /**
         * BreedDocID
         * The **doc_id** of the breed associated with the pet in **UUID** format.
         */
        "breed_doc_id": string
    }

    export interface CreateUserRequest {
        /**
         * Email
         * The email address of the user.
         */
        email: string

        /**
         * FirstName
         * The first name of the user.
         * 
         * Name must be between 1 and 100 characters and must not contain numbers.
         */
        "first_name": string

        /**
         * LastName
         * The last name of the user.
         * 
         * Name must be between 1 and 100 characters and must not contain numbers.
         */
        "last_name": string

        /**
         * PhoneNumber
         * The user's phone number.
         * It should be a valid number for the selected **country_code**.
         */
        "phone_number": string

        /**
         * CountryCode
         * The country code of the user in **ISO 3166-1** format (e.g. **GB**).
         * Phone number can contain **(**, **)**, **-** and spaces.
         */
        "country_code": string

        /**
         * PostCode
         * The post code of the user.
         * 
         * Post code can contain only letters, numbers, hyphens and spaces.
         */
        "post_code": string

        /**
         * Terms
         * Indicates if the user has accepted the terms and conditions.
         */
        terms: boolean
    }

    export interface CreateUserResponse {
        /**
         * DocID
         * The **doc_id** of the new user in **UUID** format.
         */
        "doc_id": string
    }

    export interface CreateWeightRequest {
        /**
         * Weight
         * The pet's weight object. Accepted values: **kg**, **g**, **lbs**.
         */
        weight: entities.WeightType

        /**
         * MeasuredAt
         * **measured_at** in UTC time format (e.g. **2017-09-04T04:00:00Z**)
         */
        "measured_at"?: string
    }

    export interface DeletePetRequest {
        ReasonID?: string
    }

    export interface GetAddressResponse {
        "user_doc_id": uuid.UUID
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
        postcode: string
    }

    export interface GetBreedsQueryParams {
        /**
         * The **doc_id** of the species in **UUID** format.
         */
        SpeciesDocID: string
    }

    export interface GetPetDeletionReasonResponse {
        id: number
        name: string
    }

    export interface GetSpeciesResponse {
        species: GetSpeciesResponseItem[]
    }

    export interface GetSpeciesResponseItem {
        /**
         * DocID
         * The **doc_id** of the species in **UUID** format.
         */
        "doc_id": string

        /**
         * Name
         * The name of the species.
         */
        name: string
    }

    export interface GetUserResponse {
        /**
         * DocID
         * The **doc_id** of the user in **UUID** format.
         */
        "doc_id": string

        /**
         * Email
         * The email address of the user.
         */
        email: string

        /**
         * FirstName
         * The first name of the user.
         */
        "first_name": string

        /**
         * LastName
         * The last name of the user.
         */
        "last_name": string

        /**
         * CreatedAt
         * **created_at** in UTC time format (e.g. **2017-09-04T04:00:00Z**)
         */
        "created_at": string

        /**
         * PhoneNumber
         * The user's phone number.
         */
        "phone_number": string

        /**
         * CountryCode
         * The country code of the user in **ISO 3166-1** format (e.g. **GB**).
         */
        "country_code": string

        /**
         * CurrentPolicyCustomerNo
         * The policy number of the user.
         */
        "current_policy_customer_no"?: string

        /**
         * RequiresVerification
         * Indicates if user's policy data has been verified by the user
         * after it has been retrieved from an insurer.
         */
        "requires_verification": boolean

        /**
         * PostCode
         * The post code of the user.
         */
        "post_code": string

        /**
         * TrialActive
         * Indicates if the user has an active trial.
         */
        "trial_active": boolean

        /**
         * DeletionDate
         * The date and time when the user will be deleted
         */
        "deletion_date": string
    }

    export interface ListPetDeletionReasonsResponse {
        reasons: PetDeletionReasons[]
    }

    export interface PatchPetPhotoRequest {
        /**
         * PhotoUrl
         * The location of the pet photo.
         * 
         * Accepted format: **folder_name/file_name.png**
         * 
         * Accepted file extendions: **.jpg**, **.jpeg**, **.png**, **.gif**, **.bmp**, **.svg**, **.tif**, **.tiff**, **.webp**, **.heic**
         */
        "photo_url": string
    }

    export interface PetDeletionReasons {
        id: number
        name: string
    }

    export interface PetPracticeRequest {
        "google_place_id": string
        name: string
        address: string
        long: string
        lat: string
        "phone_number"?: string
        email?: string
    }

    export interface PetPracticeResponse {
        "google_place_id": string
        name: string
        address: string
        long: string
        lat: string
        "phone_number": string
        email: string
    }

    export interface SyncPetsRequest {
    }

    export interface SyncPetsResponse {
    }

    export interface UpdateAddressRequest {
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
        postcode: string
    }

    export interface UpdatePetRequest {
        /**
         * Name
         * The name of the pet.
         */
        name: string

        /**
         * Gender
         * The gender of the pet.
         * 
         * Accepted values are:  **Male**, **Female**, **Unknown**.
         */
        gender: string

        /**
         * Weight
         * The weight of the pet.
         */
        weight?: entities.WeightType

        /**
         * DateOfBirth
         * The pet date of birth.
         * 
         * Accepted date format: **YYYY-MM-DD**.
         */
        "date_of_birth": string

        /**
         * Neutered
         * Indicates if the pet has been neutered.
         */
        neutered?: boolean

        /**
         * BreedDocID
         * The **doc_id** of the breed associated with the pet in **UUID** format.
         */
        "breed_doc_id": string
    }

    export interface UpdateUserRequest {
        /**
         * Email
         * The email address of the user.
         */
        email: string

        /**
         * FirstName
         * The first name of the user.
         * 
         * Name must be between 1 and 100 characters and must not contain numbers.
         */
        "first_name": string

        /**
         * LastName
         * The last name of the user.
         * 
         * Name must be between 1 and 100 characters and must not contain numbers.
         */
        "last_name": string

        /**
         * PhoneNumber
         * The user's phone number.
         * It should be a valid number for the selected **country_code**.
         * Phone number can contain **(**, **)**, **-** and spaces.
         */
        "phone_number": string

        /**
         * CountryCode
         * The country code of the user in **ISO 3166-1** format (e.g. **GB**).
         */
        "country_code": string

        /**
         * PostCode
         * The post code of the user.
         * 
         * Post code can contain only letters, numbers, hyphens and spaces.
         */
        "post_code": string
    }

    export interface UpdateUserResponse {
        /**
         * DocID
         * The **doc_id** of the user in **UUID** format.
         */
        "doc_id": string

        /**
         * Email
         * The email address of the user.
         */
        email: string

        /**
         * FirstName
         * The first name of the user.
         */
        "first_name": string

        /**
         * LastName
         * The first name of the user.
         */
        "last_name": string

        /**
         * PhoneNumber
         * The user's phone number containing the country code (e.g. +441204151617).
         */
        "phone_number": string

        /**
         * PostCode
         * The post code of the user.
         */
        "post_code": string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Undelete User
         * This endpoint unsets the deletion request for a user.
         */
        public async CancelDeleteUser(user_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/users/${encodeURIComponent(user_doc_id)}/cancel-delete`)
        }

        /**
         * Create New Address
         * This endpoint creates a new address for an existing user with the provided body parameter values.
         */
        public async CreateAddress(user_doc_id: string, params: CreateAddressRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/address`, JSON.stringify(params))
        }

        /**
         * Create New Pet
         * This endpoint creates a new pet for an existing user with the provided body parameter values.
         */
        public async CreatePet(user_doc_id: string, params: CreatePetRequest): Promise<entities.CreatePetResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/pets`, JSON.stringify(params))
            return await resp.json() as entities.CreatePetResponse
        }

        /**
         * Create New User
         * This endpoint creates a new user with the provided body parameter values.
         */
        public async CreateUser(params: CreateUserRequest): Promise<CreateUserResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users`, JSON.stringify(params))
            return await resp.json() as CreateUserResponse
        }

        /**
         * Create New Pet Weight
         * This endpoint creates a new stored weight for a pet.
         */
        public async CreateWeight(user_doc_id: string, pet_doc_id: string, params: CreateWeightRequest): Promise<entities.CreateWeightResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}/weights`, JSON.stringify(params))
            return await resp.json() as entities.CreateWeightResponse
        }

        /**
         * Delete Address
         * This endpoint deletes an existing address.
         */
        public async DeleteAddress(user_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/users/${encodeURIComponent(user_doc_id)}/address`)
        }

        /**
         * Delete Pet
         * This endpoint soft deletes an existing pet for an existing user.
         */
        public async DeletePet(user_doc_id: string, pet_doc_id: string, params: DeletePetRequest): Promise<void> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                reasonid: params.ReasonID,
            })

            await this.baseClient.callTypedAPI("DELETE", `/v1/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}`, undefined, {query})
        }

        /**
         * Delete User
         * This endpoint soft deletes a user if the user exists.
         */
        public async DeleteUser(user_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/users/${encodeURIComponent(user_doc_id)}`)
        }

        /**
         * Get Address
         * This endpoint retrieves an existing address.
         */
        public async GetAddress(user_doc_id: string): Promise<GetAddressResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/address`)
            return await resp.json() as GetAddressResponse
        }

        /**
         * Get Address Details
         * This endpoint returns the full address by ID.
         */
        public async GetAddressDetails(id: string): Promise<AddressDetailsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/address/details/${encodeURIComponent(id)}`)
            return await resp.json() as AddressDetailsResponse
        }

        /**
         * Get Address Suggestions
         * This endpoint suggests partial address results for a given term.
         */
        public async GetAddressSuggestions(params: AddressSuggestionsRequest): Promise<AddressSuggestionsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                term: params.SearchTerm,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/address/suggestions`, undefined, {query})
            return await resp.json() as AddressSuggestionsResponse
        }

        /**
         * Get Pet
         * This endpoint retrieves a given pet for a given user.
         */
        public async GetPet(user_doc_id: string, pet_doc_id: string): Promise<entities.PetResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}`)
            return await resp.json() as entities.PetResponse
        }

        /**
         * Get Pet deletion reason by ID
         * This endpoint takes an ID of a pet deletion reason, and returns the name of the reason
         */
        public async GetPetDeletionReasonById(id: string): Promise<GetPetDeletionReasonResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/petdeletionreason/${encodeURIComponent(id)}`)
            return await resp.json() as GetPetDeletionReasonResponse
        }

        /**
         * Get Pet Practice
         * This endpoint retrieves pet's practice if it has one or returns null.
         */
        public async GetPetPractice(user_doc_id: string, pet_doc_id: string): Promise<PetPracticeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/pet/${encodeURIComponent(pet_doc_id)}/practice`)
            return await resp.json() as PetPracticeResponse
        }

        /**
         * Get User
         * This endpoint retrieves user's data if the user exists.
         */
        public async GetUser(user_doc_id: string): Promise<GetUserResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}`)
            return await resp.json() as GetUserResponse
        }

        /**
         * Get User By Policy Number
         * Retrieves user details by policy number. It returns the same details as the GetUser request.
         */
        public async GetUserByPolicyNumber(policy_number: string): Promise<GetUserResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(policy_number)}/by-policy-number`)
            return await resp.json() as GetUserResponse
        }

        /**
         * ListBreeds
         * This endpoint retrieves a list of breeds for the requested species.
         * The breeds are supported in multiple languages ( e.g. English, German).
         * If no language is provided in the authentication data, the default language is used, which is English.
         */
        public async ListBreeds(params: GetBreedsQueryParams): Promise<entities.GetBreedsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "species_doc_id": params.SpeciesDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/breeds`, undefined, {query})
            return await resp.json() as entities.GetBreedsResponse
        }

        /**
         * List Countries
         * This endpoint returns list of valid countries
         */
        public async ListCountries(): Promise<CountryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/countries`)
            return await resp.json() as CountryResponse
        }

        /**
         * List Pet deletion reasons
         * This endpoint returns all pet deletion reasons
         */
        public async ListPetDeletionReasons(): Promise<ListPetDeletionReasonsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/petdeletionreason`)
            return await resp.json() as ListPetDeletionReasonsResponse
        }

        /**
         * List Pets
         * This endpoint retrieves a list of all pets for a given user.
         */
        public async ListPets(user_doc_id: string): Promise<entities.ListPetsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/pets`)
            return await resp.json() as entities.ListPetsResponse
        }

        /**
         * List Species
         * This endpoint retrieves a list of species.
         * Species are supported in both English and German.
         * If no language is provided in the authentication data, the default language is used, which is English.
         */
        public async ListSpecies(): Promise<GetSpeciesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/species`)
            return await resp.json() as GetSpeciesResponse
        }

        /**
         * List Stored Pet Weights
         * This endpoint retrieves all stored pet weights for a pet.
         */
        public async ListWeights(user_doc_id: string, pet_doc_id: string): Promise<entities.ListWeightsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}/weights`)
            return await resp.json() as entities.ListWeightsResponse
        }

        /**
         * Update Pet With Photo
         * This endpoint updates an existing pet's photo.
         */
        public async PatchPetPhoto(user_doc_id: string, pet_doc_id: string, params: PatchPetPhotoRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}/photo`, JSON.stringify(params))
        }

        /**
         * Sync Pets
         * This endpoint syncs pets and their policies with the insurer for a given user.
         */
        public async SyncPets(user_doc_id: string, params: SyncPetsRequest): Promise<SyncPetsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/sync`)
            return await resp.json() as SyncPetsResponse
        }

        /**
         * Update Address
         * This endpoint updates an existing address with the provided body parameter values.
         */
        public async UpdateAddress(user_doc_id: string, params: UpdateAddressRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/users/${encodeURIComponent(user_doc_id)}/address`, JSON.stringify(params))
        }

        /**
         * Update Pet
         * This endpoint updates a given pet for a given user with the provided body parameter values.
         */
        public async UpdatePet(user_doc_id: string, pet_doc_id: string, params: UpdatePetRequest): Promise<entities.UpdatePetResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}`, JSON.stringify(params))
            return await resp.json() as entities.UpdatePetResponse
        }

        /**
         * Update Pet Practice
         * This endpoint retrieves updates the pets practice.
         */
        public async UpdatePetPractice(user_doc_id: string, pet_doc_id: string, params: PetPracticeRequest): Promise<PetPracticeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/pet/${encodeURIComponent(pet_doc_id)}/practice`, JSON.stringify(params))
            return await resp.json() as PetPracticeResponse
        }

        /**
         * Update User
         * This endpoint updates user's data if the user exists.
         */
        public async UpdateUser(user_doc_id: string, params: UpdateUserRequest): Promise<UpdateUserResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/users/${encodeURIComponent(user_doc_id)}`, JSON.stringify(params))
            return await resp.json() as UpdateUserResponse
        }
    }
}

export namespace accountinternal {
    export interface AFIDocuments {
        url: string
    }

    export interface AFIPolicyAddress {
        building: string
        postcode: string
        street: string
        town: string
    }

    export interface Address {
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
        postcode: string
    }

    export interface AddressDetailsResponse {
        postcode: string
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
    }

    export interface AddressSuggestion {
        address: string
        id: string
    }

    export interface AddressSuggestionsRequest {
        SearchTerm: string
    }

    export interface AddressSuggestionsResponse {
        suggestions: AddressSuggestion[]
    }

    export interface CountryResponse {
        countries: string[]
    }

    export interface CreateAPIKeyRequest {
        "business_id": string
    }

    export interface CreateAPIKeyResponse {
        key: string
    }

    export interface CreateAddressRequest {
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
        postcode: string
    }

    export interface CreateBodyConditionScoreRequest {
        score: number
        "measured_at"?: string
    }

    export interface CreateBodyConditionScoreResponse {
        "doc_id": uuid.UUID
    }

    export interface CreatePetDeletionRequest {
        name: string
    }

    export interface CreateWeightRequest {
        /**
         * the weight of the pet in grams
         */
        weight: entities.WeightType

        /**
         * the timestamp when the weight was measured
         */
        "measured_at"?: string
    }

    export interface DeleteUserRequest {
        IsMarked: boolean
    }

    export interface GetAFIClinicalHistoryDocumentRequest {
        Url: string
    }

    export interface GetAFIClinicalHistoryDocumentResponse {
        response: string
    }

    export interface GetAFIClinicalHistoryRequest {
        PetName: string
        PolicyHolderSurname: string
        PolicyNoLong: string
    }

    export interface GetAFIClinicalHistoryResponse {
        documents: AFIDocuments[]
        errorMessages: string[]
        hasError: boolean
        policyAddress: AFIPolicyAddress
    }

    export interface GetAPIKeyResponse {
        id: string
        key: string
        /**
         * Name will be a free text field for values like “Live”, “Dev', “User only access” etc.
         */
        name: string

        "symptom_checker_integrator": boolean
        "booking_integrator": boolean
        "users_read_integrator": boolean
        "users_write_integrator": boolean
        "shop_read_integrator": boolean
        "clinical_admin_integrator": boolean
        "user_submissions_write_integrator": boolean
        "chat_integrator": boolean
    }

    export interface ListAPIKeyResponse {
        "api_keys": GetAPIKeyResponse[]
    }

    export interface ListPetsResponse {
        pets: PetResponse[]
    }

    export interface ListPracticeRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        Name: string
    }

    export interface ListPracticeResponse {
        practices: PetPracticeResponse[]
        pagination: Pagination
    }

    export interface ListUserAddressesResponse {
        addresses: Address[]
    }

    export interface ListUsersRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        Name: string
        Email: string
    }

    export interface ListUsersResponse {
        users: UserResponse[]
        pagination: Pagination
    }

    export interface MyAuthParams {
        /**
         * Accept-Language indicates the natural language and locale that the client prefers
         */
        Language: string

        /**
         * Authorization is the raw value of the "Authorization" header
         * without any parsing.
         */
        Token: string

        /**
         * ClientOperatingSystem is the operating system of the client such as iOS, Android, Windows, etc.
         */
        ClientOperatingSystem: string

        /**
         * ClientOSVersion is the version of the operating system of the client such as 14.0, 10.0, etc.
         */
        ClientOSVersion: string

        /**
         * ClientBuildNumber is the build number of the client application. such as 1.0.0, 2.0.0, etc.
         */
        ClientBuildNumber: string
    }

    export interface Pagination {
        total: number
        "current_page": number
        pages: number
    }

    export interface PetPracticeRequest {
        "google_place_id": string
        name: string
        address: string
        long: string
        lat: string
        "phone_number"?: string
        email?: string
    }

    export interface PetPracticeResponse {
        "google_place_id": string
        name: string
        address: string
        long: string
        lat: string
        "phone_number": string
        email: string
        id: number
    }

    export interface PetResponse {
        /**
         * the id of the pet
         */
        "doc_id": string

        /**
         * the name of the pet
         */
        name: string

        /**
         * the gender of the pet
         */
        gender: string

        /**
         * the weight of the pet in different units
         */
        weight?: entities.WeightUnitsResponse

        /**
         * whether or not the pet has been neutered
         */
        neutered?: boolean

        /**
         * the date of birth of the pet
         */
        "date_of_birth": string

        /**
         * the breed associated with the pet
         */
        breed?: entities.GetBreedsResponseItem

        /**
         * the timestamp when the pet was created
         */
        "created_at": string

        /**
         * the species associated with the pet
         */
        species: string

        /**
         * the species id associated with the pet
         */
        "species_id": uuid.UUID

        /**
         * the photo of the pet
         */
        "photo_url"?: string

        /**
         * the policy customer number of the user associated with the pet
         */
        "policy_customer_no"?: string

        /**
         * the policy number of the pet
         */
        "policy_no"?: string

        /**
         * is policy of the pet active
         */
        "policy_active": boolean

        /**
         * the timestamp when the pet's policy was registered
         */
        "policy_registered_date"?: string

        /**
         * the timestamp when the pet's policy was disabled
         */
        "policy_disabled_date"?: string

        /**
         * does policy require verification
         */
        "requires_verification": boolean

        /**
         * does pet have triage
         */
        triage: boolean

        /**
         * the age of the pet
         */
        age: entities.Age

        /**
         * the start date of the pet's policy
         */
        "policy_start_date"?: string
    }

    export interface SearchPetResponse {
        /**
         * the id of the pet
         */
        "pet_doc_id": string

        "pet_name": string
        /**
         * the id of the user
         */
        "user_doc_id": string

        "user_name": string
        "user_email_address": string
        breed?: string
        "breed_doc_id"?: string
        species: string
        "species_doc_id": string
        gender: string
        age: entities.Age
        deleted: boolean
    }

    export interface SearchPetsRequestPaginated {
        RequestedItemsPerPage: number
        RequestedPageNumber: number
        SearchTerm: string
        IncludeDeleted: boolean
    }

    export interface SearchPetsResponsePaginated {
        pagination: shared.Pagination
        pets: SearchPetResponse[]
    }

    export interface SignUrlRequest {
        URLS: string[]
    }

    export interface SignUrlResponse {
        urls: string[]
    }

    export interface UpdateAddressRequest {
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
        postcode: string
    }

    export interface UpdateApiKeysRequest {
        /**
         * Name will be a free text field for values like “Live”, “Dev', “User only access” etc.
         */
        name: string

        "symptom_checker_integrator": boolean
        "booking_integrator": boolean
        "users_read_integrator": boolean
        "users_write_integrator": boolean
        "shop_read_integrator": boolean
        "clinical_admin_integrator": boolean
        "user_submissions_write_integrator": boolean
        "chat_integrator": boolean
    }

    export interface UpdatePetDeletionRequest {
        "new_name": string
    }

    export interface UpdatePracticeRequest {
        "phone_number": string
        email: string
    }

    export interface UpdateUserRequest {
        email: string
        "first_name": string
        "last_name": string
        "phone_number": string
        "post_code": string
    }

    export interface UserAddress {
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
        postcode: string
    }

    export interface UserResponse {
        /**
         * the id of the user
         */
        id: number

        /**
         * the doc id of the user
         */
        "doc_id": uuid.UUID

        /**
         * the email of the user
         */
        email: string

        /**
         * the first name of the user
         */
        "first_name": string

        /**
         * the last name of the user
         */
        "last_name": string

        /**
         * the phone number of the user
         */
        "phone_number"?: string

        /**
         * the raw phone number of the user
         */
        "phone_number_raw"?: string

        /**
         * the phone number country code of the user
         */
        "phone_number_cc"?: string

        /**
         * the current policy number for user
         */
        "current_policy_customer_no"?: string

        /**
         * does policy require verification
         */
        "requires_verification": boolean

        /**
         * the post code of the user
         */
        "post_code": string

        /**
         * user marked for deletion flag
         */
        "mark_for_deletion"?: boolean

        /**
         * when user was marked for deletion
         */
        "mark_for_deletion_at"?: string

        /**
         * the actual deletion date
         */
        "mark_for_deletion_date"?: string

        /**
         * the actual deletion date
         */
        address?: UserAddress
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Create API Key
         * This endpoint adds a new API key to the database.
         * Obviously this will eventually need auth itself, but is useful to get us going with test.
         */
        public async CreateAPIKey(params: CreateAPIKeyRequest): Promise<CreateAPIKeyResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/api-key/generate`, JSON.stringify(params))
            return await resp.json() as CreateAPIKeyResponse
        }

        /**
         * Create New Address
         * This endpoint creates a new address for an existing user with the provided body parameter values.
         */
        public async CreateAddress(user_doc_id: string, params: CreateAddressRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/usersinternal/${encodeURIComponent(user_doc_id)}/address`, JSON.stringify(params))
        }

        /**
         * Create New Pet BodyConditionScore
         * This endpoint creates a new stored BodyConditionScore for a pet. A valid pet_doc_id path parameter is required.
         */
        public async CreateBodyConditionScore(user_doc_id: string, pet_doc_id: string, params: CreateBodyConditionScoreRequest): Promise<CreateBodyConditionScoreResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}/bcs`, JSON.stringify(params))
            return await resp.json() as CreateBodyConditionScoreResponse
        }

        /**
         * Create business
         */
        public async CreateBusiness(params: entities.CreateBusinessRequest): Promise<entities.CreateBusinessResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/business`, JSON.stringify(params))
            return await resp.json() as entities.CreateBusinessResponse
        }

        /**
         * Fetches an existing user.
         * Fetches an existing user with the given id.
         */
        public async CreateMyStaff(params: entities.CreateStaffRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/my-staff`)
        }

        /**
         * Create Pet deletion reason
         * This endpoint creates a new pet deletion reason
         */
        public async CreatePetDeletionReason(params: CreatePetDeletionRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/petdeletionreason`, JSON.stringify(params))
        }

        /**
         * Create webhook for business
         */
        public async CreateWebhook(businessId: string, params: entities.WebhookRequest): Promise<entities.WebhookResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/businesses/${encodeURIComponent(businessId)}/webhooks`, JSON.stringify(params))
            return await resp.json() as entities.WebhookResponse
        }

        /**
         * Create webhook event
         */
        public async CreateWebhookEvent(params: entities.WebhookEventRequest): Promise<entities.WebhookEventResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/webhookevents`, JSON.stringify(params))
            return await resp.json() as entities.WebhookEventResponse
        }

        /**
         * Create webhook event for business
         */
        public async CreateWebhookEventMapping(businessId: string, webhookId: string, params: entities.WebhookEventMappingRequest): Promise<entities.WebhookEventMappingResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/businesses/${encodeURIComponent(businessId)}/webhooks/${encodeURIComponent(webhookId)}/events`, JSON.stringify(params))
            return await resp.json() as entities.WebhookEventMappingResponse
        }

        /**
         * Create New Pet Weight
         * This endpoint creates a new stored weight for a pet.
         */
        public async CreateWeight(user_doc_id: string, pet_doc_id: string, params: CreateWeightRequest): Promise<entities.CreateWeightResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/usersinternal/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}/weights`, JSON.stringify(params))
            return await resp.json() as entities.CreateWeightResponse
        }

        /**
         * Delete API Key
         * This endpoint deletes an api key. Valid **api_key_doc_id** path parameter is required.
         */
        public async DeleteAPIKey(api_key_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/api-key/${encodeURIComponent(api_key_doc_id)}`)
        }

        /**
         * Delete Address
         * This endpoint deletes an existing address.
         */
        public async DeleteAddress(user_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/usersinternal/${encodeURIComponent(user_doc_id)}/address`)
        }

        /**
         * DeleteBusiness deletes an existing business.
         */
        public async DeleteBusiness(params: entities.DeleteBusinessRequest): Promise<void> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                id: params.DocID,
            })

            await this.baseClient.callTypedAPI("DELETE", `/v1/business`, undefined, {query})
        }

        /**
         * Delete Pet deletion reason
         * This endpoint deletes the pet deletion reason by ID
         */
        public async DeletePetDeletionReason(id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/petdeletionreason/${encodeURIComponent(id)}`)
        }

        /**
         * DeleteUser deletes a user by docID
         */
        public async DeleteUser(doc_id: string, params: DeleteUserRequest): Promise<void> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "is_marked": String(params.IsMarked),
            })

            await this.baseClient.callTypedAPI("DELETE", `/v1/usersinternal/${encodeURIComponent(doc_id)}`, undefined, {query})
        }

        /**
         * Run CRON job from portal
         * 
         * Automatically delete all users marked for deletion.
         */
        public async DeleteUsersMarkedForDeletion(): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/cron/delete-users-marked-for-deletion`)
        }

        /**
         * Create webhook event for business
         */
        public async DeleteWebhookEventMapping(businessId: string, webhookId: string, webhookEventId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/businesses/${encodeURIComponent(businessId)}/webhooks/${encodeURIComponent(webhookId)}/events/${encodeURIComponent(webhookEventId)}`)
        }

        /**
         * GetAFIDocument
         * This endpoint makes a request to afi for the specified url
         */
        public async GetAFIClinicaHistoryDocument(params: GetAFIClinicalHistoryDocumentRequest): Promise<GetAFIClinicalHistoryDocumentResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                url: params.Url,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/clinical-history-document/afi`, undefined, {query})
            return await resp.json() as GetAFIClinicalHistoryDocumentResponse
        }

        /**
         * GetAFICLinicalHistory
         * This endpoint calls Animal Friends to get the pets clinical histroy
         */
        public async GetAFIClinicalHistory(params: GetAFIClinicalHistoryRequest): Promise<GetAFIClinicalHistoryResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                petName:             params.PetName,
                policyHolderSurname: params.PolicyHolderSurname,
                policyNoLong:        params.PolicyNoLong,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/clinical-history/afi`, undefined, {query})
            return await resp.json() as GetAFIClinicalHistoryResponse
        }

        /**
         * Get API Key
         * This endpoint returns an api key. Valid **api_key_doc_id** path parameter is required.
         */
        public async GetAPIKey(api_key_doc_id: string): Promise<GetAPIKeyResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/api-key/${encodeURIComponent(api_key_doc_id)}`)
            return await resp.json() as GetAPIKeyResponse
        }

        /**
         * Get Address Details
         * This endpoint returns the full address by ID.
         */
        public async GetAddressDetails(id: string): Promise<AddressDetailsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/internal/address/details/${encodeURIComponent(id)}`)
            return await resp.json() as AddressDetailsResponse
        }

        /**
         * Get Address Suggestions
         * This endpoint suggests partial address results for a given term.
         */
        public async GetAddressSuggestions(params: AddressSuggestionsRequest): Promise<AddressSuggestionsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                term: params.SearchTerm,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/internal/address/suggestions`, undefined, {query})
            return await resp.json() as AddressSuggestionsResponse
        }

        /**
         * Fetches an existing business by its ID to check if it exists.
         */
        public async GetBusiness(params: entities.GetBusinessRequest): Promise<entities.BusinessResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                id: params.DocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/business`, undefined, {query})
            return await resp.json() as entities.BusinessResponse
        }

        /**
         * GetBusinessByUserDocID fetches a business by user doc ID
         */
        public async GetBusinessByUserDocID(user_doc_id: string): Promise<entities.BusinessResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/business/user/${encodeURIComponent(user_doc_id)}`)
            return await resp.json() as entities.BusinessResponse
        }

        /**
         * Fetches an existing staff member.
         * Fetches an existing staff member to check they exist.
         */
        public async GetMyStaff(): Promise<void> {
            await this.baseClient.callTypedAPI("GET", `/v1/my-staff`)
        }

        /**
         * Get Pet
         * This endpoint returns a given pet for a given user.
         */
        public async GetPet(user_doc_id: string, pet_doc_id: string): Promise<PetResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/usersinternal/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}`)
            return await resp.json() as PetResponse
        }

        /**
         * Get pet Practice
         * This endpoint retrieves pet's data if the pet exists.
         */
        public async GetPetPractice(pet_doc_id: string): Promise<PetPracticeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/petsinternal/${encodeURIComponent(pet_doc_id)}/practice`)
            return await resp.json() as PetPracticeResponse
        }

        /**
         * Get Practice
         * This endpoint retrieves a practice.
         */
        public async GetPractice(id: string): Promise<PetPracticeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/practices/${encodeURIComponent(id)}`)
            return await resp.json() as PetPracticeResponse
        }

        /**
         * Fetches a user
         * Fetches a user with the given id
         */
        public async GetUser(id: string): Promise<UserResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/usersinternal/${encodeURIComponent(id)}`)
            return await resp.json() as UserResponse
        }

        /**
         * Get webhook for business
         */
        public async GetWebhook(businessId: string, id: string): Promise<entities.WebhookResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/businesses/${encodeURIComponent(businessId)}/webhooks/${encodeURIComponent(id)}`)
            return await resp.json() as entities.WebhookResponse
        }

        /**
         * Get webhook event
         */
        public async GetWebhookEvent(id: string): Promise<entities.WebhookEventResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/webhookevents/${encodeURIComponent(id)}`)
            return await resp.json() as entities.WebhookEventResponse
        }

        /**
         * List API Key
         * This endpoint returns a list of api keys for given **business_doc_id**.
         */
        public async ListAPIKeys(business_doc_id: string): Promise<ListAPIKeyResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/business/${encodeURIComponent(business_doc_id)}/api-keys`)
            return await resp.json() as ListAPIKeyResponse
        }

        /**
         * Lists all businesses by name
         */
        public async ListBusinesses(params: entities.ListBusinessesRequest): Promise<entities.ListBusinessesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                name:             params.Name,
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/businesses`, undefined, {query})
            return await resp.json() as entities.ListBusinessesResponse
        }

        /**
         * List Countries
         * This endpoint returns list of valid countries
         */
        public async ListCountries(): Promise<CountryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/internal/countries`)
            return await resp.json() as CountryResponse
        }

        /**
         * List Pets
         * This endpoint returns a list of all pets for the requested **user_doc_id**.
         */
        public async ListPets(user_doc_id: string): Promise<ListPetsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/usersinternal/${encodeURIComponent(user_doc_id)}/pets`)
            return await resp.json() as ListPetsResponse
        }

        /**
         * List Practices
         * This endpoint retrieves all practices.
         */
        public async ListPractices(params: ListPracticeRequest): Promise<ListPracticeResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                name:             params.Name,
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/practices`, undefined, {query})
            return await resp.json() as ListPracticeResponse
        }

        /**
         * Lists addresses for user
         */
        public async ListUserAddresses(user_doc_id: string): Promise<ListUserAddressesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/usersinternal/${encodeURIComponent(user_doc_id)}/addresses`)
            return await resp.json() as ListUserAddressesResponse
        }

        /**
         * Lists users
         * Lists users with pagination support
         */
        public async ListUsers(params: ListUsersRequest): Promise<ListUsersResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                email:            params.Email,
                "items_per_page": String(params.RequestedItemsPerPage),
                name:             params.Name,
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users`, undefined, {query})
            return await resp.json() as ListUsersResponse
        }

        /**
         * List webhooks and events for business
         */
        public async ListWebhookEventMapping(businessId: string, webhookId: string): Promise<entities.ListWebhookEventsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/businesses/${encodeURIComponent(businessId)}/webhooks/${encodeURIComponent(webhookId)}/events`)
            return await resp.json() as entities.ListWebhookEventsResponse
        }

        /**
         * List webhook events
         */
        public async ListWebhookEvents(): Promise<entities.ListWebhookEventsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/webhookevents`)
            return await resp.json() as entities.ListWebhookEventsResponse
        }

        /**
         * List webhooks
         */
        public async ListWebhooks(businessId: string): Promise<entities.ListWebhookResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/businesses/${encodeURIComponent(businessId)}/webhooks`)
            return await resp.json() as entities.ListWebhookResponse
        }

        /**
         * NotifyAFIOfRegistration
         * This endpoint notifies Animal Friends whenever one of their clients registers with us
         */
        public async NotifyAFIOfRegistration(method: "POST", body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/v1/notify-registration/afi`, body, options)
        }

        /**
         * Search Pets
         * This endpoint returns a list of pets that meets the search term
         */
        public async SearchPets(params: SearchPetsRequestPaginated): Promise<SearchPetsResponsePaginated> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "include_deleted": String(params.IncludeDeleted),
                "items_per_page":  String(params.RequestedItemsPerPage),
                "page_number":     String(params.RequestedPageNumber),
                "search_term":     params.SearchTerm,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/pets/search`, undefined, {query})
            return await resp.json() as SearchPetsResponsePaginated
        }

        /**
         * Sign URL
         */
        public async SignURL(params: SignUrlRequest): Promise<SignUrlResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                urls: params.URLS.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/sign-url`, undefined, {query})
            return await resp.json() as SignUrlResponse
        }

        /**
         * Update API Key
         * This endpoint updates api key name and role based permissions.
         */
        public async UpdateAPIKey(api_key_doc_id: string, params: UpdateApiKeysRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/api-key/${encodeURIComponent(api_key_doc_id)}`, JSON.stringify(params))
        }

        /**
         * Update Address
         * This endpoint updates an existing address with the provided body parameter values.
         */
        public async UpdateAddress(user_doc_id: string, params: UpdateAddressRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/usersinternal/${encodeURIComponent(user_doc_id)}/address`, JSON.stringify(params))
        }

        /**
         * UpdateBusiness updates an existing business.
         */
        public async UpdateBusiness(params: entities.UpdateBusinessRequest): Promise<entities.BusinessResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/business`, JSON.stringify(params))
            return await resp.json() as entities.BusinessResponse
        }

        /**
         * Update Pet deletion reason by ID
         * This endpoint takes an ID of a pet deletion reason, and allows you to edit the name of the reason
         */
        public async UpdatePetDeletionReason(id: string, params: UpdatePetDeletionRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/petdeletionreason/${encodeURIComponent(id)}`, JSON.stringify(params))
        }

        /**
         * Get Pet Practice
         * This endpoint retrieves pet's data if the pet exists.
         */
        public async UpdatePetPractice(pet_doc_id: string, params: PetPracticeRequest): Promise<PetPracticeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/petsinternal/${encodeURIComponent(pet_doc_id)}/practice`, JSON.stringify(params))
            return await resp.json() as PetPracticeResponse
        }

        /**
         * Update Practice
         * This endpoint lets you update a practice
         */
        public async UpdatePractice(id: number, params: UpdatePracticeRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/practices/${encodeURIComponent(id)}`, JSON.stringify(params))
        }

        /**
         * UpdateUser given a user id, it updates the user
         * Updates a user with a given ID
         */
        public async UpdateUser(id: string, params: UpdateUserRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/usersinternal/${encodeURIComponent(id)}`, JSON.stringify(params))
        }

        /**
         * Update webhook for business
         */
        public async UpdateWebhook(businessId: string, id: string, params: entities.WebhookRequest): Promise<entities.WebhookResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/businesses/${encodeURIComponent(businessId)}/webhooks/${encodeURIComponent(id)}`, JSON.stringify(params))
            return await resp.json() as entities.WebhookResponse
        }

        /**
         * Update webhook event
         */
        public async UpdateWebhookEvent(id: string, params: entities.WebhookEventRequest): Promise<entities.WebhookEventResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/webhookevents/${encodeURIComponent(id)}`, JSON.stringify(params))
            return await resp.json() as entities.WebhookEventResponse
        }
    }
}

export namespace bookingexternal {
    export interface BookingScreenPageViewRequest {
        "pet_doc_id": string
    }

    export interface ChatAvailabilityRequest {
        ChatServiceID: string
    }

    export interface ChatAvailabilityResponse {
        available: boolean
        busy: boolean
        "next_chat_available_in_minutes": number
    }

    export interface CreateAppointmentPhotoRequest {
        /**
         * PhotoURL
         * The location of the appointment photo.
         * 
         * Accepted format: **folder_name/file_name.png**
         * 
         * Accepted file extendions: **.jpg**, **.jpeg**, **.png**, **.gif**, **.bmp**, **.svg**, **.tif**, **.tiff**, **.webp**, **.heic**
         */
        "photo_url": string
    }

    export interface CreateAppointmentRequest {
        /**
         * AvailabilityID
         * The id of an available slot for booking.
         */
        "availability_doc_id": string

        /**
         * PetID
         * The **doc_id** of the pet, selected for the appointment in **UUID** format.
         */
        "pet_doc_id": string

        /**
         * SCSessionID
         * The id of the symptom checker session.
         * Only available if the user is trying to book an
         * appointment through Symptom Checker flow.
         */
        "sc_session_id"?: string

        /**
         * OnDemand
         * Indicates if the appointment is an on-demand appointment.
         */
        "on_demand"?: boolean

        /**
         * PreviousChatDocID
         * Indicates that an appointment was preceded by a chat that we would like to link it to.
         */
        "previous_chat_doc_id"?: string
    }

    export interface CreateAppointmentResponse {
        /**
         * DocId
         * The **doc_id** of the new appointment in **UUID** format.
         */
        "doc_id": string

        /**
         * CreatedAt
         * **created_at** in UTC time format (e.g. **2017-09-04T04:00:00.000000Z**)
         */
        "created_at": string
    }

    export interface CreateChatRequest {
        /**
         * this is the unique identifier for a chat service
         */
        "chat_service_id": string

        /**
         * pet doc ID is the identifier for the pet
         */
        "pet_doc_id": string

        /**
         * time zone is the time zone of the user
         */
        timezone: string

        /**
         * status of the chat. Only 'curious', 'llm-chat-triage', or 'llm-call-triage'. Will use 'curious' if not specified.
         */
        status?: string
    }

    export interface CreateChatResponse {
        /**
         * this is the unique identifier for a chat room
         */
        "doc_id": string

        /**
         * channel ID is the identifier for the chat room the users are in
         */
        "channel_id": string

        /**
         * chat video room doc ID is the identifier for the chat video room
         */
        "chat_video_room_doc_id": string

        /**
         * token is users token from getstream
         */
        token: string
    }

    export interface CreateOnDemandAppointmentRequest {
        /**
         * ServiceDocID
         * The **doc_id** of the service, selected for the appointment in **UUID** format.
         */
        "sevice_doc_id": string

        /**
         * PetID
         * The **doc_id** of the pet, selected for the appointment in **UUID** format.
         */
        "pet_doc_id": string

        /**
         * SCSessionID
         * The id of the symptom checker session.
         * Only available if the user is trying to book an
         * appointment through Symptom Checker flow.
         */
        "sc_session_id"?: string

        /**
         * TimeZone
         * **timezone** in IANA format
         * (e.g **Europe/London**, **UTC**, **Europe/Berlin**)
         */
        timezone: string

        /**
         * PreviousChatDocID
         * Indicates that an appointment was preceded by a chat that we would like to link it to.
         */
        "previous_chat_doc_id"?: string
    }

    export interface GetAppointmentPhotoResponse {
        /**
         * DocId
         * The **doc_id** of the appointment photo in **UUID** format.
         */
        "doc_id": string

        /**
         * PhotoURL
         * Valid url for retrieving appointment photo. The url is only valid for one hour.
         */
        "photo_url": string
    }

    export interface GetAppointmentResponse {
        /**
         * DocId
         * The **doc_id** of the appointment in **UUID** format.
         */
        "doc_id": string

        /**
         * Start
         */
        start: utilities.Dates

        /**
         * End
         */
        end: utilities.Dates

        /**
         * Status
         * Current status of the appointment.
         * 
         * Valid values: **Reserved**, **PaymentRequired**, **Booked**,
         * **Waiting**, **Abandoned**, **Cancelled**, **DidNotAttend**
         */
        status: string

        /**
         * ConsultStatus
         * Current vet consultation status for the appointment.
         * 
         * Valid values: **Ready**, **InProgress**, **Complete**, **Disrupted**
         */
        "consult_status"?: string

        /**
         * PetDocID
         * The **doc_id** of the pet in **UUID** format.
         */
        "pet_id": string

        /**
         * PreviousChatDocID
         * The **doc_id** of the chat that preceded the appointment (if any) in **UUID** format.
         */
        "previous_chat_doc_id"?: string

        /**
         * StaffProfileName
         * The name of the staff who will participate in the vet call.
         */
        "staff_profile_name"?: string

        /**
         * StaffProfileDescription
         * The **doc_id** of the staff who will participate in the vet call in **UUID** format.
         */
        "staff_profile_description"?: string

        /**
         * StaffProfilePhotoUrl
         * The photo url of the staff who will participate in the vet call.
         * The URL is valid for one hour.
         */
        "staff_profile_photo_url"?: string

        /**
         * ServiceName
         * The name of the selected service for the appointment (e.g. **vet**).
         */
        "service_name": string

        /**
         * RoomID
         * The id of the virtual waiting room where the vet call will happen.
         */
        "room_id"?: string

        /**
         * SessionID
         */
        "session_id"?: string

        /**
         * PaymentLink
         * Link to a payment system, where uninsured users can pay for the appointment.
         */
        "payment_link"?: string

        /**
         * PaymentStatus
         * Status indicating if the user has paid for the appointment.
         * 
         * Valid values: **Paid** and **Unpaid**
         */
        "payment_status"?: string

        /**
         * CanStartWaiting
         * Indicates if user is allowed to join the waiting room.
         */
        "can_start_waiting"?: boolean

        /**
         * WaitTime
         * Estimated waiting time before the start of the vet call.
         * It is based on the staff assigned to the vet call and his/hers booked slots.
         */
        "wait_time"?: number

        /**
         * CanStartWaitingBefore
         * The calculated time left until the user can enter the waiting room.
         */
        "can_start_waiting_before"?: number

        /**
         * WaitingRoomPollingIntervalInMilliSeconds
         * Suggested time how frequently to inform the API that the user is
         * still waiting. Time is in ms.
         */
        "waiting_room_polling_interval_in_milliseconds": number

        /**
         * WaitingRoomFetchAppointmentIntervalInMilliSeconds
         * Suggested time how frequently to fetch the appointment data and
         * update the UI. Time is in ms.
         */
        "waiting_room_fetch_appointment_interval_in_milliseconds": number

        /**
         * Total
         */
        total?: string

        /**
         * SubTotal
         */
        "sub_total"?: string

        /**
         * Discount
         */
        discount?: string

        /**
         * Tax
         */
        tax?: string

        /**
         * RefundedAmount
         */
        "refunded_amount"?: string

        /**
         * TreatmentPlanReady
         * Indicates if the treatment plan for the vet consultation for
         * this appointment is available for the user.
         */
        "treatment_plan_ready": boolean

        /**
         * ServiceDocId
         * The **doc_id** of the service in **UUID** format.
         */
        "service_doc_id": string

        "video_stream_provider"?: string
    }

    export interface GetChatResponse {
        "doc_id": string
        "channel_id": string
        status: string
        "status_history": string[]
        "pet_doc_id": string
        "chat_video_room": entities.ChatVideoRoom
    }

    export interface GetServiceWithPriceResponse {
        /**
         * DocId
         * The **doc_id** of the service in **UUID** format.
         */
        "doc_id": string

        /**
         * Name
         * The name of the service.
         */
        name: string

        /**
         * Description
         * The description of the service.
         */
        description: string

        /**
         * Price
         * The price of the service.
         */
        price: number

        /**
         * Currency
         * The currency of the price for the service.
         */
        currency: string

        /**
         * SkipScreening
         * Trials users are not required to add appointment notes or photos before bookings.
         */
        "skip_screening": boolean

        /**
         * PreventDoubleBookings
         * Prevent double bookings for the same service
         */
        "prevent_double_bookings": boolean

        /**
         * IsTrialService
         * Is Trail Service
         */
        "is_trial_service": boolean

        /**
         * UserBookable
         * Иs the user allowed to book an appointment for this service
         */
        "user_bookable": boolean
    }

    export interface GetTokenResponse {
        /**
         * token is the user token for the chat service
         */
        token: string
    }

    export interface ListAppointmentPhotosResponse {
        "photo_urls": GetAppointmentPhotoResponse[]
    }

    export interface ListAppointmentsRequest {
        Desc: boolean
        /**
         * The appointments which should be excluded from the list with the given consultation statuses.
         */
        ExcludeAppointmentsInConsultationStates?: string[]

        /**
         * The number of results requested for the page. Value should be between 0 and 100.
         */
        RequestedItemsPerPage: number

        /**
         * The page number requested. Positive number expected.
         */
        RequestedPageNumber: number
    }

    export interface ListAppointmentsResponse {
        appointments: GetAppointmentResponse[]
        pagination: Pagination
    }

    export interface ListAvailabileDaysQueryParams {
        /**
         * The **doc_id** of the service, selected for the appointment in **UUID** format.
         */
        ServiceID: string

        /**
         * **timezone** in IANA format
         * (e.g **Europe/London**, **UTC**, **Europe/Berlin**)
         */
        Timezone: string

        /**
         * **start_date** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        StartDate: string

        /**
         * **end_date** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        EndDate: string
    }

    export interface ListAvailabilityQueryParams {
        /**
         * **start_date** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        StartDate: string

        /**
         * **end_date** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        EndDate: string

        /**
         * The **doc_id** of the service for which availability is needed in **UUID** format.
         */
        ServiceDocID: string

        /**
         * **timezone** in IANA format
         * (e.g **Europe/London**, **UTC**, **Europe/Berlin**)
         */
        Timezone: string

        /**
         * allow looking for availabilities in the past (only permitted in certain environments)
         */
        AllowPast: boolean
    }

    export interface ListAvailabilityResponse {
        slots: ListAvailabilitySlotItem[]
    }

    export interface ListAvailabilitySlotItem {
        /**
         * DocId
         * The **doc_id** of the available slot.
         */
        "doc_id": string

        /**
         * StaffProfileName
         * The name of the staff available to participate in the vet call.
         */
        "staff_profile_name": string

        /**
         * ServiceID
         * The **doc_id** of the service, selected for the appointment in **UUID** format.
         */
        "service_id": string

        /**
         * Start
         */
        start: utilities.Dates

        /**
         * End
         */
        end: utilities.Dates

        /**
         * Duration
         * Duration of the vet call in minutes.
         */
        duration: number
    }

    /**
     * FriendlyDay represents a day in a friendly format
     */
    export interface ListAvailableDaysItem {
        /**
         * Day
         * **day** for the slot (e.g. **Monday**, **Tuesday**, **Wednesday**, **Thursday**, **Friday**, **Saturday**, **Sunday**)
         */
        day: string

        /**
         * DateFrom
         * **date_from** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        "date_from": string

        /**
         * DateTo
         * **date_to** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        "date_to": string

        /**
         * SlotsAvailable
         * **slots_available** indicates if there are available slots for the day.
         */
        "slots_available": boolean
    }

    export interface ListAvailableDaysResponse {
        days: ListAvailableDaysItem[]
    }

    export interface ListChatsResponse {
        chats: GetChatResponse[]
    }

    export interface ListOnDemandQueryParams {
        /**
         * The **doc_id** of the service selected for the appointment in **UUID** format.
         */
        ServiceDocID: string

        /**
         * **timezone** in IANA format
         * (e.g **Europe/London**, **UTC**, **Europe/Berlin**)
         */
        Timezone: string
    }

    export interface ListServicesResponse {
        services: GetServiceWithPriceResponse[]
    }

    export interface Pagination {
        /**
         * Total
         * Total count of objects in the list.
         */
        total: number

        /**
         * CurrentPage
         * Currently selected page number.
         */
        "current_page": number

        /**
         * Pages
         * Total count of pages.
         */
        pages: number
    }

    export interface PatchAppointmentNotesRequest {
        /**
         * Notes
         * Notes that the user wants to leave for the consultant to read during the vet call.
         */
        notes: string
    }

    export interface PatchAppointmentTypeformEventIDRequest {
        /**
         * TypeformEventID
         * Unique ID for the Typeform submitted by the user
         */
        "typeform_event_id": string
    }

    /**
     * Send Message used for testing purposes
     */
    export interface SendMessageRequest {
        message: string
    }

    export interface SendMessageResponse {
    }

    export interface UpdateAppointmentStatusRequest {
        /**
         * Status
         * The new status for the appointment.Accepted values:
         * 
         * **Waiting** - this should be used for polling and to inform that the user is waiting to be seen
         * 
         * **Booked** - this should be used to update a reserved appointment to confirm the booking
         * 
         * **Cancelled** - this should be used if the user no longer wants the appointment
         */
        status: string
    }

    export interface UpdateChatVideoStatusRequest {
        /**
         * the status we wish to set the chat video status to
         */
        status: string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * ChatAvailability
         * Get chat availability
         */
        public async ChatAvailability(params: ChatAvailabilityRequest): Promise<ChatAvailabilityResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "chat_service_doc_id": params.ChatServiceID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/chatavailability`, undefined, {query})
            return await resp.json() as ChatAvailabilityResponse
        }

        /**
         * Create Appointment
         * This endpoint creates a new appointment for a vet consultation.
         */
        public async CreateAppointment(user_doc_id: string, params: CreateAppointmentRequest): Promise<CreateAppointmentResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments`, JSON.stringify(params))
            return await resp.json() as CreateAppointmentResponse
        }

        /**
         * Create Appointment Photo
         * This endpoint creates a new photo for an existing appointment.
         */
        public async CreateAppointmentPhoto(user_doc_id: string, appointment_doc_id: string, params: CreateAppointmentPhotoRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments/${encodeURIComponent(appointment_doc_id)}/photos`, JSON.stringify(params))
        }

        /**
         * Create Booking Screen Page View
         * This endpoint tracks usage of the booking screen page
         */
        public async CreateBookingScreenPageView(user_doc_id: string, params: BookingScreenPageViewRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/user/${encodeURIComponent(user_doc_id)}/bookingscreenpageview`, JSON.stringify(params))
        }

        /**
         * CreateChat
         * This endpoint creates a chat for a user and their specific pet
         */
        public async CreateChat(user_doc_id: string, params: CreateChatRequest): Promise<CreateChatResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/chat`, JSON.stringify(params))
            return await resp.json() as CreateChatResponse
        }

        /**
         * Create On-demand Appointment
         * This endpoint creates a new appointment for a consultation using the on-demand feature. If there are no available slots for on-demand, an error is returned.
         */
        public async CreateOnDemandAppointment(user_doc_id: string, params: CreateOnDemandAppointmentRequest): Promise<CreateAppointmentResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/ondemand`, JSON.stringify(params))
            return await resp.json() as CreateAppointmentResponse
        }

        /**
         * Delete Appointment Photo
         * This endpoint deletes an existing photo for an appointment.
         */
        public async DeleteAppointmentPhoto(user_doc_id: string, appointment_doc_id: string, photo_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments/${encodeURIComponent(appointment_doc_id)}/photos/${encodeURIComponent(photo_doc_id)}`)
        }

        /**
         * DeleteChat
         * Delete a chat
         */
        public async DeleteChat(user_doc_id: string, chatDocID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/users/${encodeURIComponent(user_doc_id)}/chat/${encodeURIComponent(chatDocID)}`)
        }

        /**
         * EmailCancelAppointment
         * This endpoint terminates an existing appointment, typically triggered via an email link.
         * Temporary endpoint while appointment management is being worked on.
         */
        public async EmailCancelAppointment(method: "GET", appointment_id: string, pet_id: string, body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/v1/appointments/${encodeURIComponent(appointment_id)}/${encodeURIComponent(pet_id)}/email-cancel`, body, options)
        }

        /**
         * EndChat
         * End a chat early
         */
        public async EndChat(userDocID: string, chatDocID: string): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/users/${encodeURIComponent(userDocID)}/chat/${encodeURIComponent(chatDocID)}/end`)
        }

        /**
         * Get Appointment
         * This endpoint retrieves an existing appointment for a given user.
         */
        public async GetAppointment(user_doc_id: string, appointment_doc_id: string): Promise<GetAppointmentResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments/${encodeURIComponent(appointment_doc_id)}`)
            return await resp.json() as GetAppointmentResponse
        }

        /**
         * Get Appointment Photo
         * This endpoint retrieves an existing photo for an appointment.
         */
        public async GetAppointmentPhoto(user_doc_id: string, appointment_doc_id: string, photo_doc_id: string): Promise<GetAppointmentPhotoResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments/${encodeURIComponent(appointment_doc_id)}/photos/${encodeURIComponent(photo_doc_id)}`)
            return await resp.json() as GetAppointmentPhotoResponse
        }

        /**
         * GetChat
         * Get chat for user by chat doc ID
         */
        public async GetChat(user_doc_id: string, chatDocID: string): Promise<GetChatResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/chat/${encodeURIComponent(chatDocID)}`)
            return await resp.json() as GetChatResponse
        }

        /**
         * Fetch a service by service_doc_id
         */
        public async GetService(service_doc_id: string): Promise<GetServiceWithPriceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/services/${encodeURIComponent(service_doc_id)}`)
            return await resp.json() as GetServiceWithPriceResponse
        }

        /**
         * Fetch a service by service_doc_id TO BE DEPRECATED
         */
        public async GetServiceExternal(service_doc_id: string): Promise<GetServiceWithPriceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookingexternal/services/${encodeURIComponent(service_doc_id)}`)
            return await resp.json() as GetServiceWithPriceResponse
        }

        /**
         * GetToken
         * gets a users token from chat service
         */
        public async GetToken(user_doc_id: string): Promise<GetTokenResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/chattoken`)
            return await resp.json() as GetTokenResponse
        }

        /**
         * Webhook for Getstream chat events
         * This endpoint is not passed through generic auth as the request is validated by its X-Signature and X-Api-Key
         * headers as described in the Getstream docs: https://getstream.io/chat/docs/go-golang/webhooks_overview
         */
        public async GetstreamWebhook(method: "POST", body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/v1/getstream`, body, options)
        }

        /**
         * List Appointment Photos
         * This endpoint retrieves all existing photos for an appointment.
         */
        public async ListAppointmentPhotos(user_doc_id: string, appointment_doc_id: string): Promise<ListAppointmentPhotosResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments/${encodeURIComponent(appointment_doc_id)}/photos`)
            return await resp.json() as ListAppointmentPhotosResponse
        }

        /**
         * List Appointments
         * This endpoint retrieves a list of all appointments for a given user.
         */
        public async ListAppointments(user_doc_id: string, params: ListAppointmentsRequest): Promise<ListAppointmentsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                desc:                                          String(params.Desc),
                "exclude_appointments_in_consultation_states": params.ExcludeAppointmentsInConsultationStates?.map((v) => v),
                "items_per_page":                              String(params.RequestedItemsPerPage),
                "page_number":                                 String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments`, undefined, {query})
            return await resp.json() as ListAppointmentsResponse
        }

        /**
         * List Available Appointment Days
         * This endpoint returns a list of days with available appointment time slots.
         */
        public async ListAvailabileDays(params: ListAvailabileDaysQueryParams): Promise<ListAvailableDaysResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "end_date":       params.EndDate,
                "service_doc_id": params.ServiceID,
                "start_date":     params.StartDate,
                timezone:         params.Timezone,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/availabledays`, undefined, {query})
            return await resp.json() as ListAvailableDaysResponse
        }

        /**
         * List Available Appointments
         * This endpoint returns a list of available appointment time slots.
         */
        public async ListAvailability(params: ListAvailabilityQueryParams): Promise<ListAvailabilityResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "allow_past":     String(params.AllowPast),
                "end_date":       params.EndDate,
                "service_doc_id": params.ServiceDocID,
                "start_date":     params.StartDate,
                timezone:         params.Timezone,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/availability`, undefined, {query})
            return await resp.json() as ListAvailabilityResponse
        }

        /**
         * ListChats
         * List all chats for a user
         */
        public async ListChats(user_doc_id: string): Promise<ListChatsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/chat`)
            return await resp.json() as ListChatsResponse
        }

        /**
         * List Next On Demand Appointments
         * This endpoint returns a slot representing the next available on demand appointment time slot. If there are no available slots for on-demand, an empty array is returned.
         */
        public async ListOnDemand(params: ListOnDemandQueryParams): Promise<ListAvailabilityResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "service_doc_id": params.ServiceDocID,
                timezone:         params.Timezone,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/ondemand`, undefined, {query})
            return await resp.json() as ListAvailabilityResponse
        }

        /**
         * List Services
         * This endpoint provides a list of available televet appointment types such as Vet, Nurse or Specialists. These services are used to filter available appointment times.
         */
        public async ListServices(): Promise<ListServicesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/services`)
            return await resp.json() as ListServicesResponse
        }

        /**
         * Update Appointment Notes
         * This endpoint updates an exisiting appointment's notes.
         */
        public async PatchAppointmentNotes(user_doc_id: string, appointment_doc_id: string, params: PatchAppointmentNotesRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments/${encodeURIComponent(appointment_doc_id)}/notes`, JSON.stringify(params))
        }

        /**
         * Update Appointment Typeform Event ID
         * This endpoint updates an exisiting appointment's typeform event id.
         */
        public async PatchAppointmentTypeformEventID(user_doc_id: string, appointment_doc_id: string, params: PatchAppointmentTypeformEventIDRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments/${encodeURIComponent(appointment_doc_id)}/typeform-event-id`, JSON.stringify(params))
        }

        /**
         * SendChatMessage
         * Send a message to a chat
         */
        public async SendChatMessage(user_doc_id: string, chatDocID: string, params: SendMessageRequest): Promise<SendMessageResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/users/${encodeURIComponent(user_doc_id)}/chat/${encodeURIComponent(chatDocID)}/message`, JSON.stringify(params))
            return await resp.json() as SendMessageResponse
        }

        /**
         * Update Appointment Status
         * This endpoint updates the status of an existing appointment.
         */
        public async UpdateAppointmentStatus(user_doc_id: string, appointment_doc_id: string, params: UpdateAppointmentStatusRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/users/${encodeURIComponent(user_doc_id)}/appointments/${encodeURIComponent(appointment_doc_id)}/status`, JSON.stringify(params))
        }

        /**
         * UpdateChatVideoStatus
         * Update chat video status
         */
        public async UpdateChatVideoStatus(user_doc_id: string, chatDocID: string, params: UpdateChatVideoStatusRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/users/${encodeURIComponent(user_doc_id)}/chat/${encodeURIComponent(chatDocID)}/video`, JSON.stringify(params))
        }
    }
}

export namespace bookinginternal {
    /**
     * For DeactivateServiceSchedule
     */
    export interface ActivateServiceScheduleRequest {
    }

    export interface ActivateServiceScheduleResponse {
    }

    export interface AddHandoverReasonRequest {
        reason: string
    }

    export interface AddHandoverReasonResponse {
        "reason_id": number
    }

    export interface AppointmentEventsResponse {
        events: entities.AppointmentEvent[]
    }

    export interface CancelAppointmentRequest {
        source: string
    }

    export interface ChatAuditMessage {
        "chat_id": number
        "participant_id": number
        "participant_doc_id": string
        "message_id": string
        "sent_at": string
        content: string
        "message_type": string
        "is_pinned": boolean
        attachments: ChatAuditMessageAttachment[]
        /**
         * either the user/staff doc ID, or DR_JOII_AI (llm) or VET_AI (welcome bot)
         */
        "session_user_id": string

        "pet_doc_id": uuid.UUID
    }

    export interface ChatAuditMessageAttachment {
        "bucket_path": string
        "mime_type": string
    }

    export interface CreateAppointmentRequest {
        /**
         * AvailabilityDocID
         * The id of an available slot for booking.
         */
        "availability_doc_id": string

        /**
         * PetDocID
         * The **doc_id** of the pet, selected for the appointment in **UUID** format.
         */
        "pet_doc_id": string

        /**
         * UserDocID
         * The **doc_id** of the user, selected for the appointment in **UUID** format.
         */
        "user_doc_id": string

        /**
         * Previous appointment doc id
         * The optional **doc_id** of the previous appointment in **UUID** format.
         */
        "previous_appointment_doc_id": string

        /**
         * PreviousChatDocID
         * Indicates that an appointment was preceded by a chat that we would like to link it to.
         */
        "previous_chat_doc_id"?: string
    }

    export interface CreateAppointmentResponse {
        /**
         * DocId
         * The **doc_id** of the new appointment in **UUID** format.
         */
        "doc_id": string

        /**
         * CreatedAt
         * **created_at** in UTC time format (e.g. **2017-09-04T04:00:00.000000Z**)
         */
        "created_at": string
    }

    export interface CreateBusinessServiceRequest {
        "business_doc_id": string
    }

    export interface CreateChatServiceRequest {
        name: string
        description: string
    }

    export interface CreateChatServiceResponse {
        "doc_id": string
    }

    export interface CreateChatServiceStaffRequest {
        "chat_service_doc_id": string
        enabled: boolean
    }

    /**
     * For CreateServicePrice
     */
    export interface CreateServicePriceRequest {
        price: number
        currency: string
        "external_pricing_id": string
    }

    export interface CreateServicePriceResponse {
        id: string
    }

    export interface CreateServicePricesBusinessRequest {
        BusinessDocID: string
    }

    export interface CreateServiceRequest {
        name: string
        description: string
        colour?: string
        "user_bookable": boolean
        "skip_screening": boolean
        "is_trial_service": boolean
        "prevent_double_bookings": boolean
    }

    export interface CreateServiceResponse {
        id: string
    }

    /**
     * For CreateServiceSchedule
     */
    export interface CreateServiceScheduleRequest {
        name: string
        duration: number
        "padding_after": number
        "activation_date": string
        active: boolean
    }

    export interface CreateServiceScheduleResponse {
        id: string
    }

    export interface CreateShiftRequest {
        "start_date": string
        "end_date": string
        timezone: string
        chat: boolean
        "shift_type": string
        "shift_status": string
        /**
         * if passed, will create a shift with a staff profile, otherwise will create a shift without a staff profile
         */
        "staff_profile_id": string
    }

    export interface CreateShiftResponse {
        id: string
    }

    export interface CreateStaffProfileRequest {
        name: string
        description: string
        "sling_id"?: string
        "rcvs_number"?: string
    }

    export interface CreateStaffProfileResponse {
        id: string
    }

    export interface CreateStaffSkillRequest {
        "service_doc_id": string
        enabled: boolean
    }

    /**
     * For DeactivateServiceSchedule
     */
    export interface DeactivateServiceScheduleRequest {
    }

    export interface DeactivateServiceScheduleResponse {
    }

    export interface GetAppointmentRequest {
        Timezone: string
    }

    export interface GetAppointmentResponse {
        "doc_id": string
        start: utilities.Dates
        end: utilities.Dates
        status: string
        "pet_id": string
        "user_id": string
        "room_id"?: string
        "session_id"?: string
        "payment_intent_id"?: string
        "staff_profile_id"?: string
        "staff_profile_name"?: string
        "staff_profile_description"?: string
        "staff_profile_photo_url"?: string
        "staff_profile_rcvs_number"?: string
        "is_waiting": boolean
        "is_open": boolean
        "is_late": boolean
        "is_priority": boolean
        /**
         * IsOverdue, if true, means the appointment is overdue and can be considered as missed, (subject to change; 120 minutes after the appointment end time)
         */
        "is_overdue": boolean

        "started_waiting_time"?: string
        "last_seen_waiting"?: string
        "waiting_time_in_minutes"?: number
        "actual_start_time"?: string
        "actual_end_time"?: string
        "consult_status"?: string
        "disrupted_reason"?: string
        pet: accountinternal.PetResponse
        user: accountinternal.UserResponse
        business: entities.BusinessResponse
        notes?: string
        "appointment_photos_urls": string[]
        "symptom_checker_session_doc_id"?: string
        "symptom_checker_session_algorithm_title"?: string
        "symptom_checker_session_outcome_urgency"?: string
        "service_name": string
        "service_doc_id": string
        "service_is_trial": boolean
        "typeform_event_id": string
        "on_demand": boolean
        "created_by_staff_profile_doc_id"?: string
        "video_stream_provider"?: string
        "previous_chat_doc_id"?: string
        reassigned: boolean
    }

    export interface GetChatServiceResponse {
        "doc_id": string
        name: string
        description: string
    }

    export interface GetHandoverReasonsResponse {
        reasons: entities.HandoverReason[]
    }

    export interface GetHandoverResponse {
        id: number
        "doc_id": string
        "chat_doc_id": string
        "from_staff_doc_id": string
        "to_staff_doc_id"?: string
        "reason_id": number
        note: string
        "created_at": string
        "updated_at": string
    }

    export interface GetRefundResponse {
        id: string
        amount: number
        total: number
        "fully_refunded": boolean
        "created_at": string
        "updated_at": string
        "payment_intent_id"?: string
    }

    export interface GetServicePriceResponse {
        id: uuid.UUID
        price: number
        "business_id": string
        currency: string
        "external_pricing_id": string
    }

    export interface GetServiceResponse {
        id: string
        name: string
        description: string
        duration: number
        colour?: string
        "business_ids"?: string[]
        "user_bookable": boolean
        "skip_screening": boolean
        "is_trial_service": boolean
        "prevent_double_bookings": boolean
    }

    export interface GetServiceScheduleResponse {
        id: uuid.UUID
        name: string
        duration: number
        "padding_after": number
        "activation_date": string
        active: boolean
    }

    export interface GetShiftResponse {
        id: string
        "start_date": string
        "local_start_date": string
        "end_date": string
        "local_end_date": string
        timezone: string
        "shift_type": string
        "shift_status": string
        "staff_profile_id"?: string
        "service_id"?: string
        "service_name"?: string
        "service_colour"?: string
        bookable?: boolean
        "chat_available"?: boolean
    }

    export interface GetStaffProfileResponse {
        id: string
        name: string
        description: string
        "sling_id"?: string
        "photo_url"?: string
        "rcvs_number"?: string
        "video_services": GetServiceResponse[]
        "chat_services": GetChatServiceResponse[]
    }

    export interface GetStaffTokenResponse {
        token: string
    }

    export interface GetStaffVideoTokenResponse {
        token: string
    }

    export interface HandoverChatRequest {
        "staff_doc_id": string
        "reason_id": number
        note: string
    }

    export interface ListAppointmentFollowUpsRequest {
        Timezone: string
    }

    export interface ListAppointmentsResponse {
        appointments: GetAppointmentResponse[]
    }

    export interface ListAppointmentsV2Request {
        ServiceDocID?: string
        UserDocID?: string
        PetDocID?: string
        ShiftDocID?: string
        BusinessDocID?: string
        Status?: string[]
        StartDate?: string
        EndDate?: string
        Timezone: string
        Waiting?: boolean
        Open?: boolean
        StaffProfileDocID?: string
        ListAppointmentWithoutStaffProfiles?: boolean
        OrderBy?: string
        ExcludeAppointmentsInConsultationStates?: string[]
        /**
         * If set to true, it filters all appointments
         * whose `start_time` is within the next `TimeBeforeAppointmentAllowedToEnterWaitingRoomInSeconds` seconds
         */
        WaitingRoomEntry?: boolean

        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number
    }

    export interface ListAppointmentsV2Response {
        appointments: GetAppointmentResponse[]
        pagination: Pagination
    }

    export interface ListAvailabileDaysQueryParams {
        /**
         * The **doc_id** of the service, selected for the appointment in **UUID** format.
         */
        ServiceID: string

        /**
         * **timezone** in IANA format
         * (e.g **Europe/London**, **UTC**, **Europe/Berlin**)
         */
        Timezone: string

        /**
         * **start_date** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        StartDate: string

        /**
         * **end_date** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        EndDate: string
    }

    export interface ListAvailabilityInternalQueryParams {
        /**
         * **start_date** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        StartDate: string

        /**
         * **end_date** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        EndDate: string

        /**
         * The **doc_id** of the service for which availability is needed in **UUID** format.
         */
        ServiceDocID: string

        /**
         * **timezone** in IANA format
         * (e.g **Europe/London**, **UTC**, **Europe/Berlin**)
         */
        Timezone: string
    }

    export interface ListAvailabilityResponse {
        slots: ListAvailabilitySlotItem[]
    }

    export interface ListAvailabilitySlotItem {
        /**
         * DocId
         * The **doc_id** of the available slot.
         */
        "doc_id": string

        /**
         * StaffProfileName
         * The name of the staff available to participate in the vet call.
         */
        "staff_profile_name": string

        /**
         * Start
         */
        start: utilities.Dates

        /**
         * End
         */
        end: utilities.Dates

        /**
         * Duration
         * Duration of the vet call in minutes.
         */
        duration: number
    }

    /**
     * FriendlyDay represents a day in a friendly format
     */
    export interface ListAvailableDaysItem {
        /**
         * Day
         * **day** for the slot (e.g. **Monday**, **Tuesday**, **Wednesday**, **Thursday**, **Friday**, **Saturday**, **Sunday**)
         */
        day: string

        /**
         * DateFrom
         * **date_from** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        "date_from": string

        /**
         * DateTo
         * **date_to** in local time format (e.g. **2017-09-04T04:00:00**)
         */
        "date_to": string

        /**
         * SlotsAvailable
         * **slots_available** indicates if there are available slots for the day.
         */
        "slots_available": boolean
    }

    export interface ListAvailableDaysResponse {
        days: ListAvailableDaysItem[]
    }

    export interface ListChatMessagesResponse {
        messages: ChatAuditMessage[]
    }

    export interface ListChatServicesResponse {
        "chat_services": GetChatServiceResponse[]
    }

    export interface ListRefundsResponse {
        refunds: GetRefundResponse[]
    }

    export interface ListSerciceScheduleResponseItem {
        id: uuid.UUID
        name: string
        duration: number
        "padding_after": number
        "activation_date": string
        active: boolean
    }

    export interface ListServicePricesResponse {
        prices: ListServicePricesResponseItem[]
    }

    export interface ListServicePricesResponseItem {
        id: uuid.UUID
        price: number
        currency: string
        "external_pricing_id": string
    }

    export interface ListServiceSchedulesResponse {
        schedules: ListSerciceScheduleResponseItem[]
    }

    export interface ListServicesByBusinessResponse {
        services: ServiceResponse[]
    }

    export interface ListServicesRequest {
        ActiveOnly?: boolean
    }

    export interface ListServicesResponse {
        services: GetServiceResponse[]
    }

    export interface ListShiftsRequest {
        "start_date": string
        "end_date": string
        timezone: string
        "staff_profile_id": string
        "shift_status": string
    }

    export interface ListShiftsResponse {
        shifts: GetShiftResponse[]
    }

    export interface ListStaffChatServicesRequest {
        OnlyEnabled: boolean
    }

    export interface ListStaffChatServicesResponse {
        services: StaffChatServiceResponse[]
    }

    export interface ListStaffProfilesResponse {
        pagination: shared.Pagination
        staffProfiles: GetStaffProfileResponse[]
    }

    export interface ListStaffSkillsRequest {
        OnlyEnabled: boolean
    }

    export interface ListStaffSkillsResponse {
        skills: StaffSkillResponse[]
    }

    export interface Pagination {
        total: number
        "current_page": number
        pages: number
    }

    export interface PatchMyStaffProfilePhotoRequest {
        "photo_url": string
    }

    export interface PatchStaffProfileRcvsNumerRequest {
        "rcvs_number": string
    }

    export interface SendProductMessageRequest {
        sku: string
        name: string
        url: string
        currency: string
        price: number
        description: string
        "photo_url": string
        message: string
    }

    export interface ServiceResponse {
        id: string
        name: string
        description: string
        duration: number
        colour?: string
        "user_bookable": boolean
        "skip_screening": boolean
        "prevent_double_bookings": boolean
    }

    export interface StaffChatServiceResponse {
        "chat_service_doc_id": string
        "service_name": string
        enabled: boolean
        description: string
    }

    export interface StaffSkillResponse {
        "service_doc_id": string
        "service_name": string
        enabled: boolean
        description: string
    }

    export interface TypeformSubmissionsRequest {
        PetDocID: string
    }

    export interface TypeformSubmissionsResponse {
        Submissions: string[]
    }

    export interface UpdateAppointmentConsultStatusRequest {
        /**
         * Accepted Values: **Ready**, **InProgress**, **Complete**, **Disrupted**.
         */
        "consult_status": string

        /**
         * Required field only for status **Disrupted**.
         */
        "disrupted_reason"?: string
    }

    export interface UpdateAppointmentSlotRequest {
        "new_availability_doc_id": string
        source: string
    }

    export interface UpdateAppointmentStaffProfileRequest {
        "staff_profile_id"?: string
    }

    export interface UpdateChatCallStateRequest {
        status: string
    }

    export interface UpdateChatConsultStatusRequest {
        /**
         * Accepted Values: **Ready**, **InProgress**, **Complete**, **Disrupted**.
         */
        "consult_status": string

        /**
         * Required field only for status **Disrupted**.
         */
        "disrupted_reason"?: string
    }

    export interface UpdateChatServiceRequest {
        name: string
        description: string
    }

    export interface UpdateChatServiceStaffRequest {
        enabled: boolean
    }

    export interface UpdateChatStatusRequest {
        status: string
    }

    export interface UpdateChatStatusResponse {
    }

    export interface UpdateHandoverReasonRequest {
        reason: string
    }

    /**
     * For UpdateServicePrice
     */
    export interface UpdateServicePriceRequest {
        price: number
        currency: string
        "external_pricing_id": string
    }

    export interface UpdateServicePriceResponse {
    }

    export interface UpdateServiceRequest {
        name: string
        description: string
        colour?: string
        "user_bookable": boolean
        "skip_screening": boolean
        "is_trial_service": boolean
        "prevent_double_bookings": boolean
    }

    export interface UpdateServiceResponse {
    }

    /**
     * For UpdateServiceSchedule
     */
    export interface UpdateServiceScheduleRequest {
        name: string
        duration: number
        "padding_after": number
        "activation_date": string
        active: boolean
    }

    export interface UpdateServiceScheduleResponse {
    }

    export interface UpdateShiftDatesRequest {
        "start_date": string
        "end_date": string
        timezone: string
    }

    export interface UpdateShiftStaffProfileRequest {
        "staff_profile_id": string
    }

    export interface UpdateShiftStatusRequest {
        "shift_status": string
    }

    export interface UpdateStaffProfileRequest {
        name: string
        description: string
        "sling_id"?: string
        "rcvs_number"?: string
    }

    export interface UpdateStaffProfileResponse {
    }

    export interface UpdateStaffSkillRequest {
        enabled: boolean
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Run CRON job from portal
         */
        public async AbandonCart(): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/cron/cart`)
        }

        /**
         * DeactivateServiceSchedule deactivates an existing service schedule
         */
        public async ActivateServiceSchedule(serviceId: string, scheduleId: string, params: ActivateServiceScheduleRequest): Promise<ActivateServiceScheduleResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PATCH", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/schedules/${encodeURIComponent(scheduleId)}/activate`)
            return await resp.json() as ActivateServiceScheduleResponse
        }

        /**
         * AddChatParticipant
         * Add participant to a existing chat
         */
        public async AddChatParticipant(chatDocID: string, staffProfileID: string): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/chats/${encodeURIComponent(chatDocID)}/participant/${encodeURIComponent(staffProfileID)}`)
        }

        /**
         * AddHandoverReason
         * Add a new handover reason
         */
        public async AddHandoverReason(params: AddHandoverReasonRequest): Promise<AddHandoverReasonResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/handover/reasons`, JSON.stringify(params))
            return await resp.json() as AddHandoverReasonResponse
        }

        /**
         * Cancel an appointment
         */
        public async CancelAppointment(appointmentId: string, params: CancelAppointmentRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/appointments/${encodeURIComponent(appointmentId)}/cancel`, JSON.stringify(params))
        }

        /**
         * CompleteHandover
         * Update the status of a handover and store staff member who picked up the handover
         */
        public async CompleteHandover(chatID: string, staffID: string): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/chats/${encodeURIComponent(chatID)}/handover/complete/${encodeURIComponent(staffID)}`)
        }

        /**
         * CreateBusinessService creates a new service for business
         */
        public async CreateBusinessService(serviceId: string, params: CreateBusinessServiceRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/business`, JSON.stringify(params))
        }

        /**
         * CreateChatService
         * Create a chat service
         */
        public async CreateChatService(params: CreateChatServiceRequest): Promise<CreateChatServiceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/chat-service`, JSON.stringify(params))
            return await resp.json() as CreateChatServiceResponse
        }

        /**
         * Add a chat service to a staff member.
         * 
         * CreateChatServiceStaff adds a new chat service to a staff member.
         */
        public async CreateChatServiceStaff(staffDocID: string, params: CreateChatServiceStaffRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/staff/${encodeURIComponent(staffDocID)}/chat-services`, JSON.stringify(params))
        }

        /**
         * Create appointment
         * This endpoint creates a new appointment for a user and pet.
         */
        public async CreateInternalAppointment(params: CreateAppointmentRequest): Promise<CreateAppointmentResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/appointments`, JSON.stringify(params))
            return await resp.json() as CreateAppointmentResponse
        }

        /**
         * Creates a new staff profile for the current staff user
         */
        public async CreateMyStaffProfile(params: CreateStaffProfileRequest): Promise<CreateStaffProfileResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/mystaffprofiles`, JSON.stringify(params))
            return await resp.json() as CreateStaffProfileResponse
        }

        /**
         * Creates a service for a location
         * Creates a for a location determined by the API region
         */
        public async CreateService(params: CreateServiceRequest): Promise<CreateServiceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/bookinginternal/services`, JSON.stringify(params))
            return await resp.json() as CreateServiceResponse
        }

        /**
         * CreateServicePrice creates a new service price
         */
        public async CreateServicePrice(serviceId: string, params: CreateServicePriceRequest): Promise<CreateServicePriceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/prices`, JSON.stringify(params))
            return await resp.json() as CreateServicePriceResponse
        }

        /**
         * CreateServicePricesBusiness creates a new service price for business
         */
        public async CreateServicePricesBusiness(serviceId: string, priceId: string, params: CreateServicePricesBusinessRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/prices/${encodeURIComponent(priceId)}/businesses`, JSON.stringify(params))
        }

        /**
         * CreateServiceSchedule creates a new service schedule
         */
        public async CreateServiceSchedule(serviceId: string, params: CreateServiceScheduleRequest): Promise<CreateServiceScheduleResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/schedules`, JSON.stringify(params))
            return await resp.json() as CreateServiceScheduleResponse
        }

        /**
         * CreateShift creates a shift with a staff profile if staff profile id is passed, otherwise creates a shift without a staff profile
         */
        public async CreateShift(params: CreateShiftRequest): Promise<CreateShiftResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/shifts`, JSON.stringify(params))
            return await resp.json() as CreateShiftResponse
        }

        /**
         * Creates a new staff profile for a JWT user for user in the portal and testing
         */
        public async CreateStaffProfile(params: CreateStaffProfileRequest): Promise<CreateStaffProfileResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/staffprofiles`, JSON.stringify(params))
            return await resp.json() as CreateStaffProfileResponse
        }

        /**
         * Add a service to a staff member.
         * 
         * CreateStaffSkill adds a new service ("skill") to a staff member.
         */
        public async CreateStaffSkill(staffDocID: string, params: CreateStaffSkillRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/staff/${encodeURIComponent(staffDocID)}/services`, JSON.stringify(params))
        }

        /**
         * DeactivateServiceSchedule deactivates an existing service schedule
         */
        public async DeactivateServiceSchedule(serviceId: string, scheduleId: string, params: DeactivateServiceScheduleRequest): Promise<DeactivateServiceScheduleResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PATCH", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/schedules/${encodeURIComponent(scheduleId)}/deactivate`)
            return await resp.json() as DeactivateServiceScheduleResponse
        }

        /**
         * DeleteBusinessService deletes service for business
         */
        public async DeleteBusinessService(serviceId: string, businessId: string): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/business/${encodeURIComponent(businessId)}`)
        }

        /**
         * DeleteChatService
         * Delete a chat service by doc id
         */
        public async DeleteChatService(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/chat-service/${encodeURIComponent(docID)}`)
        }

        /**
         * Remove a chat service from a staff member.
         * 
         * DeleteChatServiceStaff deletes a relationship between a chat service and a staff member
         */
        public async DeleteChatServiceStaff(staffDocID: string, serviceDocID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/staff/${encodeURIComponent(staffDocID)}/chat-services/${encodeURIComponent(serviceDocID)}`)
        }

        /**
         * DeleteHandoverReason
         * Delete a handover reason
         */
        public async DeleteHandoverReason(reasonID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/handover/reasons/${encodeURIComponent(reasonID)}`)
        }

        /**
         * Deletes a service for a location
         */
        public async DeleteService(serviceId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}`)
        }

        /**
         * DeleteServicePricesBusiness deletes a service price for business
         */
        public async DeleteServicePricesBusiness(serviceId: string, priceId: string, businessId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/prices/${encodeURIComponent(priceId)}/businesses/${encodeURIComponent(businessId)}`)
        }

        /**
         * DeleteShift deletes a shift by id
         */
        public async DeleteShift(shiftId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/shifts/${encodeURIComponent(shiftId)}`)
        }

        /**
         * Delete Staff Profile
         * This endpoint deletes an existing staff profile by a provided **staff_profile_doc_id**.
         */
        public async DeleteStaffProfile(staff_profile_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/staffprofiles/${encodeURIComponent(staff_profile_doc_id)}`)
        }

        /**
         * Remove a service ("skill") from a staff member.
         * 
         * DeleteServiceStaff deletes a relationship between a service and a staff member
         */
        public async DeleteStaffSkill(staffDocID: string, serviceDocID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/staff/${encodeURIComponent(staffDocID)}/services/${encodeURIComponent(serviceDocID)}`)
        }

        /**
         * Run CRON job from portal
         */
        public async DynamicallyReassignAppointments(): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/cron/reassign-appointments`)
        }

        /**
         * FreezeChannel
         * Freeze channel by chat doc ID
         */
        public async FreezeChannel(chatDocID: string): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/chats/${encodeURIComponent(chatDocID)}/freeze`)
        }

        /**
         * GetChat
         * Get chat by doc ID
         */
        public async GetChat(chatDocID: string): Promise<entities.ChatResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/chats/${encodeURIComponent(chatDocID)}`)
            return await resp.json() as entities.ChatResponse
        }

        /**
         * GetChatService
         * Get a chat service by doc id
         */
        public async GetChatService(docID: string): Promise<GetChatServiceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/chat-service/${encodeURIComponent(docID)}`)
            return await resp.json() as GetChatServiceResponse
        }

        /**
         * GetHandover
         * Get a handover by chat id
         */
        public async GetHandover(chatID: string): Promise<GetHandoverResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/chats/${encodeURIComponent(chatID)}/handover`)
            return await resp.json() as GetHandoverResponse
        }

        /**
         * GetHandoverReason
         * Get handover reason by id
         */
        public async GetHandoverReason(reasonID: string): Promise<entities.HandoverReason> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/handover/reasons/${encodeURIComponent(reasonID)}`)
            return await resp.json() as entities.HandoverReason
        }

        /**
         * GetHandoverReasons
         * Get all handover reasons
         */
        public async GetHandoverReasons(): Promise<GetHandoverReasonsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/handover/reasons`)
            return await resp.json() as GetHandoverReasonsResponse
        }

        /**
         * GetAppointment fetches an appointment by id
         */
        public async GetInternalAppointment(appointmentId: string, params: GetAppointmentRequest): Promise<GetAppointmentResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                timezone: params.Timezone,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/appointments/${encodeURIComponent(appointmentId)}`, undefined, {query})
            return await resp.json() as GetAppointmentResponse
        }

        /**
         * Fetches the staff profile for the current user
         */
        public async GetMyStaffProfile(): Promise<GetStaffProfileResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/mystaffprofiles`)
            return await resp.json() as GetStaffProfileResponse
        }

        /**
         * Get all pending chats
         * Get all chats with status as pending (ordered by newest first)
         */
        public async GetPendingChats(params: entities.GetPendingChatsRequest): Promise<entities.GetPendingChatsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/pending-chats`, undefined, {query})
            return await resp.json() as entities.GetPendingChatsResponse
        }

        /**
         * Get Refund
         */
        public async GetRefund(appointment_doc_id: string, refund_doc_id: string): Promise<GetRefundResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/refunds/${encodeURIComponent(appointment_doc_id)}/refund/${encodeURIComponent(refund_doc_id)}`)
            return await resp.json() as GetRefundResponse
        }

        /**
         * Fetch a service
         */
        public async GetService(serviceId: string): Promise<GetServiceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}`)
            return await resp.json() as GetServiceResponse
        }

        /**
         * GetServicePrice fetches a service price by its ID
         */
        public async GetServicePrice(serviceId: string, priceId: string): Promise<GetServicePriceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/prices/${encodeURIComponent(priceId)}`)
            return await resp.json() as GetServicePriceResponse
        }

        /**
         * GetServiceSchedule fetches a service schedule by its ID
         */
        public async GetServiceSchedule(serviceId: string, scheduleId: string): Promise<GetServiceScheduleResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/schedules/${encodeURIComponent(scheduleId)}`)
            return await resp.json() as GetServiceScheduleResponse
        }

        /**
         * GetShift fetches a shift by id
         */
        public async GetShift(shiftId: string): Promise<GetShiftResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/shifts/${encodeURIComponent(shiftId)}`)
            return await resp.json() as GetShiftResponse
        }

        /**
         * GetStaffProfile fetches a staff profile by id
         */
        public async GetStaffProfile(staffProfileId: string): Promise<GetStaffProfileResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/staffprofiles/${encodeURIComponent(staffProfileId)}`)
            return await resp.json() as GetStaffProfileResponse
        }

        /**
         * GetStaffToken
         * get the staff token
         */
        public async GetStaffToken(staffDocID: string): Promise<GetStaffTokenResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/staff/${encodeURIComponent(staffDocID)}/staff-token`)
            return await resp.json() as GetStaffTokenResponse
        }

        /**
         * GetStaffVideoToken
         */
        public async GetStaffVideoToken(): Promise<GetStaffVideoTokenResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/staff-video-token`)
            return await resp.json() as GetStaffVideoTokenResponse
        }

        /**
         * HandoverChat
         * Handover chat to another vet
         */
        public async HandoverChat(chatID: string, params: HandoverChatRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/chats/${encodeURIComponent(chatID)}/handover`, JSON.stringify(params))
        }

        /**
         * InitiateCall
         */
        public async InitiateCall(chatID: string): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/chats/${encodeURIComponent(chatID)}/call`)
        }

        /**
         * List all appointments' typeform submissions for given pet
         */
        public async ListAppointmentsTypeformSubmissions(params: TypeformSubmissionsRequest): Promise<TypeformSubmissionsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "pet_doc_id": params.PetDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/typeform-submissions`, undefined, {query})
            return await resp.json() as TypeformSubmissionsResponse
        }

        /**
         * List Appointments
         * This endpoint lists all appointments.
         */
        public async ListAppointmentsV2(params: ListAppointmentsV2Request): Promise<ListAppointmentsV2Response> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "business_id":                                 params.BusinessDocID,
                "end_date":                                    params.EndDate,
                "exclude_appointments_in_consultation_states": params.ExcludeAppointmentsInConsultationStates?.map((v) => v),
                "items_per_page":                              String(params.RequestedItemsPerPage),
                "list_appointments_without_staff_profile":     params.ListAppointmentWithoutStaffProfiles === undefined ? undefined : String(params.ListAppointmentWithoutStaffProfiles),
                open:                                          params.Open === undefined ? undefined : String(params.Open),
                "order_by":                                    params.OrderBy,
                "page_number":                                 String(params.RequestedPageNumber),
                "pet_id":                                      params.PetDocID,
                "service_id":                                  params.ServiceDocID,
                "shift_id":                                    params.ShiftDocID,
                "staff_profile_id":                            params.StaffProfileDocID,
                "start_date":                                  params.StartDate,
                status:                                        params.Status?.map((v) => v),
                timezone:                                      params.Timezone,
                "user_id":                                     params.UserDocID,
                waiting:                                       params.Waiting === undefined ? undefined : String(params.Waiting),
                "waiting_room_entry":                          params.WaitingRoomEntry === undefined ? undefined : String(params.WaitingRoomEntry),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/appointments`, undefined, {query})
            return await resp.json() as ListAppointmentsV2Response
        }

        /**
         * List Available Appointments
         * This endpoint returns a list of available appointment time slots.
         */
        public async ListAvailabilityInternal(params: ListAvailabilityInternalQueryParams): Promise<ListAvailabilityResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "end_date":       params.EndDate,
                "service_doc_id": params.ServiceDocID,
                "start_date":     params.StartDate,
                timezone:         params.Timezone,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/schedule/availability`, undefined, {query})
            return await resp.json() as ListAvailabilityResponse
        }

        /**
         * List Available Appointment Days
         * This endpoint returns a list of days with available appointment time slots.
         */
        public async ListAvailableDaysInternal(params: ListAvailabileDaysQueryParams): Promise<ListAvailableDaysResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "end_date":       params.EndDate,
                "service_doc_id": params.ServiceID,
                "start_date":     params.StartDate,
                timezone:         params.Timezone,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/availabledays`, undefined, {query})
            return await resp.json() as ListAvailableDaysResponse
        }

        /**
         * List business service mappings
         */
        public async ListBusinessServiceMapping(serviceId: string): Promise<entities.ListBusinessServiceMappingResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/business`)
            return await resp.json() as entities.ListBusinessServiceMappingResponse
        }

        /**
         * ListChatAuditMessages lists all messages in a chat
         */
        public async ListChatAuditMessages(chatDocID: string): Promise<ListChatMessagesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/chat/${encodeURIComponent(chatDocID)}/messages`)
            return await resp.json() as ListChatMessagesResponse
        }

        /**
         * ListChatServices
         * List all chat services
         */
        public async ListChatServices(): Promise<ListChatServicesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/chat-services`)
            return await resp.json() as ListChatServicesResponse
        }

        /**
         * List all appointments booked from the given appointment
         */
        public async ListFollowUpAppointments(appointmentDocID: string, params: ListAppointmentFollowUpsRequest): Promise<ListAppointmentsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                timezone: params.Timezone,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/appointment-follow-ups/${encodeURIComponent(appointmentDocID)}`, undefined, {query})
            return await resp.json() as ListAppointmentsResponse
        }

        /**
         * Lists Refunds
         */
        public async ListRefunds(): Promise<ListRefundsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/refunds`)
            return await resp.json() as ListRefundsResponse
        }

        /**
         * Lists refunds
         */
        public async ListRefundsForAppointment(appointment_doc_id: string): Promise<ListRefundsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/refunds/${encodeURIComponent(appointment_doc_id)}`)
            return await resp.json() as ListRefundsResponse
        }

        /**
         * ListServicePrices lists all service prices
         */
        public async ListServicePrices(serviceId: string): Promise<ListServicePricesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/prices`)
            return await resp.json() as ListServicePricesResponse
        }

        /**
         * List webhooks and events for business
         */
        public async ListServicePricesBusinessMapping(serviceId: string, priceId: string): Promise<entities.ListServicePricesBusinessMappingResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/prices/${encodeURIComponent(priceId)}/businesses`)
            return await resp.json() as entities.ListServicePricesBusinessMappingResponse
        }

        /**
         * ListServiceSchedules lists all service schedules
         */
        public async ListServiceSchedules(serviceId: string): Promise<ListServiceSchedulesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/schedules`)
            return await resp.json() as ListServiceSchedulesResponse
        }

        /**
         * List Services
         * This endpoint provides a list of available televet appointment types such as Vet, Nurse or Specialists. These services are used to filter available appointment times.
         */
        public async ListServices(params: ListServicesRequest): Promise<ListServicesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "only_active": params.ActiveOnly === undefined ? undefined : String(params.ActiveOnly),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/services`, undefined, {query})
            return await resp.json() as ListServicesResponse
        }

        /**
         * Lists Services For Business
         * Lists services for user's business.
         */
        public async ListServicesForBusiness(): Promise<ListServicesByBusinessResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/bookinginternal/business/services`)
            return await resp.json() as ListServicesByBusinessResponse
        }

        /**
         * ListShifts lists all shifts
         */
        public async ListShifts(params: ListShiftsRequest): Promise<ListShiftsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "end_date":             params["end_date"],
                "shift_status":         params["shift_status"],
                "staff_profile_doc_id": params["staff_profile_id"],
                "start_date":           params["start_date"],
                timezone:               params.timezone,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/shifts`, undefined, {query})
            return await resp.json() as ListShiftsResponse
        }

        /**
         * ListChatServicesByStaff lists all chat services associated with a specific staff member
         */
        public async ListStaffChatServices(staffDocID: string, params: ListStaffChatServicesRequest): Promise<ListStaffChatServicesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "only_enabled": String(params.OnlyEnabled),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/staff/${encodeURIComponent(staffDocID)}/chat-services`, undefined, {query})
            return await resp.json() as ListStaffChatServicesResponse
        }

        /**
         * Lists all staff profiles
         */
        public async ListStaffProfiles(params: entities.ListStaffProfilesRequest): Promise<ListStaffProfilesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                name:             params.Name,
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/staffprofiles`, undefined, {query})
            return await resp.json() as ListStaffProfilesResponse
        }

        /**
         * ListServicesByStaff lists all services associated with a specific staff member
         */
        public async ListStaffSkills(staffDocID: string, params: ListStaffSkillsRequest): Promise<ListStaffSkillsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "only_enabled": String(params.OnlyEnabled),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/staff/${encodeURIComponent(staffDocID)}/services`, undefined, {query})
            return await resp.json() as ListStaffSkillsResponse
        }

        /**
         * MarkAppointmentAsDidNotAttend marks an appointment as did not attend
         */
        public async MarkAppointmentAsDidNotAttend(appointment_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/appointments/${encodeURIComponent(appointment_doc_id)}/did-not-attend`)
        }

        /**
         * MissedCallsDashboard
         * This endpoint returns a dashboard showing recent missed calls & statistics
         */
        public async MissedCallsDashboard(): Promise<entities.MissedCallDashboard> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/diagnostics/missed-calls`)
            return await resp.json() as entities.MissedCallDashboard
        }

        /**
         * MissedCallsDetail
         * This endpoint returns a dashboard showing recent missed calls & statistics
         */
        public async MissedCallsDetail(call_id: number): Promise<AppointmentEventsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/diagnostics/missed-calls/${encodeURIComponent(call_id)}`)
            return await resp.json() as AppointmentEventsResponse
        }

        /**
         * Mark appointment's treatment plan as ready
         */
        public async PatchAppointmentTreatmentPlanReady(appointmentId: string): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/appointments/${encodeURIComponent(appointmentId)}/treatment-plan-ready`)
        }

        /**
         * PatchMyStaffProfilePhoto Update My Staff Profile Photo
         * This endpoint updates the authed staff profiles with a photo url.
         */
        public async PatchMyStaffProfilePhoto(params: PatchMyStaffProfilePhotoRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/mystaffprofiles/photo`, JSON.stringify(params))
        }

        /**
         * PatchStaffProfilePhoto Update Staff Profiles With Photo
         * This endpoint allows a clinical admin to update the staff profiles with a photo url.
         */
        public async PatchStaffProfilePhoto(staffDocID: string, params: PatchMyStaffProfilePhotoRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/staffprofiles/${encodeURIComponent(staffDocID)}/photo`, JSON.stringify(params))
        }

        /**
         * PatchStaffProfileRcvsNumer Update rcvs number of a staff profile
         * This endpoint allows a clinical admin to update the rcvs number of a staff.
         */
        public async PatchStaffProfileRcvsNumer(staffDocID: string, params: PatchStaffProfileRcvsNumerRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/staffprofiles/${encodeURIComponent(staffDocID)}/rcvs`, JSON.stringify(params))
        }

        /**
         * Run CRON job from portal
         */
        public async ProcessDidNotAttend(): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/cron/not-attended-appointments`)
        }

        /**
         * Receives Stripe webhooks
         */
        public async ProcessStripeWebhook(method: "POST", body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/v1/stripe/webhook`, body, options)
        }

        /**
         * SendProductMessageToChat
         */
        public async SendProductMessageToChat(chatID: string, params: SendProductMessageRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/chats/${encodeURIComponent(chatID)}/product-message`, JSON.stringify(params))
        }

        /**
         * SyncShifts syncs shifts with Sling
         */
        public async SyncShifts(): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/shifts/sync`)
        }

        /**
         * UpdateAppointmentConsultStatus updates an appointment's consult status
         */
        public async UpdateAppointmentConsultStatus(appointmentId: string, params: UpdateAppointmentConsultStatusRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/staffappointments/${encodeURIComponent(appointmentId)}/consultstatus`, JSON.stringify(params))
        }

        /**
         * Update appointment time
         */
        public async UpdateAppointmentSlot(appointmentId: string, params: UpdateAppointmentSlotRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/appointments/${encodeURIComponent(appointmentId)}/slot`, JSON.stringify(params))
        }

        /**
         * Update staff profile for an appointment
         */
        public async UpdateAppointmentStaffProfile(appointmentId: string, params: UpdateAppointmentStaffProfileRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/staffappointments/${encodeURIComponent(appointmentId)}/staffprofile`, JSON.stringify(params))
        }

        /**
         * Update appointment video session
         */
        public async UpdateAppointmentVideoSession(appointment_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/appointments/${encodeURIComponent(appointment_doc_id)}/video-session`)
        }

        /**
         * UpdateChatCallState
         */
        public async UpdateChatCallState(chatID: string, params: UpdateChatCallStateRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/chats/${encodeURIComponent(chatID)}/call/state`, JSON.stringify(params))
        }

        /**
         * UpdateChatConsultStatus updates a chats consult status
         */
        public async UpdateChatConsultStatus(chatID: string, params: UpdateChatConsultStatusRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/chats/${encodeURIComponent(chatID)}/consultstatus`, JSON.stringify(params))
        }

        /**
         * UpdateChatService
         * Update a chat service
         */
        public async UpdateChatService(docID: string, params: UpdateChatServiceRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/chat-service/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * UpdateChatServiceStaff updates the enabled status of a chat service for a staff member
         */
        public async UpdateChatServiceStaff(staffDocID: string, serviceDocID: string, params: UpdateChatServiceStaffRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/staff/${encodeURIComponent(staffDocID)}/chat-services/${encodeURIComponent(serviceDocID)}`, JSON.stringify(params))
        }

        /**
         * UpdateChatStatus
         * Update chat status
         */
        public async UpdateChatStatus(chatDocID: string, params: UpdateChatStatusRequest): Promise<UpdateChatStatusResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/user/chat/${encodeURIComponent(chatDocID)}/status`, JSON.stringify(params))
            return await resp.json() as UpdateChatStatusResponse
        }

        /**
         * UpdateHandoverReason
         * Update a chat handover reason
         */
        public async UpdateHandoverReason(reasonID: string, params: UpdateHandoverReasonRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/handover/reasons/${encodeURIComponent(reasonID)}`, JSON.stringify(params))
        }

        /**
         * Updates an existing staff profile for a JWT user
         */
        public async UpdateMyStaffProfile(params: UpdateStaffProfileRequest): Promise<UpdateStaffProfileResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/mystaffprofiles`, JSON.stringify(params))
            return await resp.json() as UpdateStaffProfileResponse
        }

        /**
         * Updates a service for a location
         * Updates a service for a location determined by the API region
         */
        public async UpdateService(serviceId: string, params: UpdateServiceRequest): Promise<UpdateServiceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}`, JSON.stringify(params))
            return await resp.json() as UpdateServiceResponse
        }

        /**
         * UpdateServicePrice updates an existing service price
         */
        public async UpdateServicePrice(serviceId: string, priceId: string, params: UpdateServicePriceRequest): Promise<UpdateServicePriceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/prices/${encodeURIComponent(priceId)}`, JSON.stringify(params))
            return await resp.json() as UpdateServicePriceResponse
        }

        /**
         * UpdateServiceSchedule updates an existing service schedule
         */
        public async UpdateServiceSchedule(serviceId: string, scheduleId: string, params: UpdateServiceScheduleRequest): Promise<UpdateServiceScheduleResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/bookinginternal/services/${encodeURIComponent(serviceId)}/schedules/${encodeURIComponent(scheduleId)}`, JSON.stringify(params))
            return await resp.json() as UpdateServiceScheduleResponse
        }

        /**
         * UpdateShiftDates updates the start and end dates of a shift
         */
        public async UpdateShiftDates(shiftId: string, params: UpdateShiftDatesRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/shifts/${encodeURIComponent(shiftId)}/dates`, JSON.stringify(params))
        }

        /**
         * UpdateShiftStaffProfile updates the staff profile of a shift
         */
        public async UpdateShiftStaffProfile(shiftId: string, params: UpdateShiftStaffProfileRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/shifts/${encodeURIComponent(shiftId)}/staffprofile`, JSON.stringify(params))
        }

        /**
         * UpdateShiftStatus updates the status of a shift
         */
        public async UpdateShiftStatus(shiftId: string, params: UpdateShiftStatusRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/shifts/${encodeURIComponent(shiftId)}/status`, JSON.stringify(params))
        }

        /**
         * Updates an existing staff profile
         */
        public async UpdateStaffProfile(staffProfileId: string, params: UpdateStaffProfileRequest): Promise<UpdateStaffProfileResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/staffprofiles/${encodeURIComponent(staffProfileId)}`, JSON.stringify(params))
            return await resp.json() as UpdateStaffProfileResponse
        }

        /**
         * UpdateStaffSkill updates the enabled status of a service for a staff member
         */
        public async UpdateStaffSkill(staffDocID: string, serviceDocID: string, params: UpdateStaffSkillRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/staff/${encodeURIComponent(staffDocID)}/services/${encodeURIComponent(serviceDocID)}`, JSON.stringify(params))
        }
    }
}

export namespace consultationexternal {
    export interface CartLinksWithMultipass {
        "cart_url"?: string
        "basket_url": string
        "expires_at": string
    }

    export interface ConsultationTreatmentPlanCountResponse {
        /**
         * TotalUnreadTreatmentPlansCount
         * Total count of treatment plans from vet consultations that the user hasn't read yet.
         */
        "total_unread_treatment_plans": number
    }

    export interface ConsultationTreatmentPlanResponse {
        "treatment_plan": string
        resources: string[]
        "recommended_products": RecommendedProductResponse[]
        reasons: Reason[]
        "is_treatment_plan_read": boolean
        /**
         * This is the checkout url of the cart
         */
        "basket_url": string

        /**
         * This is the url for adding the items to the cart
         */
        "cart_url"?: string

        /**
         * the expiry time of the shopping cart links, if passed should be regenerated
         */
        "expires_at"?: string

        "staff_name": string
        "staff_profile_url": string
        "staff_role": string
    }

    export interface CreateClinicalHistoryReportRequest {
        /**
         * PetDocID
         * The **doc_id** of the pet in **UUID** format.
         */
        "pet_doc_id": string
    }

    export interface CreateClinicalHistoryReportResponse {
        /**
         * FileLocation
         * Valid url for retrieving clinical history report. The url is only valid for one hour.
         */
        "file_location": string
    }

    export interface GetRecommendedProductsRequest {
        ConsultationDocID?: string
        AppointmentDocID?: string
        ChatDocID?: string
    }

    export interface InstantRecommendationResponse {
        "recommended_products": RecommendedProductResponse[]
        "basket_url": string
        "cart_url"?: string
        "staff_name": string
        "pet_name": string
        "expires_at"?: string
    }

    export interface ListTreatmentPlansRequest {
        OrderBy: string
        PetDocID: string
        Timezone: string
        RequestedItemsPerPage: number
        RequestedPageNumber: number
    }

    export interface Reason {
        "likely_diagnosis_codes": VenomCode[]
        "likely_diagnosis_groups": VenomGroup[]
        "other_diagnosis_codes": VenomCode[]
        "other_diagnosis_groups": VenomGroup[]
    }

    export interface RecommendedProductResponse {
        "product_doc_id": string
        frequency: string
        usage: string
        "product_url": string
        "product_name": string
        "product_image": string
        "product_price": number
        currency: string
        quantity: number
        sku: string
    }

    export interface VenomCode {
        "display_name": string
        "venom_code": string
    }

    export interface VenomGroup {
        "display_name": string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Create Clinical History Report
         * This endpoint generates a PDF report of a pet’s history for a particular pet and returns the file location where it is stored.
         */
        public async CreateClinicalHistoryReport(params: CreateClinicalHistoryReportRequest): Promise<CreateClinicalHistoryReportResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/clinical-history-report`, JSON.stringify(params))
            return await resp.json() as CreateClinicalHistoryReportResponse
        }

        /**
         * Get Cart Links With Multipass
         * This endpoint returns the a url with multipass token which then will redirect to the appropriate cart page
         */
        public async GetCartLinksWithMultipass(params: GetRecommendedProductsRequest): Promise<CartLinksWithMultipass> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "appointment_doc_id":  params.AppointmentDocID,
                "chat_doc_id":         params.ChatDocID,
                "consultation_doc_id": params.ConsultationDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/recommended-products/multipass-cart-links`, undefined, {query})
            return await resp.json() as CartLinksWithMultipass
        }

        /**
         * GetRecommendedProducts
         * This endpoint returns the recommended products by a given document ID.
         */
        public async GetRecommendedProducts(params: GetRecommendedProductsRequest): Promise<InstantRecommendationResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "appointment_doc_id":  params.AppointmentDocID,
                "chat_doc_id":         params.ChatDocID,
                "consultation_doc_id": params.ConsultationDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/recommended-products`, undefined, {query})
            return await resp.json() as InstantRecommendationResponse
        }

        /**
         * Get Treatment Plan
         * This endpoint returns a treatment plan for an existing appointment with completed vet consultation.
         */
        public async GetTreatmentPlan(appointment_doc_id: string): Promise<ConsultationTreatmentPlanResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/appointment/${encodeURIComponent(appointment_doc_id)}/treatment-plan`)
            return await resp.json() as ConsultationTreatmentPlanResponse
        }

        /**
         * GetTreatmentPlanByConsultation
         * This endpoint returns a treatment plan by consultation docID.
         */
        public async GetTreatmentPlanByConsultation(consultation_doc_id: string): Promise<ConsultationTreatmentPlanResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/treatment-plan/${encodeURIComponent(consultation_doc_id)}`)
            return await resp.json() as ConsultationTreatmentPlanResponse
        }

        /**
         * Get Unread Treatment Plan Count
         * This endpoint returns the count of unread treatment plans for the user.
         */
        public async GetUnreadTreatmentPlanCountForPet(user_doc_id: string, pet_doc_id: string): Promise<ConsultationTreatmentPlanCountResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}/unread-treatment-plans`)
            return await resp.json() as ConsultationTreatmentPlanCountResponse
        }

        /**
         * ListTreatmentPlansForPet
         * This endpoint returns a list of treatment plans for the user's pet.
         */
        public async ListTreatmentPlansForPet(user_doc_id: string, pet_doc_id: string, params: ListTreatmentPlansRequest): Promise<entities.TreatmentPlanReadyList> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "order_by":       params.OrderBy,
                "page_number":    String(params.RequestedPageNumber),
                "pet_doc_id":     params.PetDocID,
                timezone:         params.Timezone,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}/treatment-plans`, undefined, {query})
            return await resp.json() as entities.TreatmentPlanReadyList
        }

        /**
         * MarkTreatmentPlanAsRead Mark treatment plan as read
         * This endpoint marks a treatment plan as read by the user.
         */
        public async MarkTreatmentPlanAsRead(appointment_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/appointment/${encodeURIComponent(appointment_doc_id)}/treatment-plan-read`)
        }

        /**
         * MarkTreatmentPlanAsReadByConsultation Mark treatment plan as read
         * This endpoint marks a treatment plan as read by the user, using a consultationDocID.
         */
        public async MarkTreatmentPlanAsReadByConsultation(consultation_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/treatment-plan/${encodeURIComponent(consultation_doc_id)}/read`)
        }
    }
}

export namespace consultationinternal {
    export interface AddChatConsultationResponse {
        /**
         * consultation doc_id
         */
        "doc_id": string
    }

    export interface CheckPostcodeEligibilityRequest {
        Postcode: string
    }

    export interface CheckPostcodeEligibilityResponse {
        "is_eligible": boolean
        "covered_by_partner_practice"?: GetPartnerPracticeResponse
    }

    export interface ClinicalHistory {
        "clinical_history_category_doc_id": string
        "clinical_history_category_name": string
        "clinical_history_submission": SubmissionResponse
    }

    export interface ClinicalNoteProductRecommendation {
        frequency: string
        usage: string
        "product_name": string
    }

    export interface ClinicalNotePromptConfigResponse {
        "doc_id": string
        "clinical_note_example": string
        "clinical_note_system_prompt": string
        "is_active": boolean
        "created_at": string
        "updated_at"?: string
    }

    export interface ConfigureReasonsPostClinicalHistoryFormsRequest {
        "post_clinical_history_forms": string[]
    }

    export interface ConfigureReasonsPreClinicalHistoryFormsRequest {
        "pre_clinical_history_forms": string[]
    }

    export interface ConfigureReasonsPreExaminationFormsRequest {
        "pre_examination_forms": string[]
    }

    export interface Consultation {
        "doc_id": uuid.UUID
        "appointment_id": uuid.UUID
        "pet_id": uuid.UUID
        "owner_id": uuid.UUID
        "created_at": string
        state: string
        notes: string
        appointment?: bookinginternal.GetAppointmentResponse
        "chat_doc_id": uuid.UUID
        chat?: entities.ChatResponse
    }

    export interface ConsultationHistory {
        "consultation_doc_id": uuid.UUID
        "completed_on": string
        notes: string
        "treatment_plan"?: string
        "staff_doc_id"?: uuid.UUID
        reasons: ConsultationHistoryReason[]
        "recommended_products": RecommendedProductResponse[]
        "external_shopify_order_id"?: number
        "purchased_at"?: string
    }

    export interface ConsultationHistoryReason {
        "reason_doc_id": uuid.UUID
        "likely_diagnosis_codes": ReasonDiagnosis[]
        "likely_diagnosis_groups": ReasonDiagnosis[]
        "other_diagnosis_codes": ReasonDiagnosis[]
        "other_diagnosis_groups": ReasonDiagnosis[]
        examinations: Examination[]
        "clinical_history": ClinicalHistory[]
        "pre_clinical_history_submissions": SubmissionResponse[]
        "post_clinical_history_submissions": SubmissionResponse[]
        "pre_examinations_submissions": SubmissionResponse[]
    }

    export interface ConsultationStateResponse {
        state: string
        "state_changed_at": string
    }

    export interface ConsultationTypeResponse {
        "doc_id": uuid.UUID
        type: string
    }

    export interface CreateClinicalHistoryCategoryRequest {
        name: string
        "form_name": string
        "group_doc_id": string
    }

    export interface CreateClinicalHistoryCategoryResponse {
        "doc_id": string
    }

    export interface CreateClinicalHistoryGroupRequest {
        name: string
    }

    export interface CreateClinicalHistoryGroupResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateClinicalNoteChatPromptConfigRequest {
        "clinical_note_example": string
        "clinical_note_system_prompt": string
    }

    export interface CreateClinicalNoteResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateConsultationRequest {
        "appointment_id": string
        "pet_id": string
        "owner_id": string
        notes: string
    }

    export interface CreateConsultationResponse {
        id: uuid.UUID
    }

    export interface CreateConsultationTypeRequest {
        type: string
    }

    export interface CreateConsultationTypeResponse {
        DocID: uuid.UUID
    }

    export interface CreateExaminationCategoryRequest {
        name: string
        "form_name": string
        "group_doc_id": string
    }

    export interface CreateExaminationCategoryResponse {
        "doc_id": string
    }

    export interface CreateExaminationGroupRequest {
        name: string
    }

    export interface CreateExaminationGroupResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateFormRequest {
        name: string
        type: string
    }

    export interface CreateFormResponse {
        DocID: uuid.UUID
    }

    export interface CreatePartnerPracticeRequest {
        address: string
        name: string
        "google_place_id": string
        long: string
        lat: string
    }

    export interface CreatePartnerPracticeResponse {
        "doc_id": uuid.UUID
    }

    export interface CreatePrescriptionPDFRequest {
        prescription: PrescriptionFormSubmission
    }

    export interface CreatePrescriptionPDFResponse {
        path: string
    }

    export interface CreateReason {
        "reason_type_doc_id": uuid.UUID
        "likely_diagnosis_codes": uuid.UUID[]
        "likely_diagnosis_groups": uuid.UUID[]
        "other_diagnosis_codes": uuid.UUID[]
        "other_diagnosis_groups": uuid.UUID[]
        examinations: CreateReasonExamination[]
        "clinical_history": CreateReasonClinicalHistory[]
        "pre_clinical_history_submissions": FormSubmission[]
        "post_clinical_history_submissions": FormSubmission[]
        "pre_examinations_submissions": FormSubmission[]
    }

    export interface CreateReasonClinicalHistory {
        "clinical_history_category_doc_id": uuid.UUID
        "form_name": string
        "form_data": FormData
    }

    export interface CreateReasonExamination {
        "examination_category_doc_id": uuid.UUID
        "form_name": string
        "form_data": FormData
    }

    export interface CreateReasonGroupRequest {
        name: string
    }

    export interface CreateReasonGroupResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateReasonTypeRequest {
        name: string
        "group_doc_id": string
    }

    export interface CreateReasonTypeResponse {
        "doc_id": string
    }

    export interface CreateReasonsRequest {
        reasons: CreateReason[]
    }

    export interface CreateSubmissionRequest {
        /**
         * form io payload of the submission including the form version and data
         */
        "form_data": FormData

        /**
         * name of the form that was completed
         */
        "form_name": string

        /**
         * id of the consultation that the form was submitted for
         */
        "consultation_id": uuid.UUID
    }

    export interface CreateSubmissionResponse {
        id: uuid.UUID
    }

    export interface CreateTreatmentPlanTemplateRequest {
        name: string
        template: string
        resources: string[]
    }

    export interface CreateTreatmentPlanTemplateResponse {
        id: uuid.UUID
    }

    export interface EditTreatmentPlanTemplateRequest {
        name: string
        template: string
        resources: string[]
    }

    export interface EditTreatmentPlanTemplateResponse {
        "doc_id": uuid.UUID
    }

    export interface Examination {
        "examination_category_doc_id": string
        "examination_category_name": string
        "examination_submission": SubmissionResponse
    }

    export interface FormData {
        "_fvid": number
        data: JSONValue
    }

    export interface FormResponse {
        "doc_id": uuid.UUID
        name: string
        "consultation_type": string
        "created_at": string
    }

    export interface FormSubmission {
        "form_data": FormData
        "form_name": string
    }

    export interface GenerateClinicalNoteRequest {
        reasons: CreateReason[]
        "treatment_plan"?: string
        "recommended_products"?: ClinicalNoteProductRecommendation[]
        outcome?: JSONValue
    }

    export interface GenerateClinicalNoteResponse {
        "clinical_note_generated": string
    }

    export interface GetAllConsultationsResponse {
        pagination: shared.Pagination
        consultations: Consultation[]
    }

    export interface GetClinicalHistoryCategoryResponse {
        "doc_id": string
        name: string
        "form_name": string
        "group_doc_id": string
    }

    export interface GetClinicalHistoryGroupResponse {
        "doc_id": uuid.UUID
        name: string
    }

    export interface GetConsultationAgeResponse {
        years: number
        months: number
    }

    export interface GetConsultationAppointmentDatesResponse {
        utc: string
        local: string
        "date_friendly": string
        "time_friendly": string
        timezone: string
    }

    export interface GetConsultationAppointmentResponse {
        "doc_id": string
        start: GetConsultationAppointmentDatesResponse
        end: GetConsultationAppointmentDatesResponse
        status: string
        "pet_id": string
        "user_id": string
        "room_id"?: string
        "session_id"?: string
        "payment_intent_id"?: string
        "staff_profile_id"?: string
        "staff_profile_name"?: string
        "staff_profile_description"?: string
        "staff_profile_photo_url"?: string
        "staff_profile_rcvs_number"?: string
        "is_waiting": boolean
        "is_open": boolean
        "is_late": boolean
        "is_priority": boolean
        "is_overdue": boolean
        "started_waiting_time"?: string
        "last_seen_waiting"?: string
        "waiting_time_in_minutes"?: number
        "actual_start_time"?: string
        "actual_end_time"?: string
        "consult_status"?: string
        "disrupted_reason"?: string
        pet: GetConsultationPetResponse
        user: GetConsultationUserResponse
        business: GetConsultationBusinessResponse
        notes?: string
        "appointment_photos_urls": string[]
        "symptom_checker_session_doc_id"?: string
        "symptom_checker_session_algorithm_title"?: string
        "symptom_checker_session_outcome_urgency"?: string
        "service_name": string
        "service_doc_id": string
        "service_is_trial": boolean
        "typeform_event_id": string
        "on_demand": boolean
        "video_stream_provider"?: string
        "previous_chat_doc_id"?: string
        "previous_chat"?: GetConsultationChatResponse
        reassigned: boolean
    }

    export interface GetConsultationBusinessResponse {
        id: string
        name: string
        "external_id"?: string
        "partner_integration": string
    }

    export interface GetConsultationByAppointmentResponse {
        "consultation_doc_id": string
    }

    export interface GetConsultationChatResponse {
        "doc_id": string
        "channel_id": string
        status: string
        "status_history": string[]
        "channel_type": string
        "updated_at": utilities.Dates
        pet: GetConsultationPetResponse
        user: GetConsultationUserResponse
        business: GetConsultationBusinessResponse
        participants: entities.GetParticipantResponse[]
        "created_at": utilities.Dates
        "consult_status": utilities.ConsultStatus
        "disrupted_reason": string
        "actual_start_time": utilities.Dates
        "actual_end_time": utilities.Dates
        "service_name": string
        "service_doc_id": string
        "time_zone": string
        "chat_video_room": entities.ChatVideoRoom
    }

    export interface GetConsultationHistoryResponse {
        "doc_id": uuid.UUID
        "appointment_id": uuid.UUID
        "pet_id": uuid.UUID
        "owner_id": uuid.UUID
        "created_at": string
        "staff_id"?: uuid.UUID
        "treatment_okan"?: string
        "basket_url": string
        "states_history": ConsultationStateResponse[]
        resources: string[]
        notes: string
        "chat_doc_id": uuid.UUID
    }

    export interface GetConsultationPetBreedsResponseItem {
        "doc_id": string
        name: string
    }

    export interface GetConsultationPetResponse {
        "doc_id": string
        name: string
        gender: string
        weight?: GetConsultationPetWeightUnitsResponse
        neutered?: boolean
        "date_of_birth": string
        breed?: GetConsultationPetBreedsResponseItem
        "created_at": string
        species: string
        "species_id": uuid.UUID
        "photo_url"?: string
        "policy_customer_no"?: string
        "policy_no"?: string
        "policy_active": boolean
        "policy_registered_date"?: string
        "policy_disabled_date"?: string
        "requires_verification": boolean
        triage: boolean
        age: GetConsultationAgeResponse
    }

    export interface GetConsultationPetWeightUnitsResponse {
        g: number
        kg: number
        lbs: number
    }

    export interface GetConsultationResponse {
        "doc_id": uuid.UUID
        "appointment_id"?: uuid.UUID
        "pet_id": uuid.UUID
        "owner_id": uuid.UUID
        pet: GetConsultationPetResponse
        user: GetConsultationUserResponse
        business: GetConsultationBusinessResponse
        "created_at": string
        "staff_id"?: uuid.UUID
        state: string
        "treatment_plan"?: string
        resources: string[]
        submissions: SubmissionResponse[]
        notes: string
        appointment?: GetConsultationAppointmentResponse
        reasons: Reason[]
        "termination_reason"?: SubmissionResponse
        "recommended_products": RecommendedProductResponse[]
        outcome?: SubmissionResponse
        prescription: PrescriptionSubmissionResponse
        "prescription_submitted": boolean
        "booked_appointments"?: GetConsultationAppointmentResponse[]
        "chat_doc_id"?: uuid.UUID
        chat?: GetConsultationChatResponse
        "patient_media_files": PatientMediaFileResponse[]
        "recommended_products_locked"?: string
        "nfavps_submission"?: NFAVPSSubmission
        "nfavps_submitted": boolean
        "purchased_at"?: string
        "external_shopify_order_id"?: number
    }

    export interface GetConsultationTypeResponse {
        "doc_id": uuid.UUID
        type: string
    }

    export interface GetConsultationUserResponse {
        id: number
        "doc_id": uuid.UUID
        email: string
        "first_name": string
        "last_name": string
        "phone_number"?: string
        "phone_number_raw"?: string
        "phone_number_cc"?: string
        "current_policy_customer_no"?: string
        "requires_verification": boolean
        "post_code": string
        address?: UserAddress
    }

    export interface GetConsultationsQueryParams {
        /**
         * the id of the pet that is participating in the consultation
         */
        PetID: string

        /**
         * the time before which the consultations were created
         */
        CreatedBefore: string

        /**
         * the time after which the consultations were created
         */
        CreatedAfter: string

        /**
         * the order by field
         */
        OrderBy: string

        /**
         * the consultation status to filter by
         */
        ConsultStatus: string

        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number
    }

    export interface GetExaminationCategoryResponse {
        "doc_id": string
        name: string
        "form_name": string
    }

    export interface GetExaminationGroupResponse {
        "doc_id": uuid.UUID
        name: string
    }

    export interface GetFormsResponse {
        forms: FormResponse[]
    }

    export interface GetPartnerPracticeResponse {
        "doc_id": uuid.UUID
        address: string
        name: string
        "google_place_id": string
        long: string
        lat: string
    }

    export interface GetPetConsultationsHistoryResposnse {
        "consultations_history": ConsultationHistory[]
    }

    export interface GetReasonGroupResponse {
        "doc_id": uuid.UUID
        name: string
    }

    export interface GetReasonPostClinicalHistoryFormConfigResponse {
        "post_clinical_history_forms": string[]
    }

    export interface GetReasonPreClinicalHistoryFormConfigResponse {
        "pre_clinical_history_forms": string[]
    }

    export interface GetReasonPreExaminationFormConfigResponse {
        "pre_examination_forms": string[]
    }

    export interface GetReasonResponse {
        "reason_doc_id": uuid.UUID
        "reason_type_doc_id": uuid.UUID
        "likely_diagnosis_codes": uuid.UUID[]
        "likely_diagnosis_groups": uuid.UUID[]
        "other_diagnosis_codes": uuid.UUID[]
        "other_diagnosis_groups": uuid.UUID[]
        examinations: CreateReasonExamination[]
        "clinical_history": CreateReasonClinicalHistory[]
        "pre_clinical_history_submissions": FormSubmission[]
        "post_clinical_history_submissions": FormSubmission[]
        "pre_examinations_submissions": FormSubmission[]
    }

    export interface GetSubmissionsResponse {
        submissions: uuid.UUID[]
    }

    export interface HealthCheckReportRequest {
        "health_score": HealthScoreForm
    }

    export interface HealthCheckReportResponse {
        "file_location": string
    }

    export interface HealthScoreForm {
        "form_data": FormData
        "form_name": string
    }

    export interface ListClinicalHistoryCategoriesResponse {
        "clinical_history_categories": GetClinicalHistoryCategoryResponse[]
    }

    export interface ListClinicalHistoryGroupWithCategoriesResponse {
        "clinical_history_groups_with_categories": entities.ListClinicalHistoryGroupWithCategories[]
    }

    export interface ListConsultationTypesResponse {
        "consultation_type": ConsultationTypeResponse[]
    }

    export interface ListExaminationCategoriesResponse {
        "examination_categories": GetExaminationCategoryResponse[]
    }

    export interface ListExaminationCategoryResponse {
        "doc_id": uuid.UUID
        name: string
        "form_name": string
    }

    export interface ListExaminationGroupWithCategories {
        "group_doc_id": uuid.UUID
        "group_name": string
        "examination_categories": ListExaminationCategoryResponse[]
    }

    export interface ListExaminationGroupWithCategoriesResponse {
        "examination_groups_with_categories": ListExaminationGroupWithCategories[]
    }

    export interface ListPartnerPracticesReponse {
        practices: entities.PartnerPractice[]
    }

    export interface ListPatientMediaFilesRequest {
        PetDocID: string
        RequestedItemsPerPage: number
        RequestedPageNumber: number
        FileName: string
        MediaType: string
        StartDate: string
        EndDate: string
        ConsultationDocID: string
    }

    export interface ListPatientMediaFilesResponse {
        "patient_files": PatientMediaFileResponse[]
        pagination: shared.Pagination
    }

    export interface ListPromptConfigsResponse {
        "clinical_note_prompt_configs": ClinicalNotePromptConfigResponse[]
    }

    export interface ListReasonGroupWithTypesResponse {
        "reason_groups_with_types": entities.ListReasonGroupWithTypes[]
    }

    export interface ListReasonTypesResponse {
        "reason_types": entities.ListReasonTypesResponse[]
    }

    export interface ListTreatmentPlanTemplatesResponse {
        templates: TemplateResponse[]
    }

    export interface NFAVPSFormRequest {
        "form_data": FormData
        "form_name": string
    }

    export interface NFAVPSSubmission {
        "submission_doc_id": string
        "form_data": FormData
        "form_name": string
    }

    export interface OutcomeSubmission {
        "form_data": FormData
        "form_name": string
    }

    export interface PaginatedConsultationHistory {
        "consultations_history": GetConsultationResponse[]
        pagination: shared.Pagination
    }

    export interface PaginatedConsultationsHistoryRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * order by "asc" or "desc" based on created consultation date
         */
        OrderBy: string
    }

    export interface PatientMediaFileResponse {
        /**
         * The unique identifier for the file
         */
        "doc_id": string

        /**
         * The pet this file is associated with
         */
        "pet_doc_id": string

        /**
         * Name of the file
         */
        "file_name": string

        /**
         * URL to the file
         */
        "file_url": string

        /**
         * Possible values are: lab_report, photo, video
         */
        "media_type": string

        /**
         * The consultation this file is associated with
         */
        "consultation_doc_id"?: string
    }

    export interface PrescriptionCartResponse {
        "checkout_url": string
        "cart_url": string
    }

    export interface PrescriptionFormSubmission {
        "form_data": FormData
        "form_name": string
    }

    export interface PrescriptionSubmission {
        "form_data": FormData
        "form_name": string
        "partner_practice_name": string
        "share_document": boolean
        "document_url": string
    }

    export interface PrescriptionSubmissionResponse {
        "form_data": FormData
        "form_name": string
        "partner_practice_name": string
        "share_document": boolean
        "document_url": string
        "submission_doc_id": string
    }

    export interface Reason {
        "likely_diagnosis_codes": VenomCode[]
        "likely_diagnosis_groups": VenomGroup[]
        "other_diagnosis_codes": VenomCode[]
        "other_diagnosis_groups": VenomGroup[]
        "reason_type_doc_id": uuid.UUID
        "reason_type_name": string
        examinations: Examination[]
        "clinical_history": ClinicalHistory[]
        "pre_clinical_history_submissions": SubmissionResponse[]
        "post_clinical_history_submissions": SubmissionResponse[]
        "pre_examinations_submissions": SubmissionResponse[]
    }

    export interface ReasonDiagnosis {
        "doc_id": uuid.UUID
        name: string
    }

    export interface ReasonFormConfigResponse {
        "pre_clinical_history_forms": string[]
        "post_clinical_history_forms": string[]
        "pre_examination_forms": string[]
    }

    export interface ReasonsResponse {
        reasons: GetReasonResponse[]
    }

    export interface RecommendedProduct {
        "product_doc_id": uuid.UUID
        frequency: string
        usage: string
        "product_url": string
        "product_name": string
        "product_image": string
        "product_price": number
        currency: string
        quantity: number
        sku: string
    }

    export interface RecommendedProductResponse {
        "product_doc_id": uuid.UUID
        frequency: string
        usage: string
        "product_url": string
        "product_name": string
        "product_image": string
        "product_price": number
        currency: string
        quantity: number
        sku: string
        purchased: boolean
        "purchased_quantity": number
    }

    export interface SetTerminatedConsultationStateRequest {
        "form_data": FormData
        "form_name": string
    }

    export interface SubmissionResponse {
        "doc_id": uuid.UUID
        "form_data": FormData
        "form_name": string
    }

    export interface TemplateResponse {
        "doc_id": uuid.UUID
        name: string
        template: string
        resources: string[]
    }

    export interface UpdateClinicalHistoryCategoryRequest {
        name: string
        "form_name": string
        "group_doc_id": string
    }

    export interface UpdateClinicalHistoryCategoryResponse {
        "doc_id": string
    }

    export interface UpdateClinicalHistoryGroupRequest {
        name: string
    }

    export interface UpdateConsultationRequest {
        /**
         * the id of the staff member that is participating in the consultation
         */
        "staff_id": string

        /**
         * the treatment plan of the consultation
         */
        "treatment_plan": string

        /**
         * the resources of the consultation
         */
        resources: string[]

        /**
         * the internal resources of the consultation. This is additive and not accessable via any endpoint.
         */
        "internal_resources": string[]

        /**
         * the internal notes of the consultation
         */
        notes: string

        /**
         * the recommended products of the consultation
         */
        "recommended_products": RecommendedProduct[]

        /**
         * the outcome form submission
         */
        outcome?: OutcomeSubmission

        /**
         * the prescription form submission
         */
        prescription?: PrescriptionSubmission
    }

    export interface UpdateConsultationStateRequest {
        /**
         * the state of the consultation
         */
        state: string
    }

    export interface UpdateConsultationTypeRequest {
        type: string
    }

    export interface UpdateExaminationCategoryRequest {
        name: string
        "form_name": string
        "group_doc_id": string
    }

    export interface UpdateExaminationCategoryResponse {
        "doc_id": string
    }

    export interface UpdateExaminationGroupRequest {
        name: string
    }

    export interface UpdateFormRequest {
        name: string
        type: string
    }

    export interface UpdatePartnerPracticeRequest {
        address: string
        name: string
        "google_place_id": string
        long: string
        lat: string
    }

    export interface UpdateReasonGroupRequest {
        name: string
    }

    export interface UpdateReasonTypeRequest {
        name: string
        "group_doc_id": string
    }

    export interface UpdateSubmissionRequest {
        "form_name": string
        "form_data": FormData
        "is_completed": boolean
    }

    /**
     * UploadPatientFileRequest represents the request payload
     */
    export interface UploadPatientFileRequest {
        "pet_doc_id": string
        "media_type": string
        "file_name": string
        "file_url": string
        "consultation_doc_id"?: string
    }

    /**
     * UploadPatientFileResponse represents the response payload
     */
    export interface UploadPatientFileResponse {
        "doc_id": uuid.UUID
    }

    /**
     * ====== END OF PET RESPONSE ======
     */
    export interface UserAddress {
        "line_1": string
        "line_2": string
        "town_or_city": string
        county: string
        country: string
        postcode: string
    }

    export interface VenomCode {
        "display_name": string
        "venom_code": string
        "venom_code_doc_id": uuid.UUID
    }

    export interface VenomGroup {
        "display_name": string
        "venom_group_doc_id": uuid.UUID
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Activate Prompt Config
         */
        public async ActivatePromptConfig(doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/clinical-note-prompts-config/${encodeURIComponent(doc_id)}/activate`)
        }

        /**
         * AddChatConsultation
         * Create chat consultation
         */
        public async AddChatConsultation(params: entities.CreateChatConsultationRequest): Promise<AddChatConsultationResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/consultation/chat`, JSON.stringify(params))
            return await resp.json() as AddChatConsultationResponse
        }

        /**
         * CheckPostcodeEligibility checks if a postcode is eligible for prescriptions
         */
        public async CheckPostcodeEligibility(params: CheckPostcodeEligibilityRequest): Promise<CheckPostcodeEligibilityResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                postcode: params.Postcode,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultationinternal/check_postcode_eligibility`, undefined, {query})
            return await resp.json() as CheckPostcodeEligibilityResponse
        }

        /**
         * Create Clinical History Category
         * Add a new Clinical History Category
         */
        public async CreateClinicalHistoryCategory(params: CreateClinicalHistoryCategoryRequest): Promise<CreateClinicalHistoryCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/clinical-history-category`, JSON.stringify(params))
            return await resp.json() as CreateClinicalHistoryCategoryResponse
        }

        /**
         * Create Clinical History Group
         */
        public async CreateClinicalHistoryGroup(params: CreateClinicalHistoryGroupRequest): Promise<CreateClinicalHistoryGroupResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/clinical-history-group`, JSON.stringify(params))
            return await resp.json() as CreateClinicalHistoryGroupResponse
        }

        /**
         * Create Prompt Config
         * Add a new Clinical Note Chat Prompt Config to the system for the chatbot to use
         */
        public async CreateClinicalNoteChatPromptConfig(params: CreateClinicalNoteChatPromptConfigRequest): Promise<CreateClinicalNoteResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/clinical-note-prompts-config`, JSON.stringify(params))
            return await resp.json() as CreateClinicalNoteResponse
        }

        /**
         * Create Consultation
         * Add a new consultation
         */
        public async CreateConsultation(params: CreateConsultationRequest): Promise<CreateConsultationResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/consultation`, JSON.stringify(params))
            return await resp.json() as CreateConsultationResponse
        }

        /**
         * Creates a new consultation type.
         */
        public async CreateConsultationType(params: CreateConsultationTypeRequest): Promise<CreateConsultationTypeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/consultation-type`, JSON.stringify(params))
            return await resp.json() as CreateConsultationTypeResponse
        }

        /**
         * Create Examination Category
         * Add a new Examination Category
         */
        public async CreateExaminationCategory(params: CreateExaminationCategoryRequest): Promise<CreateExaminationCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/examinationCategory`, JSON.stringify(params))
            return await resp.json() as CreateExaminationCategoryResponse
        }

        /**
         * Create Examination Group
         */
        public async CreateExaminationGroup(params: CreateExaminationGroupRequest): Promise<CreateExaminationGroupResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/examination-group`, JSON.stringify(params))
            return await resp.json() as CreateExaminationGroupResponse
        }

        /**
         * Creates a new form.
         */
        public async CreateForm(params: CreateFormRequest): Promise<CreateFormResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/form`, JSON.stringify(params))
            return await resp.json() as CreateFormResponse
        }

        /**
         * Create Health Check Report
         */
        public async CreateHealthCheckReport(docID: string, params: HealthCheckReportRequest): Promise<HealthCheckReportResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/consultations/${encodeURIComponent(docID)}/health-check-report`, JSON.stringify(params))
            return await resp.json() as HealthCheckReportResponse
        }

        /**
         * CreateNFAVPSSubmission
         * creates a new NFA-VPS form submission entry
         */
        public async CreateNFAVPSSubmission(consultationDocID: string, params: NFAVPSFormRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/consultationinternal/consultations/${encodeURIComponent(consultationDocID)}/forms/nfa-vps`, JSON.stringify(params))
        }

        /**
         * CreatePartnerPractice
         * Creates a new partner practice
         */
        public async CreatePartnerPractice(params: CreatePartnerPracticeRequest): Promise<CreatePartnerPracticeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/consultation/partner-practice`, JSON.stringify(params))
            return await resp.json() as CreatePartnerPracticeResponse
        }

        /**
         * Generate prescription PDF and upload it to bucket
         * This endpoint generates and uploads the prescription pdf to our bucket. Then returns the URL
         */
        public async CreatePrescriptionPDF(consultationID: string, params: CreatePrescriptionPDFRequest): Promise<CreatePrescriptionPDFResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/consultations/${encodeURIComponent(consultationID)}/upload-prescription`, JSON.stringify(params))
            return await resp.json() as CreatePrescriptionPDFResponse
        }

        /**
         * Create Reason Group
         */
        public async CreateReasonGroup(params: CreateReasonGroupRequest): Promise<CreateReasonGroupResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/reason-group`, JSON.stringify(params))
            return await resp.json() as CreateReasonGroupResponse
        }

        /**
         * Create Reason Type
         * Add a new Reason Type
         */
        public async CreateReasonType(params: CreateReasonTypeRequest): Promise<CreateReasonTypeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/reason-type`, JSON.stringify(params))
            return await resp.json() as CreateReasonTypeResponse
        }

        /**
         * Add reason with venom code diagnosis
         * Add reason with venom code diagnosis to consultation
         */
        public async CreateReasons(docID: string, params: CreateReasonsRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/consultations/${encodeURIComponent(docID)}/reasons`, JSON.stringify(params))
        }

        /**
         * Configure Post Clinical History Forms
         * Configure the post clinical history forms and the order they appear
         */
        public async CreateReasonsPostClinicalHistoryFormsConfig(params: ConfigureReasonsPostClinicalHistoryFormsRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/reasons-config/post-clinical-history-forms`, JSON.stringify(params))
        }

        /**
         * Configure Pre Clinical History Forms
         * Configure the pre clinical history forms and the order they appear
         */
        public async CreateReasonsPreClinicalHistoryFormsConfig(params: ConfigureReasonsPreClinicalHistoryFormsRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/reasons-config/pre-clinical-history-forms`, JSON.stringify(params))
        }

        /**
         * Configure Pre General Examination Forms
         * Configure the pre clinical history forms and the order they appear
         */
        public async CreateReasonsPreExaminationFormsConfig(params: ConfigureReasonsPreExaminationFormsRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/reasons-config/pre-examination-forms`, JSON.stringify(params))
        }

        /**
         * CreateShopifyCartLink
         * Generates a shopify cart link for a consultation
         */
        public async CreateShopifyCartLink(docID: string, params: entities.PrescriptionCartRequest): Promise<PrescriptionCartResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/consultation/cart/${encodeURIComponent(docID)}`, JSON.stringify(params))
            return await resp.json() as PrescriptionCartResponse
        }

        /**
         * Create Submission
         * Add a new form submission containing the data and the form that was submitted
         */
        public async CreateSubmission(params: CreateSubmissionRequest): Promise<CreateSubmissionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/submission`, JSON.stringify(params))
            return await resp.json() as CreateSubmissionResponse
        }

        /**
         * CreateTreatmentPlanTemplate adds a new treatment plan template.
         */
        public async CreateTreatmentPlanTemplate(params: CreateTreatmentPlanTemplateRequest): Promise<CreateTreatmentPlanTemplateResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/treatment-plan-templates`, JSON.stringify(params))
            return await resp.json() as CreateTreatmentPlanTemplateResponse
        }

        /**
         * Delete Clinical History Category
         * Delete an Clinical History Category
         */
        public async DeleteClinicalHistoryCategory(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/clinical-history-category/${encodeURIComponent(docID)}`)
        }

        /**
         * Delete Clinical History Group
         */
        public async DeleteClinicalHistoryGroup(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/clinical-history-group/${encodeURIComponent(docID)}`)
        }

        /**
         * Delete Examination Category
         * Delete an Examination Category
         */
        public async DeleteExaminationCategory(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/examination-categories/${encodeURIComponent(docID)}`)
        }

        /**
         * Delete Examination Group
         */
        public async DeleteExaminationGroup(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/examination-group/${encodeURIComponent(docID)}`)
        }

        /**
         * Deletes an existing form.
         */
        public async DeleteForm(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/form/${encodeURIComponent(docID)}`)
        }

        /**
         * DeletePartnerPractice
         * Delete Partner Practice
         */
        public async DeletePartnerPractice(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/consultation/partner-practice/${encodeURIComponent(docID)}`)
        }

        /**
         * DeletePatientMediaFile deletes a media file for a pet
         */
        public async DeletePatientMediaFile(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/consultationinternal/patient_files/${encodeURIComponent(docID)}`)
        }

        /**
         * Delete Reason Group
         */
        public async DeleteReasonGroup(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/reason-group/${encodeURIComponent(docID)}`)
        }

        /**
         * Delete Reason Type
         * Delete an Reason Type
         */
        public async DeleteReasonType(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/reason-type/${encodeURIComponent(docID)}`)
        }

        /**
         * Delete treatment plan template
         */
        public async DeleteTreatmentPlanTemplate(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/treatment-plan-templates/${encodeURIComponent(docID)}`)
        }

        /**
         * EditTreatmentPlanTemplate edits a given treatment plan template.
         */
        public async EditTreatmentPlanTemplate(docID: string, params: EditTreatmentPlanTemplateRequest): Promise<EditTreatmentPlanTemplateResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/treatment-plan-templates/${encodeURIComponent(docID)}`, JSON.stringify(params))
            return await resp.json() as EditTreatmentPlanTemplateResponse
        }

        /**
         * Generates a clinical note
         * Generated an ai clinical note for a consultation using pets condition information
         */
        public async GenerateClinicalNote(docID: string, params: GenerateClinicalNoteRequest): Promise<GenerateClinicalNoteResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/consultations/${encodeURIComponent(docID)}/generate-clinical-note`, JSON.stringify(params))
            return await resp.json() as GenerateClinicalNoteResponse
        }

        /**
         * Get Clinical History Group
         */
        public async GetClinicalHistoryGroup(docID: string): Promise<GetClinicalHistoryGroupResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/clinical-history-group/${encodeURIComponent(docID)}`)
            return await resp.json() as GetClinicalHistoryGroupResponse
        }

        /**
         * Get a consultation
         * Get a consultation by doc_id
         */
        public async GetConsultation(docID: string): Promise<GetConsultationResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultations/${encodeURIComponent(docID)}`)
            return await resp.json() as GetConsultationResponse
        }

        /**
         * Get consultation ID for appointment
         */
        public async GetConsultationByAppointment(docID: string): Promise<GetConsultationByAppointmentResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/appointments/${encodeURIComponent(docID)}/consultation`)
            return await resp.json() as GetConsultationByAppointmentResponse
        }

        /**
         * Get consultation history
         * Audit log of consultation history
         */
        public async GetConsultationHistory(docID: string): Promise<GetConsultationHistoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/consultations/${encodeURIComponent(docID)}/history`)
            return await resp.json() as GetConsultationHistoryResponse
        }

        public async GetConsultationType(docID: string): Promise<GetConsultationTypeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultation-type/${encodeURIComponent(docID)}`)
            return await resp.json() as GetConsultationTypeResponse
        }

        /**
         * Get Examination Group
         */
        public async GetExaminationGroup(docID: string): Promise<GetExaminationGroupResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/examination-group/${encodeURIComponent(docID)}`)
            return await resp.json() as GetExaminationGroupResponse
        }

        /**
         * Retrieves a form by its document ID.
         */
        public async GetForm(docID: string): Promise<FormResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/form/${encodeURIComponent(docID)}`)
            return await resp.json() as FormResponse
        }

        /**
         * Retrieves forms by their consultation type.
         */
        public async GetFormsByType(consultationType: string): Promise<GetFormsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/forms/type/${encodeURIComponent(consultationType)}`)
            return await resp.json() as GetFormsResponse
        }

        /**
         * GetPaginatedConsultationsHistoryByPetID Get paginated consultation history for a pet
         */
        public async GetPaginatedConsultationsHistoryByPetID(petDocID: string, params: PaginatedConsultationsHistoryRequest): Promise<PaginatedConsultationHistory> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "order_by":       params.OrderBy,
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/consultations-history/${encodeURIComponent(petDocID)}/paginated`, undefined, {query})
            return await resp.json() as PaginatedConsultationHistory
        }

        /**
         * GetPartnerPractice
         * Get a partner practice
         */
        public async GetPartnerPractice(docID: string): Promise<GetPartnerPracticeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultation/partner-practice/${encodeURIComponent(docID)}`)
            return await resp.json() as GetPartnerPracticeResponse
        }

        /**
         * GetPatientMediaFile retrieves a media file for a pet
         */
        public async GetPatientMediaFile(docID: string): Promise<PatientMediaFileResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultationinternal/patient_files/${encodeURIComponent(docID)}`)
            return await resp.json() as PatientMediaFileResponse
        }

        /**
         * Get consultation history for a pet
         */
        public async GetPetConsultationsHistory(pet_doc_id: string): Promise<GetPetConsultationsHistoryResposnse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/consultations-history/${encodeURIComponent(pet_doc_id)}`)
            return await resp.json() as GetPetConsultationsHistoryResposnse
        }

        /**
         * Get Prompt Config By Doc ID
         */
        public async GetPromptConfigByDocID(doc_id: string): Promise<ClinicalNotePromptConfigResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/clinical-note-prompts-config/${encodeURIComponent(doc_id)}`)
            return await resp.json() as ClinicalNotePromptConfigResponse
        }

        /**
         * Get Reason Form Configurations
         * Get the configured reason form configurations
         */
        public async GetReasonFormConfigs(): Promise<ReasonFormConfigResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reasons-config`)
            return await resp.json() as ReasonFormConfigResponse
        }

        /**
         * Get Reason Group
         */
        public async GetReasonGroup(docID: string): Promise<GetReasonGroupResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reason-group/${encodeURIComponent(docID)}`)
            return await resp.json() as GetReasonGroupResponse
        }

        /**
         * Get Post Clinical History Forms Config
         * Get the post clinical history forms configuration
         */
        public async GetReasonPostClinicalHistoryFormConfig(): Promise<GetReasonPostClinicalHistoryFormConfigResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reasons-config/post-clinical-history-forms`)
            return await resp.json() as GetReasonPostClinicalHistoryFormConfigResponse
        }

        /**
         * Get Pre Clinical History Forms Config
         * Get the pre clinical history forms configuration
         */
        public async GetReasonPreClinicalHistoryFormConfig(): Promise<GetReasonPreClinicalHistoryFormConfigResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reasons-config/pre-clinical-history-forms`)
            return await resp.json() as GetReasonPreClinicalHistoryFormConfigResponse
        }

        /**
         * Get Pre Examination Forms Config
         * Get the pre examination forms configuration
         */
        public async GetReasonPreExaminationFormConfig(): Promise<GetReasonPreExaminationFormConfigResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reasons-config/pre-examination-forms`)
            return await resp.json() as GetReasonPreExaminationFormConfigResponse
        }

        /**
         * GetReasonsByConsultationDocID retrieves the reasons for a consultation
         */
        public async GetReasonsByConsultationDocID(docID: string): Promise<ReasonsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultations/${encodeURIComponent(docID)}/reasons`)
            return await resp.json() as ReasonsResponse
        }

        /**
         * Get Submission
         * Gets a submission from docId
         */
        public async GetSubmission(docID: string): Promise<SubmissionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/submission/${encodeURIComponent(docID)}`)
            return await resp.json() as SubmissionResponse
        }

        /**
         * Get all submissions
         * Gets all submissions
         */
        public async GetSubmissions(): Promise<GetSubmissionsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/submissions`)
            return await resp.json() as GetSubmissionsResponse
        }

        /**
         * HandleShopifyCreateOrderWebhook
         * Processes Shopify's orders/create webhooks. It searches in the attribute notes for a consultation doc id.
         */
        public async HandleShopifyCreateOrderWebhook(method: "POST", body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/v1/consultationinternal/shopify-webhook/create-order`, body, options)
        }

        /**
         * List Clinical History Categories
         * List all Clinical History Categories
         */
        public async ListClinicalHistoryCategories(): Promise<ListClinicalHistoryCategoriesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/clinical-history-categories`)
            return await resp.json() as ListClinicalHistoryCategoriesResponse
        }

        /**
         * GetClinicalHistoryGroupsWithTypes returns all clinicalHistory groups with their associated clinicalHistory types.
         */
        public async ListClinicalHistoryGroupsWithCategories(): Promise<ListClinicalHistoryGroupWithCategoriesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/clinical-history-groups-with-categories`)
            return await resp.json() as ListClinicalHistoryGroupWithCategoriesResponse
        }

        /**
         * Retrieves all consultation types.
         */
        public async ListConsultationTypes(): Promise<ListConsultationTypesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultation-types`)
            return await resp.json() as ListConsultationTypesResponse
        }

        /**
         * List consultations
         */
        public async ListConsultations(params: GetConsultationsQueryParams): Promise<GetAllConsultationsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "consult_status": params.ConsultStatus,
                "created_after":  String(params.CreatedAfter),
                "created_before": String(params.CreatedBefore),
                "items_per_page": String(params.RequestedItemsPerPage),
                "order_by":       params.OrderBy,
                "page_number":    String(params.RequestedPageNumber),
                "pet_id":         params.PetID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultations`, undefined, {query})
            return await resp.json() as GetAllConsultationsResponse
        }

        /**
         * List Examination Categories
         * List all Examination Categories
         */
        public async ListExaminationCategories(): Promise<ListExaminationCategoriesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/examination-categories`)
            return await resp.json() as ListExaminationCategoriesResponse
        }

        /**
         * GetExaminationGroupsWithCategories returns all examination groups with their associated examination categories.
         */
        public async ListExaminationGroupsWithCategories(): Promise<ListExaminationGroupWithCategoriesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/examination-groups-with-categories`)
            return await resp.json() as ListExaminationGroupWithCategoriesResponse
        }

        /**
         * Retrieves all forms.
         */
        public async ListForms(): Promise<GetFormsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/forms/all`)
            return await resp.json() as GetFormsResponse
        }

        /**
         * ListPatientMediaFiles retrieves all media files for a pet
         */
        public async ListPatientMediaFiles(params: ListPatientMediaFilesRequest): Promise<ListPatientMediaFilesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "consultation_doc_id": params.ConsultationDocID,
                "end_date":            params.EndDate,
                "file_name":           params.FileName,
                "items_per_page":      String(params.RequestedItemsPerPage),
                "media_type":          params.MediaType,
                "page_number":         String(params.RequestedPageNumber),
                "pet_doc_id":          params.PetDocID,
                "start_date":          params.StartDate,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultationinternal/patient_files`, undefined, {query})
            return await resp.json() as ListPatientMediaFilesResponse
        }

        /**
         * ListPractices
         * List partner practices
         */
        public async ListPractices(): Promise<ListPartnerPracticesReponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultation/partner-practice`)
            return await resp.json() as ListPartnerPracticesReponse
        }

        /**
         * List Prompt Configs
         */
        public async ListPromptConfigs(): Promise<ListPromptConfigsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/clinical-note-prompts-config`)
            return await resp.json() as ListPromptConfigsResponse
        }

        /**
         * ListReasonGroupsWithTypes returns all reason groups with their associated reason types.
         */
        public async ListReasonGroupsWithTypes(): Promise<ListReasonGroupWithTypesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reason-groups-with-types`)
            return await resp.json() as ListReasonGroupWithTypesResponse
        }

        /**
         * List Reason Type
         * List all Reason Type
         */
        public async ListReasonTypes(): Promise<ListReasonTypesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reason-types`)
            return await resp.json() as ListReasonTypesResponse
        }

        /**
         * ListReasonTypesByRecommendedProductID
         * Lists reason types which recommendations include the product with the given doc id
         */
        public async ListReasonTypesByRecommendedProductID(product_doc_id: string): Promise<ListReasonTypesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/consultationinternal/products/${encodeURIComponent(product_doc_id)}/reason-types`)
            return await resp.json() as ListReasonTypesResponse
        }

        /**
         * Lists treatment plan templates.
         */
        public async ListTreatmentPlanTemplates(): Promise<ListTreatmentPlanTemplatesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/treatment-plan-templates`)
            return await resp.json() as ListTreatmentPlanTemplatesResponse
        }

        /**
         * Terminate consultation
         * Set consultation Termination with reason form
         */
        public async SetTerminatedConsultationState(docID: string, params: SetTerminatedConsultationStateRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/consultations/${encodeURIComponent(docID)}/terminate`, JSON.stringify(params))
        }

        /**
         * Update Clinical History Category
         * Update an Clinical History Category
         */
        public async UpdateClinicalHistoryCategory(docID: string, params: UpdateClinicalHistoryCategoryRequest): Promise<UpdateClinicalHistoryCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/clinical-history-category/${encodeURIComponent(docID)}`, JSON.stringify(params))
            return await resp.json() as UpdateClinicalHistoryCategoryResponse
        }

        /**
         * Update Clinical History Group
         */
        public async UpdateClinicalHistoryGroup(docID: string, params: UpdateClinicalHistoryGroupRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/clinical-history-group/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Update consultation
         */
        public async UpdateConsultation(docID: string, params: UpdateConsultationRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/consultations/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Update consultation state
         * Update the state of the consultation
         */
        public async UpdateConsultationState(docID: string, params: UpdateConsultationStateRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/consultations/${encodeURIComponent(docID)}/state`, JSON.stringify(params))
        }

        /**
         * Updates an existing consultation type.
         */
        public async UpdateConsultationType(docID: string, params: UpdateConsultationTypeRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/consultation-type/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Update Examination Category
         * Update an Examination Category
         */
        public async UpdateExaminationCategory(docID: string, params: UpdateExaminationCategoryRequest): Promise<UpdateExaminationCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/examination-categories/${encodeURIComponent(docID)}`, JSON.stringify(params))
            return await resp.json() as UpdateExaminationCategoryResponse
        }

        /**
         * Update Examination Group
         */
        public async UpdateExaminationGroup(docID: string, params: UpdateExaminationGroupRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/examination-group/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Updates an existing form.
         */
        public async UpdateForm(docID: string, params: UpdateFormRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/form/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * UpdatePartnerPractice
         * Update partner practice
         */
        public async UpdatePartnerPractice(docID: string, params: UpdatePartnerPracticeRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/consultation/partner-practice/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Update Reason Group
         */
        public async UpdateReasonGroup(docID: string, params: UpdateReasonGroupRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/reason-group/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Update Reason Type
         * Update an Reason Type
         */
        public async UpdateReasonType(docID: string, params: UpdateReasonTypeRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/reason-type/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Updates an existing form submission with new data.
         */
        public async UpdateSubmission(docID: string, params: UpdateSubmissionRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/submission/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * UploadPatientFile uploads a media file to a pet record
         */
        public async UploadPatientMediaFile(params: UploadPatientFileRequest): Promise<UploadPatientFileResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/consultationinternal/patient_files`, JSON.stringify(params))
            return await resp.json() as UploadPatientFileResponse
        }
    }
}

export namespace crmexternal {

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * HandleCRM handles incoming CRMs from Joii.
         * 
         * This endpoint is used to handle incoming CRMs from Joii. It will decode the request body and then pass it to the CRM service to handle the CRM event.
         * The CRM will then be processed and sent to the CRM provider (Braze).
         * If the CRM event is successfully handled, it will return a 200 status code. If there is an error, it will return a 500 status code with an error message
         */
        public async HandleCRM(method: "POST", body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/v1/webhook`, body, options)
        }

        /**
         * SendCallKitNotification sends a call kit notification to the user.
         * 
         * This endpoint is used to send a call kit notification to the user. Uses APNs to signal iOS devices to start a call.
         */
        public async SendCallKitNotification(params: entities.SendCallKitNotificationRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/callkit`, JSON.stringify(params))
        }
    }
}

export namespace prodexternal {
    export interface ListRecommendationsRequest {
        /**
         * The **doc_id** of the product.
         */
        ProductDocID: string[]

        /**
         * The **doc_id** of the venom code to search for.
         */
        VenomCodeDocID: string[]

        /**
         * The **doc_id** of the venom group to search for.
         */
        VenomGroupDocID: string[]

        /**
         * The **doc_id** of the category to search for.
         */
        VenomCategoryDocID: string[]

        /**
         * A source to be added in the product URL as part of the ref query param.
         * 
         * Accepts any string (e.g. **sc** and **consultation**).
         */
        Source: string

        /**
         * A **source_id** to be added in the product URL as part of the ref query param.
         * 
         * Accepted values: GUID string
         */
        SourceID: string
    }

    export interface ListRecommendationsResponse {
        recommendations: Recommendation[]
    }

    export interface Recommendation {
        "doc_id": uuid.UUID
        sku: string
        name: string
        url: string
        /**
         * TODO - create a friendly price here - refer to the appointment response that has a friendly price
         */
        currency: string

        price: number
        description: string
        "photo_url": string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * List Recommended Products
         * This endpoint returns a list of recommended products for the respective **product_doc_id**'s and relevant venom codes.
         */
        public async ListRecommendations(params: ListRecommendationsRequest): Promise<ListRecommendationsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "product_doc_id":        params.ProductDocID.map((v) => v),
                source:                  params.Source,
                "source_id":             params.SourceID,
                "venom_category_doc_id": params.VenomCategoryDocID.map((v) => v),
                "venom_code_doc_id":     params.VenomCodeDocID.map((v) => v),
                "venom_group_doc_id":    params.VenomGroupDocID.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/recommendations`, undefined, {query})
            return await resp.json() as ListRecommendationsResponse
        }
    }
}

export namespace prodinternal {
    export interface CatalogueProductResponse {
        "doc_id": uuid.UUID
        sku: string
        name: string
        url: string
        currency: string
        price: number
        description: string
        "photo_url": string
        "legal_category": string
        "catalogue_id": uuid.UUID
        "created_at": string
        "updated_at": string
        "classification_description": string
        "classification_doc_id": string
        "out_of_stock": boolean
        "external_product_variant_id"?: string
        "external_inventory_item_id"?: string
    }

    export interface ClassificationResponse {
        "doc_id": string
        name: string
        description: string
    }

    export interface CreateCatalogueProductRequest {
        sku: string
        name: string
        url: string
        "catalogue_id": uuid.UUID
        currency: string
        price: number
        description: string
        "photo_url": string
        "legal_category": string
    }

    export interface CreateProdResponse {
        id: uuid.UUID
    }

    export interface CreateProductReasonRecommendationRequest {
        "reason_doc_id": string
        "product_doc_ids": string[]
        species: string
    }

    export interface CreateProductRecommendationForReasonsRequest {
        recommendations: CreateProductReasonRecommendationRequest[]
    }

    export interface CreateProductRequest {
        gtin: string
        name: string
        "product_desc": string
    }

    export interface DeleteProductRecommendationsForReasonRequest {
        "reason_doc_id": string
        "product_ids": string[]
        species: string
    }

    export interface GetProdResponse {
        id: number
        "doc_id": uuid.UUID
        gtin: string
        name: string
        "product_desc": string
        "created_at": string
        "updated_at": string
        deleted: boolean
        "deleted_at": string
        "catalogue_products": entities.CatalogueProduct[]
    }

    export interface GetProductRequest {
        "doc_id": string
    }

    export interface ListClassificationsResponse {
        classifications: ClassificationResponse[]
    }

    export interface ListReasonTypesResponse {
        "reason_type_doc_ids": uuid.UUID[]
    }

    export interface ListRecommendationsRequest {
        ProductDocID: string[]
        VenomCodeDocID: string[]
        VenomGroupDocID: string[]
        VenomCategoryDocID: string[]
    }

    export interface ListRecommendationsResponse {
        recommendations: Recommendation[]
    }

    export interface ListRecommendedProductsByReasonParams {
        ReasonDocID: string
        Species: string
        BusinessDocID: string
        RequestedItemsPerPage: number
        RequestedPageNumber: number
    }

    export interface ListRecommendedProductsByReasonResponse {
        recommendations: Recommendation[]
        pagination: shared.Pagination
    }

    export interface MapVenomCodeRequest {
        "venom_group_id": uuid.UUID
    }

    export interface Recommendation {
        "doc_id": uuid.UUID
        sku: string
        name: string
        url: string
        /**
         * TODO - create a friendly price here - refer to the appointment response that has a friendly price
         */
        currency: string

        price: number
        description: string
        "photo_url": string
        "out_of_stock": boolean
        "classification_id": number
        "classification_doc_id": uuid.UUID
        "legal_category": string
        "classification_description": string
    }

    export interface UpdateCatalogueProductRequest {
        sku: string
        name: string
        url: string
        "catalogue_id": uuid.UUID
        currency: string
        price: number
        description: string
        "photo_url": string
        "legal_category": string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Create catalogue
         * Creates a standard catalogue to map against
         */
        public async CreateCatalogue(params: entities.CreateCatalogueRequest): Promise<entities.CreateCatalogueResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/products/catalogue`, JSON.stringify(params))
            return await resp.json() as entities.CreateCatalogueResponse
        }

        /**
         * CreateCatalogueBusinessMapping
         * Creates catalogue business mapping
         */
        public async CreateCatalogueBusinessMapping(catalogueId: string, params: entities.CreateCatalogueBusinessRequest): Promise<entities.CreateCatalogueBusinessResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/products/mappers/${encodeURIComponent(catalogueId)}/business-mapper`, JSON.stringify(params))
            return await resp.json() as entities.CreateCatalogueBusinessResponse
        }

        /**
         * CreateCategory
         * Creates a standard category to map against
         */
        public async CreateCategory(params: entities.CreateCategoryRequest): Promise<entities.CreateCategoryResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                name: params.Name,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/product/category`, undefined, {query})
            return await resp.json() as entities.CreateCategoryResponse
        }

        /**
         * CreateProduct
         * Creates a standard product to map against
         */
        public async CreateProduct(params: CreateProductRequest): Promise<CreateProdResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/product`, JSON.stringify(params))
            return await resp.json() as CreateProdResponse
        }

        /**
         * CreateProductCatalogue
         * Creates catalogue product
         */
        public async CreateProductCatalogue(params: CreateCatalogueProductRequest): Promise<entities.CreateCatalogueProductResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/products/product-catalogue`, JSON.stringify(params))
            return await resp.json() as entities.CreateCatalogueProductResponse
        }

        /**
         * CreateProductCatalogueMapping
         * Creates catalogue product mapping
         */
        public async CreateProductCatalogueMapping(productId: string, catalogueId: string): Promise<entities.CreateCatalogueProductMappingResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/products/mappers/${encodeURIComponent(productId)}/catalogue-mapper/${encodeURIComponent(catalogueId)}`)
            return await resp.json() as entities.CreateCatalogueProductMappingResponse
        }

        /**
         * CreateProductRecommendationForReason creates a new ProductRecommendation for a given reason
         */
        public async CreateProductRecommendationForReason(params: CreateProductRecommendationForReasonsRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/productinternal/recommendations/reason`, JSON.stringify(params))
        }

        /**
         * CreateProductSubcategory
         */
        public async CreateProductSubcategory(params: entities.ProductSubcategoryRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/product/product-subcategory-mapping`, JSON.stringify(params))
        }

        /**
         * Create venom code product mapping
         */
        public async CreateProductVenomCode(productId: string, params: entities.CreateProductVenomCodeRequest): Promise<entities.CreateProductVenomCodeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/products/venomcode/${encodeURIComponent(productId)}`, JSON.stringify(params))
            return await resp.json() as entities.CreateProductVenomCodeResponse
        }

        /**
         * CreateSubcategory
         */
        public async CreateSubcategory(params: entities.CreateSubcategoryRequest): Promise<entities.CreateSubcategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/product/categories/subcategory`, JSON.stringify(params))
            return await resp.json() as entities.CreateSubcategoryResponse
        }

        /**
         * Create venom product category
         * Creates a standard venom product category to map against
         */
        public async CreateVenomProductCategory(id: string, params: entities.CreateProductVenomCategoryRequest): Promise<entities.CreateProductVenomCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/products/venomcategory/${encodeURIComponent(id)}`, JSON.stringify(params))
            return await resp.json() as entities.CreateProductVenomCategoryResponse
        }

        /**
         * DeleteCatalogueBusinessMapping
         * Delete catalogue business mapping
         */
        public async DeleteCatalogueBusinessMapping(businessId: string, catalogueId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/products/mappers/${encodeURIComponent(businessId)}/business-mapper/${encodeURIComponent(catalogueId)}`)
        }

        /**
         * DeleteCatalogueProduct
         * Delete catalogue product
         */
        public async DeleteCatalogueProduct(catalogueProductId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/products/product-catalogue/${encodeURIComponent(catalogueProductId)}`)
        }

        /**
         * DeleteCategory
         */
        public async DeleteCategory(categoryId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/products/category/${encodeURIComponent(categoryId)}`)
        }

        /**
         * DeleteProduct
         */
        public async DeleteProduct(productId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/product/${encodeURIComponent(productId)}`)
        }

        /**
         * DeleteProductCatalogueMapping
         * Delete catalogue product mapping
         */
        public async DeleteProductCatalogueMapping(productId: string, catalogueProductId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/products/mappers/${encodeURIComponent(productId)}/catalogue-mapper/${encodeURIComponent(catalogueProductId)}`)
        }

        /**
         * DeleteProductRecommendationForReason
         * Delete a product recommendation for a reason
         */
        public async DeleteProductRecommendationForReason(params: DeleteProductRecommendationsForReasonRequest): Promise<void> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "product_doc_i_ds": params["product_ids"].map((v) => v),
                "reason_doc_id":    params["reason_doc_id"],
                species:            params.species,
            })

            await this.baseClient.callTypedAPI("DELETE", `/v1/productinternal/recommendations/reason`, undefined, {query})
        }

        /**
         * DeleteProductSubcategory
         */
        public async DeleteProductSubcategory(productId: string, subcategoryId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/products/product-subcategory-mapping/${encodeURIComponent(productId)}/${encodeURIComponent(subcategoryId)}`)
        }

        /**
         * DeleteProductVenomCode Delete venom code product mapping
         */
        public async DeleteProductVenomCode(venom_code_id: string, prod_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/products/venomcode/${encodeURIComponent(venom_code_id)}/product/${encodeURIComponent(prod_id)}`)
        }

        /**
         * DeleteSubcategory
         */
        public async DeleteSubcategory(subcategoryId: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/products/categories/subcategory/${encodeURIComponent(subcategoryId)}`)
        }

        /**
         * Delete VenomCode to product mapping
         * Delete a venom code group id to a product id
         */
        public async DeleteVenomGroupToProduct(venom_group_id: string, product_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/products/venomgroup/${encodeURIComponent(venom_group_id)}/product/${encodeURIComponent(product_id)}`)
        }

        /**
         * DeleteVenomProductCategory
         * Delete a standard venom product category to map against
         */
        public async DeleteVenomProductCategory(venon_category_id: string, id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/products/venomcategory/${encodeURIComponent(venon_category_id)}/product/${encodeURIComponent(id)}`)
        }

        /**
         * Get catalogue
         * Returns a single catalogue
         */
        public async GetCatalogue(params: entities.GetCatalogueRequest): Promise<entities.GetCatalogueResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "doc_id": params.DocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/catalogue`, undefined, {query})
            return await resp.json() as entities.GetCatalogueResponse
        }

        /**
         * GetCatalogueProduct
         * Returns a single catalog-product
         */
        public async GetCatalogueProduct(catalogueId: string): Promise<CatalogueProductResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/product-catalogue/${encodeURIComponent(catalogueId)}`)
            return await resp.json() as CatalogueProductResponse
        }

        /**
         * GetCategory
         */
        public async GetCategory(categoryId: string): Promise<entities.Category> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/category/${encodeURIComponent(categoryId)}`)
            return await resp.json() as entities.Category
        }

        /**
         * Get product
         */
        public async GetProduct(params: GetProductRequest): Promise<GetProdResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "doc_id": params["doc_id"],
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/product`, undefined, {query})
            return await resp.json() as GetProdResponse
        }

        /**
         * GetProductCatalogueMapping
         * Get products for catalogue mapper
         */
        public async GetProductCatalogueMapping(catalogueProductId: string): Promise<entities.ListProductsForCatalogue> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/mappers/catalogue-mapper/${encodeURIComponent(catalogueProductId)}`)
            return await resp.json() as entities.ListProductsForCatalogue
        }

        /**
         * GetProductSubcategories
         */
        public async GetProductSubcategories(productId: string): Promise<entities.SubcategoriesResponseWithCat> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/product-subcategory-mapping/${encodeURIComponent(productId)}`)
            return await resp.json() as entities.SubcategoriesResponseWithCat
        }

        /**
         * GetSubcategory
         */
        public async GetSubcategory(subcategoryId: string): Promise<entities.SubcategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/categories/subcategory/${encodeURIComponent(subcategoryId)}`)
            return await resp.json() as entities.SubcategoryResponse
        }

        /**
         * HandleShopifyInventoryUpdateWebhook
         * Processes Shopify's inventory_levels/update webhooks. Skips if the sku is not found.
         */
        public async HandleShopifyInventoryUpdateWebhook(method: "POST", body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/v1/productsinternal/shopify-webhook/inventory-update`, body, options)
        }

        /**
         * ListCatalogueBusinessesMapping
         * List catalogue business mapping
         */
        public async ListCatalogueBusinessesMapping(params: entities.CatalogueBusinessMappingRequest): Promise<entities.ListCatalogueBusinessMappingResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "catalogue_id":   params.CatalogueId,
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/mappers/business-mapper`, undefined, {query})
            return await resp.json() as entities.ListCatalogueBusinessMappingResponse
        }

        /**
         * ListCatalogues
         * Returns a paginated list of catalogues
         */
        public async ListCatalogues(params: entities.ListCataloguesRequest): Promise<entities.ListCataloguesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                name:             params.Name,
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/catalogues`, undefined, {query})
            return await resp.json() as entities.ListCataloguesResponse
        }

        /**
         * ListCategories
         */
        public async ListCategories(params: entities.ListCategoriesRequest): Promise<entities.ListCategoriesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                name:                       params.name,
                "requested_items_per_page": String(params["items_per_page"]),
                "requested_page_number":    String(params["page_number"]),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/categories-list`, undefined, {query})
            return await resp.json() as entities.ListCategoriesResponse
        }

        /**
         * ListCategoriesSubcategories
         */
        public async ListCategoriesSubcategories(): Promise<entities.CategoriesWithSubcategoriesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/category-subcategory-listing`)
            return await resp.json() as entities.CategoriesWithSubcategoriesResponse
        }

        /**
         * ListMappedSubCategoriesForCategory
         * List all subcategories for a given category
         */
        public async ListMappedSubCategoriesForCategory(categoryId: string): Promise<entities.ListMappedSubCategoriesForCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/category/${encodeURIComponent(categoryId)}/mapped-subcategories`)
            return await resp.json() as entities.ListMappedSubCategoriesForCategoryResponse
        }

        /**
         * ListProductCatalogue
         * Lists all products in catalogue
         */
        public async ListProductCatalogue(params: entities.ListProductCatalogueRequest): Promise<entities.ListProductCatalogueResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "catalogue_id":   params.CatalogueID,
                "items_per_page": String(params.RequestedItemsPerPage),
                name:             params.Name,
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/catalogue-list`, undefined, {query})
            return await resp.json() as entities.ListProductCatalogueResponse
        }

        /**
         * List Product Catalogues Classifications
         * List all product catalogue classifications
         */
        public async ListProductCatalogueClassifications(): Promise<ListClassificationsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/prodinternal/catalogue/classifications`)
            return await resp.json() as ListClassificationsResponse
        }

        /**
         * ListProductCatalogueMapping
         * List catalogue products mapped to a product
         */
        public async ListProductCatalogueProductMapping(productID: string): Promise<entities.ListCatalogueProductsMappedToProductResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/mappers/product-catalogue-mapper/${encodeURIComponent(productID)}`)
            return await resp.json() as entities.ListCatalogueProductsMappedToProductResponse
        }

        /**
         * ListProductCatalogues
         * List all catalogues that a product is in
         */
        public async ListProductCatalogues(params: entities.ListProductCataloguesRequest): Promise<entities.ListProductCataloguesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
                "product_id":     params.ProductID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/product-catalogues`, undefined, {query})
            return await resp.json() as entities.ListProductCataloguesResponse
        }

        /**
         * List venom code category product mappings
         */
        public async ListProductVenomCategories(product_id: string): Promise<entities.ListProductVenomCategoriesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/venomcategories/${encodeURIComponent(product_id)}`)
            return await resp.json() as entities.ListProductVenomCategoriesResponse
        }

        /**
         * List venom code product mappings
         */
        public async ListProductVenomCodes(product_id: string): Promise<entities.ListProductVenomCodesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/venomcodes/${encodeURIComponent(product_id)}`)
            return await resp.json() as entities.ListProductVenomCodesResponse
        }

        /**
         * List venom code groups product mappings
         */
        public async ListProductVenomGroups(product_id: string): Promise<entities.ListProductVenomGroupsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/venomgroups/${encodeURIComponent(product_id)}`)
            return await resp.json() as entities.ListProductVenomGroupsResponse
        }

        /**
         * ListProducts Lists all products by name
         * Returns a paginaged list of products
         */
        public async ListProducts(params: entities.ListProductsRequest): Promise<entities.ListProductsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                name:             params.Name,
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/product-list`, undefined, {query})
            return await resp.json() as entities.ListProductsResponse
        }

        /**
         * ListProductsBySubCategory
         */
        public async ListProductsBySubCategory(params: entities.ProductSubCategoriesRequest): Promise<entities.ListProductsBySubcategory> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page":             String(params.RequestedItemsPerPage),
                "page_number":                String(params.RequestedPageNumber),
                "subcategories_doc_ids_list": params.SubcatDocIDs.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/subcategory-products-listing`, undefined, {query})
            return await resp.json() as entities.ListProductsBySubcategory
        }

        /**
         * List Recommended Products
         */
        public async ListRecommendationsUserDocId(user_doc_id: string, params: ListRecommendationsRequest): Promise<ListRecommendationsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "product_doc_id":        params.ProductDocID.map((v) => v),
                "venom_category_doc_id": params.VenomCategoryDocID.map((v) => v),
                "venom_code_doc_id":     params.VenomCodeDocID.map((v) => v),
                "venom_group_doc_id":    params.VenomGroupDocID.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/recommendations`, undefined, {query})
            return await resp.json() as ListRecommendationsResponse
        }

        /**
         * ListRecommendedProductsByReason
         * lists recommended products by consultation reason for visit, paginated
         */
        public async ListRecommendedProductsByReason(params: ListRecommendedProductsByReasonParams): Promise<ListRecommendedProductsByReasonResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "business_doc_id": params.BusinessDocID,
                "items_per_page":  String(params.RequestedItemsPerPage),
                "page_number":     String(params.RequestedPageNumber),
                "reason_doc_id":   params.ReasonDocID,
                species:           params.Species,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/productinternal/recommendations/reason`, undefined, {query})
            return await resp.json() as ListRecommendedProductsByReasonResponse
        }

        /**
         * ListRecommendedReasonTypesByProductDocID
         * Lists recommended reason types by product doc id
         */
        public async ListRecommendedReasonTypesByProductDocID(doc_id: string): Promise<ListReasonTypesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/productinternal/products/${encodeURIComponent(doc_id)}/reason-types`)
            return await resp.json() as ListReasonTypesResponse
        }

        /**
         * ListSubcategories
         */
        public async ListSubcategories(params: entities.ListSubcategoriesRequest): Promise<entities.ListSubcategoriesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                name:                       params.name,
                "requested_items_per_page": String(params["items_per_page"]),
                "requested_page_number":    String(params["page_number"]),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/products/categories/subcategories-list`, undefined, {query})
            return await resp.json() as entities.ListSubcategoriesResponse
        }

        /**
         * Create VenomCode to product mapping
         * Links a venom code group id to a product id
         */
        public async MapVenomGroupToProduct(product_id: string, params: MapVenomCodeRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/products/venomgroup/${encodeURIComponent(product_id)}`, JSON.stringify(params))
        }

        /**
         * Updates a catalogue
         */
        public async UpdateCatalogue(catalogueID: string, params: entities.UpdateCatalogueRequest): Promise<entities.UpdateCatalogueResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/products/catalogue/${encodeURIComponent(catalogueID)}`, JSON.stringify(params))
            return await resp.json() as entities.UpdateCatalogueResponse
        }

        /**
         * UpdateCatalogueProduct
         * Returns an updated single catalog-product
         */
        public async UpdateCatalogueProduct(catalogueProductId: string, params: UpdateCatalogueProductRequest): Promise<CatalogueProductResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/products/product-catalogue/${encodeURIComponent(catalogueProductId)}`, JSON.stringify(params))
            return await resp.json() as CatalogueProductResponse
        }

        /**
         * UpdateCategory
         */
        public async UpdateCategory(categoryId: string, params: entities.UpdateCategoryRequest): Promise<entities.UpdateCategoryResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                name: params.Name,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/products/category/${encodeURIComponent(categoryId)}`, undefined, {query})
            return await resp.json() as entities.UpdateCategoryResponse
        }

        /**
         * UpdateProduct
         * Updates a product authenticated with a JWT
         */
        public async UpdateProduct(params: entities.UpdateProductRequest): Promise<entities.UpdateProductResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/product`, JSON.stringify(params))
            return await resp.json() as entities.UpdateProductResponse
        }

        /**
         * UpdateSubcategory
         */
        public async UpdateSubcategory(subcategoryId: string, params: entities.UpdateSubcategoryRequest): Promise<entities.UpdateSubcategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/product/categories/subcategory/${encodeURIComponent(subcategoryId)}`, JSON.stringify(params))
            return await resp.json() as entities.UpdateSubcategoryResponse
        }

        /**
         * Upload Products
         * Upload products to catalogue through csv file
         */
        public async UploadCatalogueProducts(params: entities.UploadProductsRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/catalogue/csv-upload-products`, JSON.stringify(params))
        }
    }
}

export namespace reportingexternal {
    export interface ConsultationsReportRequest {
        /**
         * This parameter specifies the location for which you want to retrieve (e.g., "uk").
         */
        Location: string

        /**
         * This parameter defines the starting date for the reporting data you want.
         */
        StartDate: string

        /**
         * This parameter defines the ending date for the reporting data you want.
         */
        EndDate: string

        /**
         * This parameter indicates the version of the reporting data you want to retrieve.This should be a positive integer value (e.g., 1, 2, 3).
         */
        Version: number
    }

    export interface ConsultationsReportRequestByCustomerReference {
        /**
         * This parameter specifies the location for which you want to retrieve (e.g., "uk").
         */
        Location: string

        /**
         * This parameter defines the starting date for the reporting data you want.
         */
        StartDate: string

        /**
         * This parameter defines the ending date for the reporting data you want.
         */
        EndDate: string

        /**
         * This parameter indicates the version of the reporting data you want to retrieve.This should be a positive integer value (e.g., 1, 2, 3).
         */
        Version: number

        /**
         * This parameter defines either the customer policy number or customer reference of the data you want to retrieve.
         */
        "Customer_ref": string
    }

    /**
     * Response represents the entire JSON structure
     */
    export interface ConsultationsReportResponse {
        result: ConsultationsReportResponseResult[]
    }

    export interface ConsultationsReportResponseDiagnosis {
        id: string
        diagnosis: string
        likelihood: string
        "diagnosis_groups": ConsultationsReportResponseDiagnosisGroups[]
    }

    export interface ConsultationsReportResponseDiagnosisGroups {
        "diagnosis_group_id": string
        "diagnosis_group": string
    }

    export interface ConsultationsReportResponsePrescriptionFulfilled {
        Id: string
        SKU: string
        Vendor: string
        VariantTitle: string
        Name: string
        Quantity: string
        Price: string
        Categories: string
    }

    export interface ConsultationsReportResponsePrescriptionGiven {
        Id: string
        Repeats: string
        Directions: string
        MedicationName: string
        Quantity: string
        Strength: string
    }

    export interface ConsultationsReportResponseResult {
        "Interaction_id": string
        GUID: string
        PolicyNo: string
        InteractionType: string
        InteractionDate: string
        Diagnoses: ConsultationsReportResponseDiagnosis[]
        FoodPurchasesCount: number
        TotalFoodPurchases: string
        TotalMedicationPurchases: string
        RiskType: string
        RiskName: string
        Breed: string
        "Age_months": number
        "Weight_kg": number
        rating: number
        "rating_count": number
        "actual_queue_time": number
        "Vol_Previous_Pet_Consultations": number
        "follow_up_consultation_id": number
        "Products_purchased": string
        "Price_paid": number
        Reasons: string[]
        "Treatment_plan": string
        "Patient_notes": string
        Resolved: number
        outcome: string[]
        PrescriptionOutcome: string
        PrescriptionGiven: ConsultationsReportResponsePrescriptionGiven[]
        PrescriptionFulfilled: ConsultationsReportResponsePrescriptionFulfilled[]
    }

    /**
     * Response represents the entire JSON structure
     */
    export interface PupStartReportResponse {
        result: PupStartReportResponseResult[]
    }

    export interface PupStartReportResponseResult {
        /**
         * pup start fields
         */
        "Interaction_id": string

        InteractionDate: string
        PolicyNo: string
    }

    export interface PupStartRequest {
        /**
         * This parameter specifies the location for which you want to retrieve (e.g., "uk").
         */
        Location: string

        /**
         * This parameter defines the starting date for the reporting data you want.
         */
        StartDate: string

        /**
         * This parameter defines the ending date for the reporting data you want.
         */
        EndDate: string

        /**
         * This parameter indicates the version of the reporting data you want to retrieve.This should be a positive integer value (e.g., 1, 2, 3).
         */
        Version: number
    }

    export interface ReportingsRequest {
        /**
         * This parameter specifies the location for which you want to retrieve (e.g., "uk").
         */
        Location: string

        /**
         * This parameter indicates the version of the reportings data you want to retrieve.This should be a positive integer value (e.g., 1, 2, 3).
         */
        Version: number

        /**
         * This parameter specifies the interaction id, as returned in the consultations endpoint
         */
        InteractionId: string
    }

    export interface ResponsePDFReportings {
        "file_location": string
    }

    export interface SymptomCheckerSessionsReportRequest {
        /**
         * This parameter specifies the location for which you want to retrieve (e.g., "uk").
         */
        Location: string

        /**
         * This parameter defines the starting date for the reporting data you want.
         */
        StartDate: string

        /**
         * This parameter defines the ending date for the reporting data you want.
         */
        EndDate: string

        /**
         * This parameter indicates the version of the reporting data you want to retrieve.This should be a positive integer value (e.g., 1, 2, 3).
         */
        Version: number
    }

    export interface SymptomCheckerSessionsReportRequestByCustomerReference {
        /**
         * This parameter specifies the location for which you want to retrieve (e.g., "uk").
         */
        Location: string

        /**
         * This parameter defines the starting date for the reporting data you want.
         */
        StartDate: string

        /**
         * This parameter defines the ending date for the reporting data you want.
         */
        EndDate: string

        /**
         * This parameter indicates the version of the reporting data you want to retrieve.This should be a positive integer value (e.g., 1, 2, 3).
         */
        Version: number

        /**
         * This parameter defines either the customer policy number or customer reference of the data you want to retrieve.
         */
        "Customer_ref": string
    }

    /**
     * Response represents the entire JSON structure
     */
    export interface SymptomCheckerSessionsReportResponse {
        result: SymptomCheckerSessionsReportResponseResult[]
    }

    export interface SymptomCheckerSessionsReportResponseResult {
        "policy_num": string
        "session_id": string
        time: string
        "algo_name": string
        "products_ordered": string
        outcome: string[]
    }

    export interface UsersReportRequest {
        /**
         * This parameter specifies the location for which you want to retrieve (e.g., "uk").
         */
        Location: string

        /**
         * This parameter defines the starting date for the reporting data you want.
         */
        StartDate: string

        /**
         * This parameter defines the ending date for the reporting data you want.
         */
        EndDate: string

        /**
         * This parameter indicates the version of the reporting data you want to retrieve.This should be a positive integer value (e.g., 1, 2, 3).
         */
        Version: number
    }

    export interface UsersReportRequestByCustomerReference {
        /**
         * This parameter specifies the location for which you want to retrieve (e.g., "uk").
         */
        Location: string

        /**
         * This parameter defines the starting date for the reporting data you want.
         */
        StartDate: string

        /**
         * This parameter defines the ending date for the reporting data you want.
         */
        EndDate: string

        /**
         * This parameter indicates the version of the reporting data you want to retrieve.This should be a positive integer value (e.g., 1, 2, 3).
         */
        Version: number

        /**
         * This parameter defines either the customer policy number or customer reference of the data you want to retrieve.
         */
        "Customer_ref": string
    }

    /**
     * Response represents the entire JSON structure
     */
    export interface UsersReportResponse {
        result: UsersReportResponseResult[]
    }

    export interface UsersReportResponseResult {
        /**
         * user fields
         */
        "doc_id": string

        "user_id": string
        "policy_customer_no": string
        "policy_no": string
        "created_at": string
        "user_created_date": string
        "pet_created_date": string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * ConsultationsReport
         * This endpoint facilitates the retrieval of reporting data, such as analytics or statistics, based on specified parameters.
         * It requires parameters including location, start date, end date, endpoint, and version to be provided.
         * Upon successful validation and retrieval, it returns the requested reporting data for consultations.
         */
        public async ConsultationsReport(params: ConsultationsReportRequest): Promise<ConsultationsReportResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "end_date":   params.EndDate,
                location:     params.Location,
                "start_date": params.StartDate,
                version:      String(params.Version),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reporting/consultations`, undefined, {query})
            return await resp.json() as ConsultationsReportResponse
        }

        /**
         * ConsultationsReportByCustomerReference
         * This endpoint facilitates the retrieval of reporting data, such as analytics or statistics, based on specified parameters.
         * It requires parameters including location, start date, end date, endpoint, version and customer reference to be provided.
         * Upon successful validation and retrieval, it returns the requested reporting data for consultations.
         * Customer reference can either be the pet policy number or the customer reference number
         */
        public async ConsultationsReportByCustomerReference(params: ConsultationsReportRequestByCustomerReference): Promise<ConsultationsReportResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "customer_ref": params["Customer_ref"],
                "end_date":     params.EndDate,
                location:       params.Location,
                "start_date":   params.StartDate,
                version:        String(params.Version),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reporting/consultationsByCustomerReference`, undefined, {query})
            return await resp.json() as ConsultationsReportResponse
        }

        /**
         * PupStartReport
         * This endpoint facilitates the retrieval of reporting data, such as analytics or statistics, based on specified parameters.
         * It requires parameters including location, start date, end date, endpoint, and version to be provided.
         * Upon successful validation and retrieval, it returns the requested reporting data for pup start consultations.
         */
        public async PupStartReport(params: PupStartRequest): Promise<PupStartReportResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "end_date":   params.EndDate,
                location:     params.Location,
                "start_date": params.StartDate,
                version:      String(params.Version),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reporting/pupStart`, undefined, {query})
            return await resp.json() as PupStartReportResponse
        }

        /**
         * Get PDF Consultation Report
         * This endpoint retrieves PDF reports for consultations based on specified parameters such as **location**, **interactionId** and **version**.
         * Upon successful retrieval, it returns the PDF report for the consultation.
         */
        public async ReportingPetConsultationPDF(params: ReportingsRequest): Promise<ResponsePDFReportings> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                interactionId: params.InteractionId,
                location:      params.Location,
                version:       String(params.Version),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reportingPetConsultationPDF`, undefined, {query})
            return await resp.json() as ResponsePDFReportings
        }

        /**
         * Get PDF Prescription Report
         * This endpoint retrieves the prescription PDF given within a consultation based on specified parameters such as **location**, **interactionId** and **version**.
         * Upon successful retrieval, it returns the prescription PDF report for the consultation.
         */
        public async ReportingPrescriptionPDF(params: ReportingsRequest): Promise<ResponsePDFReportings> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                interactionId: params.InteractionId,
                location:      params.Location,
                version:       String(params.Version),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reportingPrescriptionPDF`, undefined, {query})
            return await resp.json() as ResponsePDFReportings
        }

        /**
         * SymptomCheckerSessionsReport
         * This endpoint facilitates the retrieval of reporting data, such as analytics or statistics, based on specified parameters.
         * It requires parameters including location, start date, end date, endpoint, and version to be provided.
         * Upon successful validation and retrieval, it returns the requested reporting data for symptom checker.
         */
        public async SymptomCheckerSessionsReport(params: SymptomCheckerSessionsReportRequest): Promise<SymptomCheckerSessionsReportResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "end_date":   params.EndDate,
                location:     params.Location,
                "start_date": params.StartDate,
                version:      String(params.Version),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/symptomcheckersessions`, undefined, {query})
            return await resp.json() as SymptomCheckerSessionsReportResponse
        }

        /**
         * SymptomCheckerSessionsReportByCustomerReference
         * This endpoint facilitates the retrieval of reporting data, such as analytics or statistics, based on specified parameters.
         * It requires parameters including location, start date, end date, endpoint, version and customer reference to be provided.
         * Upon successful validation and retrieval, it returns the requested reporting data for symptom checker.
         * Customer reference can either be the pet policy number or the customer reference number
         */
        public async SymptomCheckerSessionsReportByCustomerReference(params: SymptomCheckerSessionsReportRequestByCustomerReference): Promise<SymptomCheckerSessionsReportResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "customer_ref": params["Customer_ref"],
                "end_date":     params.EndDate,
                location:       params.Location,
                "start_date":   params.StartDate,
                version:        String(params.Version),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reporting/symptomcheckersessionsByCustomerReference`, undefined, {query})
            return await resp.json() as SymptomCheckerSessionsReportResponse
        }

        /**
         * UsersReport
         * This endpoint facilitates the retrieval of reporting data, such as analytics or statistics, based on specified parameters.
         * It requires parameters including location, start date, end date, endpoint, and version to be provided.
         * Upon successful validation and retrieval, it returns the requested reporting data for users.
         */
        public async UsersReport(params: UsersReportRequest): Promise<UsersReportResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "end_date":   params.EndDate,
                location:     params.Location,
                "start_date": params.StartDate,
                version:      String(params.Version),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reporting/users`, undefined, {query})
            return await resp.json() as UsersReportResponse
        }

        /**
         * UsersReportByCustomerReference
         * This endpoint facilitates the retrieval of reporting data, such as analytics or statistics, based on specified parameters.
         * It requires parameters including location, start date, end date, endpoint, version and customer reference to be provided.
         * Upon successful validation and retrieval, it returns the requested reporting data for users.
         * Customer reference can either be the pet policy number or the customer reference number
         */
        public async UsersReportByCustomerReference(params: UsersReportRequestByCustomerReference): Promise<UsersReportResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "customer_ref": params["Customer_ref"],
                "end_date":     params.EndDate,
                location:       params.Location,
                "start_date":   params.StartDate,
                version:        String(params.Version),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/reporting/usersByCustomerReference`, undefined, {query})
            return await resp.json() as UsersReportResponse
        }
    }
}

export namespace scexternal {
    export interface AIBackendJob {
        /**
         * Model
         * This is the model that the job is pending on.
         */
        model: string

        /**
         * Status
         * This is the status of the pending job. Status can be success,received or
         * failure. If failure, failure message will be populated with a user facing
         * error message
         */
        status: string

        /**
         * FailureMessage
         * This is a user facing error message that can be shown to the user if
         * the status is failure
         */
        "failure_message"?: string
    }

    /**
     * this is all an algorithm can be to the external services, they should have no
     * further knowledge
     */
    export interface AlgorithmDTO {
        order: number
        title: string
        description: string
        "doc_id": uuid.UUID
    }

    export interface CategoryDTO {
        id: number
        order: number
        "doc_id": string
        name: string
        "icon_url": string
    }

    export interface CreateSessionRequest {
        /**
         * PetID
         * Deprecated. Use PetDocID.
         */
        "pet_id"?: uuid.UUID

        /**
         * PetDocID
         * The DocID of the pet to create the session for.
         */
        "pet_doc_id": uuid.UUID

        /**
         * AlgorithmID
         * The DocID of the algorithm to create the session for.
         */
        "algorithm_id": uuid.UUID
    }

    export interface CreateSessionRequestWithPetDetails {
        /**
         * AlgorithmDocID
         * The ID of the algorithm.
         */
        "algorithm_doc_id": uuid.UUID

        /**
         * Name
         * The name of the pet.
         */
        name: string

        /**
         * Gender
         * The gender of the pet.
         * 
         * Accepted values are:  **Male**, **Female**, **Unknown**.
         */
        gender: string

        /**
         * Weight
         * The weight of the pet.
         */
        weight: SessionPetDetailsWeight

        /**
         * DateOfBirth
         * The pet date of birth.
         * 
         * Accepted date format: **YYYY-MM-DD**.
         */
        "date_of_birth": string

        /**
         * Neutered
         * Indicates if the pet has been neutered.
         */
        neutered: boolean

        /**
         * SpeciesDocID
         * The doc id of the species of the pet
         * Species doc ids are returned from the ListSpecies endpoint
         * /v1/species
         */
        "species_doc_id": string

        /**
         * BreedDocID
         * The doc id of the breed of the pet
         * Breed doc ids are returned from the ListBreeds endpoint
         * /v1/breeds
         */
        "breed_doc_id": string
    }

    export interface CreateSessionResponse {
        session: Session
        "current_node": Node
    }

    export interface DataPointLabel {
        id: number
        position: string
        value: string
    }

    export interface DataPointOption {
        /**
         * ID
         * Must be sent in the payload to /session/next if the question contained
         * options. Used to record which option the user chose
         */
        id: number

        /**
         * ImageURL
         * If present, this is a URL to an image that should be displayed,
         * for example if the question type is image-single-select, the user is
         * prompted to chose from several images the one that best represents the
         * problem
         */
        "image_url": string

        /**
         * Label
         * Label is the user facing text for this option
         */
        label: string

        /**
         * Value
         * Value is the value that should be sent in the payload to
         * /session/next along with the option ID
         */
        value: string

        /**
         * Order
         * represents the order in which the options should be displayed
         */
        order: number

        /**
         * Coordinates
         * will only be present for a question of type issue-location
         * represents the bounding box for a particular location that if the user
         * clicks within, their intention was to select this option
         */
        coordinates: number[][]

        /**
         * DescriptionPlainText
         * if present, this is a plain text description of the option
         */
        "description_plain_text": string

        /**
         * DescriptionRichText
         * if present, this is a rich text description of the option
         */
        "description_rich_text": string
    }

    export interface FeaturedAlgorithmDTO {
        order: number
        title: string
        "doc_id": uuid.UUID
        description: string
    }

    export interface FeauturedAlgorithmCategoriesRequest {
        SpeciesDocID: string
    }

    /**
     * for the user facing API, we'll flatten data points into "form fields" for now.
     * it'll more closely resemble the proposed API structure here
     * https://miro.com/app/board/uXjVNIFTY9w=/?moveToWidget=3458764573945885963&cot=14
     * and hopefully removes a layer of confusion for the consumer
     */
    export interface FormField {
        "is_default": boolean
        /**
         * ID
         * ID is the unique identifier for the data point. This should be used in
         * the payload to session/next
         */
        id: number

        /**
         * Type
         * The type of the form field. This can be
         * select,text,multi-select,image-single-select,image-multi-select,
         * severity-scale,slider,number,image-upload,video-upload,issue-location
         */
        type: string

        /**
         * ValueType
         * ValueType gives an indication of what form the response should
         * take when submitted possible values are.
         * text,
         * number,
         * currency,
         * age,
         * weight,
         * text-array,
         * issue-location,
         */
        "value_type": string

        /**
         * Placeholder
         * if present, used to represent any text that should be used as
         * a placeholder for text inputs
         */
        placeholder: string

        /**
         * Placeholder
         * if present, contains a URL to an image that should be used as
         * the background for the issue-location question type.
         */
        "image_map_url": string

        /**
         * Options
         * Options is an array of possible answers to the question. If
         * present, the payload to /next must have the option_id field filled with
         * one of the IDs from this array
         */
        options: DataPointOption[]

        /**
         * Labels
         * labels are used in conjunction with the severity-scale question.
         */
        labels: DataPointLabel[]

        /**
         * Upload Bucket
         * The name of the bucket which the response will be uploaded to
         */
        "upload_bucket": string

        /**
         * AI Backend
         * This is an optional field that, if not empty, will contain the
         * name of the AI backend that this datapoint will be sent to. For now we
         * only support a single model called "gait". If this field contains the
         * word "gait", the data point will invariably be of type video-upload.
         * Frontend validation should be applied to ensure that only a single video
         * is being uploaded in this case.
         */
        "ai_backend"?: string
    }

    export interface FormFieldInformation {
        /**
         * Type
         * This can be either text, image or video. This should be used as
         * guidance on how to render the rest of the information
         */
        type: string

        /**
         * ID
         * Should be included in the response to /session/next as the data_point_id
         */
        id: number

        /**
         * ImageURLs
         * An array of URLs to images that should be displayed on the
         * screen if present. Will only contain data if the type is image
         */
        "image_urls": string[]

        /**
         * VideoURL
         * A single URL to a video that should be displayed on the
         * screen if present. Will only contain data if the type is video
         */
        "video_url": string

        /**
         * TextBody
         * Deprecated. Will be removed.
         */
        "text_body": string
    }

    export interface GetFeaturedAlgorithmRequest {
        /**
         * SpeciesDocID
         * The **doc_id** of the species in **UUID** format.
         */
        SpeciesDocID: string
    }

    export interface GetFeaturedAlgorithmResponse {
        "featured_algorithm": FeaturedAlgorithmDTO
    }

    export interface GetFeaturedAlgorithmResponseV2 {
        "featured_algorithm"?: FeaturedAlgorithmDTO
    }

    export interface GetFeaturedCategoriesResponse {
        "algorithm_category": CategoryDTO[]
    }

    export interface HealthScore {
        /**
         * Score
         * Score is the health score e.g. 18
         */
        score: number

        /**
         * ScoreLabel
         * ScoreLabel is the label for the health score e.g. "Good"
         */
        "score_label": string

        /**
         * UsefullnessScore
         * UsefullnessScore is the score that indicates how useful the video is
         */
        "usefulness_score"?: number

        /**
         * ScoreRanges
         * ScoreRanges is an array of score ranges.
         */
        "score_ranges": ScoreRange[]
    }

    export interface InformationSection {
        /**
         * Type
         * Type for an information section will always be "information"
         */
        type: string

        /**
         * FormFields
         * FormFields is an array containing different parts of the
         * information screen that must be presented to inform the user. In the current
         * version of the symptom checker there will only ever be one form_field
         * element in the array, but this is designed to be extensible for the
         * future
         */
        "form_fields": FormFieldInformation[]
    }

    export interface ListAlgorithmCategoriesRequest {
        SpeciesDocID: string
    }

    export interface ListAlgorithmCategoriesResponse {
        categories: CategoryDTO[]
    }

    export interface ListAlgorithmsInCategoryRequest {
        CategoryDocID: string
        SpeciesDocID: string
    }

    export interface ListAlgorithmsInCategoryResponse {
        algorithms: AlgorithmDTO[]
    }

    export interface ListAlgorithmsRequest {
        Name: string
    }

    export interface ListAlgorithmsResponse {
        algorithms: AlgorithmDTO[]
    }

    export interface ListUserSessionsRequest {
        ItemsPerPage: number
        PageNumber: number
        PetDocID?: string
    }

    export interface ListUserSessionsResponse {
        sessions: SessionResponse[]
        pagination: Pagination
    }

    /**
     * represents the data to display on a screen during a symptom checker session
     */
    export interface Node {
        /**
         * Title
         * Title is the title of the node. This provides a clinical reference
         * for the node, but should not be shown to the user
         */
        title: string

        /**
         * DescriptionPlainText
         * A plain text description of the node. This should be
         * shown to the user if the rich text one cannot be used
         */
        "description_plain_text": string

        /**
         * DescriptionRichText
         * A rich text description of the node using markdown. This should be shown to the user.
         */
        "description_rich_text": string

        /**
         * QuestionSections
         * QuestionSections is an array containing different
         * sections of the form that require user input. If there are questions,
         * there won't be any information sections
         */
        "question_sections": QuestionSection[]

        /**
         * InformationSections
         * InformationSections is an array containing different
         * sections of the form that contain information to show to a user. If there
         * are information sections, , there won't be any question sections
         */
        "information_sections": InformationSection[]

        "is_skippable": boolean
        /**
         * IsRootNode
         * IsRootNode is a boolean that indicates if the node is the first one in the algorithm
         */
        "is_root_node": boolean
    }

    /**
     * represents a user facing outcome. This model is deliberately different to the
     * scinternal outcome aggregate, as whilst that one is intended to give a full
     * view of the outcome for administration (multi language, creator etc), this
     * one is a lot thinner, representing just what the customer should know
     */
    export interface Outcome {
        /**
         * Urgency
         * Represents how urgently the clinicians believe action should be
         * taken. Can be "low", "medium" or "high
         * this is marked as optional in order to support algorithms for our own clinical trials
         * for external integrators this will always be set
         */
        urgency: string

        /**
         * OutcomeType
         * OutcomeType can be either default, ai_gait or trial. For external
         * integrators it will always be default, and can be ignored. For our
         * internal clinical trials, it will be set to trial. For the gait AI model
         * it will be set to ai_gait, but the ai gait model is not available
         * externally to integrators.
         */
        "outcome_type": string

        /**
         * PrimaryRecommendation
         * The primary recommendation that the user should take away from outcome
         */
        "primary_recommendation": OutcomePrimaryRecommendation

        /**
         * Advice
         * Complimentary advice that the user should take away from the outcome
         */
        advice: OutcomeAdvice

        /**
         * VenomCodes
         * An extension of the standard venom code set that the
         * clinicians believe pertain to this particular outcome.
         */
        "venom_codes": VenomCode[]

        /**
         * ProductRecommendation
         * Outcomes may contain products that the clinicans believe are relevant
         */
        "product_recommendations": ProductRecommendation[]

        /**
         * WhatYouNeedToKnow
         * a section of the outcome that should be shown on the
         * outcome providing them with secondary information and a potential
         * call-to-action
         */
        "what_you_need_to_know"?: WhatYouNeedToKnow

        "what_to_do_next"?: WhatToDoNext
        "wtdn_product_recommendations": ProductRecommendation[]
        "wyntk_product_recommendations": ProductRecommendation[]
        sections?: OutcomeSection[]
    }

    export interface OutcomeAdvice {
        /**
         * RichText
         * RichText is the rich text representation of the advice in markdown
         */
        "rich_text": string

        /**
         * PlainText PlainText is the plain text representation of the advice. Can
         * be used as a fallback for the rich text
         */
        "plain_text": string
    }

    export interface OutcomePrimaryRecommendation {
        /**
         * RichText
         * RichText is the rich text representation of the primary recommendation
         */
        "rich_text": string

        /**
         * PlainText
         * PlainText is the plain text representation of the primary recommendation
         */
        "plain_text": string

        /**
         * Type
         * Type is the type of the primary recommendation. This can be
         * vet-call, nurse-call, visit-vet-practice, products, care-at-home
         */
        type: string
    }

    export interface OutcomeSection {
        type: string
        title: string
        language: entities.LanguageISO639
        "rich_text": string
        "plain_text": string
        "image_url"?: string
        "health_score"?: HealthScore
        "gait_result"?: string
        /**
         * UserGaitVideo
         * this is a link to the original video upload by the user for the gait model
         */
        "original_media_url"?: string
    }

    export interface Pagination {
        total: number
        "current_page": number
        pages: number
    }

    export interface ProductRecommendation {
        /**
         * DocID
         * DocID is the unique internal vet-ai identifier for the product,
         * this should be used in conjunction with the /recommendations endpoint for
         * products to get the products mapped to a particular integrator
         */
        "doc_id": uuid.UUID

        /**
         * Name
         * Name is the human readable name for the product
         */
        name: string

        /**
         * SKU
         * Deprecated. Please use GTIN instead. To be removed once frontend catches up.
         */
        sku: string

        /**
         * GTIN
         * GTIN is the Global Trade Item Number which is a unique identifier for a product.
         */
        gtin: string

        /**
         * Description
         * Description is a human readable description of the product
         */
        description: string
    }

    export interface QuestionSection {
        /**
         * Type
         * Type for a question section will always be "question"
         */
        type: string

        /**
         * FormFields
         * FormFields is an array containing different parts of the
         * question that must be presented for the user to answer. In the current
         * version of the symptom checker there will only ever be one form_field
         * element in the array, but this is designed to be extensible for the
         * future
         */
        "form_fields": FormField[]

        "is_skippable": boolean
    }

    export interface RateLimitedResponse {
        message: string
    }

    export interface ScoreRange {
        min: number
        max: number
        label: string
    }

    export interface Session {
        /**
         * SessionID
         * SessionID is the unique identifier for the session. This should
         * be saved on the client and is repeatedly used every time there is an API
         * request to facilitate the session. e.g. through using session/next
         */
        "session_id": uuid.UUID
    }

    export interface SessionCurrentNodeResponse {
        "current_node": Node
        outcome: Outcome
    }

    export interface SessionNextRequest {
        /**
         * IsSkipped
         * if the question is marked as skippable, and the user elects to skip, set
         * this to true in the request for the next resource.
         */
        "is_skipped": boolean

        /**
         * SessionID
         * The session_doc_id of the session that the user is currently in.
         * Deprecated. Use SessionDocID.
         */
        "session_id": uuid.UUID

        /**
         * SessionDocID
         * The session_doc_id of the session that the user is currently in.
         * Deprecated. Use SessionDocID.
         */
        "session_doc_id"?: uuid.UUID

        /**
         * DataPoints
         * DataPoints is an array of the user's responses. In the initial version of
         * The API, there will only ever be one data point in this array.
         */
        "data_points": SubmittedDataPoint[]
    }

    /**
     * if you've reached the end of the algo, it'll be the outcome, otherwise the
     * question
     */
    export interface SessionNextResponse {
        /**
         * Node
         * The next node in the session. This can be either a question or an
         * information node. If it is an outcome node, the outcome section of this
         * payload will be populated
         */
        node: Node

        /**
         * Outcome
         * If the next node is an outcome, this will be populated.
         */
        outcome: Outcome

        /**
         * PendingJobs
         * Used as part of an asyncronous algorithm. These are for internal use only
         * at joii, and are not available for external integrators.
         */
        "pending_jobs"?: AIBackendJob[]
    }

    export interface SessionOutcomeResponse {
        outcome: Outcome
    }

    export interface SessionPetDetailsWeight {
        /**
         * WeightQuantity
         * The weight of the pet as a floating point number
         */
        "weight_quantity": number

        /**
         * Unit
         * The unit of the weight. This should be "kg", "g" or "lbs"
         */
        unit: string
    }

    export interface SessionPreviousRequest {
        "session_doc_id": uuid.UUID
    }

    export interface SessionPreviousResponse {
        node: Node
    }

    export interface SessionResponse {
        "algorithm_name": string
        complete: boolean
        "started_at": string
        "completed_at": string
        "session_doc_id": string
        category: CategoryDTO
        "pet_doc_id": string
        "algorithm_doc_id": string
    }

    export interface SubmittedDataPoint {
        /**
         * DataPointID
         * The id of the received question/information node.
         */
        "data_point_id": number

        /**
         * Value
         * This is the raw value of the users response. The type is JSON, as the
         * value type here will be determined by the data point value type that is
         * received.
         * age - "string" with the format YYYY, YYYY-MM, YYYY-MM-DD
         * weight - int or float
         * text-array - an array of strings.
         * number - a floating point number, or a string.
         */
        value: JSONValue

        /**
         * SelectedOptionID
         * If the data point is a question and the user has selected an option, this
         * should be set to the ID of the selected option.
         */
        "selected_option_id": number
    }

    export interface VenomCode {
        /**
         * DocID
         * DocID is the unique internal vet-ai identifier for the venom code.
         */
        "doc_id": uuid.UUID

        /**
         * VenomID
         * VenomID is part of a superset of the standard venom codes. It
         * _may_ contain a venomcode from the standard set.
         */
        "venom_id": string

        /**
         * TermName
         * TermName is the human readable name for the venom code. Can be shown to the user
         */
        "term_name": string
    }

    export interface WhatToDoNext {
        /**
         * Type
         * Type is used to determine the call to action associated with this
         * section, possible values are. products, vet-call nurse-call visit-vet-practice
         */
        type?: string

        /**
         * PlainText
         * PlainText is the plain text representation showing the user "what to do next"
         */
        "plain_text": string

        /**
         * RichText
         * RichText is the rich text representation showing the user "what to do next"
         */
        "rich_text": string
    }

    export interface WhatYouNeedToKnow {
        /**
         * Type
         * Type is used to determine the call to action associated with this
         * section, possible values are. products, vet-call nurse-call visit-vet-practice
         */
        type?: string

        /**
         * PlainText
         * PlainText is the plain text representation showing the user "what you need to know"
         */
        "plain_text": string

        /**
         * RichText
         * RichText is the rich text representation showing the user "what you need to know"
         */
        "rich_text": string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        public async GetFeaturedAlgorithm(params: GetFeaturedAlgorithmRequest): Promise<GetFeaturedAlgorithmResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "species_doc_id": params.SpeciesDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/featured-algorithm`, undefined, {query})
            return await resp.json() as GetFeaturedAlgorithmResponse
        }

        public async GetFeaturedAlgorithmV2(params: GetFeaturedAlgorithmRequest): Promise<GetFeaturedAlgorithmResponseV2> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "species_doc_id": params.SpeciesDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v2/featured-algorithm`, undefined, {query})
            return await resp.json() as GetFeaturedAlgorithmResponseV2
        }

        public async GetFeaturedCategories(params: FeauturedAlgorithmCategoriesRequest): Promise<GetFeaturedCategoriesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "species_doc_id": params.SpeciesDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/featured-categories`, undefined, {query})
            return await resp.json() as GetFeaturedCategoriesResponse
        }

        /**
         * Get Current Session Node
         * This endpoint returns the user's current node in the algorithm for that session. Used to facilitate refresh/session resumption.
         */
        public async GetSessionCurrentNode(sessionID: string): Promise<SessionCurrentNodeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/session/${encodeURIComponent(sessionID)}/current`)
            return await resp.json() as SessionCurrentNodeResponse
        }

        /**
         * Get User Session
         * returns a user session
         */
        public async GetUserSession(session_doc_id: string): Promise<SessionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/session/${encodeURIComponent(session_doc_id)}`)
            return await resp.json() as SessionResponse
        }

        /**
         * Get User Session Outcome
         * returns a outcome reached from a user session
         */
        public async GetUserSessionOutcome(session_doc_id: string): Promise<SessionOutcomeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/session/${encodeURIComponent(session_doc_id)}/summary`)
            return await resp.json() as SessionOutcomeResponse
        }

        /**
         * List Algorithm Categories
         * This endpoint returns a list of all algorithm categories in the requested language.
         * If no language is provided, the default language is used, which is English.
         * 
         * An algorithm category may be "breathing" for example, and the algorithms in that category may be "coughing" or "sneezing”.
         */
        public async ListAlgorithmCategories(params: ListAlgorithmCategoriesRequest): Promise<ListAlgorithmCategoriesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "species_doc_id": params.SpeciesDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/algorithm-categories`, undefined, {query})
            return await resp.json() as ListAlgorithmCategoriesResponse
        }

        /**
         * List Algorithms
         * This endpoint returns all published algorithms in the requested language.
         * If no language is provided, the default language is used, which is English.
         */
        public async ListAlgorithms(params: ListAlgorithmsRequest): Promise<ListAlgorithmsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                name: params.Name,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/algorithms`, undefined, {query})
            return await resp.json() as ListAlgorithmsResponse
        }

        /**
         * List Algorithms in Category
         * This endpoint returns a list of all algorithms in the requested category in the requested language.
         * If no language is provided, the default language is used, which is English.
         */
        public async ListAlgorithmsInCategory(params: ListAlgorithmsInCategoryRequest): Promise<ListAlgorithmsInCategoryResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "category_doc_id": params.CategoryDocID,
                "species_doc_id":  params.SpeciesDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/algorithm-categories/algorithms`, undefined, {query})
            return await resp.json() as ListAlgorithmsInCategoryResponse
        }

        /**
         * List User Sessions
         * returns a list of user sessions. Defaults to complete, but will be extended with query params at a later date
         */
        public async ListUserSessions(params: ListUserSessionsRequest): Promise<ListUserSessionsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.ItemsPerPage),
                "page_number":    String(params.PageNumber),
                "pet_doc_id":     params.PetDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/user/sessions`, undefined, {query})
            return await resp.json() as ListUserSessionsResponse
        }

        public async RateLimited(): Promise<RateLimitedResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/rate-limited`)
            return await resp.json() as RateLimitedResponse
        }

        /**
         * Get Next Session Node
         * This endpoint is used to move the user through their session.
         * It takes in the **session_doc_id** and the answer that should be submitted for the question the user is currently on.
         * Then, it returns the next node of the user's session. This may be either a question, information or outcome node.
         * If it is an outcome, that indicates that the user has reached the end of the session.
         */
        public async SessionNext(params: SessionNextRequest): Promise<SessionNextResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/session/next`, JSON.stringify(params))
            return await resp.json() as SessionNextResponse
        }

        /**
         * Get Previous Session Node.
         * Moves the user back to the previous node in the session. This marks their
         * answer to the current question as invalid, meaning it won't be considered for
         * deciding their next question.
         */
        public async SessionPrevious(params: SessionPreviousRequest): Promise<SessionPreviousResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/session/previous`, JSON.stringify(params))
            return await resp.json() as SessionPreviousResponse
        }

        /**
         * Start Session
         * This endpoint starts a new session with the symptom checker on the given algorithm, with the given pet after performing some preliminary validation around the user.
         * 
         * It returns a **session_doc_id**, which is a UUID that must be resubmitted on all future requests to move through the session.
         * 
         * It also returns the first node encountered in the algorithm, which may be a question or information.
         */
        public async StartSession(params: CreateSessionRequest): Promise<CreateSessionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/session`, JSON.stringify(params))
            return await resp.json() as CreateSessionResponse
        }

        /**
         * Start Session With Pet Details
         * 
         * Similar to the start session endpoint, but does not require the pet to
         * already exist in the system. Instead, the pet details such as name, age,
         * gender etc are provided in this request and used to run the session. The
         * intention is that the user is presented with a form capturing the pet
         * details before filtering algorithms and starting a session for example,
         * as lead generation/prospects.
         */
        public async StartSessionWithPetDetails(params: CreateSessionRequestWithPetDetails): Promise<CreateSessionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/guests/session`, JSON.stringify(params))
            return await resp.json() as CreateSessionResponse
        }
    }
}

export namespace scinternal {
    export interface AIBackend {
        name: string
    }

    export interface AddAlgorithmToCategoryRequest {
        "algorithm_doc_id": string
        "category_doc_id": string
    }

    export interface Algorithm {
        "doc_id": uuid.UUID
        id: number
        texts: AlgorithmText[]
        name: string
        "created_at": string
        status: entities.LifecycleStatus
        nodes: Node[]
        connections: Connection[]
        availability: Availability
        formulas?: AlgorithmFormula[]
    }

    export interface AlgorithmCategory {
        id: number
        order: number
        "doc_id": uuid.UUID
        texts: AlgorithmCategoryText[]
        "icon_entry_id": string
        hidden: boolean
    }

    export interface AlgorithmCategoryText {
        language: string
        name: string
    }

    export interface AlgorithmCloneRequest {
        species?: uuid.UUID[]
        regions?: string[]
        businesses?: uuid.UUID[]
        categories?: uuid.UUID[]
    }

    export interface AlgorithmCloneResponse {
        "algorithm_id": number
    }

    /**
     * AlgorithmFormula
     * 
     * A formula represents a way for the ADs to calculate a value, based on
     * previous submissions and the pet profile and reuse that value in both rules
     * and as a templated variable
     */
    export interface AlgorithmFormula {
        /**
         * FormulaTemplate
         * The formula template might be something like "1 + {PET_HEALTH} * 2 / 10"
         */
        "formula_template": string

        /**
         * OutputVariable
         * The output variable is the name of the variable that will be used in the
         * rules and templated variables. For example, "PET_HEALTH"
         */
        "output_variable": string
    }

    /**
     * used to list algorithms right now
     */
    export interface AlgorithmPreview {
        title: string
        order: number
        description: string
        "doc_id": uuid.UUID
        "latest_status": string
        "created_at": string
        categories: CategoryPreview[]
        "latest_id": number
        species: Species[]
    }

    export interface AlgorithmRoot {
        "doc_id": uuid.UUID
        id: number
        name: string
        "created_at": string
        status: entities.LifecycleStatus
    }

    export interface AlgorithmText {
        name: string
        language: string
    }

    export interface AlgorithmTranslatedPreview {
        "algorithm_preview": AlgorithmPreview
        "translation_percentages": TranslationPercentage[]
    }

    export interface AnswerOption {
        "display_value": string
        "was_selected": boolean
    }

    export interface ArchiveQuestionResponse {
    }

    export interface Availability {
        species: Species[]
        categories: AlgorithmCategory[]
        Regions: Region[]
    }

    export interface CategoryPreview {
        "doc_id": string
        name: string
    }

    export interface CheckDataPointKeyResponse {
        "is_unique": boolean
        count: number
    }

    export interface CheckFormulaRequest {
        /**
         * FormulaTemplate
         * The formula to check, without any substitution. For example, "1 + {PET_HEALTH} * 2 / 10"
         */
        "formula_template": string

        /**
         * TemplatedVariables
         * The variables to substitute into the formula. For example, "PET_HEALTH": 5
         * These should be without the curly braces.
         */
        "templated_variables": { [key: string]: number }

        /**
         * AlgorithmID
         * The ID of the algorithm to check the formula against. This
         * will be used to check all data point keys submitted exist in the
         * algorithm, and that the value they come from is numeric.
         */
        "algorithm_id": number
    }

    export interface CheckFormulaResponse {
        result: number
    }

    export interface Connection {
        "target_id": number
        "source_id": number
        "source_resource_id": number
        "target_resource_id": number
        "source_resource_instance_id": uuid.UUID
        "target_resource_instance_id": uuid.UUID
        /**
         * we'll make use of the entities directly in here for now as it's just a
         * read, however, I think we'll eventually need to decouple the DTO here
         * from the actual rule entity
         */
        rules: entities.Rule[]
    }

    export interface CreateAlgorithmCategoryRequest {
        "icon_entry_id": string
        texts: AlgorithmCategoryText[]
        hidden?: boolean
    }

    export interface CreateAlgorithmCategoryResponse {
        "doc_id": uuid.UUID
        id: number
        "created_at": string
    }

    export interface CreateAlgorithmRequest {
        texts: AlgorithmText[]
        /**
         * uuids of the species
         */
        species: string[]

        regions: string[]
        businesses: string[]
        categories: string[]
    }

    export interface CreateAlgorithmResponse {
        algorithm: Algorithm
    }

    export interface CreateInformationRequest {
        title: string
        "description_plain_text": string
        "description_rich_text": string
        type: string
        "video_url": string
        "image_urls": string[]
        "text_body": string
        "legacy_id": string
        "resource_label_ids": number[]
    }

    export interface CreateInformationResponse {
        "doc_id": string
        id: number
        "created_at": string
    }

    export interface CreateLabelRequest {
        position: string
        value: string
    }

    export interface CreateOptionRequest {
        value: string
        order: number
        /**
         * optional. used at the moment just for the image-selection questions
         */
        "image_url": string

        /**
         * optional. used for issue-location questions
         */
        coordinates: number[][]

        /**
         * optional. used for drop down questions
         */
        efficacy: number

        "frequency_quantity": number
        "frequency_unit": string
        texts: OptionText[]
    }

    export interface CreateOutcomeRequest {
        urgency: string
        "recommendation_type": string
        "advice_plain_text": string
        "advice_rich_text": string
        "recommendation_rich_text": string
        "recommendation_plain_text": string
        "what_to_do_next_type"?: string
        "what_to_do_next_plain_text"?: string
        "what_to_do_next_rich_text"?: string
        "what_you_need_to_know"?: string
        "what_you_need_to_know_plain_text"?: string
        "what_you_need_to_know_rich_text"?: string
        "venom_code_doc_ids": uuid.UUID[]
        "venom_code_group_doc_ids": uuid.UUID[]
        "product_recommendation_doc_ids": uuid.UUID[]
        "wtdn_product_recommendation_doc_ids": uuid.UUID[]
        "wyntk_product_recommendation_doc_ids": uuid.UUID[]
        /**
         * an internal identifier for the outcome
         */
        name: string

        "resource_labels"?: number[]
        sections?: CreateOutcomeSection[]
        /**
         * IsTrialsOutcome
         * set by a checkbox on the FE when first creating an
         * outcome, if checked, the outcome is a trials outcome and the validation
         * for the presence of most fields is bypassed
         * Deprecated. Use OutcomeType instead
         */
        "is_trials_outcome"?: boolean

        /**
         * OutcomeType
         * OutcomeType can be either default, ai_gait or trial. If this
         * isn't set, the backend will default to default
         */
        "outcome_type": string

        /**
         * TMSStepStatus
         * TMSStepStatus is required for OutcomeType = trial
         * If it is not set the backend will return an error
         * Accepted values are Complete/Action Required
         */
        "tms_step_status"?: string
    }

    export interface CreateOutcomeResponse {
        id: number
        "doc_id": uuid.UUID
    }

    export interface CreateOutcomeSection {
        type: string
        title: string
        language: entities.LanguageISO639
        "rich_text": string
        "plain_text": string
    }

    export interface CreateQuestionRequest {
        type: string
        "value_type": string
        /**
         * only populated if its a text input for example
         */
        placeholder: string

        key: string
        options: CreateOptionRequest[]
        /**
         * these are the optional labels for the slider datapoint
         */
        labels: CreateLabelRequest[]

        /**
         * a nullable field requested by Sam D to aid the import
         */
        "legacy_id": string

        /**
         * these are the actual labels to associate with the question
         */
        "question_label_ids": number[]

        /**
         * optional. needed for the issue location image map
         */
        "image_map_url": string

        /**
         * optional. needed for upload file questions
         */
        "upload_bucket": string

        /**
         * AIBackendNames
         * a list of AI backends that can be used to process the data point. e.g.
         * "gait" for gait analysis
         */
        "ai_backend"?: string

        texts: QuestionText[]
    }

    export interface CreateQuestionResponse {
        Question: Question
    }

    export interface CreateResourceLabelRequest {
        name: string
    }

    export interface CreateResourceLabelResponse {
        "node_label": ResourceLabel
    }

    export interface DataPoint {
        id: number
        type: string
        "value_type": string
        placeholder: string
        options: DataPointOption[]
        labels: DataPointLabel[]
        key: string
        "image_map_url": string
        "upload_bucket": string
        "ai_backend_name"?: string
    }

    export interface DataPointLabel {
        id: number
        position: string
        value: string
    }

    export interface DataPointOption {
        id: number
        value: string
        order: number
        "image_url": string
        "bucket_path": string
        coordinates: number[][]
        efficacy: number
        "frequency_quantity": number
        "frequency_unit": string
        texts: OptionText[]
        /**
         * I have to keep these on here for now so as not to break the algo builder
         * with this change. We'll remove them in a future PR
         */
        label: string

        "description_rich_text"?: string
        "description_plain_text"?: string
    }

    export interface DeclareAndCondition {
        "or_conditions": DeclareOrCondition[]
    }

    /**
     * the frontend declares which nodes should be connected, by referencing the
     * target ID/version and the source ID/version (and eventually any rules). The API will
     * 1. check that those resources are in the algorithm.
     * 2. check if they are already connected.
     * 3. if not, will create a connection between them.
     */
    export interface DeclareConnectionRequest {
        /**
         * modelling in this way saves the frontend having to manage whether they
         * send a node id if one exists, if not etc...
         */
        "source_resource": DeclareConnectionResource

        "target_resource": DeclareConnectionResource
        rules: DeclareRuleRequest[]
    }

    export interface DeclareConnectionResource {
        "resource_id": number
        "resource_type": string
        "resource_instance_id"?: uuid.UUID
        "is_skippable": boolean
    }

    export interface DeclareConnectionResponse {
        "error_message": string
    }

    export interface DeclareFormulaRequest {
        "formula_template": string
        "output_variable": string
    }

    /**
     * the frontend declares "this question/information screen should be in the
     * algorithm. The API will check it is, and if not, add it as a node.
     */
    export interface DeclareNodeRequest {
        /**
         * information or question
         */
        type: string

        /**
         * the id of the question or information screen. Remember an ID uniquely
         * identifies a resource at a particular version.
         */
        "resource_id": number

        "resource_instance_id"?: uuid.UUID
        "is_skippable": boolean
    }

    export interface DeclareNodeResponse {
        "error_message": string
        /**
         * in the response, there should always be an actual nodeid, as the
         * question/information is now part of the algo and therefore has an entry
         * in the nodes.
         */
        "node_id": number
    }

    export interface DeclareOrCondition {
        property: string
        "property_ref": string
        operation: string
        value: string
    }

    export interface DeclareRuleRequest {
        name: string
        "and_conditions": DeclareAndCondition[]
    }

    export interface FilterAIBackends {
        Accepts: string
    }

    export interface GaitModelResponse {
        status: string
        "session_doc_id": string
        "video_section_results": GaitModelVideoSectionResults[]
        "full_url": string
    }

    export interface GaitModelVideoSectionResults {
        result: number
        contextresult: number
        start: number
        end: number
        usefresult: number
    }

    export interface GetAlgorithmCategoryResponse {
        "algorithm_category": AlgorithmCategory
    }

    export interface GetFeaturedAlgorithmResponse {
        "featured_algorithm": Algorithm
    }

    export interface GetFeaturedCategoriesResponse {
        "algorithm_category": AlgorithmCategory[]
    }

    export interface GetOutcomeByIDResponse {
        id: number
        "doc_id": uuid.UUID
        urgency?: string
        "outcome_type": string
        "tms_step_status"?: string
        "created_at": string
        "primary_recommendation": OutcomePrimaryRecommendation
        "outcome_advice_texts": OutcomeAdviceText[]
        "what_to_do_next": OutcomeWhatToDoNext
        "what_you_need_to_know": OutcomeWhatYouNeedToKnow
        "venom_codes": VenomCode[]
        "venom_code_groups": VenomCodeGroup[]
        "product_recommendations": ProductRecommendation[]
        "wtdn_product_recommendations": ProductRecommendation[]
        "wyntk_product_recommendations": ProductRecommendation[]
        name: string
        "resource_labels"?: ResourceLabel[]
        sections?: OutcomeSection[]
    }

    export interface GetOutcomeSectionText {
        title: string
        language: entities.LanguageISO639
        "rich_text": string
        "plain_text": string
    }

    export interface Information {
        id: number
        type: entities.InformationType
        "doc_id": uuid.UUID
        texts: InformationText[]
        "image_urls": InformationImageURL[]
        "video_url": InformationVideoURL
        "legacy_id": string
        "resource_labels": ResourceLabel[]
    }

    export interface InformationCloneResponse {
        "information_doc_id": uuid.UUID
    }

    export interface InformationImageURL {
        "bucket_path": string
        "image_url": string
    }

    export interface InformationText {
        language: string
        title: string
        "description_plain_text": string
        "description_rich_text": string
        "text_body": string
    }

    export interface InformationVideoURL {
        "bucket_path": string
        "video_url": string
    }

    export interface ListAIBackendResponses {
        "ai_backends": AIBackend[]
    }

    export interface ListAlgorithmCategoriesRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the filter which we apply to the results
         */
        Filter: string
    }

    export interface ListAlgorithmCategoriesResponse {
        "algorithm_categories": AlgorithmCategory[]
        pagination: Pagination
    }

    export interface ListAlgorithmRevisionsResponse {
        revisions: AlgorithmRoot[]
    }

    export interface ListAlgorithmTranslationPreviewResponse {
        "algorithm_translated_previews": AlgorithmTranslatedPreview[]
        pagination: Pagination
    }

    export interface ListAlgorithmsInCategoryResponse {
        algorithms: AlgorithmPreview[]
    }

    export interface ListAlgorithmsRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the name which is searched for
         */
        Name: string

        /**
         * the species to search
         */
        SpeciesDocIDs: string[]
    }

    export interface ListAlgorithmsResponse {
        algorithms: AlgorithmPreview[]
        pagination: Pagination
    }

    export interface ListInformationAlgorithm {
        id: number
        "doc_id": uuid.UUID
        "created_at": string
        name: string
        status: string
        "is_using_latest_version": boolean
    }

    export interface ListInformationAlgorithmsRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the filter which we apply to the results
         */
        Filter: string
    }

    /**
     * ListInformationAlgorithms response
     */
    export interface ListInformationAlgorithmsResponse {
        pagination: Pagination
        algorithms: ListInformationAlgorithm[]
    }

    export interface ListInformationsRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the filter which we apply to the results
         */
        Filter: string

        /**
         * the label IDs on which to filter
         */
        FilterLabelIDs: number[]
    }

    export interface ListInformationsResponse {
        Pagination: Pagination
        Informations: Information[]
    }

    export interface ListOutcomeAlgorithm {
        id: number
        "doc_id": uuid.UUID
        "created_at": string
        name: string
        status: string
        "is_using_latest_version": boolean
    }

    export interface ListOutcomeAlgorithmsRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the filter which we apply to the results
         */
        Filter: string
    }

    /**
     * ListOutcomeAlgorithms response
     */
    export interface ListOutcomeAlgorithmsResponse {
        pagination: Pagination
        algorithms: ListOutcomeAlgorithm[]
    }

    export interface ListOutcomeUrgency {
        "outcome_urgency_levels": string[]
    }

    export interface ListOutcomesRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the filter which we apply to the results
         */
        Filter: string

        Language: string
        FilterLabelIDs: number[]
    }

    export interface ListOutcomesResponse {
        Pagination: Pagination
        Outcomes: OutcomePreview[]
    }

    export interface ListQuestionAlgorithm {
        id: number
        "doc_id": uuid.UUID
        "created_at": string
        name: string
        status: string
        "is_using_latest_version": boolean
    }

    export interface ListQuestionAlgorithmsRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the filter which we apply to the results
         */
        Filter: string
    }

    /**
     * ListQuestionAlgorithm response
     */
    export interface ListQuestionAlgorithmsResponse {
        pagination: Pagination
        algorithms: ListQuestionAlgorithm[]
    }

    export interface ListQuestionsRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the filter which we apply to the results
         */
        Filter: string

        /**
         * the list of label IDs to filter the results by
         */
        FilterLabelIDs: number[]

        LegacyID?: string
    }

    export interface ListQuestionsResponse {
        questions: Question[]
        pagination: Pagination
    }

    export interface ListRecommedationTypes {
        "recommendation_types": string[]
    }

    export interface ListResourceLabelsRequest {
        /**
         * the filter which we apply to the results
         */
        Filter: string
    }

    export interface ListRuleOperationsRequest {
        ConditionProperty: string
    }

    export interface ListRuleOperationsResponse {
        Operations: entities.RuleConditionOperation[]
    }

    export interface ListRulePropertiesResponse {
        Properties: RulePropertyValue[]
    }

    export interface ListRuleValuesRequest {
        ConditionProperty: string
    }

    export interface ListRuleValuesResponse {
        "possible_values": RulePossibleValue[]
    }

    export interface ListSymptomCheckerSummariesParams {
        PetDocID: string
        /**
         * not the vet, but the user that should own the pet
         */
        UserDocID: string

        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number
    }

    export interface ListSymptomCheckerSummariesResponse {
        "symptom_checker_sessions": SymptomCheckerSummary[]
        pagination: Pagination
    }

    export interface ListWhatToDoNext {
        "what_to_do_next": string[]
    }

    export interface NewAlgorithmDraftResponse {
        id: number
    }

    export interface Node {
        id: number
        "doc_id": uuid.UUID
        "resource_id": number
        "resource_instance_id": uuid.UUID
        type: string
        "dialogue_header": string
        "dialogue_description": string
        "question_view": QuestionView
        "is_latest_version": boolean
        "latest_version_id": number
        "is_skippable": boolean
    }

    export interface OptionText {
        language: string
        label: string
        "description_plain_text"?: string
        "description_rich_text"?: string
    }

    export interface OutcomeAdviceText {
        language: string
        "plain_text": string
        "rich_text": string
    }

    export interface OutcomeCloneResponse {
        "outcome_doc_id": uuid.UUID
    }

    export interface OutcomeData {
        urgency: string
        recommendation: string
        "recommendation_text": string
        "what_to_do_next_text": string
        "advice_text": string
    }

    export interface OutcomePreview {
        id: number
        "doc_id": string
        urgency: string
        "primary_recommendation_type": string
        "created_at": string
        name: string
        "outcome_type": string
    }

    export interface OutcomePrimaryRecommendation {
        type: string
        texts: PrimaryRecommendationText[]
    }

    export interface OutcomeSection {
        type: string
        texts: GetOutcomeSectionText[]
    }

    export interface OutcomeWhatToDoNext {
        type: string
        texts: OutcomeWhatToDoNextText[]
    }

    export interface OutcomeWhatToDoNextText {
        language: string
        "plain_text": string
        "rich_text": string
    }

    export interface OutcomeWhatYouNeedToKnow {
        type: string
        texts: OutcomeWhatYouNeedToKnowText[]
    }

    export interface OutcomeWhatYouNeedToKnowText {
        language: string
        "plain_text": string
        "rich_text": string
    }

    export interface Pagination {
        total: number
        "current_page": number
        pages: number
    }

    export interface PrimaryRecommendationText {
        language: string
        "plain_text": string
        "rich_text": string
    }

    export interface ProductRecommendation {
        DocID: uuid.UUID
        Name: string
    }

    export interface Question {
        id: number
        version: number
        "doc_id": uuid.UUID
        status: string
        "data_points": DataPoint[]
        texts: QuestionText[]
        "legacy_id": string
        "resource_labels": ResourceLabel[]
        "data_point_labels": DataPointLabel[]
    }

    export interface QuestionCloneResponse {
        "question_doc_id": uuid.UUID
    }

    export interface QuestionRevisionsResponse {
        revisions: Question[]
    }

    export interface QuestionText {
        language: string
        title: string
        "description_plain_text": string
        "description_rich_text": string
    }

    export interface QuestionView {
        "answer_options": QuestionViewDataPoint[]
        "outcome_data"?: OutcomeData
    }

    export interface QuestionViewDataPoint {
        DataPoint: DataPoint
    }

    export interface Region {
        "short_code": string
    }

    export interface ReorderAlgorithmCategoriesRequest {
        "source_category_doc_id": string
        "target_category_doc_id": string
    }

    export interface ReorderAlgorithmsInCategoryRequest {
        "source_algorithm_doc_id": string
        "target_algorithm_doc_id": string
    }

    export interface ResourceLabel {
        id: number
        name: string
        "created_at": string
    }

    export interface ResourceLabelsResponse {
        labels: ResourceLabel[]
    }

    export interface ResourceMissingTranslation {
        "doc_id": string
        title: string
    }

    export interface RulePossibleValue {
        display: string
        submit: string
    }

    export interface RulePropertyValue {
        "property_name": string
        "expected_property_value": string
    }

    export interface SessionOutcome {
        "urgency_level": string
        "venom_code": SessionOutcomeVenomCode[]
        "recommended_products": SessionOutcomeRecommendedProduct[]
    }

    export interface SessionOutcomeRecommendedProduct {
        "doc_id": uuid.UUID
        sku: string
        name: string
        description: string
    }

    export interface SessionOutcomeVenomCode {
        "venom_id": string
        "doc_id": uuid.UUID
        "term_name": string
        synonym: string
        "customer_synonym": string
    }

    export interface SessionResponse {
        title: string
        "data_point_type": string
        response: string
        "answer_options": AnswerOption[]
    }

    export interface Species {
        species: string
        "doc_id": uuid.UUID
    }

    export interface SymptomCheckerSummary {
        "algorithm_doc_id": string
        "algorithm_title": string
        "session_responses": SessionResponse[]
        "session_outcome": SessionOutcome
        media: string[]
    }

    export interface TextRequest {
        title: string
        "description_plain_text": string
        "description_rich_text": string
        "text_body": string
        language: entities.LanguageISO639
    }

    export interface ToggleFeatureAlgorithmResponse {
        "algorithm_doc_id": string
        featured: boolean
    }

    export interface ToggleFeatureCategoryResponse {
        "category_doc_id": string
        featured: boolean
    }

    export interface TranslationPercentage {
        language: entities.LanguageISO639
        "missing_outcome_doc_ids": ResourceMissingTranslation[]
        "missing_question_doc_ids": ResourceMissingTranslation[]
        "missing_information_doc_ids": ResourceMissingTranslation[]
        "percent_translated": number
    }

    export interface UnarchiveQuestionResponse {
    }

    export interface UpdateAlgorithmCategoryRequest {
        "icon_entry_id": string
        order: number
        texts: AlgorithmCategoryText[]
        hidden: boolean
    }

    export interface UpdateAlgorithmMetaDataRequest {
        species: uuid.UUID[]
        regions: string[]
        categories: uuid.UUID[]
        texts: AlgorithmText[]
    }

    /**
     * used by the algorithm developers when they hit "save", saves the entire state
     * of the algorithm in the database in a transaction. An algorithm must have an
     * open draft in order to be updated. the concept here is a "declarive" update.
     * The frontend sends what questions, info, rules etc should be in the algorithm
     * and how they are connected, and the backend sets the algorithm to represent
     * that state.
     */
    export interface UpdateAlgorithmRequest {
        nodes: DeclareNodeRequest[]
        connections: DeclareConnectionRequest[]
        formulas?: DeclareFormulaRequest[]
    }

    /**
     * we need to return any errors from the save, for the frontend to display. We
     * can return the same structure that was submitted for response, but compose it
     * with errors.
     */
    export interface UpdateAlgorithmResponse {
        Nodes: DeclareNodeResponse[]
        Connections: DeclareConnectionResponse[]
    }

    export interface UpdateInformationRequest {
        type: string
        "video_url": string
        "image_urls": string[]
        texts: TextRequest[]
    }

    export interface UpdateInformationResponse {
        "doc_id": uuid.UUID
        id: number
    }

    export interface UpdateLabelRequest {
        position: string
        value: string
    }

    export interface UpdateOptionRequest {
        order: number
        /**
         * optional. used at the moment just for the image-selection questions
         */
        "image_url": string

        value: string
        /**
         * optional. currently used for dropdown
         */
        efficacy: number

        "frequency_quantity": number
        "frequency_unit": string
        texts: OptionText[]
    }

    export interface UpdateOutcomeRequest {
        name: string
        urgency: string
        "primary_recommendation": OutcomePrimaryRecommendation
        "outcome_advice_texts": OutcomeAdviceText[]
        "what_to_do_next": OutcomeWhatToDoNext
        "what_you_need_to_know": OutcomeWhatYouNeedToKnow
        "venom_code_doc_ids": uuid.UUID[]
        "venom_code_group_doc_ids": uuid.UUID[]
        "product_recommendation_doc_ids": uuid.UUID[]
        "wtdn_product_recommendation_doc_ids": uuid.UUID[]
        "wyntk_product_recommendation_doc_ids": uuid.UUID[]
        sections?: OutcomeSection[]
        "tms_step_status"?: string
    }

    export interface UpdateOutcomeResponse {
        id: number
    }

    export interface UpdateQuestionRequest {
        type: string
        texts: QuestionText[]
        /**
         * only populated if its a text input for example
         */
        placeholder: string

        options: UpdateOptionRequest[]
        /**
         * these are the optional labels for the slider datapoint
         */
        labels: UpdateLabelRequest[]

        /**
         * a nullable field requested by Sam D to aid the import
         */
        "legacy_id": string

        /**
         * these are the actual labels to associate with the question
         */
        "question_label_ids": number[]

        "upload_bucket": string
        "ai_backend"?: string
    }

    export interface UpdateQuestionResponse {
        Question: Question
    }

    export interface VenomCode {
        VenomID: string
        DocID: uuid.UUID
        TermName: string
        Synonym: string
        CustomerSynonym: string
    }

    export interface VenomCodeGroup {
        DocID: uuid.UUID
        Name: string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Add algorithm to a category
         * Add the given algorithm to the given category
         */
        public async AddAlgorithmToCategory(params: AddAlgorithmToCategoryRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/internal/algorithm-categories/algorithm`, JSON.stringify(params))
        }

        /**
         * Archives an entire algorithm given the doc ID
         * algorithms that have been published can never be archived, only paused
         */
        public async ArchiveAlgorithm(algorithmDocID: string): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/algorithms/${encodeURIComponent(algorithmDocID)}/archive`)
        }

        /**
         * ArchiveInformation archives an information screen
         * Can't be used if the information screen is used in a published/draft algorithm
         */
        public async ArchiveInformation(informationDocID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/information/${encodeURIComponent(informationDocID)}`)
        }

        /**
         * ArchiveQuestion archives a question
         * Can't be used if the question is used in a published or draft algorithm
         */
        public async ArchiveQuestion(questionDocID: string): Promise<ArchiveQuestionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("DELETE", `/v1/questions/${encodeURIComponent(questionDocID)}`)
            return await resp.json() as ArchiveQuestionResponse
        }

        /**
         * Count Datapoint Keys.
         * Used by the frontend to help suggest a new datapoint key to the user.
         * the user shouldn't have to worry too much about the datapoint key as its auto
         * generated, but a bit like google service accounts, if there is a conflict we
         * should allow them to modify the key a little
         */
        public async CheckDataPointKey(keyID: string): Promise<CheckDataPointKeyResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/datapoints/keys/check/${encodeURIComponent(keyID)}`)
            return await resp.json() as CheckDataPointKeyResponse
        }

        /**
         * Check Formula
         * 
         * Check a formula for correctness
         */
        public async CheckFormula(params: CheckFormulaRequest): Promise<CheckFormulaResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/formulas/evaluate`, JSON.stringify(params))
            return await resp.json() as CheckFormulaResponse
        }

        /**
         * Returns a new copy of an existing algorithm, with a brand new doc ID
         */
        public async CloneAlgorithm(id: number, params: AlgorithmCloneRequest): Promise<AlgorithmCloneResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/algorithms/${encodeURIComponent(id)}/clone`, JSON.stringify(params))
            return await resp.json() as AlgorithmCloneResponse
        }

        /**
         * Returns a new copy of an existing information, with a brand new doc ID
         */
        public async CloneInformation(docID: string): Promise<InformationCloneResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/informations/${encodeURIComponent(docID)}/clone`)
            return await resp.json() as InformationCloneResponse
        }

        /**
         * Returns a new copy of an existing outcome, with a brand new doc ID
         */
        public async CloneOutcome(docID: string): Promise<OutcomeCloneResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/outcomes/${encodeURIComponent(docID)}/clone`)
            return await resp.json() as OutcomeCloneResponse
        }

        /**
         * Returns a new copy of an existing question, with a brand new doc ID
         */
        public async CloneQuestion(docID: string): Promise<QuestionCloneResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/questions/${encodeURIComponent(docID)}/clone`)
            return await resp.json() as QuestionCloneResponse
        }

        /**
         * Create algorithm.
         * Creates an algorithm with a single node.
         */
        public async CreateAlgorithm(params: CreateAlgorithmRequest): Promise<CreateAlgorithmResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/algorithms`, JSON.stringify(params))
            return await resp.json() as CreateAlgorithmResponse
        }

        /**
         * Create algorithm category.
         * Creates an algorithm category.
         */
        public async CreateAlgorithmCategory(params: CreateAlgorithmCategoryRequest): Promise<CreateAlgorithmCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/internal/algorithm-categories`, JSON.stringify(params))
            return await resp.json() as CreateAlgorithmCategoryResponse
        }

        /**
         * Create Information.
         * Creates a new Information.
         */
        public async CreateInformation(params: CreateInformationRequest): Promise<CreateInformationResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/information`, JSON.stringify(params))
            return await resp.json() as CreateInformationResponse
        }

        /**
         * Creates a new version of an existing algorithm. Will error if the algorithm ID is already draft or published
         */
        public async CreateNewAlgorithmDraft(algorithmDocID: string): Promise<NewAlgorithmDraftResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PATCH", `/v1/algorithms-id/${encodeURIComponent(algorithmDocID)}/new-version`)
            return await resp.json() as NewAlgorithmDraftResponse
        }

        /**
         * Create Outcome.
         * Creates a new outcome. All input is assumed to be in english on creation.
         */
        public async CreateOutcome(params: CreateOutcomeRequest): Promise<CreateOutcomeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/outcome`, JSON.stringify(params))
            return await resp.json() as CreateOutcomeResponse
        }

        /**
         * Create Question.
         * Creates a new Question.
         */
        public async CreateQuestion(params: CreateQuestionRequest): Promise<CreateQuestionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/questions`, JSON.stringify(params))
            return await resp.json() as CreateQuestionResponse
        }

        /**
         * Lists all labels in the database
         * were published or drafted and by whom.
         */
        public async CreateResourceLabel(params: CreateResourceLabelRequest): Promise<CreateResourceLabelResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/question-labels`, JSON.stringify(params))
            return await resp.json() as CreateResourceLabelResponse
        }

        /**
         * Deletes an algorithm category.
         * Does not delete all the algorithms in that category. Just deletes the algo->category link
         */
        public async DeleteAlgorithmCategory(categoryDocID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/internal/algorithm-categories/${encodeURIComponent(categoryDocID)}`)
        }

        /**
         * GaitModeResponseReceiver receives the response from the gait model
         * and updates the session with the score
         */
        public async GaitModeResponseReceiver(params: GaitModelResponse): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/gait-response`, JSON.stringify(params))
        }

        /**
         * Get algorithm.
         * Returns an algorithm with a tree of nodes. Does not support pagination for now
         * TODO: this was a method to get us going, hence the hardcoded version.
         * We'll want to update this eventually, but for now I'll keep it the same to reduce diff
         * in this PR
         */
        public async GetAlgorithmByDocID(docID: string): Promise<Algorithm> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/algorithms/${encodeURIComponent(docID)}`)
            return await resp.json() as Algorithm
        }

        /**
         * Get algorithm by ID
         * Returns an algorithm that matches the given ID, effectively denoting the algorithm at a particular version
         * We need the id to avoid a path clash. We'll need to revisit this bit of the API as a group
         */
        public async GetAlgorithmByID(id: number): Promise<Algorithm> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/algorithms-id/${encodeURIComponent(id)}`)
            return await resp.json() as Algorithm
        }

        /**
         * Get information about a single algorithm category
         * Get the information about a single algorithm category
         */
        public async GetAlgorithmCategory(categoryDocID: string): Promise<GetAlgorithmCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/internal/algorithm-categories/${encodeURIComponent(categoryDocID)}`)
            return await resp.json() as GetAlgorithmCategoryResponse
        }

        public async GetFeaturedAlgorithm(): Promise<GetFeaturedAlgorithmResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/feature-algorithm`)
            return await resp.json() as GetFeaturedAlgorithmResponse
        }

        public async GetFeaturedCategories(): Promise<GetFeaturedCategoriesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/feature-categories`)
            return await resp.json() as GetFeaturedCategoriesResponse
        }

        /**
         * GetInformation retrieves an information screen by doc ID
         */
        public async GetInformation(informationDocID: string): Promise<Information> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/information/${encodeURIComponent(informationDocID)}`)
            return await resp.json() as Information
        }

        /**
         * GetInformation retrieves an information screen by its ID
         */
        public async GetInformationByID(id: number): Promise<Information> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/information-id/${encodeURIComponent(id)}`)
            return await resp.json() as Information
        }

        /**
         * Get Outcome By DocID.
         * Returns the latest version of that outcome by its doc ID.
         */
        public async GetOutcomeByDocID(docID: string): Promise<GetOutcomeByIDResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/outcome/${encodeURIComponent(docID)}`)
            return await resp.json() as GetOutcomeByIDResponse
        }

        /**
         * Get Outcome By ID.
         * Returns an outcome by its ID.
         */
        public async GetOutcomeByID(id: number): Promise<GetOutcomeByIDResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/outcome-id/${encodeURIComponent(id)}`)
            return await resp.json() as GetOutcomeByIDResponse
        }

        /**
         * Get question.
         * Returns a question, as an aggregate with its datapoint(s) and options
         */
        public async GetQuestionByDocID(docID: string): Promise<Question> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/questions/${encodeURIComponent(docID)}`)
            return await resp.json() as Question
        }

        /**
         * Get question. Returns a question, as an aggregate with its datapoint(s) and
         * options We'll need to revisit this part of the API along with its companion
         * method algorithm-id
         */
        public async GetQuestionByID(id: number): Promise<Question> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/questions-id/${encodeURIComponent(id)}`)
            return await resp.json() as Question
        }

        /**
         * Get symptom checker summary
         * Returns a list of questions and their responses from this session with links to attached image/video files
         */
        public async GetSessionSymptonCheckerSummary(docID: string): Promise<SymptomCheckerSummary> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/sessions/${encodeURIComponent(docID)}/symptom-checker-summary`)
            return await resp.json() as SymptomCheckerSummary
        }

        /**
         * Lists all AI Backends that are available
         * Use the optional filters to filter the list to those that are compatible with the given data point type
         */
        public async ListAIBackends(params: FilterAIBackends): Promise<ListAIBackendResponses> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "data_point_type": params.Accepts,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/ai-backends`, undefined, {query})
            return await resp.json() as ListAIBackendResponses
        }

        /**
         * List algorithm categories.
         * Lists algorithm categories with pagination support
         */
        public async ListAlgorithmCategories(params: ListAlgorithmCategoriesRequest): Promise<ListAlgorithmCategoriesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
                search:           params.Filter,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/internal/algorithm-categories`, undefined, {query})
            return await resp.json() as ListAlgorithmCategoriesResponse
        }

        /**
         * List algorithms.
         * Lists algorithms with pagination support
         */
        public async ListAlgorithmTranslationPreviews(params: ListAlgorithmsRequest): Promise<ListAlgorithmTranslationPreviewResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page":  String(params.RequestedItemsPerPage),
                "page_number":     String(params.RequestedPageNumber),
                search:            params.Name,
                "species_doc_ids": params.SpeciesDocIDs.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/internal/algorithms/translations`, undefined, {query})
            return await resp.json() as ListAlgorithmTranslationPreviewResponse
        }

        /**
         * List algorithms.
         * Lists algorithms with pagination support
         */
        public async ListAlgorithms(params: ListAlgorithmsRequest): Promise<ListAlgorithmsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page":  String(params.RequestedItemsPerPage),
                "page_number":     String(params.RequestedPageNumber),
                search:            params.Name,
                "species_doc_ids": params.SpeciesDocIDs.map((v) => v),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/internal/algorithms`, undefined, {query})
            return await resp.json() as ListAlgorithmsResponse
        }

        /**
         * Get algorithms in a category
         * Gets the algorithms contained in a category
         */
        public async ListAlgorithmsInCategory(docID: string): Promise<ListAlgorithmsInCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/internal/algorithm-categories/${encodeURIComponent(docID)}/algorithms`)
            return await resp.json() as ListAlgorithmsInCategoryResponse
        }

        /**
         * Lists all question revisions for a given question by docID, showing when they
         * were published or drafted and by whom.
         */
        public async ListAlgoritmRevisions(docID: string): Promise<ListAlgorithmRevisionsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/algorithms/${encodeURIComponent(docID)}/revisions`)
            return await resp.json() as ListAlgorithmRevisionsResponse
        }

        /**
         * Lists all information algorithms
         * List all algorithms an information is apart of
         */
        public async ListInformationAlgorithms(docID: string, params: ListInformationAlgorithmsRequest): Promise<ListInformationAlgorithmsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                filter:           params.Filter,
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/information/${encodeURIComponent(docID)}/algorithms`, undefined, {query})
            return await resp.json() as ListInformationAlgorithmsResponse
        }

        /**
         * List information pages
         * Lists information pages with pagination support
         */
        public async ListInformations(params: ListInformationsRequest): Promise<ListInformationsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                filter:             params.Filter,
                "filter_label_ids": params.FilterLabelIDs.map((v) => String(v)),
                "items_per_page":   String(params.RequestedItemsPerPage),
                "page_number":      String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/information`, undefined, {query})
            return await resp.json() as ListInformationsResponse
        }

        /**
         * Lists all outcome algorithms
         * List all algorithms an outcome is apart of
         */
        public async ListOutcomeAlgorithms(docID: string, params: ListOutcomeAlgorithmsRequest): Promise<ListOutcomeAlgorithmsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                filter:           params.Filter,
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/outcome/${encodeURIComponent(docID)}/algorithms`, undefined, {query})
            return await resp.json() as ListOutcomeAlgorithmsResponse
        }

        /**
         * List existing outcomes
         * Lists Outcome pages with pagination support
         */
        public async ListOutcomePreviews(params: ListOutcomesRequest): Promise<ListOutcomesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                filter:             params.Filter,
                "filter_label_ids": params.FilterLabelIDs.map((v) => String(v)),
                "items_per_page":   String(params.RequestedItemsPerPage),
                language:           params.Language,
                "page_number":      String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/outcome-previews`, undefined, {query})
            return await resp.json() as ListOutcomesResponse
        }

        /**
         * Lists all question algorithms
         * List all algorithms a question is apart of
         */
        public async ListQuestionAlgorithms(docID: string, params: ListQuestionAlgorithmsRequest): Promise<ListQuestionAlgorithmsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                filter:           params.Filter,
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/questions/${encodeURIComponent(docID)}/algorithms`, undefined, {query})
            return await resp.json() as ListQuestionAlgorithmsResponse
        }

        /**
         * Lists all question revisions for a given question by docID, showing when they
         * were published or drafted and by whom.
         */
        public async ListQuestionRevisions(docID: string): Promise<QuestionRevisionsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/questions/${encodeURIComponent(docID)}/revisions`)
            return await resp.json() as QuestionRevisionsResponse
        }

        /**
         * List questions.
         * Lists questions with pagination support
         */
        public async ListQuestions(params: ListQuestionsRequest): Promise<ListQuestionsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                filter:             params.Filter,
                "filter_label_ids": params.FilterLabelIDs.map((v) => String(v)),
                "items_per_page":   String(params.RequestedItemsPerPage),
                "legacy_id":        params.LegacyID,
                "page_number":      String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/questions`, undefined, {query})
            return await resp.json() as ListQuestionsResponse
        }

        /**
         * List Recommendation Types
         * Returns all available recommendation types
         */
        public async ListRecommendationTypes(): Promise<ListRecommedationTypes> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/recommendation-types`)
            return await resp.json() as ListRecommedationTypes
        }

        /**
         * Lists all question labels in the database
         */
        public async ListResourceLabels(params: ListResourceLabelsRequest): Promise<ResourceLabelsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                filter: params.Filter,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/node-labels`, undefined, {query})
            return await resp.json() as ResourceLabelsResponse
        }

        /**
         * Returns all available operations for a given condition property
         * 
         * e.g. given Breed, returns "equals", "not equals"
         * given Age (Weeks) returns "equals", "not equals", "greater than", "less than" etc
         * this probably warrants some services later, but is not captured right now
         */
        public async ListRuleOperations(params: ListRuleOperationsRequest): Promise<ListRuleOperationsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "condition_property": params.ConditionProperty,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/rules/operations`, undefined, {query})
            return await resp.json() as ListRuleOperationsResponse
        }

        /**
         * List available rule condition properties. e.g. "Breed", "Gender", "Species".
         * 
         * This is a flat list right now, so doesn't contain business logic.
         */
        public async ListRuleProperties(): Promise<ListRulePropertiesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/rules/properties`)
            return await resp.json() as ListRulePropertiesResponse
        }

        /**
         * list potential rule values. e.g. for Breed, return all possible breeds
         * 
         * e.g. given Breed, returns all the breeds in the system
         * given gender, returns the genders in the system
         * if given something it doesn't understand, returns an error.
         * this is a little bit like a poor mans version of the "resolvers" in the old system
         * the downside of that is that it tightly coupled the rules engine to
         * everything in the system, but it did allow for higher cohesion.
         * This is quicker to implement. If others start using the rules, we can reintroduce the resolvers
         */
        public async ListRuleValues(params: ListRuleValuesRequest): Promise<ListRuleValuesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "condition_property": params.ConditionProperty,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/rules/property-values`, undefined, {query})
            return await resp.json() as ListRuleValuesResponse
        }

        /**
         * List symptom checker session summaries
         * Returns a list of symptom checker sessions for a particular pet
         * this is intended as an internal endpoint for our vets to use. Its created for trials
         * but should be generic
         */
        public async ListSymptomCheckerSummaries(params: ListSymptomCheckerSummariesParams): Promise<ListSymptomCheckerSummariesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
                "pet_doc_id":     params.PetDocID,
                "user_doc_id":    params.UserDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/internal/symptom-checker-sessions`, undefined, {query})
            return await resp.json() as ListSymptomCheckerSummariesResponse
        }

        /**
         * List Urgency Types
         * Returns all available urgency types
         */
        public async ListUrgencies(): Promise<ListOutcomeUrgency> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/urgencies`)
            return await resp.json() as ListOutcomeUrgency
        }

        /**
         * List What To Do Next Types
         * Returns all available what to do next types
         */
        public async ListWhatToDoNextTypes(): Promise<ListWhatToDoNext> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/what-to-do-next-types`)
            return await resp.json() as ListWhatToDoNext
        }

        /**
         * Sets a particular algorithm ID to be paused. Will error if that algorithm ID is
         * already published or isn't draft
         */
        public async PauseAlgorithm(algorithmID: number): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/algorithms-id/${encodeURIComponent(algorithmID)}/pause`)
        }

        /**
         * Sets a particular algorithm ID to published. Will error if that algorithm ID is
         * already published or isn't draft
         */
        public async PublishAlgorithm(algorithmID: number): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/algorithms-id/${encodeURIComponent(algorithmID)}/publish`)
        }

        /**
         * Reorder the order of two algorithm categories
         * Reorders the order of two algorithm categories
         */
        public async ReorderOrderAlgorithmCategories(params: ReorderAlgorithmCategoriesRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/internal/algorithm-categories/category-order`, JSON.stringify(params))
        }

        /**
         * Reorder the order of two algorithms in a category
         * Reorders the order of two algorithms in a category
         */
        public async ReorderOrderAlgorithmsInCategory(categoryDocID: string, params: ReorderAlgorithmsInCategoryRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/internal/algorithm-categories/algorithm-order/${encodeURIComponent(categoryDocID)}`, JSON.stringify(params))
        }

        /**
         * Sets a particular algorithm ID to published. Will error if that algorithm ID is
         * already published or isn't draft
         */
        public async SetAlgorithmForReview(algorithmID: number): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/algorithms-id/${encodeURIComponent(algorithmID)}/review`)
        }

        /**
         * Sets a particular algorithm ID to draft from in review. Will error if that
         * algorithm ID is already draft or isn't in review
         */
        public async SetAlgorithmToDraft(algorithmID: number): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/algorithms-id/${encodeURIComponent(algorithmID)}/draft`)
        }

        public async ToggleFeatureAlgorithm(algorithm_doc_id: string): Promise<ToggleFeatureAlgorithmResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/feature-algorithm/${encodeURIComponent(algorithm_doc_id)}`)
            return await resp.json() as ToggleFeatureAlgorithmResponse
        }

        public async ToggleFeatureCategory(category_doc_id: string): Promise<ToggleFeatureCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/feature-categories/${encodeURIComponent(category_doc_id)}`)
            return await resp.json() as ToggleFeatureCategoryResponse
        }

        /**
         * Unarchive Question removes the archived status of a question
         */
        public async UnArchiveQuestion(questionDocID: string): Promise<UnarchiveQuestionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PATCH", `/v1/questions/${encodeURIComponent(questionDocID)}/unarchive`)
            return await resp.json() as UnarchiveQuestionResponse
        }

        /**
         * UnarchiveInformation removes the archived status of an information screen
         */
        public async UnarchiveInformation(informationDocID: string): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/information/${encodeURIComponent(informationDocID)}/unarchive`)
        }

        /**
         * Save algorithm.
         * Saves the entire algorithm in the database.
         */
        public async UpdateAlgorithm(id: number, params: UpdateAlgorithmRequest): Promise<UpdateAlgorithmResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/algorithms/${encodeURIComponent(id)}`, JSON.stringify(params))
            return await resp.json() as UpdateAlgorithmResponse
        }

        /**
         * Update algorithm category.
         * Updates an algorithm category.
         */
        public async UpdateAlgorithmCategory(docID: string, params: UpdateAlgorithmCategoryRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/internal/algorithm-categories/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Update Algorithm Metadata.
         * Updates the algorithms region, species and categories
         */
        public async UpdateAlgorithmMetadata(docID: string, params: UpdateAlgorithmMetaDataRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/algorithms/${encodeURIComponent(docID)}/metadata`, JSON.stringify(params))
        }

        /**
         * UpdateInformation updates a draft information.
         */
        public async UpdateInformation(docID: string, params: UpdateInformationRequest): Promise<UpdateInformationResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/information/${encodeURIComponent(docID)}`, JSON.stringify(params))
            return await resp.json() as UpdateInformationResponse
        }

        /**
         * Updates draft Outcome By DocID.
         */
        public async UpdateOutcome(docID: string, params: UpdateOutcomeRequest): Promise<UpdateOutcomeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/outcome/${encodeURIComponent(docID)}`, JSON.stringify(params))
            return await resp.json() as UpdateOutcomeResponse
        }

        /**
         * Update Question.
         * Updates a Question. This produces a new version of that question, ready for
         * use in algorithms. It does not immediately update algorithms.
         */
        public async UpdateQuestion(docID: string, params: UpdateQuestionRequest): Promise<UpdateQuestionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PUT", `/v1/questions/${encodeURIComponent(docID)}`, JSON.stringify(params))
            return await resp.json() as UpdateQuestionResponse
        }
    }
}

export namespace seeding {
    export interface WebhookRequest {
        Token: string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        public async Webhook(params: WebhookRequest): Promise<void> {
            // Convert our params into the objects we need for the request
            const headers = makeRecord<string, string>({
                "x-access-token": params.Token,
            })

            await this.baseClient.callTypedAPI("POST", `/v1/seeding/webhook`, undefined, {headers})
        }
    }
}

export namespace trialmanagementexternal {
    export interface GetBookingWindowRequest {
        "pet_doc_id": string
        "service_doc_id": string
    }

    export interface GetBookingWindowResponse {
        /**
         * start time in UTC format
         */
        "start_time"?: string

        /**
         * end time in UTC format
         */
        "end_time"?: string
    }

    export interface TrialAppointmentBookedRequest {
        "pet_doc_id": string
        "service_doc_id": string
        "service_name": string
        "appointment_start_date": string
    }

    export interface TrialAppointmentCanncelledOrAbandonedRequest {
        "pet_doc_id": string
        "service_doc_id": string
        "service_name": string
        "appointment_status": string
    }

    export interface TrialParticipantRequest {
        "pet_doc_id": string
        "service_doc_id": string
    }

    export interface TrialParticipantResponse {
        ParticipantsCount: number
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * GetTrialStudyCallStepBookingWindow retrieves allowed booking window for the step
         */
        public async GetTrialStudyCallStepBookingWindow(user_doc_id: string, params: GetBookingWindowRequest): Promise<GetBookingWindowResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "pet_doc_id":     params["pet_doc_id"],
                "service_doc_id": params["service_doc_id"],
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/trial-appointment-booking-window`, undefined, {query})
            return await resp.json() as GetBookingWindowResponse
        }

        /**
         * TrialAppointmentBooked moves participant to next step Study Call
         */
        public async TrialAppointmentBooked(user_doc_id: string, params: TrialAppointmentBookedRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/users/${encodeURIComponent(user_doc_id)}/trial-appointment-booked`, JSON.stringify(params))
        }

        /**
         * TrialAppointmentCanncelledOrAbandoned cancels or abandons appointment
         */
        public async TrialAppointmentCanncelledOrAbandoned(user_doc_id: string, params: TrialAppointmentCanncelledOrAbandonedRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/users/${encodeURIComponent(user_doc_id)}/trial-appointment-cancelled-or-abandoned`, JSON.stringify(params))
        }

        /**
         * TrialParticipantExists updates the status of a trial step for a participant
         */
        public async TrialParticipantExists(user_doc_id: string, params: TrialParticipantRequest): Promise<TrialParticipantResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "pet_doc_id":     params["pet_doc_id"],
                "service_doc_id": params["service_doc_id"],
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/users/${encodeURIComponent(user_doc_id)}/trial-participant-exists`, undefined, {query})
            return await resp.json() as TrialParticipantResponse
        }
    }
}

export namespace trialmanagementinternal {
    export interface ActiveStep {
        "doc_id": uuid.UUID
        status: string
        name: string
        number: number
        duration: number
        "appointment_date": string
        "start_date": string
        "end_date": string
    }

    export interface AddAdverseEventToParticipantStepHistoryRequest {
        AdverseEventDocID: string
    }

    export interface AdverseEventAuditResponse {
        "created_at": string
        "created_by": string
        "changes_summary": string
        "change_reason": entities.ChangeReasonType
        "change_notes": string
    }

    export interface AdverseEventsResponse {
        "doc_id": uuid.UUID
        summary: string
        "form_name": string
        "form_version_id": number
        "form_data": JSONValue
        "created_at": string
        "created_by": string
        "updated_at": string
        "updated_by": string
    }

    export interface AdverseEventsSummary {
        "adverse_event_doc_id": uuid.UUID
        summary: string
    }

    export interface Age {
        years: number
        months: number
    }

    export interface CompleteScreeningCallRequest {
        "user_doc_id": string
        "pet_doc_id": string
        "service_doc_id": string
        eligibility: string
        source: string
    }

    export interface CreateAdverseEventRequest {
        summary: string
        "form_data": JSONValue
        "form_version_id": number
    }

    export interface CreateAdverseEventResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateParticipantRequest {
        "user_doc_id": string
        "pet_doc_id": string
    }

    export interface CreateParticipantResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateTrialRequest {
        name: string
        description: string
        "adverse_events_form_io_id": string
    }

    export interface CreateTrialResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateTrialStepRequest {
        "step_name": string
        "step_type": string
        duration: number
        "service_doc_id": string
        "algorithm_doc_id": string
        "auto_progress": boolean
        metadata: JSONValue
        "booking_window"?: number
    }

    export interface CreateTrialStepResponse {
        "doc_id": uuid.UUID
    }

    export interface DeleteParticipantRequest {
        reason: string
    }

    export interface GetParticipantByUserPetAndServiceResponse {
        "trial_doc_id": string
        "participant_doc_id": string
        "user_doc_id": string
        "pet_doc_id": string
        "participant_status": string
        step: ParticipantServiceStep
    }

    export interface GetParticipantMetadataResponse {
        metadata: JSONValue
    }

    export interface GetParticipantPet {
        "doc_id": string
        name: string
        species: string
        breed: string
        "is_insured": boolean
        insurer: string
        age: Age
        gender: string
    }

    export interface GetParticipantResponse {
        id: number
        "doc_id": string
        user: GetParticipantUser
        pet: GetParticipantPet
        "participant_status": string
    }

    export interface GetParticipantUser {
        "doc_id": string
        name: string
        "phone_number": string
        email: string
    }

    export interface GetTrialParticipantStepResponse {
        id: number
        "doc_id": uuid.UUID
        "trial_step_doc_id": uuid.UUID
        "participant_doc_id": uuid.UUID
        "step_status": string
        "step_name": string
        "step_type": string
        "step_number": number
        "appointment_date": string
        "start_date": string
        "end_date": string
        "step_complete_date": string
    }

    export interface GetTrialStepResponse {
        id: number
        "doc_id": uuid.UUID
        "trial_doc_id": uuid.UUID
        "step_name": string
        "step_type": string
        "step_number": number
        duration: number
        "service_doc_id": uuid.UUID
        "service_name": string
        "algorithm_doc_id": uuid.UUID
        "auto_progress": boolean
        metadata: JSONValue
        "booking_window"?: number
    }

    export interface ListAdverseEventAuditRecordsResponse {
        audits: AdverseEventAuditResponse[]
    }

    export interface ListAdverseEventsResponse {
        "adverse_events": AdverseEventsResponse[]
        pagination: shared.Pagination
    }

    /**
     * ListParticipantAdverseEventsRequest is the request payload for ListParticipantAdverseEvents
     */
    export interface ListParticipantAdverseEventsRequest {
        "items_per_page": number
        "page_number": number
    }

    export interface ListParticipantAdverseEventsSummariesResponse {
        Summaries: AdverseEventsSummary[]
    }

    export interface ListParticipantPet {
        "doc_id": string
        name: string
    }

    export interface ListParticipantUser {
        "doc_id": string
        name: string
    }

    export interface ListParticipantsRequest {
        SortColumn?: string
        /**
         * filter by step status field
         */
        StepStatuses?: string[]

        /**
         * filter by participant status field
         */
        ParticipantStatuses?: string[]

        /**
         * filter by step name field
         */
        StepNames?: string[]

        /**
         * filter by owner name field
         */
        UserName?: string

        /**
         * sort order e.g. ASC/DESC
         */
        SortOrder?: string

        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number
    }

    export interface ListParticipantsResponse {
        participants: ParticipantResponse[]
        pagination: shared.Pagination
    }

    export interface ListTrialParticipantStepHistory {
        history: StepHistory[]
        pagination: shared.Pagination
    }

    export interface ListTrialParticipantStepHistoryRequest {
        /**
         * filter by category field
         * values: Trial Step / Consultation / Algorithm
         */
        Categories?: string[]

        /**
         * filter by event field
         * values: Step status updated / Appointment status updated / Algorithm completed
         */
        Events?: string[]

        /**
         * filter by type field
         * every step / service / algorithm name attached to the trial
         */
        Type?: string

        /**
         * filter by status field
         * values: Upcoming / In Progress / Complete / Skipped / Missed / Failed / Action Required / On Hold
         */
        Statuses?: string[]

        /**
         * filter by adverse events field values = yes, no, all
         */
        HasAdverseEvents?: string

        /**
         * filter by notes field values = yes, no, all
         */
        HasNotes?: string

        /**
         * sort column e.g. date / category / event/ type / status / adverse_event / notes
         */
        SortColumn?: string

        /**
         * sort order e.g. ASC/DESC
         */
        SortOrder?: string

        RequestedItemsPerPage: number
        RequestedPageNumber: number
    }

    export interface ListTrialParticipantStepResponse {
        steps: TrialParticipantStepResponse[]
    }

    export interface ListTrialStepsRequest {
        RequestedItemsPerPage: number
        RequestedPageNumber: number
    }

    export interface ListTrialStepsResponse {
        steps: GetTrialStepResponse[]
        pagination: shared.Pagination
    }

    export interface ListTrialsRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number
    }

    export interface ListTrialsResponse {
        trials: TrialResponse[]
        pagination: shared.Pagination
    }

    export interface NextStepRequest {
        reason: string
    }

    export interface ParticipantResponse {
        "doc_id": string
        user: ListParticipantUser
        pet: ListParticipantPet
        "participant_status": string
        step: ActiveStep
    }

    export interface ParticipantServiceStep {
        "doc_id": uuid.UUID
        status: string
        name: string
        number: number
        duration: number
        "step_service": string
        "appointment_date": string
        "start_date": string
        "end_date": string
        "auto_progress": boolean
    }

    export interface ParticipantStepHistoryAdverseEvent {
        "linked_at": string
        "linked_by": string
        "adverse_event_doc_id": uuid.UUID
        "adverse_event_summary": string
    }

    export interface ParticipantStepHistoryAdverseEventResponse {
        "adverse_events": ParticipantStepHistoryAdverseEvent[]
    }

    export interface PreviousStepRequest {
        reason: string
    }

    export interface ReorderTrialStepsRequest {
        "source_doc_id": string
        "target_doc_id": string
    }

    export interface StepHisotryNotesAudit {
        notes: string
        "created_by": string
        "created_at": string
    }

    export interface StepHisotryNotesAuditResponse {
        audits: StepHisotryNotesAudit[]
    }

    export interface StepHistory {
        "doc_id": uuid.UUID
        "user_doc_id": uuid.UUID
        "pet_doc_id": uuid.UUID
        "participant_step_doc_id": uuid.UUID
        category: string
        event: string
        type: string
        status: string
        "updated_at": string
        "updated_by": string
        reason: string
        "has_notes": boolean
        notes: string
        "service_doc_id": uuid.UUID
        "algorithm_doc_id": uuid.UUID
        "appointment_date": string
        "has_adverse_events": boolean
    }

    export interface StepHistoryNotesAuditRequest {
        notes: string
    }

    export interface TerminateScreeningCallRequest {
        "user_doc_id": string
        "pet_doc_id": string
        "service_doc_id": string
        "is_call_disconnected": boolean
        "reason_for_termination": string
        source: string
    }

    export interface TrialParticipantStepResponse {
        id: number
        "doc_id": uuid.UUID
        "trial_step_doc_id": uuid.UUID
        "participant_doc_id": uuid.UUID
        "step_status": string
        "step_name": string
        "step_type": string
        "step_number": number
        "step_service_doc_id": uuid.UUID
        "appointment_date": string
        "start_date": string
        "end_date": string
        "updated_at": string
        "step_complete_date": string
        "auto_progress_datetime": string
        "step_auto_progress_datetime_missed": boolean
    }

    export interface TrialResponse {
        id: number
        "doc_id": uuid.UUID
        name: string
        description: string
        status: string
        "adverse_events_form_io_id": string
    }

    export interface UpdateAdverseEventRequest {
        "form_data": JSONValue
        "change_reason": entities.ChangeReasonType
        "change_notes": string
    }

    export interface UpdateAdverseEventSummaryRequest {
        summary: string
    }

    export interface UpdateAutoProgressDateRequest {
        /**
         * The date when a step should go in progress
         */
        "auto_progress_datetime"?: string
    }

    export interface UpdateParticipantMetadataRequest {
        metadata: JSONValue
    }

    export interface UpdateParticipantStatusRequest {
        status: entities.ParticipantStatus
        reason: string
        source: string
    }

    export interface UpdateTrialParticipantStepRequest {
        "step_status": string
        reason: string
        source: string
    }

    export interface UpdateTrialRequest {
        name: string
        description: string
        "adverse_events_form_io_id": string
    }

    export interface UpdateTrialStatusRequest {
        status: string
    }

    export interface UpdateTrialStepRequest {
        "step_name": string
        "step_type": string
        duration: number
        "service_doc_id": string
        "algorithm_doc_id": string
        "auto_progress": boolean
        metadata: JSONValue
        "booking_window"?: number
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * AddAdverseEventToParticipantStepHistory links adverse event to participant step history
         */
        public async AddAdverseEventToParticipantStepHistory(trial_doc_id: string, participant_doc_id: string, step_doc_id: string, step_history_doc_id: string, params: AddAdverseEventToParticipantStepHistoryRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/${encodeURIComponent(step_doc_id)}/steps-history/${encodeURIComponent(step_history_doc_id)}/adverse-events`, JSON.stringify(params))
        }

        /**
         * CompleteScreeningCall updates participant step on consultation complete
         */
        public async CompleteScreeningCall(params: CompleteScreeningCallRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials-consultation/complete-screening-call`, JSON.stringify(params))
        }

        /**
         * CreateParticipant creates a trial participant
         */
        public async CreateParticipant(trial_doc_id: string, params: CreateParticipantRequest): Promise<CreateParticipantResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants`, JSON.stringify(params))
            return await resp.json() as CreateParticipantResponse
        }

        /**
         * CreateParticipantAdverseEvent creates a new adverse event
         */
        public async CreateParticipantAdverseEvent(trial_doc_id: string, participant_doc_id: string, params: CreateAdverseEventRequest): Promise<CreateAdverseEventResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/adverse-events`, JSON.stringify(params))
            return await resp.json() as CreateAdverseEventResponse
        }

        /**
         * CreateTrial creates new trial
         */
        public async CreateTrial(params: CreateTrialRequest): Promise<CreateTrialResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/trials`, JSON.stringify(params))
            return await resp.json() as CreateTrialResponse
        }

        /**
         * CreateTrialStep creates a new trial step
         */
        public async CreateTrialStep(trial_doc_id: string, params: CreateTrialStepRequest): Promise<CreateTrialStepResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/trials/${encodeURIComponent(trial_doc_id)}/steps`, JSON.stringify(params))
            return await resp.json() as CreateTrialStepResponse
        }

        /**
         * Delete Participant
         * This endpoint soft deletes a participant.
         */
        public async DeleteParticipant(trial_doc_id: string, participant_doc_id: string, params: DeleteParticipantRequest): Promise<void> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                reason: params.reason,
            })

            await this.baseClient.callTypedAPI("DELETE", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}`, undefined, {query})
        }

        /**
         * DeleteParticipantStepHisotryAdverseEvent retrieves adverse events for participant step history event
         */
        public async DeleteParticipantStepHisotryAdverseEvent(trial_doc_id: string, participant_doc_id: string, step_doc_id: string, step_history_doc_id: string, adverse_event_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/${encodeURIComponent(step_doc_id)}/steps-history/${encodeURIComponent(step_history_doc_id)}/adverse-events/${encodeURIComponent(adverse_event_doc_id)}`)
        }

        /**
         * DeleteTrialStep deletes a trial step if the trial is in Draft status
         */
        public async DeleteTrialStep(trial_doc_id: string, step_doc_id: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/trials/${encodeURIComponent(trial_doc_id)}/steps/${encodeURIComponent(step_doc_id)}`)
        }

        /**
         * GetParticipant fetches a trial participant by doc_id
         */
        public async GetParticipant(trial_doc_id: string, participant_doc_id: string): Promise<GetParticipantResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}`)
            return await resp.json() as GetParticipantResponse
        }

        /**
         * GetParticipantAdverseEvent gets a specific adverse event
         */
        public async GetParticipantAdverseEvent(trial_doc_id: string, participant_doc_id: string, adverse_event_doc_id: string): Promise<AdverseEventsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/adverse-events/${encodeURIComponent(adverse_event_doc_id)}`)
            return await resp.json() as AdverseEventsResponse
        }

        /**
         * GetParticipantByUserPetAndService fetches  all trial's participant bu given user_doc_id pet_doc_id and service_doc_id
         */
        public async GetParticipantByUserPetAndService(user_doc_id: string, pet_doc_id: string, service_doc_id: string): Promise<GetParticipantByUserPetAndServiceResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials-participants/users/${encodeURIComponent(user_doc_id)}/pets/${encodeURIComponent(pet_doc_id)}/services/${encodeURIComponent(service_doc_id)}`)
            return await resp.json() as GetParticipantByUserPetAndServiceResponse
        }

        /**
         * GetParticipantMetadata fetches a trial participant's metadata
         */
        public async GetParticipantMetadata(trial_doc_id: string, participant_doc_id: string): Promise<GetParticipantMetadataResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/metadata`)
            return await resp.json() as GetParticipantMetadataResponse
        }

        /**
         * GetTrial fetches a trial by doc_id
         */
        public async GetTrial(doc_id: string): Promise<TrialResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(doc_id)}`)
            return await resp.json() as TrialResponse
        }

        /**
         * GetTrialParticipantStep retrieves a trial step for a participant
         */
        public async GetTrialParticipantStep(trial_doc_id: string, participant_doc_id: string, participant_step_doc_id: string): Promise<GetTrialParticipantStepResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/${encodeURIComponent(participant_step_doc_id)}`)
            return await resp.json() as GetTrialParticipantStepResponse
        }

        /**
         * GetTrialStep fetches a trial step by its DocID
         */
        public async GetTrialStep(trial_doc_id: string, step_doc_id: string): Promise<GetTrialStepResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/steps/${encodeURIComponent(step_doc_id)}`)
            return await resp.json() as GetTrialStepResponse
        }

        /**
         * ListParticipantAdverseEventAuditRecords lists all audit records for a specific adverse event
         */
        public async ListParticipantAdverseEventAuditRecords(trial_doc_id: string, participant_doc_id: string, adverse_event_doc_id: string): Promise<ListAdverseEventAuditRecordsResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/adverse-events/${encodeURIComponent(adverse_event_doc_id)}/audits`)
            return await resp.json() as ListAdverseEventAuditRecordsResponse
        }

        /**
         * ListParticipantAdverseEvents lists all adverse events for a participant
         */
        public async ListParticipantAdverseEvents(trial_doc_id: string, participant_doc_id: string, params: ListParticipantAdverseEventsRequest): Promise<ListAdverseEventsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params["items_per_page"]),
                "page_number":    String(params["page_number"]),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/adverse-events`, undefined, {query})
            return await resp.json() as ListAdverseEventsResponse
        }

        /**
         * ListParticipantAdverseEventsSummaries lists all adverse events' summaries for a participant
         */
        public async ListParticipantAdverseEventsSummaries(trial_doc_id: string, participant_doc_id: string): Promise<ListParticipantAdverseEventsSummariesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/adverse-events-summaries`)
            return await resp.json() as ListParticipantAdverseEventsSummariesResponse
        }

        /**
         * ListParticipantStepHisotryAdverseEvents retrieves adverse events for participant step history event
         */
        public async ListParticipantStepHisotryAdverseEvents(trial_doc_id: string, participant_doc_id: string, step_doc_id: string, step_history_doc_id: string): Promise<ParticipantStepHistoryAdverseEventResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/${encodeURIComponent(step_doc_id)}/steps-history/${encodeURIComponent(step_history_doc_id)}/adverse-events`)
            return await resp.json() as ParticipantStepHistoryAdverseEventResponse
        }

        /**
         * ListParticipants fetches  all trial's participants with pagination
         */
        public async ListParticipants(trial_doc_id: string, params: ListParticipantsRequest): Promise<ListParticipantsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "filter_column":        params.SortColumn,
                "items_per_page":       String(params.RequestedItemsPerPage),
                "page_number":          String(params.RequestedPageNumber),
                "participant_statuses": params.ParticipantStatuses?.map((v) => v),
                "sort_order":           params.SortOrder,
                "step_names":           params.StepNames?.map((v) => v),
                "step_statuses":        params.StepStatuses?.map((v) => v),
                "user_name":            params.UserName,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants`, undefined, {query})
            return await resp.json() as ListParticipantsResponse
        }

        /**
         * ListTrialParticipantStepHisotryNotesAuidit retrieves notes audit for a participant step history log
         */
        public async ListTrialParticipantStepHisotryNotesAuidit(trial_doc_id: string, participant_doc_id: string, step_doc_id: string, step_history_doc_id: string): Promise<StepHisotryNotesAuditResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/${encodeURIComponent(step_doc_id)}/steps-history/${encodeURIComponent(step_history_doc_id)}/notes-audit`)
            return await resp.json() as StepHisotryNotesAuditResponse
        }

        /**
         * ListTrialParticipantStep retrieves all trial steps for a participant
         */
        public async ListTrialParticipantSteps(trial_doc_id: string, participant_doc_id: string): Promise<ListTrialParticipantStepResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps`)
            return await resp.json() as ListTrialParticipantStepResponse
        }

        /**
         * ListTrialParticipantStepsHistory retrieves history for all trial steps for a participant
         */
        public async ListTrialParticipantStepsHistory(trial_doc_id: string, participant_doc_id: string, params: ListTrialParticipantStepHistoryRequest): Promise<ListTrialParticipantStepHistory> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                categories:           params.Categories?.map((v) => v),
                events:               params.Events?.map((v) => v),
                "filter_column":      params.SortColumn,
                "has_adverse_events": params.HasAdverseEvents,
                "has_notes":          params.HasNotes,
                "items_per_page":     String(params.RequestedItemsPerPage),
                "page_number":        String(params.RequestedPageNumber),
                "sort_order":         params.SortOrder,
                statuses:             params.Statuses?.map((v) => v),
                type:                 params.Type,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps-history`, undefined, {query})
            return await resp.json() as ListTrialParticipantStepHistory
        }

        /**
         * Lists trial steps for a given trial DocID with pagination.
         */
        public async ListTrialSteps(trial_doc_id: string, params: ListTrialStepsRequest): Promise<ListTrialStepsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials/${encodeURIComponent(trial_doc_id)}/steps`, undefined, {query})
            return await resp.json() as ListTrialStepsResponse
        }

        /**
         * ListTrials fetches  all trials with pagination
         */
        public async ListTrials(params: ListTrialsRequest): Promise<ListTrialsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/trials`, undefined, {query})
            return await resp.json() as ListTrialsResponse
        }

        /**
         * NextStep completes current active steps and moves the participant to the next one
         */
        public async NextStep(trial_doc_id: string, participant_doc_id: string, params: NextStepRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/next-step`, JSON.stringify(params))
        }

        /**
         * PreviousStep moves participant back to a previous step
         */
        public async PreviousStep(trial_doc_id: string, participant_doc_id: string, step_doc_id: string, params: PreviousStepRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/previous-step/${encodeURIComponent(step_doc_id)}`, JSON.stringify(params))
        }

        /**
         * Run CRON job from portal
         * 
         * Automatically processes all steps that have auto progress date time set. Marks Participants' steps with In Progress status.
         */
        public async ProcessAutoProgressSteps(): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/cron/process-trial-auto-progress-steps`)
        }

        /**
         * Run CRON job from portal
         * 
         * Automatically processes all steps that have been Missed. Marks Participant with Action Required status.
         */
        public async ProcessMissedSteps(): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/cron/process-trial-missed-steps`)
        }

        /**
         * Reorder trials steps
         * Updates the order of trial's steps. Only allowed if the trial is in Draft status
         */
        public async ReorderTrialSteps(trial_doc_id: string, params: ReorderTrialStepsRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials/${encodeURIComponent(trial_doc_id)}/steps/steps-order`, JSON.stringify(params))
        }

        /**
         * TerminateScreeningCall updates participant step on consultation terminate
         */
        public async TerminateScreeningCall(params: TerminateScreeningCallRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials-consultation/terminate-screening-call`, JSON.stringify(params))
        }

        /**
         * UpdateParticipantAdverseEvent updates an existing adverse event
         */
        public async UpdateParticipantAdverseEvent(trial_doc_id: string, participant_doc_id: string, adverse_event_doc_id: string, params: UpdateAdverseEventRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/adverse-events/${encodeURIComponent(adverse_event_doc_id)}`, JSON.stringify(params))
        }

        /**
         * UpdateParticipantAdverseEventSummary updates an existing adverse event's summary
         */
        public async UpdateParticipantAdverseEventSummary(trial_doc_id: string, participant_doc_id: string, adverse_event_doc_id: string, params: UpdateAdverseEventSummaryRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/adverse-events/${encodeURIComponent(adverse_event_doc_id)}/summary`, JSON.stringify(params))
        }

        /**
         * UpdateParticipantMetadata updates a participant's metadata
         */
        public async UpdateParticipantMetadata(trial_doc_id: string, participant_doc_id: string, params: UpdateParticipantMetadataRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/metadata`, JSON.stringify(params))
        }

        /**
         * UpdateParticipantStatus updates a participant's trial status
         */
        public async UpdateParticipantStatus(trial_doc_id: string, participant_doc_id: string, params: UpdateParticipantStatusRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participants/${encodeURIComponent(participant_doc_id)}/status`, JSON.stringify(params))
        }

        /**
         * UpdateParticipantStepHistoryNotes adds notes to a participant step history log
         */
        public async UpdateParticipantStepHistoryNotes(trial_doc_id: string, participant_doc_id: string, step_doc_id: string, step_history_doc_id: string, params: StepHistoryNotesAuditRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/${encodeURIComponent(step_doc_id)}/steps-history/${encodeURIComponent(step_history_doc_id)}/notes`, JSON.stringify(params))
        }

        /**
         * UpdateTrial updates trial data
         */
        public async UpdateTrial(doc_id: string, params: UpdateTrialRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/trials/${encodeURIComponent(doc_id)}`, JSON.stringify(params))
        }

        /**
         * UpdateTrialParticipantStepAutoProgressDate updates the auto progress date for a step
         */
        public async UpdateTrialParticipantStepAutoProgressDate(trial_doc_id: string, participant_doc_id: string, participant_step_doc_id: string, params: UpdateAutoProgressDateRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/${encodeURIComponent(participant_step_doc_id)}/auto-progress-date`, JSON.stringify(params))
        }

        /**
         * UpdateTrialParticipantStepStatus updates the status of a trial step for a participant
         */
        public async UpdateTrialParticipantStepStatus(trial_doc_id: string, participant_doc_id: string, participant_step_doc_id: string, params: UpdateTrialParticipantStepRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials/${encodeURIComponent(trial_doc_id)}/participant/${encodeURIComponent(participant_doc_id)}/steps/${encodeURIComponent(participant_step_doc_id)}`, JSON.stringify(params))
        }

        /**
         * UpdateTrialStatus updates participant trial status
         */
        public async UpdateTrialStatus(doc_id: string, params: UpdateTrialStatusRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PATCH", `/v1/trials/${encodeURIComponent(doc_id)}/status`, JSON.stringify(params))
        }

        /**
         * UpdateTrialStep updates an existing trial step
         */
        public async UpdateTrialStep(trial_doc_id: string, step_doc_id: string, params: UpdateTrialStepRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/trials/${encodeURIComponent(trial_doc_id)}/steps/${encodeURIComponent(step_doc_id)}`, JSON.stringify(params))
        }
    }
}

export namespace usersubmission {
    export interface AnswerOption {
        /**
         * DisplayValue
         * String representation of the answer option.
         */
        "display_value": string

        /**
         * WasSelected
         * Indicator for selection.
         */
        "was_selected": boolean
    }

    export interface CreateUserSubmissionRequest {
        /**
         * EventID
         * A valid Typeform EventID. Unique ID for the webhook. Automatically assigned by Typeform.
         */
        "event_id": string

        /**
         * EventType
         * A valid Typeform EventType. Reason the webhook is being sent.
         */
        "event_type": string

        /**
         * FormResponse
         * A valid Typeform FormResponse data in json format. Object that contains information about the typeform and account associated with the webhook.
         */
        "form_response": JSONValue
    }

    export interface GetUserSubmissionResponse {
        /**
         * PetDocID
         * The **doc_id** of the pet for which the typeform was submitted.
         */
        "pet_doc_id": uuid.UUID

        /**
         * TrialDocID
         * The **doc_id** of the trial for which the typeform was submitted.
         */
        "trial_doc_id": uuid.UUID

        /**
         * StepDocID
         * The **doc_id** of the step of the trial for which the typeform was submitted.
         */
        "step_doc_id": uuid.UUID

        /**
         * EventID
         * A valid Typeform EventID. Unique ID for the webhook. Automatically assigned by Typeform.
         */
        "event_id": string

        /**
         * EventType
         * A valid Typeform EventType. Reason the webhook is being sent.
         */
        "event_type": string

        /**
         * FormTitle
         * Title of the typeform.
         */
        "form_title": string

        /**
         * SubmittedAt
         * Date and time the typeform responses were submitted. In ISO 8601 format, Coordinated Universal Time (UTC), to the second.
         * (e.g. **2017-09-04T04:00:00Z**)
         */
        "submitted_at": string

        /**
         * LandedAt
         * Date and time the typeform responses were submitted. In ISO 8601 format, Coordinated Universal Time (UTC), to the second.
         * (e.g. **2017-09-04T04:00:00Z**)
         */
        "landed_at": string

        /**
         * FormResponses
         * List of all questions with selected answers.
         */
        "form_responses": UserSubmissionFormData[]
    }

    export interface ListUserSubmissionsRequest {
        /**
         * EventID
         * A valid Typeform EventID. Unique ID for the webhook. Automatically assigned by Typeform.
         * If present the response will contain only one form data for the selected EventID.
         */
        EventID: string

        /**
         * PetDocID
         * An existing pet **doc_id**.
         * If present the response will contain all form data for the selected PetDocID.
         */
        PetDocID: string

        /**
         * ExcludeEventIDs
         * List of unique typeform event_ids to be excluded from the final list of pet's form submissions.
         * If present the response will contain all form data for the selected PetDocID excluding the forms with event_ids from the ExcludeEventIDs list.
         */
        ExcludeEventIDs: string[]

        /**
         * RequestedItemsPerPage
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * RequestedPageNumber
         * the page number requested
         */
        RequestedPageNumber: number
    }

    export interface ListUserSubmissionsResponse {
        "pet_forms": GetUserSubmissionResponse[]
        pagination: shared.Pagination
    }

    export interface UserSubmissionFormData {
        /**
         * Title
         * Typeform question text.
         */
        title: string

        /**
         * AnswerType
         * Type of the display data e.g. **text**, **img** or **video**.
         */
        "answer_type": string

        /**
         * Response
         * When the answer is a single value it would be populated here.
         */
        response: string

        /**
         * AnswerOptions
         * When the answer is a selection from multiple possible values all possible values will be populated here
         *  along with information if they were selected or not.
         */
        "answer_options": AnswerOption[]
    }

    export interface UserSubmissionResponse {
        "doc_id": uuid.UUID
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Create User Submission
         * This endpoint expects webhook data coming through Typeform.
         */
        public async CreateUserSubmission(params: CreateUserSubmissionRequest): Promise<UserSubmissionResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/usersubmissions`, JSON.stringify(params))
            return await resp.json() as UserSubmissionResponse
        }

        /**
         * List User Submissions
         * This endpoint returns user submission data in a format suitable for PMS visualisation.
         * The list of data is sorted desc by typeform submitted_at date.
         */
        public async ListUserSubmission(params: ListUserSubmissionsRequest): Promise<ListUserSubmissionsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "event_id":         params.EventID,
                "exclude_event_id": params.ExcludeEventIDs.map((v) => v),
                "items_per_page":   String(params.RequestedItemsPerPage),
                "page_number":      String(params.RequestedPageNumber),
                "pet_doc_id":       params.PetDocID,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/usersubmissions`, undefined, {query})
            return await resp.json() as ListUserSubmissionsResponse
        }
    }
}

export namespace venomcodeinternal {
    export interface AllVenomCodesResponse {
        categories: CategoryResponse[]
    }

    export interface CategoryResponse {
        "doc_id": uuid.UUID
        name: string
        groups: VenomGroupWithCodesResponse[]
    }

    export interface CreateVenomCategoryRequest {
        name: string
    }

    export interface CreateVenomCategoryResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateVenomCodeRequest {
        "venom_id": string
        "term_name": string
        "group_doc_id": string
        synonym: string
        "customer_synonym": string
    }

    export interface CreateVenomCodeResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateVenomGroupRequest {
        name: string
        "category_doc_id": string
    }

    export interface CreateVenomGroupResponse {
        "doc_id": uuid.UUID
    }

    export interface GetVenomCategoryResponse {
        "doc_id": uuid.UUID
        name: string
    }

    export interface GetVenomCodeResponse {
        "doc_id": uuid.UUID
        "venom_id": string
        "term_name": string
        synonym: string
        "customer_synonym": string
        groups: VenomGroupResponse[]
    }

    export interface GetVenomGroupResponse {
        "doc_id": uuid.UUID
        name: string
        "category_doc_id": uuid.UUID
    }

    export interface ListVenomCategoriesRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the name which is searched for
         */
        Name: string
    }

    export interface ListVenomCategoriesResponse {
        pagination: shared.Pagination
        "venom_categories": VenomCategoryResponse[]
    }

    export interface ListVenomCodesRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the term name which is searched for
         */
        TermName: string
    }

    export interface ListVenomCodesResponse {
        pagination: shared.Pagination
        "venom_codes": VenomCodeResponse[]
    }

    export interface ListVenomGroupsRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * the name which is searched for
         */
        Name: string
    }

    export interface ListVenomGroupsResponse {
        pagination: shared.Pagination
        "venom_groups": VenomGroupResponse[]
    }

    export interface UpdateVenomCodeRequest {
        synonym: string
        "customer_synonym": string
        "group_doc_id": string[]
        deleted: boolean
    }

    export interface UpdateVenomGroupRequest {
        "category_doc_id": string
        deleted: boolean
    }

    export interface UploadVenomCodesRequest {
        /**
         * the category that this csv file belongs to
         */
        category: string

        /**
         * csv file in base64 format that contains venom codes
         */
        "venom_codes_csv": string
    }

    export interface VenomCategoryResponse {
        "doc_id": uuid.UUID
        name: string
    }

    export interface VenomCodeResponse {
        "doc_id": uuid.UUID
        "venom_id": string
        "term_name": string
        synonym: string
        "customer_synonym": string
    }

    export interface VenomGroupResponse {
        "doc_id": uuid.UUID
        name: string
    }

    export interface VenomGroupWithCodesResponse {
        "doc_id": uuid.UUID
        name: string
        "venom_codes": VenomCodeResponse[]
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Create Venom Category
         */
        public async CreateVenomCategory(params: CreateVenomCategoryRequest): Promise<CreateVenomCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/venom-categories`, JSON.stringify(params))
            return await resp.json() as CreateVenomCategoryResponse
        }

        /**
         * Create Venom Code
         */
        public async CreateVenomCode(params: CreateVenomCodeRequest): Promise<CreateVenomCodeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/venom-codes`, JSON.stringify(params))
            return await resp.json() as CreateVenomCodeResponse
        }

        /**
         * Create Venom Group
         */
        public async CreateVenomGroup(params: CreateVenomGroupRequest): Promise<CreateVenomGroupResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("POST", `/v1/venom-groups`, JSON.stringify(params))
            return await resp.json() as CreateVenomGroupResponse
        }

        /**
         * Delete Venom Code
         */
        public async DeleteVenomCode(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/venom-codes/${encodeURIComponent(docID)}`)
        }

        /**
         * Delete Venom Group
         */
        public async DeleteVenomGroup(docID: string): Promise<void> {
            await this.baseClient.callTypedAPI("DELETE", `/v1/venom-groups/${encodeURIComponent(docID)}`)
        }

        /**
         * Get Venom Category
         */
        public async GetVenomCategory(docID: string): Promise<GetVenomCategoryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/venom-categories/${encodeURIComponent(docID)}`)
            return await resp.json() as GetVenomCategoryResponse
        }

        /**
         * Get Venom Code
         */
        public async GetVenomCode(docID: string): Promise<GetVenomCodeResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/venom-codes/codes/${encodeURIComponent(docID)}`)
            return await resp.json() as GetVenomCodeResponse
        }

        /**
         * Get Venom Group
         * Get a Venom Group by its doc ID
         */
        public async GetVenomGroup(docID: string): Promise<GetVenomGroupResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/venom-groups/${encodeURIComponent(docID)}`)
            return await resp.json() as GetVenomGroupResponse
        }

        /**
         * List Venom Codes
         */
        public async ListVenomCategories(params: ListVenomCategoriesRequest): Promise<ListVenomCategoriesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
                search:           params.Name,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/venom-categories`, undefined, {query})
            return await resp.json() as ListVenomCategoriesResponse
        }

        /**
         * List All Venom Codes
         */
        public async ListVenomCategoriesWithCodes(): Promise<AllVenomCodesResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/venom-codes/all`)
            return await resp.json() as AllVenomCodesResponse
        }

        /**
         * List Venom Codes
         */
        public async ListVenomCodes(params: ListVenomCodesRequest): Promise<ListVenomCodesResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
                search:           params.TermName,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/venom-codes`, undefined, {query})
            return await resp.json() as ListVenomCodesResponse
        }

        /**
         * List Venom Codes
         */
        public async ListVenomGroups(params: ListVenomGroupsRequest): Promise<ListVenomGroupsResponse> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "items_per_page": String(params.RequestedItemsPerPage),
                "page_number":    String(params.RequestedPageNumber),
                search:           params.Name,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/venom-groups`, undefined, {query})
            return await resp.json() as ListVenomGroupsResponse
        }

        /**
         * Update Venom Code
         */
        public async UpdateVenomCode(docID: string, params: UpdateVenomCodeRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/venom-codes/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Update Venom Group
         */
        public async UpdateVenomGroup(docID: string, params: UpdateVenomGroupRequest): Promise<void> {
            await this.baseClient.callTypedAPI("PUT", `/v1/venom-groups/${encodeURIComponent(docID)}`, JSON.stringify(params))
        }

        /**
         * Upload Venom Codes
         * Upload venom codes through csv file
         */
        public async UploadVenomCodes(params: UploadVenomCodesRequest): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/venom-codes/csv-upload`, JSON.stringify(params))
        }
    }
}

export namespace vetaichatexternal {
    export interface SkipChatResponse {
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * Message the AI Vet
         * 
         * Will proxy anything in the "data" struct straight onto the server
         */
        public async Chat(method: "POST", petDocID: string, extra: string[], body?: BodyInit, options?: CallParameters): Promise<globalThis.Response> {
            return this.baseClient.callAPI(method, `/chat/${encodeURIComponent(petDocID)}/${extra.map(encodeURIComponent).join("/")}`, body, options)
        }

        /**
         * Skips the Given LLM Chat.
         * If it's an llm-triage-chat, it will set the status to pending
         * If it's an llm-appointment-chat, it will set the status to completed
         * in both instances it'll summarise the notes for a vet.
         */
        public async SkipChat(userDocID: string, chatDocID: string): Promise<SkipChatResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PATCH", `/v1/users/${encodeURIComponent(userDocID)}/chat/${encodeURIComponent(chatDocID)}/skip`)
            return await resp.json() as SkipChatResponse
        }
    }
}

export namespace vetaichatinternal {
    export interface GetChatSummaryResponse {
        "chat_doc_id": string
        "channel_id": string
        "channel_type": string
        summary: string
    }

    export interface RateChatSummaryRequest {
        rating: string
    }

    export interface RateChatSummaryResponse {
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * GaitModeResponseReceiver receives the response from the gait model
         * and updates the session with the score
         */
        public async GaitModeResponseReceiver(params: entities.GaitModelResponse): Promise<void> {
            await this.baseClient.callTypedAPI("POST", `/v1/ai-gait-response`, JSON.stringify(params))
        }

        /**
         * Get Chat Summary by Chat Doc ID.
         * Returns the summary for a given chat.
         */
        public async GetChatSummary(chatDocID: string): Promise<GetChatSummaryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("GET", `/v1/chats/${encodeURIComponent(chatDocID)}/summary`)
            return await resp.json() as GetChatSummaryResponse
        }

        /**
         * PingLLMChat is a dummy method to try authentication with LLM
         */
        public async PingLLMChat(): Promise<void> {
            await this.baseClient.callTypedAPI("GET", `/v1/ping-llm-chat`)
        }

        /**
         * Rate a chat summary
         * Intended for use by clinicians to provide feedback on an AI chat summary
         * and rate the quality of the summary.
         * send a rating of 'good', or 'bad' to rate the summary.
         */
        public async RateChatSummary(chatDocID: string, params: RateChatSummaryRequest): Promise<RateChatSummaryResponse> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callTypedAPI("PATCH", `/v1/chats/${encodeURIComponent(chatDocID)}/summary/rating`, JSON.stringify(params))
            return await resp.json() as RateChatSummaryResponse
        }
    }
}

export namespace entities {
    export interface Age {
        years: number
        months: number
    }

    /**
     * An AndCondition is composed of a list of OR conditions where at least one condition must evaluate to true for the
     * AND condition to be true
     */
    export interface AndCondition {
        /**
         * The ID of the rule group
         */
        id: number

        /**
         * The OR conditions to refine this and condition
         */
        "or_conditions": OrCondition[]
    }

    export interface AppointmentEvent {
        user: UserSummary
        "appointment_status": utilities.AppointmentStatus
        "consult_status": utilities.ConsultStatus
        "platform_os": string
        "platform_os_version": string
        "client_version": string
        trace: string
        timestamp: string
    }

    export interface Business {
        "business_doc_id": uuid.UUID
        name: string
    }

    export interface BusinessResponse {
        id: string
        name: string
        "external_id"?: string
        "partner_integration": string
    }

    export interface BusinessServiceMappingResponse {
        /**
         * the id of the business
         */
        id: string

        name: string
    }

    export interface Catalogue {
        id: number
        "doc_id": uuid.UUID
        name: string
        description: string
    }

    export interface CatalogueBusinessMappingRequest {
        RequestedItemsPerPage: number
        RequestedPageNumber: number
        CatalogueId: string
    }

    export interface CatalogueProduct {
        id: number
        "doc_id": uuid.UUID
        sku: string
        "catalogue_name": string
        url: string
        "catalogue_id": number
        currency: string
        price: number
        description: string
        "photo_url": string
        "created_at": string
        "updated_at": string
        deleted: boolean
        "deleted_at": string
        "classification_doc_id": uuid.UUID
        "legal_category": string
        "classification_description": string
        "external_product_variant_id"?: string
        "external_inventory_item_id"?: string
        "out_of_stock": boolean
        "external_inventory_policy": string
    }

    export interface CatalogueProductWithCatalogueDocID {
        id: number
        "doc_id": uuid.UUID
        sku: string
        "catalogue_name": string
        url: string
        "catalogue_id": number
        currency: string
        price: number
        description: string
        "photo_url": string
        "created_at": string
        "updated_at": string
        "catalogue_doc_id": uuid.UUID
        "catalogue_title": string
        "classification_doc_id": uuid.UUID
        "legal_category": string
        "classification_description": string
    }

    export interface CatalogueProducts {
        id: number
        "doc_id": uuid.UUID
        sku: string
        name: string
        url: string
        "catalogue_id": number
        currency: string
        price: number
        description: string
        "photo_url": string
        "created_at": string
        "updated_at": string
        "classification_id": number
        "classification_doc_id": uuid.UUID
        "legal_category": string
        "classification_description": string
        "out_of_stock": boolean
    }

    export interface CategoriesWithSubcategoriesResponse {
        categories: CategoryWithSubcategoriesResponse[]
    }

    /**
     * Category
     */
    export interface Category {
        ID: number
        DocID: uuid.UUID
        Name: string
    }

    export interface CategoryWithSubcategoriesResponse {
        "category_id": uuid.UUID
        name: string
        subcategories: SubcategoryNameResponse[]
    }

    export type ChangeReasonType = string

    export interface ChatResponse {
        /**
         * this is the unique identifier for a chat room
         */
        "doc_id": string

        /**
         * channel ID is the identifier for the chat room the users are in
         */
        "channel_id": string

        /**
         * status is the current status of this chat
         */
        status: string

        /**
         * status history of the chat in order from first to current over its lifetime
         */
        "status_history": string[]

        /**
         * Channel Type is the type of chat room the users are in (messaging, video)
         */
        "channel_type": string

        /**
         * the last time the chat room was updated
         */
        "updated_at": utilities.Dates

        /**
         * the time the chat room was created
         */
        "created_at": utilities.Dates

        /**
         * the participants in the chat
         */
        participants: GetParticipantResponse[]

        /**
         * the pet that is associated with this chat
         */
        pet: accountinternal.PetResponse

        /**
         * the user that is associated with this chat
         */
        user: accountinternal.UserResponse

        /**
         * the business that is associated with this chat
         */
        business: BusinessResponse

        /**
         * the consult status of the chat
         */
        "consult_status": utilities.ConsultStatus

        /**
         * the reason the chat was disrupted
         */
        "disrupted_reason": string

        /**
         * the actual start time of the chat
         */
        "actual_start_time": utilities.Dates

        /**
         * the actual end time of the chat
         */
        "actual_end_time": utilities.Dates

        /**
         * the name of the chat service
         */
        "service_name": string

        /**
         * the unique identifier for the chat service
         */
        "service_doc_id": string

        /**
         * the timezone of the chat
         */
        "time_zone": string

        /**
         * the video room associated with the chat
         */
        "chat_video_room": ChatVideoRoom
    }

    export interface ChatVideoRoom {
        id: number
        "doc_id": string
        "session_room_id": string
        "current_call_details": CurrentCallDetails
    }

    export type ChatVideoRoomStatuses = string

    export interface CreateBusinessRequest {
        name: string
        "external_id": string
        "partner_integration": string
    }

    export interface CreateBusinessResponse {
        id: uuid.UUID
    }

    export interface CreateCatalogueBusinessRequest {
        "business_doc_id": uuid.UUID
    }

    export interface CreateCatalogueBusinessResponse {
        "business_doc_id": uuid.UUID
    }

    export interface CreateCatalogueProductMappingResponse {
        "product_id": number
        "catalogue_product_id": number
    }

    export interface CreateCatalogueProductResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateCatalogueRequest {
        "catalogue_name": string
        "catalogue_description": string
    }

    export interface CreateCatalogueResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateCategoryRequest {
        Name: string
    }

    export interface CreateCategoryResponse {
        "doc_id": uuid.UUID
    }

    export interface CreateChatConsultationRequest {
        "chat_doc_id": string
        "pet_doc_id": string
        "owner_doc_id": string
    }

    export interface CreatePetResponse {
        /**
         * DocID
         * The **doc_id** of the new pet in **UUID** format.
         */
        "doc_id": string

        /**
         * CreatedfAt
         * The timestamp when the pet was created.
         */
        "created_at": string
    }

    export interface CreateProductVenomCategoryRequest {
        "venom_category_id": uuid.UUID
    }

    export interface CreateProductVenomCategoryResponse {
        "venom_category_id": uuid.UUID
    }

    export interface CreateProductVenomCodeRequest {
        "venom_code_doc_id": uuid.UUID
    }

    export interface CreateProductVenomCodeResponse {
        "venom_code_doc_id": uuid.UUID
        "product_id": uuid.UUID
    }

    export interface CreateStaffRequest {
    }

    export interface CreateSubcategoryRequest {
        name: string
        "category_id": uuid.UUID
    }

    export interface CreateSubcategoryResponse {
        "subcategory_doc_id": uuid.UUID
    }

    export interface CreateWeightResponse {
        /**
         * DocID
         * The **doc_id** of the weight in **UUID** format.
         */
        "doc_id": string
    }

    export interface CurrentCallDetails {
        "doc_id": string
        "message_id": string
        "staff_name": string
        "staff_picture": string
        status: ChatVideoRoomStatuses
        "created_at": string
        duration: number
    }

    export interface DeleteBusinessRequest {
        DocID: string
    }

    export interface GaitModelResponse {
        status: string
        "session_doc_id": string
        "video_section_results": GaitModelVideoSectionResults[]
        "full_url": string
    }

    export interface GaitModelVideoSectionResults {
        result: number
        contextresult: number
        start: number
        end: number
        usefresult: number
    }

    export interface GetBreedsResponse {
        breeds: GetBreedsResponseItem[]
    }

    export interface GetBreedsResponseItem {
        /**
         * DocId
         * The **doc_id** of the breed in **UUID** format.
         */
        "doc_id": string

        /**
         * Name
         * The name of the breed.
         */
        name: string
    }

    export interface GetBusinessRequest {
        /**
         * the id of the business
         */
        DocID: string
    }

    export interface GetCatalogueRequest {
        DocID: string
    }

    export interface GetCatalogueResponse {
        id: number
        "doc_id": uuid.UUID
        name: string
        description: string
    }

    export interface GetParticipantResponse {
        "session_user_id": string
        "user_doc_id": string
        "pet_doc_id": string
        "staff_profile_doc_id": string
        "business_doc_id": string
        "staff_profile_name": string
        "staff_profile_photo_url": string
    }

    export interface GetPendingChatsRequest {
        RequestedItemsPerPage: number
        RequestedPageNumber: number
    }

    export interface GetPendingChatsResponse {
        pagination: shared.Pagination
        chats: ChatResponse[]
    }

    export interface HandoverReason {
        id: number
        reason: string
    }

    export interface HydratedMissedCall {
        "appointment_id": number
        user: UserSummary
        staff: UserSummary
        "client_operating_system": string
        "client_os_version": string
        "build_number": string
        "appointment_status": string
        "consult_status": string
    }

    export type InformationType = string

    export type LanguageISO639 = string

    /**
     * published, draft or archived. Shared across entities for now
     */
    export type LifecycleStatus = string

    export interface ListBusinessServiceMappingResponse {
        businesses: BusinessServiceMappingResponse[]
    }

    /**
     * once encore supports embedded query params, we could just embed the PaginatedRequest struct
     */
    export interface ListBusinessesRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * partial name of the business
         */
        Name: string
    }

    export interface ListBusinessesResponse {
        pagination: shared.Pagination
        businesses: BusinessResponse[]
    }

    export interface ListCatalogueBusinessMappingResponse {
        pagination: shared.Pagination
        Businesses: Business[]
    }

    export interface ListCatalogueProductsMappedToProductResponse {
        products: CatalogueProductWithCatalogueDocID[]
    }

    export interface ListCataloguesRequest {
        RequestedItemsPerPage: number
        RequestedPageNumber: number
        Name: string
    }

    export interface ListCataloguesResponse {
        pagination: shared.Pagination
        catalogues: Catalogue[]
    }

    export interface ListCategoriesRequest {
        "items_per_page": number
        "page_number": number
        name: string
    }

    export interface ListCategoriesResponse {
        pagination: shared.Pagination
        categories: Category[]
    }

    export interface ListClinicalHistoryCategoryResponse {
        "doc_id": uuid.UUID
        name: string
        "form_name": string
    }

    export interface ListClinicalHistoryGroupWithCategories {
        "group_doc_id": uuid.UUID
        "group_name": string
        "clinical_history_category": ListClinicalHistoryCategoryResponse[]
    }

    export interface ListMappedSubCategoriesForCategoryResponse {
        subcategories: SubcategoryResponse[]
    }

    export interface ListPetsResponse {
        pets: PetResponse[]
    }

    export interface ListProductCatalogueRequest {
        Name: string
        RequestedItemsPerPage: number
        RequestedPageNumber: number
        CatalogueID: string
    }

    export interface ListProductCatalogueResponse {
        pagination: shared.Pagination
        products: CatalogueProducts[]
    }

    export interface ListProductCataloguesRequest {
        RequestedItemsPerPage: number
        RequestedPageNumber: number
        ProductID: string
    }

    export interface ListProductCataloguesResponse {
        pagination: shared.Pagination
        catalogues: Catalogue[]
    }

    export interface ListProductVenomCategoriesResponse {
        categories: ListedVenom[]
    }

    export interface ListProductVenomCodesResponse {
        codes: ListedVenom[]
    }

    export interface ListProductVenomGroupsResponse {
        groups: ListedVenom[]
    }

    export interface ListProductsBySubcategory {
        pagination: shared.Pagination
        products: Product[]
    }

    export interface ListProductsForCatalogue {
        products: CatalogueProduct[]
    }

    export interface ListProductsRequest {
        RequestedItemsPerPage: number
        RequestedPageNumber: number
        Name: string
    }

    export interface ListProductsResponse {
        pagination: shared.Pagination
        products: Product[]
    }

    export interface ListReasonGroupWithTypes {
        "group_doc_id": uuid.UUID
        "group_name": string
        "reason_types": ListReasonTypeResponse[]
    }

    export interface ListReasonTypeResponse {
        "doc_id": uuid.UUID
        name: string
    }

    export interface ListReasonTypesResponse {
        "doc_id": string
        name: string
        "group_doc_id": uuid.UUID
    }

    export interface ListServicePricesBusinessMappingResponse {
        businesses: ServicePricesBusinessMappingResponse[]
    }

    export interface ListStaffProfilesRequest {
        /**
         * the number of results requested for the page
         */
        RequestedItemsPerPage: number

        /**
         * the page number requested
         */
        RequestedPageNumber: number

        /**
         * partial name of the business
         */
        Name: string
    }

    export interface ListSubcategoriesRequest {
        "items_per_page": number
        "page_number": number
        name: string
    }

    export interface ListSubcategoriesResponse {
        pagination: shared.Pagination
        subcategories: SubcategoryResponse[]
    }

    export interface ListWebhookEventsResponse {
        events: WebhookEventResponse[]
    }

    export interface ListWebhookResponse {
        webhooks: WebhookResponse[]
    }

    export interface ListWeightsResponse {
        weights: WeightResponse[]
    }

    export interface ListedVenom {
        id: uuid.UUID
        name: string
    }

    export interface MissedCallDashboard {
        appointments: PlatformStatusBin[]
        consultations: PlatformStatusBin[]
        "missed_calls": HydratedMissedCall[]
    }

    /**
     * An OrCondition represents a single truthy logical condition that must return true or false
     * OrCondition can only exist in an AndCondition
     */
    export interface OrCondition {
        /**
         * The ID of the rule condition
         */
        id: number

        /**
         * The id of the parent rule group
         */
        "rule_group_id": number

        /**
         * The Property to test the value for
         * required: true
         */
        property: RuleConditionProperty

        /**
         * The optional PropertyReference allows property values to be sourced from a keyed data source like a map
         */
        "property_ref": string

        /**
         * The logical Operation to apply to test the Property value
         * required: true
         */
        operation: RuleConditionOperation

        /**
         * The Value that will make this condition true.
         * 
         * example: Breed Is 'Labrador'
         * required: true
         */
        value: string
    }

    export type ParticipantStatus = string

    export interface PartnerPractice {
        name: string
        address: string
        "google_location_id": string
        "doc_id": uuid.UUID
        id: number
        long: string
        lat: string
    }

    export interface PetResponse {
        /**
         * DocID
         * The **doc_id** of the pet in **UUID** format.
         */
        "doc_id": string

        /**
         * Name
         * The name of the pet.
         */
        name: string

        /**
         * Gender
         * The gender of the pet.
         * 
         * Accepted values are:  **Male**, **Female**, **Unknown**.
         */
        gender: string

        /**
         * Weight
         * The weight of the pet.
         */
        weight?: WeightUnitsResponse

        /**
         * Neutered
         * Indicates if the pet has been neutered.
         */
        neutered?: boolean

        /**
         * DateOfBirth
         * The pet date of birth.
         * 
         * Accepted date format: **YYYY-MM-DD**.
         */
        "date_of_birth": string

        /**
         * Breed
         */
        breed?: GetBreedsResponseItem

        /**
         * CreatedAt
         * **created_at** in UTC time format (e.g. **2017-09-04T04:00:00Z**)
         */
        "created_at": string

        /**
         * Species
         * The species associated with the pet.
         */
        species: string

        /**
         * SpeciesDocID
         * The species **doc_id** associated with the pet in **UUID** format.
         */
        "species_id": uuid.UUID

        /**
         * PhotoUrl
         * Valid url for retrieving pet photo. The url is only valid for one hour.
         */
        "photo_url"?: string

        /**
         * PolicyCustomerNo
         * The policy number of the pet's owner.
         */
        "policy_customer_no"?: string

        /**
         * PolicyNo
         * The policy number of the pet.
         */
        "policy_no"?: string

        /**
         * PolicyActive
         * Indicates if the policy of the pet is active.
         */
        "policy_active": boolean

        /**
         * PolicyRegisteredDate
         * The time when the pet's policy was registered in UTC time format (e.g. **2017-09-04T04:00:00.000000Z**)
         */
        "policy_registered_date"?: string

        /**
         * PolicyDisabledDate
         * The time when the pet's policy was disabled in UTC time format (e.g. **2017-09-04T04:00:00.000000Z**)
         * It has values only when **policy_active** is false.
         */
        "policy_disabled_date"?: string

        /**
         * RequiresVerification
         * Indicates if pet's policy data has been verified by the owner
         * after it has been retrieved from an insurer.
         */
        "requires_verification": boolean

        /**
         * PolicyStartDate
         * The time when the pet's policy was started in UTC time format (e.g. **2017-09-04T04:00:00.000000Z**)
         */
        "policy_start_date"?: string
    }

    export interface PlatformStatusBin {
        bin: string
        platform: string
        statuses: { [key: string]: number }
    }

    export interface PrescriptionCartItem {
        SKU: string
        Quantity: number
    }

    export interface PrescriptionCartRequest {
        items: PrescriptionCartItem[]
    }

    export interface Product {
        ID: number
        DocID: uuid.UUID
        GTIN: string
        Name: string
        ProductDesc: string
        CreatedAt: string
        UpdatedAt: string
    }

    export interface ProductSubCategoriesRequest {
        RequestedItemsPerPage: number
        RequestedPageNumber: number
        SubcatDocIDs: string[]
    }

    export interface ProductSubcategoryRequest {
        "product_id": uuid.UUID
        "sub_category_id": uuid.UUID
    }

    /**
     * A Rule is composed of a list of AND conditions that must all evaluate to true in order for the rule to pass
     */
    export interface Rule {
        /**
         * The ID of the rule
         */
        id: number

        /**
         * The name of the rule
         * required: true
         */
        name: string

        /**
         * The AND rule groups for this rule
         */
        "and_conditions": AndCondition[]
    }

    export type RuleConditionOperation = string

    export type RuleConditionProperty = string

    export interface SendCallKitNotificationRequest {
        "notification_token": string
        "appointment_id": string
        "user_doc_id": string
        "auth_key": string
    }

    export interface ServicePricesBusinessMappingResponse {
        /**
         * the id of the business
         */
        id: string

        name: string
    }

    export interface SubcategoriesResponseWithCat {
        subcategories: SubcategoryResponseWithCategory[]
    }

    export interface SubcategoryNameResponse {
        "subcategory_id": uuid.UUID
        name: string
    }

    export interface SubcategoryResponse {
        id: number
        "doc_id": uuid.UUID
        "category_id": uuid.UUID
        name: string
    }

    export interface SubcategoryResponseWithCategory {
        id: number
        "doc_id": uuid.UUID
        category: Category
        name: string
    }

    export interface TreatmentPlanReady {
        "consultation_doc_id": uuid.UUID
        "appointment_id": string
        "chat_id": string
        "created_at": string
        "staff_profile_doc_id": string
        "staff_profile_photo_url": string
        "staff_profile_name": string
        "start_at": utilities.Dates
        "service_name": string
    }

    export interface TreatmentPlanReadyList {
        "treatment_plans": TreatmentPlanReady[]
        pagination: shared.Pagination
    }

    export interface UpdateBusinessRequest {
        id: string
        name: string
        "external_id": string
        "partner_integration": string
    }

    export interface UpdateCatalogueRequest {
        "doc_id": string
        name: string
        description: string
    }

    export interface UpdateCatalogueResponse {
        id: number
        "doc_id": uuid.UUID
        name: string
        description: string
    }

    export interface UpdateCategoryRequest {
        Name: string
    }

    export interface UpdateCategoryResponse {
        name: string
    }

    export interface UpdatePetResponse {
    }

    export interface UpdateProductRequest {
        id: number
        "doc_id": uuid.UUID
        gtin: string
        name: string
        "product_description": string
    }

    export interface UpdateProductResponse {
        id: number
        "doc_id": uuid.UUID
        gtin: string
        name: string
        "product_desc": string
    }

    export interface UpdateSubcategoryRequest {
        name: string
        "category_id": uuid.UUID
    }

    export interface UpdateSubcategoryResponse {
        name: string
        "category_id": uuid.UUID
    }

    export interface UploadProductsRequest {
        "catalogue_doc_id": string
        "products_csv_file": string
    }

    export interface UserSummary {
        "doc_id": uuid.UUID
        name: string
    }

    export interface WebhookEventMappingRequest {
        /**
         * the name of the webhook event
         */
        "webhook_event_id": string
    }

    export interface WebhookEventMappingResponse {
        /**
         * the webhook id
         */
        WebhookID: uuid.UUID

        /**
         * the webhook event id
         */
        EventID: uuid.UUID
    }

    export interface WebhookEventRequest {
        /**
         * the name of the webhook event
         */
        name: string
    }

    export interface WebhookEventResponse {
        /**
         * the id of the user
         */
        id: string

        name: string
    }

    export interface WebhookRequest {
        /**
         * the business url of the webhook
         */
        url: string
    }

    export interface WebhookResponse {
        /**
         * the id of the webhook
         */
        id: string

        /**
         * the business url of the webhook
         */
        url: string
    }

    export interface WeightResponse {
        /**
         * DocID
         * The **doc_id** of the weight in **UUID** format.
         */
        "doc_id": string

        /**
         * PetDocID
         * The **doc_id** of the pet in **UUID** format.
         */
        "pet_doc_id": string

        /**
         * Weight
         * The weight object
         */
        weight: WeightUnitsResponse

        /**
         * MeasuredAt
         * **measured_at** in UTC time format (e.g. **2017-09-04T04:00:00.000000Z**)
         */
        "measured_at"?: string
    }

    export interface WeightType {
        /**
         * WeightValue
         * The value of the weight.
         */
        "weight_value": number

        /**
         * Unit
         */
        unit: WeightUnit
    }

    export type WeightUnit = string

    export interface WeightUnitsResponse {
        /**
         * Grams
         * The weight value calculated in grams
         */
        g: number

        /**
         * Kg
         * The weight value calculated in kilograms
         */
        kg: number

        /**
         * Lbs
         * The weight value calculated in pounds
         */
        lbs: number
    }
}

export namespace shared {
    export interface Pagination {
        total: number
        "current_page": number
        pages: number
    }
}

export namespace utilities {
    export type AppointmentStatus = string

    export type ConsultStatus = string

    export interface Dates {
        /**
         * UTC
         * Time in UTC (e.g. **2017-09-04T04:00:00Z**)
         */
        utc: string

        /**
         * Local
         * Time in local (e.g. **2017-09-04T04:00:00**)
         */
        local: string

        /**
         * DateFriendly
         * Date (e.g. **04/09/2017**)
         */
        "date_friendly": string

        /**
         * TimeFriendly
         * Time (e.g. **04:00 BST**)
         */
        "time_friendly": string

        /**
         * Timezone
         * **timezone** in IANA format
         * (e.g **Europe/London**, **UTC**, **Europe/Berlin**)
         */
        timezone: string
    }
}

export namespace uuid {
    /**
     * A UUID is a 128 bit (16 byte) Universal Unique IDentifier as defined in RFC
     * 4122.
     */
    export type UUID = string
}

// JSONValue represents an arbitrary JSON value.
export type JSONValue = string | number | boolean | null | JSONValue[] | {[key: string]: JSONValue}


function encodeQuery(parts: Record<string, string | string[]>): string {
    const pairs: string[] = []
    for (const key in parts) {
        const val = (Array.isArray(parts[key]) ?  parts[key] : [parts[key]]) as string[]
        for (const v of val) {
            pairs.push(`${key}=${encodeURIComponent(v)}`)
        }
    }
    return pairs.join("&")
}

// makeRecord takes a record and strips any undefined values from it,
// and returns the same record with a narrower type.
// @ts-ignore - TS ignore because makeRecord is not always used
function makeRecord<K extends string | number | symbol, V>(record: Record<K, V | undefined>): Record<K, V> {
    for (const key in record) {
        if (record[key] === undefined) {
            delete record[key]
        }
    }
    return record as Record<K, V>
}

function encodeWebSocketHeaders(headers: Record<string, string>) {
    // url safe, no pad
    const base64encoded = btoa(JSON.stringify(headers))
      .replaceAll("=", "")
      .replaceAll("+", "-")
      .replaceAll("/", "_");
    return "encore.dev.headers." + base64encoded;
}

class WebSocketConnection {
    public ws: WebSocket;

    private hasUpdateHandlers: (() => void)[] = [];

    constructor(url: string, headers?: Record<string, string>) {
        let protocols = ["encore-ws"];
        if (headers) {
            protocols.push(encodeWebSocketHeaders(headers))
        }

        this.ws = new WebSocket(url, protocols)

        this.on("error", () => {
            this.resolveHasUpdateHandlers();
        });

        this.on("close", () => {
            this.resolveHasUpdateHandlers();
        });
    }

    resolveHasUpdateHandlers() {
        const handlers = this.hasUpdateHandlers;
        this.hasUpdateHandlers = [];

        for (const handler of handlers) {
            handler()
        }
    }

    async hasUpdate() {
        // await until a new message have been received, or the socket is closed
        await new Promise((resolve) => {
            this.hasUpdateHandlers.push(() => resolve(null))
        });
    }

    on(type: "error" | "close" | "message" | "open", handler: (event: any) => void) {
        this.ws.addEventListener(type, handler);
    }

    off(type: "error" | "close" | "message" | "open", handler: (event: any) => void) {
        this.ws.removeEventListener(type, handler);
    }

    close() {
        this.ws.close();
    }
}

export class StreamInOut<Request, Response> {
    public socket: WebSocketConnection;
    private buffer: Response[] = [];

    constructor(url: string, headers?: Record<string, string>) {
        this.socket = new WebSocketConnection(url, headers);
        this.socket.on("message", (event: any) => {
            this.buffer.push(JSON.parse(event.data));
            this.socket.resolveHasUpdateHandlers();
        });
    }

    close() {
        this.socket.close();
    }

    async send(msg: Request) {
        if (this.socket.ws.readyState === WebSocket.CONNECTING) {
            // await that the socket is opened
            await new Promise((resolve) => {
                this.socket.ws.addEventListener("open", resolve, { once: true });
            });
        }

        return this.socket.ws.send(JSON.stringify(msg));
    }

    async next(): Promise<Response | undefined> {
        for await (const next of this) return next;
        return undefined;
    }

    async *[Symbol.asyncIterator](): AsyncGenerator<Response, undefined, void> {
        while (true) {
            if (this.buffer.length > 0) {
                yield this.buffer.shift() as Response;
            } else {
                if (this.socket.ws.readyState === WebSocket.CLOSED) return;
                await this.socket.hasUpdate();
            }
        }
    }
}

export class StreamIn<Response> {
    public socket: WebSocketConnection;
    private buffer: Response[] = [];

    constructor(url: string, headers?: Record<string, string>) {
        this.socket = new WebSocketConnection(url, headers);
        this.socket.on("message", (event: any) => {
            this.buffer.push(JSON.parse(event.data));
            this.socket.resolveHasUpdateHandlers();
        });
    }

    close() {
        this.socket.close();
    }

    async next(): Promise<Response | undefined> {
        for await (const next of this) return next;
        return undefined;
    }

    async *[Symbol.asyncIterator](): AsyncGenerator<Response, undefined, void> {
        while (true) {
            if (this.buffer.length > 0) {
                yield this.buffer.shift() as Response;
            } else {
                if (this.socket.ws.readyState === WebSocket.CLOSED) return;
                await this.socket.hasUpdate();
            }
        }
    }
}

export class StreamOut<Request, Response> {
    public socket: WebSocketConnection;
    private responseValue: Promise<Response>;

    constructor(url: string, headers?: Record<string, string>) {
        let responseResolver: (_: any) => void;
        this.responseValue = new Promise((resolve) => responseResolver = resolve);

        this.socket = new WebSocketConnection(url, headers);
        this.socket.on("message", (event: any) => {
            responseResolver(JSON.parse(event.data))
        });
    }

    async response(): Promise<Response> {
        return this.responseValue;
    }

    close() {
        this.socket.close();
    }

    async send(msg: Request) {
        if (this.socket.ws.readyState === WebSocket.CONNECTING) {
            // await that the socket is opened
            await new Promise((resolve) => {
                this.socket.ws.addEventListener("open", resolve, { once: true });
            });
        }

        return this.socket.ws.send(JSON.stringify(msg));
    }
}
// CallParameters is the type of the parameters to a method call, but require headers to be a Record type
type CallParameters = Omit<RequestInit, "method" | "body" | "headers"> & {
    /** Headers to be sent with the request */
    headers?: Record<string, string>

    /** Query parameters to be sent with the request */
    query?: Record<string, string | string[]>
}

// AuthDataGenerator is a function that returns a new instance of the authentication data required by this API
export type AuthDataGenerator = () =>
  | accountinternal.MyAuthParams
  | Promise<accountinternal.MyAuthParams | undefined>
  | undefined;

// A fetcher is the prototype for the inbuilt Fetch function
export type Fetcher = typeof fetch;

const boundFetch = fetch.bind(this);

class BaseClient {
    readonly baseURL: string
    readonly fetcher: Fetcher
    readonly headers: Record<string, string>
    readonly requestInit: Omit<RequestInit, "headers"> & { headers?: Record<string, string> }
    readonly authGenerator?: AuthDataGenerator

    constructor(baseURL: string, options: ClientOptions) {
        this.baseURL = baseURL
        this.headers = {}

        // Add User-Agent header if the script is running in the server
        // because browsers do not allow setting User-Agent headers to requests
        if ( typeof globalThis === "object" && !("window" in globalThis) ) {
            this.headers["User-Agent"] = "core-6p22-Generated-TS-Client (Encore/v1.46.16)";
        }

        this.requestInit = options.requestInit ?? {};

        // Setup what fetch function we'll be using in the base client
        if (options.fetcher !== undefined) {
            this.fetcher = options.fetcher
        } else {
            this.fetcher = boundFetch
        }

        // Setup an authentication data generator using the auth data token option
        if (options.auth !== undefined) {
            const auth = options.auth
            if (typeof auth === "function") {
                this.authGenerator = auth
            } else {
                this.authGenerator = () => auth
            }
        }
    }

    async getAuthData(): Promise<CallParameters | undefined> {
        let authData: accountinternal.MyAuthParams | undefined;

        // If authorization data generator is present, call it and add the returned data to the request
        if (this.authGenerator) {
            const mayBePromise = this.authGenerator();
            if (mayBePromise instanceof Promise) {
                authData = await mayBePromise;
            } else {
                authData = mayBePromise;
            }
        }

        if (authData) {
            const data: CallParameters = {};

            data.headers = makeRecord<string, string>({
                "accept-language":    authData.Language,
                authorization:        authData.Token,
                "x-client-version":   authData.ClientBuildNumber,
                "x-platform":         authData.ClientOperatingSystem,
                "x-platform-version": authData.ClientOSVersion,
            });

            return data;
        }

        return undefined;
    }

    // createStreamInOut sets up a stream to a streaming API endpoint.
    async createStreamInOut<Request, Response>(path: string, params?: CallParameters): Promise<StreamInOut<Request, Response>> {
        let { query, headers } = params ?? {};

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                headers = {...headers, ...authData.headers};
            }
        }

        const queryString = query ? '?' + encodeQuery(query) : ''
        return new StreamInOut(this.baseURL + path + queryString, headers);
    }

    // createStreamIn sets up a stream to a streaming API endpoint.
    async createStreamIn<Response>(path: string, params?: CallParameters): Promise<StreamIn<Response>> {
        let { query, headers } = params ?? {};

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                headers = {...headers, ...authData.headers};
            }
        }

        const queryString = query ? '?' + encodeQuery(query) : ''
        return new StreamIn(this.baseURL + path + queryString, headers);
    }

    // createStreamOut sets up a stream to a streaming API endpoint.
    async createStreamOut<Request, Response>(path: string, params?: CallParameters): Promise<StreamOut<Request, Response>> {
        let { query, headers } = params ?? {};

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                headers = {...headers, ...authData.headers};
            }
        }

        const queryString = query ? '?' + encodeQuery(query) : ''
        return new StreamOut(this.baseURL + path + queryString, headers);
    }

    // callTypedAPI makes an API call, defaulting content type to "application/json"
    public async callTypedAPI(method: string, path: string, body?: BodyInit, params?: CallParameters): Promise<Response> {
        return this.callAPI(method, path, body, {
            ...params,
            headers: { "Content-Type": "application/json", ...params?.headers }
        });
    }

    // callAPI is used by each generated API method to actually make the request
    public async callAPI(method: string, path: string, body?: BodyInit, params?: CallParameters): Promise<Response> {
        let { query, headers, ...rest } = params ?? {}
        const init = {
            ...this.requestInit,
            ...rest,
            method,
            body: body ?? null,
        }

        // Merge our headers with any predefined headers
        init.headers = {...this.headers, ...init.headers, ...headers}

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                init.headers = {...init.headers, ...authData.headers};
            }
        }

        // Make the actual request
        const queryString = query ? '?' + encodeQuery(query) : ''
        const response = await this.fetcher(this.baseURL+path+queryString, init)

        // handle any error responses
        if (!response.ok) {
            // try and get the error message from the response body
            let body: APIErrorResponse = { code: ErrCode.Unknown, message: `request failed: status ${response.status}` }

            // if we can get the structured error we should, otherwise give a best effort
            try {
                const text = await response.text()

                try {
                    const jsonBody = JSON.parse(text)
                    if (isAPIErrorResponse(jsonBody)) {
                        body = jsonBody
                    } else {
                        body.message += ": " + JSON.stringify(jsonBody)
                    }
                } catch {
                    body.message += ": " + text
                }
            } catch (e) {
                // otherwise we just append the text to the error message
                body.message += ": " + String(e)
            }

            throw new APIError(response.status, body)
        }

        return response
    }
}

/**
 * APIErrorDetails represents the response from an Encore API in the case of an error
 */
interface APIErrorResponse {
    code: ErrCode
    message: string
    details?: any
}

function isAPIErrorResponse(err: any): err is APIErrorResponse {
    return (
        err !== undefined && err !== null &&
        isErrCode(err.code) &&
        typeof(err.message) === "string" &&
        (err.details === undefined || err.details === null || typeof(err.details) === "object")
    )
}

function isErrCode(code: any): code is ErrCode {
    return code !== undefined && Object.values(ErrCode).includes(code)
}

/**
 * APIError represents a structured error as returned from an Encore application.
 */
export class APIError extends Error {
    /**
     * The HTTP status code associated with the error.
     */
    public readonly status: number

    /**
     * The Encore error code
     */
    public readonly code: ErrCode

    /**
     * The error details
     */
    public readonly details?: any

    constructor(status: number, response: APIErrorResponse) {
        // extending errors causes issues after you construct them, unless you apply the following fixes
        super(response.message);

        // set error name as constructor name, make it not enumerable to keep native Error behavior
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/new.target#new.target_in_constructors
        Object.defineProperty(this, 'name', {
            value:        'APIError',
            enumerable:   false,
            configurable: true,
        })

        // fix the prototype chain
        if ((Object as any).setPrototypeOf == undefined) {
            (this as any).__proto__ = APIError.prototype
        } else {
            Object.setPrototypeOf(this, APIError.prototype);
        }

        // capture a stack trace
        if ((Error as any).captureStackTrace !== undefined) {
            (Error as any).captureStackTrace(this, this.constructor);
        }

        this.status = status
        this.code = response.code
        this.details = response.details
    }
}

/**
 * Typeguard allowing use of an APIError's fields'
 */
export function isAPIError(err: any): err is APIError {
    return err instanceof APIError;
}

export enum ErrCode {
    /**
     * OK indicates the operation was successful.
     */
    OK = "ok",

    /**
     * Canceled indicates the operation was canceled (typically by the caller).
     *
     * Encore will generate this error code when cancellation is requested.
     */
    Canceled = "canceled",

    /**
     * Unknown error. An example of where this error may be returned is
     * if a Status value received from another address space belongs to
     * an error-space that is not known in this address space. Also
     * errors raised by APIs that do not return enough error information
     * may be converted to this error.
     *
     * Encore will generate this error code in the above two mentioned cases.
     */
    Unknown = "unknown",

    /**
     * InvalidArgument indicates client specified an invalid argument.
     * Note that this differs from FailedPrecondition. It indicates arguments
     * that are problematic regardless of the state of the system
     * (e.g., a malformed file name).
     *
     * This error code will not be generated by the gRPC framework.
     */
    InvalidArgument = "invalid_argument",

    /**
     * DeadlineExceeded means operation expired before completion.
     * For operations that change the state of the system, this error may be
     * returned even if the operation has completed successfully. For
     * example, a successful response from a server could have been delayed
     * long enough for the deadline to expire.
     *
     * The gRPC framework will generate this error code when the deadline is
     * exceeded.
     */
    DeadlineExceeded = "deadline_exceeded",

    /**
     * NotFound means some requested entity (e.g., file or directory) was
     * not found.
     *
     * This error code will not be generated by the gRPC framework.
     */
    NotFound = "not_found",

    /**
     * AlreadyExists means an attempt to create an entity failed because one
     * already exists.
     *
     * This error code will not be generated by the gRPC framework.
     */
    AlreadyExists = "already_exists",

    /**
     * PermissionDenied indicates the caller does not have permission to
     * execute the specified operation. It must not be used for rejections
     * caused by exhausting some resource (use ResourceExhausted
     * instead for those errors). It must not be
     * used if the caller cannot be identified (use Unauthenticated
     * instead for those errors).
     *
     * This error code will not be generated by the gRPC core framework,
     * but expect authentication middleware to use it.
     */
    PermissionDenied = "permission_denied",

    /**
     * ResourceExhausted indicates some resource has been exhausted, perhaps
     * a per-user quota, or perhaps the entire file system is out of space.
     *
     * This error code will be generated by the gRPC framework in
     * out-of-memory and server overload situations, or when a message is
     * larger than the configured maximum size.
     */
    ResourceExhausted = "resource_exhausted",

    /**
     * FailedPrecondition indicates operation was rejected because the
     * system is not in a state required for the operation's execution.
     * For example, directory to be deleted may be non-empty, an rmdir
     * operation is applied to a non-directory, etc.
     *
     * A litmus test that may help a service implementor in deciding
     * between FailedPrecondition, Aborted, and Unavailable:
     *  (a) Use Unavailable if the client can retry just the failing call.
     *  (b) Use Aborted if the client should retry at a higher-level
     *      (e.g., restarting a read-modify-write sequence).
     *  (c) Use FailedPrecondition if the client should not retry until
     *      the system state has been explicitly fixed. E.g., if an "rmdir"
     *      fails because the directory is non-empty, FailedPrecondition
     *      should be returned since the client should not retry unless
     *      they have first fixed up the directory by deleting files from it.
     *  (d) Use FailedPrecondition if the client performs conditional
     *      REST Get/Update/Delete on a resource and the resource on the
     *      server does not match the condition. E.g., conflicting
     *      read-modify-write on the same resource.
     *
     * This error code will not be generated by the gRPC framework.
     */
    FailedPrecondition = "failed_precondition",

    /**
     * Aborted indicates the operation was aborted, typically due to a
     * concurrency issue like sequencer check failures, transaction aborts,
     * etc.
     *
     * See litmus test above for deciding between FailedPrecondition,
     * Aborted, and Unavailable.
     */
    Aborted = "aborted",

    /**
     * OutOfRange means operation was attempted past the valid range.
     * E.g., seeking or reading past end of file.
     *
     * Unlike InvalidArgument, this error indicates a problem that may
     * be fixed if the system state changes. For example, a 32-bit file
     * system will generate InvalidArgument if asked to read at an
     * offset that is not in the range [0,2^32-1], but it will generate
     * OutOfRange if asked to read from an offset past the current
     * file size.
     *
     * There is a fair bit of overlap between FailedPrecondition and
     * OutOfRange. We recommend using OutOfRange (the more specific
     * error) when it applies so that callers who are iterating through
     * a space can easily look for an OutOfRange error to detect when
     * they are done.
     *
     * This error code will not be generated by the gRPC framework.
     */
    OutOfRange = "out_of_range",

    /**
     * Unimplemented indicates operation is not implemented or not
     * supported/enabled in this service.
     *
     * This error code will be generated by the gRPC framework. Most
     * commonly, you will see this error code when a method implementation
     * is missing on the server. It can also be generated for unknown
     * compression algorithms or a disagreement as to whether an RPC should
     * be streaming.
     */
    Unimplemented = "unimplemented",

    /**
     * Internal errors. Means some invariants expected by underlying
     * system has been broken. If you see one of these errors,
     * something is very broken.
     *
     * This error code will be generated by the gRPC framework in several
     * internal error conditions.
     */
    Internal = "internal",

    /**
     * Unavailable indicates the service is currently unavailable.
     * This is a most likely a transient condition and may be corrected
     * by retrying with a backoff. Note that it is not always safe to retry
     * non-idempotent operations.
     *
     * See litmus test above for deciding between FailedPrecondition,
     * Aborted, and Unavailable.
     *
     * This error code will be generated by the gRPC framework during
     * abrupt shutdown of a server process or network connection.
     */
    Unavailable = "unavailable",

    /**
     * DataLoss indicates unrecoverable data loss or corruption.
     *
     * This error code will not be generated by the gRPC framework.
     */
    DataLoss = "data_loss",

    /**
     * Unauthenticated indicates the request does not have valid
     * authentication credentials for the operation.
     *
     * The gRPC framework will generate this error code when the
     * authentication metadata is invalid or a Credentials callback fails,
     * but also expect authentication middleware to generate it.
     */
    Unauthenticated = "unauthenticated",
}
