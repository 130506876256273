import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { accountexternal, bookingexternal, entities } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';

@Injectable({
    providedIn: 'root'
})

export class AvailabilityDataService {

    constructor(private client: ClientService) { }

    ListAvailabileDays(params: bookingexternal.ListAvailabileDaysQueryParams): Observable<bookingexternal.ListAvailableDaysResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.ListAvailabileDays(params));
    }

    ListAvailability(params: bookingexternal.ListAvailabilityQueryParams): Observable<bookingexternal.ListAvailabilityResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.ListAvailability(params));
    }

    ListOndemand(params: bookingexternal.ListAvailabilityQueryParams): Observable<bookingexternal.ListAvailabilityResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.ListOnDemand(params));
    }

    ListOnDemand(params: bookingexternal.ListOnDemandQueryParams): Observable<bookingexternal.ListAvailabilityResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.ListOnDemand(params));
    }

    ChatAvailability(chat_service_doc_id: string): Observable<bookingexternal.ChatAvailabilityResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.ChatAvailability({ ChatServiceID: chat_service_doc_id }));
    }

}
