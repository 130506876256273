import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";
import { NoPetsGuard } from "../booking/guards/pet/nopets.guard";

export const VetCareRoutes: Routes = [
    {
        path: 'vet-care',
        canActivate: [LoginGuard],
        loadComponent: () => import('./pages/vet-care-home/vet-care-home.component').then(m => m.VetCareHomeComponent),
    },
    {
        path: 'call-type',
        canActivate: [LoginGuard, NoPetsGuard],
        loadComponent: () => import('./pages/call-type/call-type.component').then(m => m.CallTypeComponent)
    }
]