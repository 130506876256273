import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as braze from "@braze/web-sdk";
import { AccountDataService } from '../account.data.service/account.data.service';
import { BehaviorSubject, Observable, catchError, first, map, of } from 'rxjs';
import { accountexternal } from 'src/shared/services/client/client';
import { BrazeUser } from './braze.interface';

enum InAppMessageType {
  PushPrimer = "push-primer"
}

const PUSH_PRIMER_PRIMARY_INDEX = 1;

export const BrazeInitializeFactory = (brazeService: BrazeService) => {
  return () => {
    if (!environment.braze)
      return;

    braze.initialize(environment.braze.apiKey, {
      baseUrl: environment.braze.baseUrl,
      enableLogging: environment.environment === 'localhost',
      allowUserSuppliedJavascript: true
    });

    activateInAppMessageSubscriptionHandler();

    braze.subscribeToContentCardsUpdates((cards: braze.ContentCards) => {
      brazeService.setContentCards(cards);
    })
    braze.requestContentCardsRefresh();
  };
};

export const activateInAppMessageSubscriptionHandler = () => {
  braze.subscribeToInAppMessage((message) => {
    if (!(message instanceof braze.InAppMessage)) {
      return;
    }

    const keyValuePairs = message.extras || {};
    if (keyValuePairs["msg-id"] === InAppMessageType.PushPrimer) {
      if (braze.isPushSupported() === false ||
        braze.isPushPermissionGranted() ||
        braze.isPushBlocked()) {
        return;
      }

      const inAppMessage = message as any;
      if (inAppMessage.buttons && inAppMessage.buttons[PUSH_PRIMER_PRIMARY_INDEX]) {
        inAppMessage.buttons[PUSH_PRIMER_PRIMARY_INDEX].subscribeToClickedEvent(function () {
          braze.requestPushPermission(
            function () {
              // success
            },
            function () {
              // decline
            }
          );
        });
      }
    }

    braze.showInAppMessage(message);
  });
}

export const mapGetUserToBrazeUser = (user: accountexternal.GetUserResponse): BrazeUser => {
  return {
    id: user.doc_id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    country: user.country_code,
    phoneNumber: user.phone_number,
  }
}

@Injectable({
  providedIn: 'root'
})
export class BrazeService {
  contentCards!: braze.ContentCards;
  contentCards$: BehaviorSubject<braze.ContentCards | null> = new BehaviorSubject<braze.ContentCards | null>(null);

  constructor(private account: AccountDataService) { }

  openCurrentUserSession(): void {
    this.getUserForBrazeSession().subscribe((user) => {
      if (user) {
        braze.changeUser(user.id);
        braze.openSession();
      }
    });
  }

  openCurrentUserSessionWithData(): void {
    this.getUserForBrazeSession().subscribe((user) => {
      if (user) {
        braze.changeUser(user.id);
        braze.openSession();
        this.setUserAttributes(user);
      }
    });
  }

  trackEvent(eventName: string, properties?: any): void {
    braze.logCustomEvent(eventName, properties);
  }

  setContentCards(cards: braze.ContentCards): void {
    this.contentCards = cards;
    this.contentCards$.next(cards);
  }

  setCachedContentCards() {
    this.setContentCards(braze.getCachedContentCards());
  }

  showContentCards(feedParentElement: HTMLElement, key?: string, petId?: string): void {
    braze.showContentCards(feedParentElement, (cards) => 
      cards?.filter((card) => this.shouldCardBeDisplayed(card, key, petId))
    );
  }

  refreshContentCards(): void {
    braze.requestContentCardsRefresh();
  }

  shouldCardBeDisplayed(card: braze.Card, key?: string, petId?: string) {
    return (key === undefined || card.extras[key] === 'true') && (petId === undefined || card.extras["PetId"] == undefined || card.extras["PetId"] === petId)
  }

  unreadContentCards(key: string): boolean {
    if (!this.contentCards) {
      return false;
    }
    return this.contentCards.cards.some((card) => !card.viewed && card.extras[key] === 'true')
  }

  isContentCardsEmpty(key: string): boolean {
    if (!this.contentCards) {
      return true;
    }
    return !this.contentCards.cards.some((card) => card.extras[key] === 'true');
  }

  setUserAttributes(user: BrazeUser): void {
    const brazeUser = braze.getUser();

    if (user.email)
      brazeUser?.setEmail(user.email);
    if (user.firstName)
      brazeUser?.setFirstName(user.firstName);
    if (user.lastName)
      brazeUser?.setLastName(user.lastName);
    if (user.country)
      brazeUser?.setCountry(user.country);
    if (user.phoneNumber)
      brazeUser?.setPhoneNumber(user.phoneNumber);
  }

  private getUserForBrazeSession(): Observable<BrazeUser | null> {
    return this.account.GetMyUser().pipe(
      first(),
      map((dataUser) => {
        return mapGetUserToBrazeUser(dataUser)
      }),
      catchError(() => {
        return of(null);
      })
    )
  }
}
