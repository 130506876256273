import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail, signInWithRedirect } from "@angular/fire/auth";
import { CookieService } from '../cookies/cookie_service';
import { getAuth } from '@angular/fire/auth';
import { Tenant } from 'src/environments/evironment.interface';
import { environment } from 'src/environments/environment';
import { jwtDecode } from "jwt-decode";
import { JWT } from './jwt.interface';
import { BrazeService } from 'src/app/services/braze.service/braze.service';
import * as Sentry from '@sentry/angular';
import { RemoteConfigService } from '../remote-config/remote-config.service';
import { take } from 'rxjs';
import { isNil, omitBy } from 'lodash';
import { AccountDataService } from 'src/app/services/account.data.service/account.data.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  accountDataService = inject(AccountDataService);
  remoteConfigService = inject(RemoteConfigService)

  userEmail = signal(this.afAuth.currentUser?.email || '');
  isStaff = computed(() => this.isStaffEmail(this.userEmail()));
  loggedIn = signal<boolean>(this.isLoggedIn());

  constructor(
    private cookieService: CookieService,
    private afAuth: Auth,
    private brazeService: BrazeService
  ) {
    if (this.isLoggedIn()) {
      this.setLoggedInCookie();
    }
  }

  // get currentUserJWTUIDFromFirebase
  getUID(): string {
    return this.afAuth.currentUser?.uid ?? "";
  }


  async decodeBearerToken(): Promise<JWT> {
    const token = await this.getBearerToken();
    const decodedToken = jwtDecode(token);
    return decodedToken as JWT;
  }

  setTenant(tenant: string) {
    localStorage.setItem('tenant', tenant);
  }

  setWeightUnit(unit: string) {
    localStorage.setItem('weight-unit', unit);
  }

  getWeightUnit(): string {
    return localStorage.getItem('weight-unit') ?? 'KG';
  }

  getTenantId(): string {
    const savedTenant = localStorage.getItem('tenant');
    if (savedTenant && environment.tenants.some(t => t.friendly_id === savedTenant)) {
      return savedTenant;
    } else {
      localStorage.removeItem('tenant');
      return environment.defaulttenant;
    }
  }

  getAllTenants(): Tenant[] {
    return environment.tenants;
  }

  getActiveTenant(skipUpdate: boolean = false): Tenant | undefined {
    const id = this.getTenantId();
    let tenant: Tenant | undefined;
    environment.tenants.forEach((t: Tenant) => {
      if (t.friendly_id == id) {
        tenant = t
      }
    });
    if (skipUpdate) return tenant;
    return tenant;
  }


  setTenantAndGetAuth(): Auth {
    const auth = getAuth();
    const tenant = this.getActiveTenant();
    if (tenant == undefined || tenant == null)
      throw Error("Tenant not set");
    auth.tenantId = tenant.id;
    return auth;
  }

  async getBearerToken(): Promise<string> {
    const user = this.afAuth.currentUser;
    if (!user) {
      console.error("User not logged in");
      return "";
    }
    const token = await user.getIdToken(true);
    return token || "";
  }

  async loginWithEmailAndPassword(email: string, password: string) {
    await signInWithEmailAndPassword(this.setTenantAndGetAuth(), email, password)
      .then(() => {
        this.setLoggedInCookie();
        if (this.getTenantId() != "vetai") {
          this.brazeService.openCurrentUserSession();
        }
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  async sendPasswordResetEmail(email: string) {
    await sendPasswordResetEmail(this.setTenantAndGetAuth(), email)
      .then(() => { })
      .catch((error) => {
        this.handleError(error);
      });
  }

  async createAccount(email: string, password: string) {
    await createUserWithEmailAndPassword(this.setTenantAndGetAuth(), email, password)
      .then(() => {
        this.setLoggedInCookie();
        if (this.getTenantId() != "vetai") {
          this.brazeService.openCurrentUserSession();
        }
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  async loginWithGoogle() {
    await signInWithPopup(this.setTenantAndGetAuth(), new GoogleAuthProvider())
      .then(() => {
        this.setLoggedInCookie();
        if (this.getTenantId() != "vetai") {
          this.brazeService.openCurrentUserSession();
        }
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  async loginWithGoogleWithRedirect() {
    await signInWithRedirect(this.setTenantAndGetAuth(), new GoogleAuthProvider())
      .then(() => {
        this.setLoggedInCookie();
        if (this.getTenantId() != "vetai") {
          this.brazeService.openCurrentUserSession();
        }
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  async loginWithFacebook() {
    await signInWithPopup(this.setTenantAndGetAuth(), new FacebookAuthProvider())
      .then(() => {
        this.setLoggedInCookie();
        if (this.getTenantId() != "vetai") {
          this.brazeService.openCurrentUserSession();
        }
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  setLoggedInCookie() {
    this.cookieService.setCookie("loggedIn", "true", 1);
    this.setSentryUser()
    this.userEmail.set(this.afAuth.currentUser?.email || '');
    this.loggedIn.set(true);
    // set remote config
    this.remoteConfigService.fetchAndActivate().pipe(take(1)).subscribe({
      error: error => {
        console.log('Error fetching remote config', error)
      }
    });
  }

  removeLoggedInCookie() {
    Sentry.setUser(null)
    this.loggedIn.set(false);
    this.cookieService.removeCookie("loggedIn");
  }

  setSentryUser() {
    Sentry.setUser({
      email: this.afAuth.currentUser?.email ?? "",
      username: this.afAuth.currentUser?.displayName ?? "",
      id: this.getUID()
    })
  }

  async loginWithApple(auth: Auth) {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    provider.setCustomParameters({
      // "redirect_uri": environment.appleRedirectUrl
    });
    await signInWithPopup(auth, provider)
      .then(() => {
        this.setLoggedInCookie();
        if (this.getTenantId() != "vetai") {
          this.brazeService.openCurrentUserSession();
        }
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  isLoggedIn(): boolean {
    return this.cookieService.getCookie("loggedIn") == "true";
  }

  async logout() {
    await this.afAuth.signOut();
    this.clearLocalStorage();
    this.removeLoggedInCookie();
  }

  clearLocalStorage() {
    localStorage.removeItem('userID');
    localStorage.removeItem('activePetId');
    localStorage.removeItem('activePetName');
    localStorage.removeItem('dynamicRedirect');
    localStorage.removeItem('tenant');
    localStorage.removeItem('scsession');
    localStorage.removeItem('scalgorithmid')
    localStorage.removeItem('publisherVideoBackgroundOn')
  }


  handleError(error: any) {
    var errorCode = error.code;
    var errorMessage = error.message;
    var message = "Please try again";
    switch (errorCode) {
      case "auth/user-not-found":
        message = "User not found";
        break;
      case "auth/wrong-password":
        message = "Incorrect Password";
        break;
      case "auth/cancelled-popup-request":
        message = "Login Cancelled";
        break;
      case "auth/popup-closed-by-user":
        message = "Login Cancelled";
        break;
      case "auth/wrong-password":
        message = "Incorrect Password";
        break;
      case 'auth/weak-password':
        message = "Password should be at least 6 characters";
        break;
      case 'auth/invalid-tenant-id':
        message = "Unrecognised Tenant";
        break;
      case 'auth/email-already-in-use':
        message = "Email address already in use, please login instead";
        break;
      default:
        message = errorMessage;
        break;
    }
    throw Error(message);
  }

  isStaffEmail(email: string): boolean {
    return email?.includes('@vet-ai.com') || false;
  }

}
