import { AppointmentsStore } from "./appointments/appointments.store";
import { ChatStore } from "./chat/chat.store";
import { OnDemandStore } from "./on-demand/on-demand.store";
import { TreatmentPlansStore } from "./treatment-plans/treatment-plans.store";

/**
 * List of stores that are used when the user is authenticated.
 * This is used so the stores can be provided to the application when the user is authenticated.
 */
export const AuthenticatedStoreProviders = [
    AppointmentsStore,
    ChatStore,
    OnDemandStore,
    TreatmentPlansStore,
]