import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { bookinginternal } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';

@Injectable({
  providedIn: 'root'
})

export class StaffProfileDataService {

  constructor(private client: ClientService) { }

  GetMyStaffProfile(): Observable<bookinginternal.GetStaffProfileResponse> {
    return this.client.requestWithClient(client => client.bookinginternal.GetMyStaffProfile());
  }

  GetStaffProfileByID(staffID: string): Observable<bookinginternal.GetStaffProfileResponse> {
    return this.client.requestWithClient(client => client.bookinginternal.GetStaffProfile(staffID));
  }

  CreateMyStaffProfile(params: bookinginternal.CreateStaffProfileRequest): Observable<bookinginternal.CreateStaffProfileResponse> {
    return this.client.requestWithClient(client => client.bookinginternal.CreateMyStaffProfile(params));
  }

  UpdateMyStaffProfile(params: bookinginternal.UpdateStaffProfileRequest): Observable<bookinginternal.UpdateStaffProfileResponse> {
    return this.client.requestWithClient(client => client.bookinginternal.UpdateMyStaffProfile(params));
  }

  PatchMyStaffProfilePhoto(params: bookinginternal.PatchMyStaffProfilePhotoRequest): Observable<void> {
    return this.client.requestWithClient(client => client.bookinginternal.PatchMyStaffProfilePhoto(params))
  }

  PatchStaffProfilePhoto(staffID: string, params: bookinginternal.PatchMyStaffProfilePhotoRequest): Observable<void> {
    return this.client.requestWithClient(client => client.bookinginternal.PatchStaffProfilePhoto(staffID, params))
  }

}
