import { effect, inject } from "@angular/core";
import { patchState, signalStoreFeature, withHooks, withState } from "@ngrx/signals";
import { entities } from "src/shared/services/client/client";
import { UserStore } from "../../user.store";

export type activePetState = { activePet: entities.PetResponse | null };

/**
 * Signal store feature that provides the active pet state to another store.
 */
export function withActivePet<_>() {
    return signalStoreFeature(
        withState<activePetState>({ activePet: null }),
        withHooks({
            onInit(store, userStore = inject(UserStore)) {
                effect(() => {
                    patchState(store, { activePet: userStore.activePet() });
                }, { allowSignalWrites: true });
            }
        })
    )
}