import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";
import { PolicyInactiveGuard } from "src/shared/guards/policyInactive.guard";

export const ChatRoutes: Routes = [
    {
        path: 'chatcall/:chatId/:id',
        canActivate: [LoginGuard, PolicyInactiveGuard],
        loadComponent: () => import('./pages/video-call/video-call.component').then(m => m.VideoCallComponent),
    }
];