import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { first } from 'lodash';
import { bookingexternal } from 'src/shared/services/client/client';

@Injectable({
  providedIn: 'root'
})
export class OndemandUtilsService {

    getChatAvailabilityStatus(availability: bookingexternal.ChatAvailabilityResponse | null, incompleteChats: bookingexternal.GetChatResponse[]) {
      if (availability?.busy && incompleteChats.length === 0) {
        return 'busy';
      }
      if (availability?.available !== undefined) {
        return availability?.available && incompleteChats.length === 0 ? 'available' : 'unavailable';
      }
      return 'unavailable';
    }
  
    getChatAvailabilityLabel(availabilty: bookingexternal.ChatAvailabilityResponse | null, incompleteChats: bookingexternal.GetChatResponse[]) {
      if (availabilty?.busy && incompleteChats.length === 0) {
        return `Busier than usual, replies within ${availabilty?.next_chat_available_in_minutes} mins`;
      }
      if (availabilty?.available !== undefined) {
        return availabilty?.available && incompleteChats.length === 0 ? `Typically replies under ${availabilty?.next_chat_available_in_minutes} mins` : 'Currently unavailable';
      }
      return 'Currently unavailable';
    }
  
    getVideoAvailabilityStatus(slots: bookingexternal.ListAvailabilitySlotItem[], videoCallInProgress: boolean, unconfirmedAppointments: boolean) {
      return slots && slots.length > 0 && !videoCallInProgress && !unconfirmedAppointments ? 'available' : 'unavailable';
    }
  
    getVideoAvailabilityLabel(slots: bookingexternal.ListAvailabilitySlotItem[], videoCallInProgress: boolean, hasBookedAppointment: boolean) {
      if (videoCallInProgress) {
      return 'Call in progress';
      }

      const slot = first(slots);
      if (!slot || hasBookedAppointment) {
      return 'Currently unavailable';
      }

      const startDateTime = slot.start.utc;
      const slotEndTime = slot.end.utc;
      const formattedStartTime = format(startDateTime, 'h:mm aa');
      const formattedEndTime = format(slotEndTime, 'h:mm aa');

      return `Available between ${formattedStartTime} - ${formattedEndTime}`;
    }
    
}
