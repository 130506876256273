import { ionAddCircle, ionAddCircleOutline, ionAddOutline, ionAlbumsOutline, ionAlertCircleOutline, ionApertureOutline, ionArrowBackOutline, ionArrowDownSharp, ionArrowForwardOutline, ionArrowUp, ionAttach, ionBasketOutline, ionCalendarClearOutline, ionCalendarOutline, ionCallOutline, ionCamera, ionCameraOutline, ionCaretBackOutline, ionCaretForwardOutline, ionCheckmarkCircle, ionCheckmarkCircleOutline, ionChevronBackOutline, ionChevronDown, ionChevronDownOutline, ionChevronForward, ionChevronForwardCircle, ionChevronForwardCircleOutline, ionChevronForwardOutline, ionChevronUp, ionChevronUpOutline, ionClipboardOutline, ionClose, ionCloseCircleOutline, ionCloseOutline, ionCloseSharp, ionCodeWorkingOutline, ionConstructOutline, ionCopyOutline, ionDesktopOutline, ionDocumentOutline, ionDownload, ionDownloadOutline, ionExitOutline, ionEyeOff, ionEyeOutline, ionFilterOutline, ionFingerPrintOutline, ionFunnelOutline, ionGitCommit, ionGitNetworkOutline, ionHeadsetOutline, ionHomeOutline, ionImageOutline, ionInformationCircleOutline, ionLink, ionLogoFacebook, ionLogoInstagram, ionLogoTwitter, ionMic, ionMicOff, ionMicOutline, ionNotifications, ionNotificationsOutline, ionOpen, ionOpenOutline, ionPauseCircleOutline, ionPawOutline, ionPencilOutline, ionPeopleOutline, ionPlayCircleOutline, ionRefresh, ionRemoveCircleOutline, ionRepeatOutline, ionSearchOutline, ionSend, ionSettingsOutline, ionSyncCircleOutline, ionTimeOutline, ionTrashBinOutline, ionVideocamOffOutline, ionVideocamOutline, ionCheckmarkDoneCircleOutline, ionWarning, ionCreate, ionMedicalOutline, ionWarningOutline, ionEllipsisVerticalOutline } from "@ng-icons/ionicons";
import { ArrowUpRightFromCircle, RefreshCcw, X, PlusCircle, Dog, Trash2, MinusCircle, LucideIconConfig, Trash, Cat, Copy, Info, Pointer, Server, SlidersHorizontal, GitCommit, Replace, ReplaceAll, Image, Video, Text, Crosshair, Monitor, ChevronsRight, Check, ChevronsUpDown, Puzzle, Pen, SquarePen, User, History, Link, Menu, Settings, PictureInPicture2, VideoOff, Mic, MicOff, ScreenShare, PhoneOff, Expand, RefreshCw, Network, ChevronRight, ImagePlus, Fullscreen, ChevronLeft, Plus, ChevronDown, CalendarCheck, Clock, Pencil, FlaskRound, Eye, Star, EyeOff, AlertCircle, Flame, Minus, HelpCircle, AlertTriangle, Filter, MessageSquare, MessagesSquare, Pin, Loader2, ArrowUpCircle, ThumbsUp, ThumbsDown, ChevronUp, Asterisk } from "lucide-angular";

export const ICONS = {
  ArrowUpRightFromCircle,
  RefreshCcw,
  RefreshCw,
  X,
  PlusCircle,
  Dog,
  Trash2,
  Trash,
  MinusCircle,
  Cat,
  Copy,
  Info,
  Pointer,
  Server,
  SlidersHorizontal,
  Text,
  GitCommit,
  Replace,
  ReplaceAll,
  Image,
  Video,
  Crosshair,
  Monitor,
  ChevronsRight,
  Check,
  ChevronsUpDown,
  Puzzle,
  Pen,
  SquarePen,
  User,
  History,
  Link,
  Network,
  Menu,
  Settings,
  ChevronRight,
  PictureInPicture2,
  VideoOff,
  Mic,
  MicOff,
  ScreenShare,
  PhoneOff,
  Expand,
  Fullscreen,
  ChevronLeft,
  Plus,
  ImagePlus,
  ChevronDown,
  CalendarCheck,
  Clock,
  Pencil,
  FlaskRound,
  Eye,
  Star,
  Flame,
  Minus,
  EyeOff,
  AlertCircle,
  HelpCircle,
  Filter,
  AlertTriangle,
  MessagesSquare,
  Pin,
  Loader2,
  ArrowUpCircle,
  ThumbsUp,
  ThumbsDown,
  ChevronUp,
  Asterisk
}

export const NGICONS = {
  ionEllipsisVerticalOutline,
  ionCheckmarkCircle,
  ionEyeOutline,
  ionEyeOff,
  ionArrowDownSharp,
  ionTimeOutline,
  ionOpen,
  ionFilterOutline,
  ionSearchOutline,
  ionFingerPrintOutline,
  ionHomeOutline,
  ionCalendarClearOutline,
  ionVideocamOutline,
  ionGitNetworkOutline,
  ionPeopleOutline,
  ionAlbumsOutline,
  ionClipboardOutline,
  ionTrashBinOutline,
  ionAddOutline,
  ionDocumentOutline,
  ionArrowBackOutline,
  ionMedicalOutline,
  ionCloseOutline,
  ionFunnelOutline,
  ionCaretForwardOutline,
  ionCaretBackOutline,
  ionChevronBackOutline,
  ionChevronForwardOutline,
  ionChevronDownOutline,
  ionChevronUpOutline,
  ionCallOutline,
  ionConstructOutline,
  ionRemoveCircleOutline,
  ionAddCircleOutline,
  ionLink,
  ionAttach,
  ionOpenOutline,
  ionBasketOutline,
  ionCodeWorkingOutline,
  ionMic,
  ionSyncCircleOutline,
  ionChevronDown,
  ionChevronUp,
  ionRefresh,
  ionCheckmarkCircleOutline,
  ionCloseCircleOutline,
  ionMicOff,
  ionApertureOutline,
  ionImageOutline,
  ionHeadsetOutline,
  ionRepeatOutline,
  ionCameraOutline,
  ionDesktopOutline,
  ionCopyOutline,
  ionMicOutline,
  ionSettingsOutline,
  ionArrowForwardOutline,
  ionGitCommit,
  ionVideocamOffOutline,
  ionExitOutline,
  ionLogoFacebook,
  ionLogoInstagram,
  ionLogoTwitter,
  ionChevronForwardCircleOutline,
  ionChevronForwardCircle,
  ionChevronForward,
  ionArrowUp,
  ionPawOutline,
  ionInformationCircleOutline,
  ionClose,
  ionSend,
  ionCamera,
  ionNotifications,
  ionNotificationsOutline,
  ionCloseSharp,
  ionDownloadOutline,
  ionCalendarOutline,
  ionAlertCircleOutline,
  ionAddCircle,
  ionPlayCircleOutline,
  ionPauseCircleOutline,
  ionPencilOutline,
  ionCheckmarkDoneCircleOutline,
  ionDownload,
  ionWarning,
  ionWarningOutline,
  ionCreate
}
