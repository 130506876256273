import { Component, input } from '@angular/core';

@Component({
  selector: 'app-missed-call-message',
  standalone: true,
  imports: [],
  templateUrl: './missed-call-message.component.html',
  styleUrl: './missed-call-message.component.scss'
})
export class MissedCallMessageComponent {
  staffName = input<string>()
  staffImage = input<string>()
}
