import { computed, effect, inject, untracked } from "@angular/core"
import { tapResponse } from "@ngrx/operators"
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals"
import { rxMethod } from "@ngrx/signals/rxjs-interop"
import { switchMap, tap, pipe, map, exhaustMap, Subscription, fromEvent, Unsubscribable, filter } from "rxjs"
import { AvailabilityDataService } from "src/app/services/availability.data.service/availability.data.service"
import { bookingexternal } from "src/shared/services/client/client"
import { RemoteConfigService } from "src/shared/services/remote-config/remote-config.service"
import { UserStore } from "../user.store"
import { ChatDataService } from "src/app/services/chat.data.service/chat.data.service"
import { ChannelService, ChatClientService, ClientEvent, DefaultStreamChatGenerics, StreamMessage } from "stream-chat-angular"
import { environment } from "src/environments/environment"
import { Channel, ChannelMemberResponse, StreamChat } from "stream-chat"
import { cloneDeep, first, get } from "lodash"
import { withAuthStateReset } from "../features/auth-reset/auth-reset.feature"
import { AuthenticationService } from "src/shared/services/auth/auth_service"
import { withAiChatStatus } from "../features/ai-chat-status/ai-chat-status.feature"

export enum ChatView {
    chat = 'chat',
    chatList = 'chatList'
}

export enum ChatStatus {
    complete = 'complete',
    curious = 'curious',
    pending = 'pending',
    llmCallTriage = 'llm-call-triage',
    llmChatTriage = 'llm-chat-triage',
    frozen = 'frozen',
    handover = 'handover',
    active = 'active',
}

type ChatState = {
    availability: bookingexternal.ChatAvailabilityResponse | null,
    loadingAvailability: boolean
    availabilityError: string | null,
    chats: bookingexternal.GetChatResponse[],
    chatService: string | null,
    loadingChats: boolean,
    creatingChat: boolean,
    chatsError: string | null
    createChatError: string | null,
    chatClient: StreamChat<DefaultStreamChatGenerics> | null
    activeChannel: Channel<DefaultStreamChatGenerics> | null // The active channel in stream chat
    activeChannelMessages: StreamMessage<DefaultStreamChatGenerics>[] | null // The active channel messages in stream chat
    incompleteChannels: Channel<DefaultStreamChatGenerics>[],
    unreadCount: number,
    latestMessageTime: Date | null,
    staffChannelMember: ChannelMemberResponse<DefaultStreamChatGenerics> | null,
    staffProfileImage: string | null
    chatWidgetExpanded: boolean,
    chatWidgetView: ChatView,
    refreshInterval: number,
    subscription: Subscription | null,
    tabActive: boolean;
    channelSearchFilter: string;
    channelEventListenerSubscription: Unsubscribable | null;
    activeChannelSubscription: Unsubscribable | null;
}

const initalState = {
    availability: null, loadingAvailability: false,
    availabilityError: null, chats: [],
    loadingChats: false, creatingChat: false,
    chatsError: null, chatService: null,
    createChatError: null, chatClient: null,
    activeChannel: null, activeChannelMessages: null,
    staffChannelMember: null, staffProfileImage: null,
    chatWidgetExpanded: false, chatWidgetView: ChatView.chat,
    incompleteChannels: [], unreadCount: 0,
    refreshInterval: 15000, subscription: null,
    tabActive: true, latestMessageTime: null,
    channelSearchFilter: '', channelEventListenerSubscription: null, activeChannelSubscription: null
};

/**
 * Chat store
 * Manages the chat state and provides methods to interact with the Stream chat service.
 */
export const ChatStore = signalStore(
    withState<ChatState>(initalState),
    withComputed(({ chats, activeChannelMessages, staffChannelMember, chatWidgetExpanded, activeChannel, tabActive }) => {
        const userStore = inject(UserStore);
        const remoteConfig = inject(RemoteConfigService);
        const authService = inject(AuthenticationService);
        const config = remoteConfig.configurations;

        const incompleteChats = computed(() => chats().filter((chat) => chat.status.toLowerCase() !== ChatStatus.complete && (chat.status !== ChatStatus.llmCallTriage || !chat?.status_history?.includes(ChatStatus.llmCallTriage))));
        const incompleteChatsForActivePet = computed(() => incompleteChats().filter(chat => userStore.activePet() !== null && chat.pet_doc_id === userStore.activePet()?.doc_id));
        const incompleteChatsForInactivePet = computed(() => incompleteChats().filter(chat => userStore.pets()?.length > 0 && chat.pet_doc_id !== userStore.activePet()?.doc_id));
        const latestVetReplyDate = computed<Date | null>(() => {
            const vetMessages = activeChannelMessages()?.filter(message => message.user?.id !== userStore.userID()) || [];
            const sortedMessages = vetMessages.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            return sortedMessages[0]?.created_at || null;
        });
        const activeChannelStaffMemberOnline = computed(() => staffChannelMember()?.user?.online || false);
        const markActiveChannelAsRead = computed(() => chatWidgetExpanded() === true && activeChannel() !== null && tabActive() === true);
        const isHidden = computed(() => {
            const isLoggedInAndChatEnabled = authService.loggedIn() && config()?.get('chat_cta') === 'true';
            const insurer = authService.getActiveTenant()?.friendly_id;
            if (!isLoggedInAndChatEnabled) return true;
            if (authService.isStaff()) return false;
            if (insurer === 'animalfriends') return config()?.get('chat_cta_afi') !== 'true';
            return insurer === 'joii';
        });
        return { incompleteChats, incompleteChatsForActivePet, incompleteChatsForInactivePet, latestVetReplyDate, activeChannelStaffMemberOnline, markActiveChannelAsRead, isHidden }
    }),
    withAiChatStatus(),
    withMethods((
        store,
        remoteConfig = inject(RemoteConfigService),
        availabilityDataService = inject(AvailabilityDataService),
        chatDataService = inject(ChatDataService),
        userStore = inject(UserStore),
        chatService = inject(ChatClientService),
        channelService = inject(ChannelService)
    ) => {
        /**
         * Event handler for when a user is added to a channel.
         * This will add the channel to the stream channel list and watch the channel.
         * It will ignore new triage channels and only add messaging channels.
         * @param clientEvent 
         * @param channelListSetter 
         * @returns void
         */
        const customAddedToChannelNotificationHandler = async (
            clientEvent: ClientEvent,
            channelListSetter: (channels: Channel<DefaultStreamChatGenerics>[]) => void,
          ) => {
            const channelResponse = clientEvent!.event!.channel!;
            const isCallTriage = get(channelResponse, 'call_triage', false);

            // Only add messaging channels and ignore call triage channels.
            if (channelResponse.type !== "messaging" || isCallTriage) { 
              return Promise.resolve();
            }
            const newChannel = chatService.chatClient.channel(
              channelResponse.type,
              channelResponse.id,
            );
            try {
              await newChannel.watch();
              const existingChannels = channelService.channels;
              channelListSetter([newChannel, ...existingChannels]);
            } catch (error) {
              console.error("Failed to watch channel", error);
            }
          };
        /**
         * Clears any existing subscriptions.
         * This is used when the user logs out or changes the chat service.
         */
        function resetSubscriptions() {
            store.subscription()?.unsubscribe();
            store.channelEventListenerSubscription()?.unsubscribe();
            store.activeChannelSubscription()?.unsubscribe();
        }
        /**
         * Toggles the chat widget between expanded and collapsed states.
         */
        function toggleChatWidget() {
            patchState(store, { chatWidgetExpanded: !store.chatWidgetExpanded() });
        }
        /**
         * Force close or open the chat widget.
         */
        function setChatWidgetExpanded(expanded: boolean) {
            patchState(store, { chatWidgetExpanded: expanded });
        }
        /**
         * Sets the chat widget to the chat list view.
         */
        function viewChannelList() {
            channelService.deselectActiveChannel();
            patchState(store, { chatWidgetView: ChatView.chatList, activeChannel: null, activeChannelMessages: [] });
        }
        /**
         * Updates the unread count in the chat store.
         */
        function updateUnreadCount() {
            const unreadCount = store.incompleteChannels().reduce((acc, channel) => {
                return acc + (channel.countUnread() || 0);
            }, 0);
            patchState(store, { unreadCount });
        }
        /**
         * Updates the latest message time in the chat store.
         */
        function updateLatestMessage() {
            const latestMessageTime = store.incompleteChannels().reduce((acc: Date | null, channel) => {
                const channelMessages = cloneDeep(channel.state.messages);
                const lastMessage = channelMessages.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).filter(x => x.user?.id !== userStore.userID())[0];
                return acc && acc > lastMessage.created_at ? acc : lastMessage.created_at;
            }, null);
            patchState(store, { latestMessageTime });
        }
        /**
         * Handles a channel event.
         * This is called when a new message is received, a message is read, a message is updated, a message is deleted, or the channel is truncated.
         * This will update the unread count and latest message time in the chat store.
         */
        function handleChannelEvent() {
            updateUnreadCount();
            updateLatestMessage();
        }
        function handleAiEvent(event: any) {
            store.setAiStatus(event, store.activeChannel()?.id);
        }
        /**
         * Sets the channel filter.
         * @param filter The filter string to set.
         */
        function setChannelFilter(filter: string) {
            patchState(store, { channelSearchFilter: filter });
        }
        /**
         * Sets the tab active state.
         * @param tabActive The tab active state to set.
         * This is used to determine if the user is currently viewing the JOII tab.
         */
        const setTabActive = rxMethod<any>(
            pipe(
                tapResponse(
                    {
                        next: (response) => {
                            patchState(store, { tabActive: document.visibilityState === 'visible' });
                        },
                        error: (error) => {
                            console.error(error, 'TabActive');
                        }
                    }
                )
            )
        );
        /**
         * Starts the channel event listener.
         * This will listen for new messages, read messages, updated messages, deleted messages, and truncated channels.
         * @param channels The channels to listen to.
         */
        const startChannelEventListener = rxMethod<Channel<DefaultStreamChatGenerics>[]>(
            pipe(
                tapResponse({
                    next: (channels) => {
                        store.subscription()?.unsubscribe();
                        patchState(store, { subscription: new Subscription() });
                        channels.forEach(channel => {
                            store.subscription()?.add(channel?.on('message.new', handleChannelEvent.bind(this)));
                            store.subscription()?.add(channel?.on('message.read', handleChannelEvent.bind(this)));
                            store.subscription()?.add(channel?.on('message.updated', handleChannelEvent.bind(this)));
                            store.subscription()?.add(channel?.on('message.deleted', handleChannelEvent.bind(this)));
                            store.subscription()?.add(channel?.on('channel.truncated', handleChannelEvent.bind(this)));
                            store.subscription()?.add(channel?.on('ai_indicator.update' as any, handleAiEvent.bind(this)));
                            store.subscription()?.add(channel?.on('ai_indicator.clear' as any, handleAiEvent.bind(this)));
                        });
                    },
                    error: (error) => {
                        console.error(error, 'channelEventListener');
                    }
                })
            )
        )
        /**
         * Sets the active channel messages in the store.
         */
        const setActiveChannelMessages = rxMethod<StreamMessage<DefaultStreamChatGenerics>[]>(
            pipe(
                tapResponse({
                    next: (messages) => {
                        // set message
                        patchState(store, { activeChannelMessages: messages });
                    },
                    error: (error) => {
                        console.error(error, 'setChannelMessages')
                        patchState(store, { activeChannelMessages: null });
                    }
                })
            )
        )
        /**
         * Sets the active channel in the store.
         * @param channel$ The channel to set as active.
         */
        const setAsActiveChannel = rxMethod<Channel<DefaultStreamChatGenerics> | undefined>(
            pipe(
                tapResponse({
                    next: (channel$) => {
                        if (channel$) {
                            channelService.setAsActiveChannel(channel$);
                            patchState(store, { activeChannel: channel$, chatWidgetView: ChatView.chat });
                            setActiveChannelMessages(channelService.activeChannelMessages$);
                            channel$.queryMembers({}).then((result) => {
                                patchState(store, { staffChannelMember: result.members.find(member => member.user_id !== userStore.userID()) });
                                patchState(store, { staffProfileImage: get(store.staffChannelMember(), 'user.image', '') as string });
                            });
                        }
                    },
                    error: (error) => {

                    }
                })
            )
        )
        /**
         * Connects to the chat service.
         * @param chat The chat to connect to.
         * This will connect to the chat service and set the active channel in the store.
         */
        const connectToChat = rxMethod<bookingexternal.GetChatResponse | undefined>(
            pipe(
                switchMap((chat) => {
                    return chatDataService.GetToken().pipe(map(({ token }) => ({ chat, token })))
                }),
                tapResponse({
                    next: async ({ chat, token }) => {

                        // Reset any existing subscriptions
                        resetSubscriptions();


                        // Log user into the chat service.
                        const userName = `${userStore.user()?.first_name} ${userStore.user()?.last_name}` || '';
                        const user = {
                            id: userStore.userID() || '',
                            name: userName,
                            image: `https://getstream.io/random_png/?name=${userName}`,
                        }
                        await chatService.init(environment.getstream?.apiKey || '', user, token);

                        // Connect and initalise the channels list with channels that the user is a member of.
                        const channels = await channelService.init(
                            {
                                type: 'messaging',
                                members: { $in: [userStore.userID()!] },
                                hidden: false,
                                call_triage: false
                            }
                        );

                        channelService.customAddedToChannelNotificationHandler = customAddedToChannelNotificationHandler.bind(this);

                        // If there is a chat, connect to the chat channel and track changes to the channel.
                        if (chat) {
                            const connectedChannel = chatService.chatClient.channel('messaging', chat?.channel_id);

                            await connectedChannel.watch();

                            const incompleteChannels = channels.filter(channel => {
                                return store.incompleteChats().some(chat => channel.id === chat.channel_id)
                            });

                            setAsActiveChannel(connectedChannel);

                            patchState(store, {
                                chatClient: chatService.chatClient,
                                incompleteChannels,
                                channelEventListenerSubscription: startChannelEventListener(incompleteChannels),
                                activeChannelSubscription: setAsActiveChannel(channelService.activeChannel$)
                            });
                        }

                        // Expand the chat window the user is in a chat.
                        if (store.incompleteChats().length > 0) {
                            patchState(store, { chatWidgetExpanded: true });
                        }

                        // Show the latest chat if the user has no incomplete chats.
                        if (store.chats().length > 0 && store.incompleteChats().length === 0) {
                            patchState(store, {
                                chatWidgetExpanded: false,
                                activeChannelSubscription: setAsActiveChannel(channelService.activeChannel$)
                            });
                        }
                    },
                    error: (error) => {
                        console.error(error, 'GetToken')
                    }
                })
            )
        );
        /**
         * Sets the chat availability.
         * This will set the chat service and the chat availability in the store.
         * This is used to determine if the user can start a new chat.
         */
        const setChatAvailability = rxMethod<void>(
            pipe(
                filter(() => !store.isHidden()),
                exhaustMap(() => {
                    patchState(store, { loadingAvailability: true, availabilityError: null });
                    return remoteConfig.$configurations.pipe(
                        map((configurations) => {
                            const services = configurations.get('chat_service_doc_id');
                            return services ? JSON.parse(services)?.vet : null;
                        })
                    )
                }),
                switchMap((service_doc_id: string) => {
                    if (!service_doc_id) {
                        throw new Error('Chat service not set');
                    }
                    patchState(store, { chatService: service_doc_id });
                    return availabilityDataService.ChatAvailability(service_doc_id).pipe(
                        tapResponse({
                            next: (response) => patchState(store, { availability: response }),
                            error: (error: any) => patchState(store, { availability: null, availabilityError: error?.message }),
                            finalize: () => patchState(store, { loadingAvailability: false })
                        })
                    )
                })
            )
        )
        /**
         * Sets the user chats in the store.
         * This will set the user chats in the store and connect to the active chat if the user has one active..
         */
        const setUserChats = rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loadingChats: true, chatsError: null })),
                switchMap(() => {
                    return chatDataService.ListMyChats().pipe(
                        tapResponse({
                            next: (response) => {
                                const standardChats = response.chats.filter((chat) => chat.status !== ChatStatus.llmCallTriage || !chat?.status_history?.includes(ChatStatus.llmCallTriage)) || [];
                                const incompleteChats = standardChats.filter(chat => chat.status !== ChatStatus.complete) || [];
                                patchState(store, { chats: standardChats, chatsError: null, loadingChats: false });
                                const activeChat = first(incompleteChats);   
                                if (activeChat || response.chats.length > 0) { connectToChat(activeChat); }
                            },
                            error: (error: any) => {
                                patchState(store, { chats: [], chatsError: error?.message, loadingChats: false });
                                console.error(error, 'ListMyChats')
                            }
                        })
                    );
                })
            )
        );
        /**
         * Starts a new chat.
         * @param params The parameters to start a new chat.
         * This will create a new chat and set the user chats in the store.
         */
        const startNewChat = rxMethod<{ pet_doc_id: string, timezone: string, status?: ChatStatus }>(
            pipe(
                tap(() => patchState(store, { createChatError: null, creatingChat: true })),
                switchMap((params) => {
                    const service = store.chatService();
                    if (!service) {
                        throw new Error('Chat service not set');
                    }
                    return chatDataService.CreateChat({ status: params?.status || ChatStatus.curious, pet_doc_id: params.pet_doc_id, timezone: params.timezone, chat_service_id: store.chatService() || '' }).pipe(
                        tapResponse({
                            next: () => {
                                patchState(store, { createChatError: null })
                                setUserChats();
                            },
                            error: (error: any) => {
                                patchState(store, { createChatError: error?.message })
                                console.error(error, 'CreateChat')
                            },
                            finalize: () => patchState(store, { creatingChat: false })
                        })
                    );
                })
            )
        );
        /**
         * This determines if the active channel should mark new messages as read.
         * This is used to show unread messages when away from the tab.
         */
        const setMarkActiveAsRead = rxMethod<boolean>(
            pipe(
                tapResponse(
                    {
                        next: (response) => {
                            if (!channelService) return;
                            channelService.shouldMarkActiveChannelAsRead = response;
                        },
                        error: (error) => {
                            console.error(error, 'MarkAsRead');
                        }
                    }
                )
            )
        );
        /**
         * Resets the chat store.
         * Disconnects from the chat service and resets the chat store to the initial state.
         * @param state 
         */
        const reset = (state: ChatState) => {
            if (store.chatClient()) {
                channelService?.reset();
                store.chatClient()?.disconnectUser();
            }
            resetSubscriptions();
            patchState(store, state);
        }

        return { setChatAvailability, setUserChats, startNewChat, toggleChatWidget, viewChannelList, reset, setMarkActiveAsRead, setTabActive, setChannelFilter, updateUnreadCount, setChatWidgetExpanded, customAddedToChannelNotificationHandler }
    }),
    withAuthStateReset<ChatState>(initalState), // Will call reset when the user logs out
    withHooks({
        onInit: (store, userStore = inject(UserStore), remoteConfig = inject(RemoteConfigService), authService = inject(AuthenticationService), chatService = inject(ChatClientService), channelService = inject(ChannelService)) => {
            // Set the chat availability and user chats when the user logs in.
            effect(() => {
                const user = userStore.user();
                const config = remoteConfig.configurations();

                untracked(() => {
                    if (!config) return;
                    const hidden = store.isHidden();
                    if (hidden) {
                        store.reset(initalState);
                    }
                    if (user && !hidden) {
                        store.setChatAvailability();
                        store.setUserChats();
                    }
                });
            });

            effect(() => {
                if (!authService.loggedIn() && store?.chatClient()) {
                    channelService?.reset();
                    chatService?.disconnectUser();
                }
            }, { allowSignalWrites: true });

            store.setMarkActiveAsRead(store.markActiveChannelAsRead);
            store.setTabActive(null);
            store.setTabActive(fromEvent(document, 'visibilitychange'));
        }
    })
)

