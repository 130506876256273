import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { exhaustMap, of, pipe } from "rxjs";
import { computed, effect, inject, untracked } from "@angular/core";
import { withActivePet } from "../features/active-pet/active-pet.feature";
import { consultationexternal, entities, shared } from "src/shared/services/client/client";
import { BookingDataService } from "src/app/services/booking.data.service/booking.data.service";
import { withUser } from "../features/user/user.feature";
import { chain, get } from "lodash";
import { tapResponse } from "@ngrx/operators";
import { ClientErrorMessage } from "src/shared/services/client/clienterror.interface";

type TreatmentPlansState = {
    treatmentPlans: entities.TreatmentPlanReadyList | null | undefined;
    unreadCount: number;
    pet_doc_id: string | null;
    pageSize: number;
    currentPage: number;
    orderBy: string;
    initialLoad: boolean;
    treatmentPlansLoading: boolean;
    treatmentPlanError: ClientErrorMessage | undefined;
}

const initialState: TreatmentPlansState = {
    treatmentPlans: null,
    unreadCount: 0,
    pet_doc_id: null,
    pageSize: 50,
    currentPage: 1,
    orderBy: 'desc',
    initialLoad: true,
    treatmentPlansLoading: false,
    treatmentPlanError: undefined
}


export const TreatmentPlansStore = signalStore(
    withState(initialState),
    withUser(),
    withActivePet(),
    withComputed(({ treatmentPlans }) => ({
        latestTreatmentPlan: computed(() => {
            return chain(treatmentPlans()?.treatment_plans)
                .sortBy(x => x.created_at)
                .last()
                .value();
        })
    })),
    withMethods((store, bookingService = inject(BookingDataService)) => {

        const getClientTimezone = () => {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        const setUnreadCount = rxMethod<void>(
            pipe(
                exhaustMap(() => {
                    const pet_doc_id = get(store.activePet(), 'doc_id', '');
                    const user_doc_id = get(store.user(), 'doc_id', '');
                    return bookingService.GetUnreadTreatmentPlansCountForPet(user_doc_id, pet_doc_id).pipe(
                        tapResponse({
                            next: (resp) => {
                                patchState(store, { unreadCount: resp.total_unread_treatment_plans });
                            },
                            error: (err) => {
                                console.error(err);
                                patchState(store, { unreadCount: 0 });
                            }
                        }));
                })
            )
        )

        const setTreatmentPlans = rxMethod<void>(
            pipe(
                exhaustMap(() => {
                    const pet_doc_id = get(store.activePet(), 'doc_id', '');
                    const user_doc_id = get(store.user(), 'doc_id', '');

                    if (!pet_doc_id || !user_doc_id) return of(null);

                    const params: consultationexternal.ListTreatmentPlansRequest = {
                        PetDocID: pet_doc_id,
                        OrderBy: store.orderBy(),
                        RequestedItemsPerPage: store.pageSize(),
                        RequestedPageNumber: store.currentPage(),
                        Timezone: getClientTimezone()
                    }

                    patchState(store, { treatmentPlansLoading: true, treatmentPlanError: undefined });
                    return bookingService.ListTreatmentPlansForPet(user_doc_id, pet_doc_id, params).pipe(
                        tapResponse({
                            next: (resp) => {
                                patchState(store, { treatmentPlans: resp, pet_doc_id, treatmentPlansLoading: false, initialLoad: false });

                            },
                            error: (err: ClientErrorMessage) => {
                                console.error(err);
                                patchState(store, { treatmentPlans: null, treatmentPlansLoading: false, treatmentPlanError: err });
                            }
                        })
                    )
                })
            )
        );

        const setPagination = (pageSize: number, currentPage: number, orderBy: string) => {
            patchState(store, { pageSize, currentPage, orderBy });
        }

        const resetPagination = () => {
            patchState(store, { pageSize: initialState.pageSize, currentPage: initialState.currentPage, orderBy: initialState.orderBy });
            setTreatmentPlans();
        }

        const concatNextPage = rxMethod<void>(
            pipe(
                exhaustMap(() => {
                    const pet_doc_id = get(store.activePet(), 'doc_id', '');
                    const user_doc_id = get(store.user(), 'doc_id', '');

                    if (!pet_doc_id || !user_doc_id) return of(null);

                    const params: consultationexternal.ListTreatmentPlansRequest = {
                        PetDocID: pet_doc_id,
                        OrderBy: store.orderBy(),
                        RequestedItemsPerPage: store.pageSize(),
                        RequestedPageNumber: store.currentPage(),
                        Timezone: getClientTimezone()
                    }

                    patchState(store, { treatmentPlansLoading: true });

                    return bookingService.ListTreatmentPlansForPet(user_doc_id, pet_doc_id, params).pipe(
                        tapResponse({
                            next: (resp) => {
                                const currentTreatmentPlans = store.treatmentPlans()?.treatment_plans || [];
                                const newTreatmentPlans = resp.treatment_plans || [];
                                patchState(store, {
                                    treatmentPlans: {
                                        ...resp,
                                        treatment_plans: [...currentTreatmentPlans, ...newTreatmentPlans]
                                    },
                                    treatmentPlansLoading: false
                                });
                            },
                            error: (err: ClientErrorMessage) => {
                                console.error(err);
                                patchState(store, { treatmentPlansLoading: false, treatmentPlanError: err });
                            }
                        }
                        ))
                })
            )
        );

        const loadMoreTreatmentPlans = () => {
            if (store.treatmentPlansLoading() || !hasMorePages(store.treatmentPlans()?.pagination)) return;
            const newPageNumber = getNextPage(store.treatmentPlans()?.pagination);
            patchState(store, { currentPage: newPageNumber });
            concatNextPage();
        }


        return { setTreatmentPlans, resetPagination, setUnreadCount, setPagination, loadMoreTreatmentPlans }
    }),
    withHooks({
        onInit(store) {
            effect(() => {
                const activePet = store.activePet();
                untracked(() => {
                    if (activePet !== null) {
                        store.resetPagination();
                        store.setUnreadCount();
                    }
                });
            }, { allowSignalWrites: true });
        }
    })
)

const getNextPage = (pagination?: shared.Pagination) => {
    if (!pagination) return 1; // default to 1

    const currentPage = pagination.current_page;
    const totalPages = pagination.pages;

    return currentPage < totalPages ? currentPage + 1 : currentPage;
}

const hasMorePages = (pagination?: shared.Pagination) => {
    if (!pagination) return false;
    return pagination.current_page < pagination.pages;
}