import { Routes } from '@angular/router';
import { AuthUserGuard } from 'src/shared/guards/authUser.guard';
import { DashboardRoutes } from './dashboard/dashboard-routing';
import { OnboardingRoutes } from './onboarding/onboarding-routes';
import { AccountRoutes } from './account/account-routes';
import { UserConsultationRoutes } from './userconsultation/userconsultation-routes';
import { BookingRoutes } from './booking/booking-routes';
import { SymptomCheckerRoutes } from '../symptomchecker/symptomchecker-routes';
import { VetCareRoutes } from './vet-care/vet-care-routing';
import { AuthenticatedContainerComponent } from './shared/components/authenticated-container/authenticated-container.component';
import { ChatRoutes } from './joii-chat/joii-chat-routes';

export const appRoutes: Routes = [
  { path: '', loadComponent: () => import('./home/pages/home/home.component').then(m => m.HomeComponent), canActivate: [AuthUserGuard] },
  { path: 'login', loadComponent: () => import('src/shared/components/authentication/login/login.component').then(m => m.LoginComponent) },
  { path: 'register', loadComponent: () => import('src/shared/components/authentication/register/register.component').then(m => m.RegisterComponent) },
  { path: 'support/contact-us', loadComponent: () => import('./account/pages/contactus/contactus.component').then(m => m.ContactUsComponent) }, // Add support routing module
  { path: 'forgottenpassword', loadComponent: () => import('src/shared/components/authentication/forgottenpassword/forgottenpassword.component').then(m => m.ForgottenPasswordComponent) },
  { path: 'tenants/:redirectUrl', loadComponent: () => import('src/shared/components/authentication/tenants/tenants.component').then(m => m.TenantsComponent) },
  { path: 'deeplink', loadComponent: () => import('src/shared/components/authentication/deeplink-handler/deeplink-handler.component').then(m => m.DeeplinkHandlerComponent) },
  {
    path: '', component: AuthenticatedContainerComponent, children: [
      ...DashboardRoutes,
      ...OnboardingRoutes,
      ...AccountRoutes,
      ...UserConsultationRoutes,
      ...BookingRoutes,
      ...SymptomCheckerRoutes,
      ...VetCareRoutes,
      ...ChatRoutes
    ]
  },
  { path: '**', redirectTo: "notfound" },
  { path: 'notfound', loadComponent: () => import('src/shared/components/notfound/notfound.component').then(m => m.NotFoundComponent) },
];
