<div
    class="h-[210px] px-4 pt-4 pb-6 bg-[#e9fae2] rounded-tl-2xl rounded-tr-2xl rounded-br-2xl border border-[#2a7407] flex-col justify-start items-start gap-2 inline-flex overflow-hidden">
    <div class="self-stretch h-[170px] flex-col justify-start items-start gap-6 flex">
        <div class="self-stretch h-[98px] flex-col justify-start items-start gap-4 flex">
            <div class="justify-start items-center gap-2 inline-flex">
                <div class="w-10 h-10 relative">
                    <img class="w-10 h-10 left-0 top-0 absolute rounded-full border border-[#2a7407]"
                        [src]="staffImage()" />
                    <div class="w-6 h-6 left-[8px] top-[8px] absolute"></div>
                </div>
                <div class="text-[#222222] text-base font-medium font-['Poppins'] leading-normal tracking-tight">Video
                    call in progress</div>
            </div>
            <div class="self-stretch text-[#222222] text-sm font-medium font-['Poppins'] leading-[21px] tracking-tight">
                {{staffName()}} is in a video call with you</div>
        </div>
        <button
            class="self-stretch px-6 py-3 bg-[#2a7407] rounded-[29px] justify-center items-center gap-2 inline-flex overflow-hidden"
            (click)="NavigateToCall()">
            <div class="text-white text-base font-semibold font-['Poppins'] leading-normal tracking-tight">Join video
                call</div>
        </button>
    </div>
</div>