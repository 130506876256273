import { accountexternal, entities } from "src/shared/services/client/client"
import { of, pipe, switchMap, tap } from 'rxjs';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals"
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { computed, effect, inject } from "@angular/core";
import { AccountDataService } from "../services/account.data.service/account.data.service";
import { PetsDataService } from "src/shared/services/pet/pets.data.service";
import { AuthenticationService } from "src/shared/services/auth/auth_service";
import { ClientErrorMessage } from "src/shared/services/client/clienterror.interface";
import { MixpanelService } from "src/shared/services/mix-panel.service/mix-panel.service";
import { environment } from "src/environments/environment";


type UserState = {
    user: accountexternal.GetUserResponse | null
    pets: entities.PetResponse[]
    activePet: entities.PetResponse | null
    loggedIn: boolean
    loading: boolean
    onboarding: boolean
    userError: ClientErrorMessage | undefined,
    petError: ClientErrorMessage | undefined,
    initalLoad: boolean
}

const initialState: UserState = {
    user: null,
    pets: [],
    activePet: null,
    loggedIn: false,
    loading: false,
    onboarding: false,
    userError: undefined,
    petError: undefined,
    initalLoad: true
}

export const UserStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withComputed(({ user }) => ({
        userID: computed(() => user()?.doc_id || null),
    })),
    withMethods((
        store,
        accountDataService = inject(AccountDataService),
        petsDataService = inject(PetsDataService)
    ) => {
        /**
         * Sets the active pet in local storage.
         * If the pet is not in the users pets list, the first pet in the list is set as the active pet.
         * @param pet The pet to set as active.
         */
        function setActivePet(pet: entities.PetResponse) {
            if (!pet) {
                localStorage.removeItem('activePetId');
                patchState(store, { activePet: null });
                return;
            }
            if (store.pets().findIndex(p => p.doc_id === pet.doc_id) === -1) {
                pet = store.pets()[0];
            }
            localStorage.setItem('activePetId', pet?.doc_id);
            patchState(store, { activePet: pet });
        }
        /**
         * Sets the active user in local storage.
         */
        function setActiveUser(user?: accountexternal.GetUserResponse) {
            if (!user) {
                localStorage.removeItem('userID');
                patchState(store, { user: null });
                return;
            }
            localStorage.setItem('userID', user.doc_id);
            patchState(store, { user });
        }
        /**
         * Refreshes the user data
         * Should only be called if the user has changed or the user data has changed.
         */
        const refreshUser = rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loading: true, userError: undefined })),
                switchMap(() => {
                    return accountDataService.GetMyUser().pipe(
                        tapResponse({
                            next: (user) => {
                                setActiveUser(user);
                                patchState(store, { loggedIn: true, onboarding: false });
                            },
                            error: (error: any) => {
                                if (error?.code === "failed_precondition") {
                                    patchState(store, { onboarding: true });
                                }
                                setActiveUser(undefined);
                                patchState(store, { userError: error });
                                console.error(error);
                            },
                            finalize: () => patchState(store, { loading: false, initalLoad: false })
                        })
                    )
                }
                )
            )
        );
        /**
         * Refreshes the pets list
         * Should only be called if the users pets have changed or the user has changed.
         */
        const refreshPets = rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loading: true, petError: undefined })),
                switchMap(() => {
                    const request = store.userID() ? petsDataService.listPets(`${store.userID()}`) : of({ pets: [] });
                    return request.pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { pets: response.pets });
                                const activePet = store.pets().find(pet => pet.doc_id === localStorage.getItem('activePetId')) || response.pets[0];
                                setActivePet(activePet);
                            },
                            error: (error: any) => {
                                patchState(store, { pets: [], petError: error });
                                console.error(error);
                            },
                            finalize: () => patchState(store, { loading: false, initalLoad: false })
                        })
                    );
                })
            )
        );
        /**
         * Sets the active user and pets
         * Should only be called when the user is logged in.
         */
        const setUserAndPets = rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loading: true })),
                switchMap(() => {
                    return accountDataService.GetMyUser().pipe(
                        tapResponse({
                            next: (user) => {
                                setActiveUser(user);
                                patchState(store, { loggedIn: true, onboarding: false });
                            },
                            error: (error: any) => {
                                if (error?.code === "failed_precondition") {
                                    patchState(store, { onboarding: true });
                                }
                                setActiveUser(undefined);
                                patchState(store, { userError: error });
                                console.error(error);
                            },
                            finalize: () => patchState(store, { loading: !store.onboarding() })
                        })
                    );
                }),
                switchMap((user) => {
                    const request = user?.doc_id ? petsDataService.listPets(user?.doc_id) : of({ pets: [] });
                    return request.pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { pets: response.pets });
                                const activePet = store.pets().find(pet => pet.doc_id === localStorage.getItem('activePetId')) || response.pets[0];
                                if (activePet) {
                                    setActivePet(activePet);
                                }
                            },
                            error: (error: any) => {
                                patchState(store, { pets: [], petError: error });
                                console.error(error);
                            },
                            finalize: () => patchState(store, { loading: false, initalLoad: false })
                        })
                    );
                }),
            ));
        return { setUserAndPets, setActivePet, refreshUser, refreshPets };
    }),
    withHooks({
        onInit: (store, auth = inject(AuthenticationService), mixPanelService = inject(MixpanelService)) => {
            /**
             * Reacts to if the user is logged in and sets the user and pets if they are.
             */
            effect(() => {
                const isLoggedIn = auth.loggedIn();
                if (isLoggedIn) {
                    store.setUserAndPets();
                } else {
                    patchState(store, initialState);
                }
            }, { allowSignalWrites: true });
            effect(() => {
                const isLoggedIn = auth.loggedIn();
                if (environment.mixPanel_enabled) {
                    let userId = store.user()?.doc_id;
                    if (isLoggedIn) {
                        if (userId) {
                            mixPanelService.identify(userId);
                        }
                    } else {
                        mixPanelService.logout();
                    }
                }
            })
        }
    }),
);

