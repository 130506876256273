@if (status().length > 0) {
<div class="w-full h-11 px-6 pb-3 bg-[#fcfcfc] justify-center items-center gap-2.5 inline-flex">
    <div class="w-8 h-8 relative">
        <div class="w-8 h-8 left-0 top-0 absolute bg-[#344d80] rounded-full"></div>
        <div data-svg-wrapper class="left-[6px] top-[9px] absolute">
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_12313_46727)">
                    <path
                        d="M16.3969 1.41827V0.120117H16.0491C14.7895 0.120117 13.7685 1.13402 13.7685 2.3847V7.36267C13.7078 9.02035 12.6811 10.4399 11.2331 11.0743C10.9862 11.1777 10.7308 11.2613 10.4695 11.322C10.3451 11.3509 10.2209 11.3738 10.0969 11.3916C9.93252 11.4134 9.76509 11.4258 9.59538 11.4283V11.4342C7.66776 11.4843 5.91385 10.2319 5.39481 8.30678C5.20833 7.61553 4.49366 7.20626 3.79671 7.39047C3.10071 7.57563 2.68738 8.28598 2.87385 8.97723C3.33614 10.6913 4.43576 12.1388 5.97023 13.0535C7.32681 13.862 8.89442 14.1757 10.4439 13.9599C13.7695 13.5183 16.3657 10.6875 16.3971 7.27132V1.41827H16.3969Z"
                        fill="white" />
                    <path
                        d="M6.61053 7.67318C6.52253 7.67318 6.44311 7.61417 6.42101 7.52585C6.15263 6.46069 5.24996 6.43913 5.21168 6.43876C5.10387 6.43762 5.01682 6.35025 5.01758 6.24358C5.01758 6.13653 5.10482 6.05029 5.21244 6.05029C5.6412 6.05029 6.52577 6.33909 6.80025 7.43185C6.82692 7.53606 6.76273 7.64159 6.65815 7.66731C6.64215 7.67129 6.62596 7.67318 6.61073 7.67318H6.61053Z"
                        fill="white" />
                    <path
                        d="M3.16245 12.8046C3.15254 12.8046 3.14264 12.8039 3.13254 12.8023C2.70911 12.7379 1.87883 12.3195 1.7733 11.198C1.7634 11.0911 1.84206 10.9964 1.94987 10.9864C2.06073 10.9754 2.15254 11.0548 2.16321 11.1619C2.26645 12.255 3.1554 12.4122 3.19292 12.4184C3.29921 12.4356 3.37178 12.5349 3.35502 12.6406C3.33978 12.7363 3.25711 12.8046 3.16245 12.8046Z"
                        fill="white" />
                    <path
                        d="M2.7469 14.1021C2.72709 14.1021 2.70709 14.0993 2.68728 14.0931C2.66519 14.0861 0.464235 13.3585 0.635473 10.6907C0.641568 10.584 0.73033 10.5029 0.842711 10.5091C0.950521 10.5159 1.03224 10.6082 1.02538 10.7155C0.874711 13.0739 2.72785 13.6976 2.80652 13.7231C2.90976 13.7562 2.96633 13.8653 2.93262 13.9675C2.90671 14.0497 2.82938 14.1023 2.7469 14.1023V14.1021Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_12313_46727">
                        <rect width="16" height="14.2222" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div class="text-center text-black text-[11px] font-light leading-none tracking-tight">Dr Joii {{status()}}</div>
</div>
}