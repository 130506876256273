<div
    class="h-[130px] p-4 bg-[#f6f6f6] rounded-tl-2xl rounded-tr-2xl rounded-br-2xl border border-[#d1d1d1] flex-col justify-start items-start gap-2 inline-flex overflow-hidden">
    <div class="self-stretch h-[98px] flex-col justify-start items-start gap-4 flex">
        <div class="justify-start items-center gap-2 inline-flex">
            <img class="w-10 h-10 rounded-full border border-[#d1d1d1]" [src]="staffImage()" />
            <div class="text-[#ca0909] text-base font-medium font-['Poppins'] leading-normal tracking-tight">Video call
                disrupted</div>
        </div>
        <div class="self-stretch text-[#222222] text-sm font-normal font-['Poppins'] leading-[21px] tracking-tight">
            Something went wrong with your video call from {{staffName()}}.</div>
    </div>
</div>