<div class="h-[72px] bg-[#212e4a] border-b border-[#e9e9eb] w-full flex justify-between items-center p-3 relative">
    <!-- Left-->
     <div class="flex gap-3 items-center">
        <lucide-angular name="chevron-left" class="cursor-pointer text-white" [size]="16" (click)="chatStore.viewChannelList()"></lucide-angular>
        @if (avatarSrc()) {
            @if (avatarSrc() && imageType()) {
                <div class="relative">
                    @switch (imageType()) {
                        @case ('svg') {
                            <div class="w-10 h-10 bg-primary rounded-full overflow-hidden flex items-center justify-center">
                                <svg-icon class="scale-125" src="{{avatarSrc()}}" />
                            </div>
                        }
                        @case ('image') {
                            <img class="w-10 h-10 rounded-full" [src]="avatarSrc()" alt="avatar" />
                        }
                    }
                    @if (!chatStore.staffChannelMember() || (chatStore.staffChannelMember() && this.isOnline())) {
                        <span class="w-[10px] h-[10px] rounded-full bg-[#20E070] border border-white absolute top-0 left-[30px]"></span>
                    }
                </div>
            }

        }
     </div>

    <div class="flex flex-col absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        @if (chatStore.staffChannelMember()) {
            <ng-container *ngTemplateOutlet="VetJoined"></ng-container>
        }
        @else {
            <ng-container *ngTemplateOutlet="WaitingForVet"></ng-container>
        }
     </div>

     <lucide-angular name="minus" color="white" class="cursor-pointer" [size]="24" (click)="chatStore.toggleChatWidget()"></lucide-angular>
</div>


<ng-template #VetJoined>
    <span class="text-center text-white text-base font-medium font-['Poppins'] leading-normal tracking-tight block">{{chatStore.staffChannelMember()?.user?.name}}</span>
    @if(this.isOnline() && !chatStore.activeChannel()?.data?.frozen) {
        <span class="text-white text-sm font-normal font-['Poppins'] leading-[21px] tracking-tight">Online Now</span>
    }
</ng-template>

<ng-template #WaitingForVet>
    <span class="text-center text-white text-base font-medium font-['Poppins'] leading-normal tracking-tight block">Joii</span>
    <span class="text-white text-sm font-normal font-['Poppins'] leading-[21px] tracking-tight">Waiting for a vet</span>
</ng-template>