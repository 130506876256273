import { effect, inject } from "@angular/core";
import { patchState, signalStoreFeature, withHooks, withState } from "@ngrx/signals";
import { accountexternal } from "src/shared/services/client/client";
import { UserStore } from "../../user.store";

export type userState = { user: accountexternal.GetUserResponse | null };

/**
 * @returns Signal store feature that provides the user state to another store.
 */
export function withUser<T>() {
    return signalStoreFeature(
        withState<userState>({ user: null }),
        withHooks({
            onInit(store, userStore = inject(UserStore)) {
                effect(() => {
                    const user = userStore.user();
                    patchState(store, { user });
                }, { allowSignalWrites: true });
            }
        })
    )
}