import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { LucideAngularModule } from 'lucide-angular';
import { derivedAsync } from 'ngxtension/derived-async';
import { filter, map, startWith } from 'rxjs';
import { ChatStore } from 'src/app/stores/chat/chat.store';
import { ChannelMemberResponse } from 'stream-chat';
import { ChatClientService, DefaultStreamChatGenerics } from 'stream-chat-angular';

/**
 * Chat header component
 * Override component for Stream Chat header.
 */
@Component({
  selector: 'app-chat-header',
  standalone: true,
  imports: [LucideAngularModule, SvgIconComponent, NgTemplateOutlet],
  templateUrl: './chat-header.component.html',
  styleUrl: './chat-header.component.scss'
})
export class ChatHeaderComponent {
  chatStore = inject(ChatStore);
  chatClientService = inject(ChatClientService);
  avatarSrc = computed(() => this.chatStore.staffProfileImage() || '/assets/svgs/joii-logo.svg');
  imageType = computed(() => {
    const url = this.avatarSrc();
    if (!url) return null;
    const cleanUrl = url.split('?')[0];
    const extension = cleanUrl.split('.').pop() || '';
    return extension === 'svg' ? 'svg' : this.supportedImageTypes.includes(extension) ? 'image' : null;
  });
  isOnline = derivedAsync(() => this.getOnlineStatus(this.chatStore.staffChannelMember()), { initialValue: false });
  private supportedImageTypes = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
  
 /**
  * Returns the online status of the user and updates it when it changes.
  * @param user 
  * @returns 
  */ 
  getOnlineStatus(user: ChannelMemberResponse<DefaultStreamChatGenerics> | null) {
    return this.chatClientService.events$.pipe(
      filter((e) => e.eventType === 'user.presence.changed'),
      map((event) => {
        if (event.event.user?.id === user?.user?.id) {
          return event.event.user?.online || false;
        }
        return user?.user?.online || false;
      }),
      startWith(user?.user?.online || false),
    )
  }

}
