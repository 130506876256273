import { Component, inject, computed, input } from '@angular/core';
import { ChatStore } from 'src/app/stores/chat/chat.store';
import { AIState } from 'stream-chat';

@Component({
  selector: 'app-ai-status',
  standalone: true,
  imports: [],
  templateUrl: './ai-status.component.html',
  styleUrl: './ai-status.component.scss'
})
export class AiStatusComponent {
  channelID = input<string>();
  ai_status = input<AIState | ''>();
  status = computed(() => this.getAIStatusText());

  getAIStatusText() {
    switch (this.ai_status()) {
      case 'AI_STATE_THINKING':
        return 'AI Thinking...';
      case 'AI_STATE_GENERATING':
        return 'AI Typing...';
      default:
        return ''
    }
  }
}
