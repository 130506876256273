import { Component, input } from '@angular/core';

@Component({
  selector: 'app-disrupted-call-message',
  standalone: true,
  imports: [],
  templateUrl: './disrupted-call-message.component.html',
  styleUrl: './disrupted-call-message.component.scss'
})
export class DisruptedCallMessageComponent {
  staffName = input<string>()
  staffImage = input<string>()
}
