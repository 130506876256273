<button class=" w-full inline-flex flex-col justify-start items-center bg-[#e1edf8] rounded-t-lg"
    (click)="openProduct()">
    <img class="w-full relative pr-1 pl-1 pt-1" [src]="productImage()" />
    <div class="w-full px-4 py-2 bg-[#344d80] flex flex-col justify-center items-center">
        <div
            class="w-full relative justify-start text-white text-sm text-left font-medium font-['Poppins'] leading-[21px] tracking-tight">
            {{productName()}}</div>
        <div
            class="w-full relative justify-start text-white text-xs text-left font-normal font-['Poppins'] leading-[18px] tracking-tight">
            {{priceDisplay()}}</div>
    </div>
</button>