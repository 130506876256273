import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from '../services/cookies/cookie_service';
import { AuthenticationService } from '../services/auth/auth_service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserGuard implements CanActivate {
  constructor(private cookieService: CookieService, private router: Router) {}

  canActivate(): boolean {
    if (this.cookieService.getCookie("loggedIn")) {
      this.router.navigate(['/vet-care']);
      return false;
    }
    return true;
  }
}