import { NgStyle } from '@angular/common';
import { Component, HostListener, input, OnInit, signal, inject, NgZone, ChangeDetectionStrategy } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

/**
 * Floating trigger for chat
 * This component is used to trigger the chat window to open
 **/
@Component({
  selector: 'app-chat-floating-trigger',
  standalone: true,
  imports: [SvgIconComponent, NgStyle],
  templateUrl: './chat-floating-trigger.component.html',
  styleUrl: './chat-floating-trigger.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatFloatingTriggerComponent implements OnInit {
  zone = inject(NgZone);
  unreadCount = input(0);
  buttonPosition = signal('1.5rem');
  /**
   * Sets the button position on init.
   */
  ngOnInit() {
    this.updateButtonPosition();
  }
  /**
   * Sets the button position when the window is resized.
   * Skips change detection to prevent performance issues.
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.zone.runOutsideAngular(() => this.updateButtonPosition()); // stop change detection triggering
  }
  /**
   * Updates the button position based on the window width and content width.
   * The button will be positioned on the right side of the content.
   * If the content width is less than the window width, the button will be positioned on the right side of the window.
   */
  updateButtonPosition() {
    const contentMaxWidth = 800;
    const padding = 24;
    const windowWidth = window.innerWidth;
    const buttonWidth = 72;

    if (windowWidth <= 640) { // Mobile view
      this.buttonPosition.set(`${padding}px`);
    } else {
      const contentWidth = Math.min(windowWidth, contentMaxWidth);
      const contentPadding = (windowWidth - contentWidth) / 2;
      const position = contentPadding > buttonWidth ? contentPadding - buttonWidth - padding : padding;
      this.buttonPosition.set(`${position}px`);
    }
  }
}
