import { patchState, signalStoreFeature, withMethods, withState } from "@ngrx/signals";
import { AIState, Event } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-angular";

type AiChatState = {
    ai_status: AIState | '';
}

export function withAiChatStatus<_>() {
    return signalStoreFeature(
        withState<AiChatState>({ ai_status: ''}),
        withMethods((store) => {
            function setAiStatus(event: Event<DefaultStreamChatGenerics>, activeChannelId: string | undefined) {
                if (activeChannelId !== event.channel_id) return;
                patchState(store, { ai_status: event.ai_state || ''});
            }
            return { setAiStatus };
        })
    )
}