import { effect, inject, untracked } from "@angular/core";
import { patchState, signalStoreFeature, type, withHooks, withMethods } from "@ngrx/signals";
import { AuthenticationService } from "src/shared/services/auth/auth_service";

/**
 * Signal store feature that provides a reset method that is called when the user logs out.
 * If an override method is not provided it will reset to the inputted state.
 */
export function withAuthStateReset<T>(
    resetState?: T
) {
    return signalStoreFeature(
        { 
            methods: type<Partial<{ reset(state?: T): void }>>()
        },
        withMethods((store) => ({
            reset(state?: T) {
                if (!store?.reset || !state) return;
                patchState(store, state);
            }
        })),
        withHooks({
            onInit(store, authService = inject(AuthenticationService)) {
                effect(() => {
                    const isLoggedIn = authService.loggedIn();
                    untracked(() => {
                        if (!isLoggedIn) {
                            store?.reset(resetState);
                        }
                    });
                });
            }
        })
    )
}