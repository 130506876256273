import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStateService } from '../symptomcheckersession/session-state.service';
import { scexternal } from 'src/shared/services/client/client';
import { toSignal } from '@angular/core/rxjs-interop';
import { OutcomeCTAEnum } from 'src/symptomchecker/models/outcome-cta.enum';
import { chain, compact, get, isBoolean } from 'lodash';
import { OutcomeCTAOption } from 'src/symptomchecker/models/outcome-cta-option.type';
import { MixpanelService } from 'src/shared/services/mix-panel.service/mix-panel.service';
import { take } from 'rxjs';

/**
 * Service that handles routing all symptom checker outcome cta links to the correct page / action.
 */
@Injectable()
export class CtaHandlerService {
  router = inject(Router);
  dialog = inject(Dialog);
  state = inject(SessionStateService)
  mixPanelService = inject(MixpanelService);
  outcome = toSignal<scexternal.Outcome | null | undefined>(this.state.outcome);

  triggerCTA(type: OutcomeCTAEnum | undefined | null, options: OutcomeCTAOption = { sessionID: '' }) {
    if (!type) return;
    this.trackCTAEvent(type, options);
    switch (type) {
      case OutcomeCTAEnum.Products:
        this.buyProducts(options);
        break;
      case OutcomeCTAEnum.VetCall:
        this.bookVetCall(options);
        break;
      case OutcomeCTAEnum.NurseCall:
        this.bookNurseCall(options);
        break;
      case OutcomeCTAEnum.StartAgain:
        this.restartSession();
        break;
      case OutcomeCTAEnum.Close:
        this.closeSession(options);
        break;
    }
  }

  /**
   * Call a vet CTA action - routes to page to book a vet call.
   * @param petID 
   * @param sessionID 
   */
  bookVetCall({ petID, sessionID }: OutcomeCTAOption = { petID: '', sessionID: '' }) {
    this.router.navigate([`/vetcall/advice/${petID || ''}`], { queryParams: { 'sc_session_id': sessionID } });
    this.dialog.closeAll();
  }

  /**
   * Nurse calls are currently not supported.
   * @param petID 
   * @param sessionID 
   */
  bookNurseCall({ petID, sessionID }: OutcomeCTAOption = { petID: '', sessionID: '' }) {
    this.router.navigate([`/vetcall/advice/${petID || ''}`], { queryParams: { 'sc_session_id': sessionID } });
    this.dialog.closeAll();
  }

  /**
   * Buy products CTA action - routes to page to buy products.
   * @param type 
   */
  buyProducts({ productSection }: { [key: string]: any } = { productSection: '' }) {
    const outcome = this.outcome();
    switch (productSection) {
      case 'wtdn':
        let wtdn_product_doc_ids = outcome?.wtdn_product_recommendations?.map((product) => product.doc_id) ?? [];
        let wtdn_doc_ids_string = wtdn_product_doc_ids.join(',');
        this.router.navigate(['/recommendations'], { queryParams: { product_doc_ids: wtdn_doc_ids_string } });
        break;
      case 'wyntk':
        let wyntk_product_doc_ids = outcome?.wyntk_product_recommendations?.map((product) => product.doc_id) ?? [];
        let wyntk_doc_ids_string = wyntk_product_doc_ids.join(',');
        this.router.navigate(['/recommendations'], { queryParams: { product_doc_ids: wyntk_doc_ids_string } });
        break;
      default:
        let product_doc_ids = outcome?.product_recommendations?.map((product) => product.doc_id) ?? [];
        let doc_ids_string = product_doc_ids.join(',');
        this.router.navigate(['/recommendations'], { queryParams: { product_doc_ids: doc_ids_string } });
        break;
    }
    this.dialog.closeAll();
  }

  /**
   * Closes the session and navigates to the home page.
   */
  closeSession({ resetSession }: { [key: string]: any } = { resetSession: true }) {
    if (!isBoolean(resetSession) || resetSession) {
      this.state.resetSession();
    }
    this.router.navigate(['/vet-care']);
    this.dialog.closeAll();
  }

  /** Restart Session */
  restartSession() {
    this.state.restartSession().pipe(take(1)).subscribe();
  }

  getAvailableCTAs(outcome: scexternal.Outcome | null | undefined): string[] {
    if (!outcome) return [];
    const primary_recommendation = outcome?.primary_recommendation?.type;
    const what_to_do_next = outcome?.what_to_do_next?.type;
    const what_you_need_to_know = outcome?.what_you_need_to_know?.type;
    const close = outcome?.outcome_type === 'trial' ? OutcomeCTAEnum.Close : undefined;
    // const mobility_history = outcome?.outcome_type === 'ai_gait' ? OutcomeCTAEnum.ViewMobilityHistory : undefined;
    return chain([primary_recommendation, what_to_do_next, what_you_need_to_know, close, 'start-again'])
      .compact()
      .uniq()
      .value();
  }

  // Track Mixpanel CTA events
  trackCTAEvent(type: OutcomeCTAEnum | undefined, options: OutcomeCTAOption) {
    if (!type) return;

    const sessionID = get(options, 'sessionID', '');
    const availableActions = this.getAvailableCTAs(this.outcome());
    //temp remove the ability 
    switch (type) {

    }
  }
}
